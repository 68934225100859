import React from 'react';
import styled from '@emotion/styled';
import { useParams } from 'react-router-dom';

import uspsLogo from 'assets/images/usps-logo.svg';
import upsLogo from 'assets/images/ups-logo.svg';
import { Button } from 'components/buttons';

import { ShippingEstimatesProps } from './types';
import { useCreateShippingLabel } from './hooks';

export const ShippingEstimates: React.FC<ShippingEstimatesProps> = ({
  rates,
  setLabelExists,
  setTrigger,
}) => {
  const { listingId } = useParams<{ listingId: string }>();
  const { mutate: createLabel } = useCreateShippingLabel(
    setLabelExists,
    setTrigger
  );

  const handleChooseEstimate = (
    objectId: string,
    shipment: string,
    provider: string
  ) => {
    if (listingId) {
      createLabel({ listingId, objectId, shipment, provider });
    }
  };

  return (
    <Container>
      {rates.map((rate) => {
        const carrierLogo =
          rate.provider.toLowerCase() === 'usps' ? uspsLogo : upsLogo;
        return (
          <Card key={rate.objectId}>
            <LogoContainer>
              <Logo
                src={carrierLogo}
                alt={`${rate.provider.toUpperCase()} logo`}
              />
            </LogoContainer>
            <Cost>${rate.amount}</Cost>
            <EstimatedDays>{`${rate.servicelevel.name}`}</EstimatedDays>
            <EstimatedDays>{`Estimated days: ${
              rate.estimatedDays || 'Unknown'
            }`}</EstimatedDays>
            <Button
              width={'140'}
              height={'36px'}
              onClick={() =>
                handleChooseEstimate(
                  rate.objectId,
                  rate.shipment,
                  rate.provider
                )
              }
            >
              Choose Label
            </Button>
          </Card>
        );
      })}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: flex-start;
  padding: 20px;
`;

const Card = styled.div`
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  width: 200px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 10px;
`;

const Cost = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const EstimatedDays = styled.div`
  font-size: 14px;
  color: #555;
  margin-bottom: 5px;
`;

const LogoContainer = styled.div`
  height: 80px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
`;

const Logo = styled.img`
  width: 100px;
  height: auto;
`;
