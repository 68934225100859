import styled from '@emotion/styled';
import { useEffect } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { AnimatePresence } from 'framer-motion';

import { ValidationErrorMessage } from 'components/validation';

import { findInputError, isFormInvalid } from './errors';

export const Dropdown = ({
  label,
  name,
  validation,
  choices,
  height,
  width,
  labelFontSize,
  existingChoice,
  defaultValue,
  mapping,
}: {
  label: string;
  name: string;
  validation: any;
  choices: string[];
  height?: string;
  width?: string;
  labelFontSize?: string;
  existingChoice?: string;
  defaultValue?: string;
  mapping?: { [key: string]: string };
}) => {
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext();
  const inputError = findInputError(errors, name);
  const isInvalid = isFormInvalid(inputError);

  useEffect(() => {
    // Set the form value once if existingChoice is provided
    if (existingChoice) {
      setValue(name, existingChoice, { shouldValidate: true });
    }
  }, [existingChoice, name, setValue]);

  return (
    <Container width={width}>
      <StyledLabel htmlFor={name} labelFontSize={labelFontSize}>
        {label}
      </StyledLabel>
      <Controller
        name={name}
        control={control}
        rules={validation}
        defaultValue={existingChoice || ''}
        render={({ field }) => (
          <StyledSelect {...field} value={field.value || ''} height={height}>
            <option value='' disabled>
              {defaultValue || 'Select option'}
            </option>
            {choices.map((internalID, index) => (
              <option key={index} value={internalID}>
                {mapping?.[internalID] || internalID}
              </option>
            ))}
          </StyledSelect>
        )}
      />

      <AnimatePresence mode='wait' initial={false}>
        {isInvalid && inputError && (
          <ValidationErrorMessage
            message={inputError.message}
            key={inputError.message}
          />
        )}
      </AnimatePresence>
      <AnimatePresence mode='wait' initial={false}></AnimatePresence>
    </Container>
  );
};

const StyledLabel = styled.label<{ labelFontSize?: string }>`
  font-size: ${(props) => props.labelFontSize || '14px'};
  font-weight: 500;
  text-transform: capitalize;
`;

const StyledSelect = styled.select<{ height?: string }>`
  height: ${(props) => props.height || '48px'};
  padding: 0 1.25rem;
  font-size: ${(props) => props.theme.fontSize.input};
  font-weight: 500;
  border: 1px solid ${(props) => props.theme.colors.border.primary};
  border-radius: ${(props) => props.theme.borderRadius.input};
  background: ${(props) => props.theme.colors.white};
  transition: border-color 0.3s ease;
  &:hover {
    box-shadow: 0 0 15px #eeeeee;
  }
  &:focus {
    border-color: ${(props) => props.theme.colors.border.focus};
    outline: none;
  }
`;

const Container = styled.div<{ width?: string }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: ${(props) => props.width || '100%'};
  gap: 8px;
`;
