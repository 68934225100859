import { useState } from 'react';

import {
  CreateListingForm,
  CreateListingPrereqBlock,
} from 'components/listingCreate';
import LoadingSpinner from 'components/loading';
import { SellerAgreementForm } from 'components/sellerAgreementForm';
import { useCheckSellerPrereqs } from 'hooks/user';

export const CreateListingPage = () => {
  const [refresh, setRefresh] = useState(false);
  const userId = localStorage.getItem('userId') || '';
  const { data: { userInfo } = {}, isLoading } = useCheckSellerPrereqs(userId);

  if (isLoading) return <LoadingSpinner />;

  // Check for Stripe account
  const stripe_missing = !userInfo?.stripe_account_id;
  if (stripe_missing)
    return <CreateListingPrereqBlock stripe_missing={stripe_missing} />;

  // Check for seller agreement
  if (!userInfo?.seller_agreement && !refresh) {
    return <SellerAgreementForm setRefresh={setRefresh} />;
  }

  return <CreateListingForm />;
};
