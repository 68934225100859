import LoadingSpinnerComponent from 'react-spinners-components';
import styled from '@emotion/styled';

import { theme } from 'styles';

const LoadingSpinner = () => {
  return (
    <CenteredContainer>
      {' '}
      <LoadingSpinnerComponent
        type={'Gear'}
        color={theme.colors.primary}
        size={'120px'}
      />
    </CenteredContainer>
  );
};

const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export default LoadingSpinner;
