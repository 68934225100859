import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Left, Right, Row, ItemBox } from './shared';
import { Star } from '@phosphor-icons/react';
import { StarRatingFormProps } from './types';

export const StarRatingForm: React.FC<StarRatingFormProps> = ({
  starRating,
  onChange,
  title,
}) => {
  const [rating, setRating] = useState<number>(starRating || 0);

  const handleStarClick = (index: number) => {
    setRating(index);
    onChange(index);
  };

  return (
    <ItemBox>
      <Row>
        <Left>
          <Column>
            <Description>{title}</Description>
          </Column>
        </Left>
        <Right>
          <StarContainer>
            {[1, 2, 3, 4, 5].map((value) => (
              <StarIcon
                key={value}
                weight={rating >= value ? 'fill' : 'light'}
                size={'30px'}
                onClick={() => handleStarClick(value)}
              />
            ))}
          </StarContainer>
        </Right>
      </Row>
    </ItemBox>
  );
};

const Column = styled.div`
  align-items: left;
  flex-direction: column;
`;

const Description = styled.p`
  font-size: 14px;
`;

const StarContainer = styled.div`
  display: flex;
  justify-content: left;
  align-items: left;
  gap: 20px;
`;

const StarIcon = styled(Star)`
  cursor: pointer;
  font-size: 24px;
  color: ${(props) => props.theme.colors.primary};
  &:hover {
    color: ${(props) => props.theme.colors.secondary};
  }
`;
