import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router';

import useAPI from 'api';
import { Toast } from 'components/toast';

import { OtherUserIdData } from './types';

export const useDoFindOrCreateConversation = () => {
  const navigate = useNavigate();
  const API = useAPI();
  return useMutation({
    mutationFn: async (data: OtherUserIdData) => {
      return await API<{ conversationId: string }>('findOrCreateConversation', {
        method: 'POST',
        body: JSON.stringify(data),
      });
    },
    onSuccess: (apiRes) => {
      navigate(`/myAccount/Messages/${apiRes.conversationId}`);
    },
    onError: (error: any) => {
      console.log(error);
      const message = error.message || 'Something went wrong. Please try .';
      Toast.error(message);
    },
  });
};
