import React from 'react';
import { SearchResults } from 'components/searchResults';
import { UserInfoProps } from './types';

const UserListings: React.FC<UserInfoProps> = (props) => {
  return (
    <>
      <SearchResults userId={props.userInfo.user_id}></SearchResults>
    </>
  );
};

export default UserListings;
