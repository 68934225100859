import { useParams } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from '@stripe/react-stripe-js';
import { useStripeCheckout } from './hooks';

const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY || '';
const stripePromise = loadStripe(stripePublicKey);

export const Stripe = () => {
  const { listingId } = useParams<{ listingId: string }>();
  const { fetchClientSecret, captureOrder } = useStripeCheckout();

  const fetchSecret = async (): Promise<string> => {
    if (!listingId) {
      throw new Error('Listing ID is required');
    }
    const result = await fetchClientSecret.mutateAsync(listingId);
    if (!result || !result.clientSecret) {
      throw new Error('Failed to fetch client secret');
    }
    return result.clientSecret; // Always return a string
  };

  const onComplete = async () => {
    if (!listingId) {
      throw new Error('Listing ID is required for completion');
    }
    const result = await fetchClientSecret.mutateAsync(listingId);
    if (!result || !result.sessionId) {
      throw new Error('Failed to fetch session ID');
    }
    await captureOrder.mutateAsync(result.sessionId); // Trigger order capture
  };

  const options = {
    fetchClientSecret: fetchSecret, // Now guarantees a string return type
    onComplete,
  };

  return (
    <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
      <EmbeddedCheckout />
    </EmbeddedCheckoutProvider>
  );
};
