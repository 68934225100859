import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';

import { UserMenu } from 'components/menu';
import { useTopnav } from 'components/topnavContext';
import { LoginButton } from 'components/buttons';

import { useNumUnreadMsg } from './hooks';
import MessageIcon from './MessagesIcon';

export const ProfileSection = () => {
  const { unreadMsgCount, setUnreadMsgCount, profilePicUrl, setProfilePicUrl } =
    useTopnav();
  const navigate = useNavigate();
  const loggedInUserId = localStorage.getItem('userId') || '';
  const { data: unreadMsgData } = useNumUnreadMsg(loggedInUserId);

  useEffect(() => {
    if (unreadMsgData) {
      setUnreadMsgCount(unreadMsgData.numUnread);
    } else {
      setUnreadMsgCount(0);
    }
  }, [unreadMsgData, setUnreadMsgCount]);

  useEffect(() => {
    if (unreadMsgData?.profile_pic_signed_s3_url) {
      setProfilePicUrl(unreadMsgData.profile_pic_signed_s3_url);
    }
  }, [unreadMsgData, setProfilePicUrl]);

  const navigateToLogin = () => {
    navigate('/login');
  };

  return (
    <>
      {loggedInUserId ? (
        <ProfileOrLogin>
          <LoggedInComponents>
            <MessageIcon numUnreadMsg={unreadMsgCount}></MessageIcon>
            <UserMenu profilePicUrl={profilePicUrl} />
          </LoggedInComponents>
        </ProfileOrLogin>
      ) : (
        <LoginButton onClick={navigateToLogin}>Sign in</LoginButton>
      )}
    </>
  );
};

const LoggedInComponents = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  @media (max-width: ${(props) => props.theme.minViewSize}) {
    margin-right: 0px;
  }
`;

const ProfileOrLogin = styled.div`
  align-items: center;
`;
