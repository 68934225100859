import styled from '@emotion/styled';
import { Routes, Route } from 'react-router-dom';

import {
  BuyerProtections,
  BuyerResources,
  CommunityGuidelines,
  HelpMain,
  HowToBuy,
  HowToSell,
  LeavingSellerFeedback,
  Payouts,
  ReceivingYourOrder,
  ReturnsAndRefunds,
  ReturnsAndRefundsPolicy,
  SellerFees,
  SellerProtections,
  SellerResources,
  SellerTiers,
  ShippingPolicy,
  TaxForms,
} from 'components/help';

export const HelpPage = () => {
  return (
    <PageContainer>
      <Routes>
        <Route path='/' element={<HelpMain />} />
        <Route path='/buyerResources' element={<BuyerResources />} />
        <Route
          path='/buyerResources/buyerProtections'
          element={<BuyerProtections />}
        />
        <Route path='/buyerResources/howToBuy' element={<HowToBuy />} />
        <Route
          path='/buyerResources/leavingFeedback'
          element={<LeavingSellerFeedback />}
        />
        <Route
          path='/buyerResources/receivingYourOrder'
          element={<ReceivingYourOrder />}
        />
        <Route
          path='/buyerResources/returnsAndRefunds'
          element={<ReturnsAndRefunds />}
        />
        <Route path='/sellerResources' element={<SellerResources />} />
        <Route path='/sellerResources/howToSell' element={<HowToSell />} />
        <Route path='/sellerResources/payouts' element={<Payouts />} />
        <Route path='/sellerResources/sellerFees' element={<SellerFees />} />
        <Route path='/sellerResources/sellerTiers' element={<SellerTiers />} />
        <Route path='/sellerResources/taxForms' element={<TaxForms />} />
        <Route
          path='/sellerResources/sellerProtections'
          element={<SellerProtections />}
        />
        <Route
          path='/sellerResources/shippingPolicy'
          element={<ShippingPolicy />}
        />
        <Route path='/communityGuidelines' element={<CommunityGuidelines />} />
        <Route
          path='/returnsAndRefundsPolicy'
          element={<ReturnsAndRefundsPolicy />}
        />
      </Routes>
    </PageContainer>
  );
};

const PageContainer = styled.div`
  max-width: 800px;
  margin: 0px auto;
  margin-bottom: 50px;
`;
