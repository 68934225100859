import { Input } from 'components/input';
import { descriptionValidation } from 'components/validation';

import {
  HeaderContainer,
  HeaderSubtitle,
  HeaderSubsubtitle,
  HeaderTitle,
  Section,
} from './shared';

export const DescriptionComponent = () => {
  return (
    <Section>
      <HeaderContainer>
        <HeaderTitle>Description</HeaderTitle>
        <HeaderSubtitle>
          Provide a detailed description of your offering.
        </HeaderSubtitle>
        <HeaderSubsubtitle>
          Include condition and/or grading information.
        </HeaderSubsubtitle>
      </HeaderContainer>
      <Input {...descriptionValidation} label='' width={'100%'} lines={6} />{' '}
    </Section>
  );
};
