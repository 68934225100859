import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';

import { LogoProps } from 'types';
import { useAuth } from 'components/auth';
import { useTopnav } from 'components/topnavContext';
import { TopNavLogo } from 'assets/images';

import { useNumUnreadMsg } from './hooks';
import { ProfileSection } from './profileSection';
import { SearchBar } from './searchBar';

const sideWidth = '260px';
const logoHeight = '60px';

const TopNav = () => {
  const { isAuthLoading } = useAuth();
  const { setUnreadMsgCount, setProfilePicUrl } = useTopnav();
  const navigate = useNavigate();
  const loggedInUserId = localStorage.getItem('userId') || '';
  const { data: unreadMsgData } = useNumUnreadMsg(loggedInUserId);

  useEffect(() => {
    if (unreadMsgData) {
      setUnreadMsgCount(unreadMsgData.numUnread);
    } else {
      setUnreadMsgCount(0);
    }
  }, [unreadMsgData, setUnreadMsgCount]);

  useEffect(() => {
    if (unreadMsgData?.profile_pic_signed_s3_url) {
      setProfilePicUrl(unreadMsgData.profile_pic_signed_s3_url);
    }
  }, [unreadMsgData, setProfilePicUrl]);

  if (isAuthLoading) {
    return null;
  }

  const navigateHome = () => {
    navigate('/');
  };

  return (
    <>
      <TopNavContainer>
        <LeftAndRight>
          <Logo height={logoHeight} onClick={navigateHome} />
          <ProfileSection />
        </LeftAndRight>
        <Left>
          <Logo height={logoHeight} onClick={navigateHome} />
        </Left>
        <Middle>
          <SearchBar />
        </Middle>
        <Right>
          <ProfileSection />
        </Right>
      </TopNavContainer>
    </>
  );
};

const LeftAndRight = styled.div`
  display: none;
  @media (max-width: ${(props) => props.theme.minViewSize}) {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    order: 1;
  }
`;

const TopNavContainer = styled.div`
  padding: 0;
  width: 100%;
  min-height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.colors.border.primary};
  position: relative;
  z-index: 1000;
  background-color: ${(props) => props.theme.colors.white};
  @media (max-width: ${(props) => props.theme.minViewSize}) {
    flex-direction: column;
    padding: 10px;
  }
`;

const Left = styled.div`
  width: ${sideWidth};
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: ${(props) => props.theme.minViewSize}) {
    display: none;
  }
`;

const Right = styled.div`
  width: ${sideWidth};
  align-items: center;
  justify-content: center;
  display: flex;
  @media (max-width: ${(props) => props.theme.minViewSize}) {
    display: none;
  }
`;

const Middle = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  margin: 5px 0 5px 0;
  align-items: center;
  padding: 0px 0px;
  position: relative;
  @media (max-width: ${(props) => props.theme.minViewSize}) {
    width: 100%;
    order: 2;
  }
`;

const Logo = styled.div<LogoProps>`
  background-image: url(${TopNavLogo});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: ${({ height }) => height};
  aspect-ratio: 3 / 1;
  cursor: pointer;
`;

export default TopNav;
