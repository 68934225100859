import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { MagnifyingGlass } from '@phosphor-icons/react';

import { useTopnav } from 'components/topnavContext';
import { useNumUnreadMsg } from './hooks';
import { CATEGORIES } from 'config';

interface CustomDropdownProps {
  categories: string[];
  selectedCategory: string;
  onCategoryChange: (uuid: string) => void;
  height?: string;
  width?: string;
  setIsFocused: (focused: boolean) => void;
  isFocused: boolean;
}

export const SearchBar: React.FC = () => {
  const { setUnreadMsgCount, setSearchQuery, setProfilePicUrl } = useTopnav();
  const [searchInput, setSearchInput] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const navigate = useNavigate();
  const loggedInUserId = localStorage.getItem('userId') || '';
  const { data: unreadMsgData } = useNumUnreadMsg(loggedInUserId);

  useEffect(() => {
    if (unreadMsgData) {
      setUnreadMsgCount(unreadMsgData.numUnread);
    } else {
      setUnreadMsgCount(0);
    }
  }, [unreadMsgData, setUnreadMsgCount]);

  useEffect(() => {
    if (unreadMsgData?.profile_pic_signed_s3_url) {
      setProfilePicUrl(unreadMsgData.profile_pic_signed_s3_url);
    }
  }, [unreadMsgData, setProfilePicUrl]);

  const handleSearch = (e: React.KeyboardEvent | React.MouseEvent) => {
    e.preventDefault();
    setSearchQuery(searchInput);
    let urlSuffix = '/search?q=' + encodeURIComponent(searchInput);
    if (selectedCategory) {
      urlSuffix += `&category=${encodeURIComponent(selectedCategory)}`;
    }
    urlSuffix += '&page=1';
    navigate(urlSuffix);
  };

  const handleCategoryChange = (uuid: string) => {
    setSelectedCategory(uuid);
  };

  return (
    <SearchBarContainer>
      <SearchInputContainer isFocused={isFocused}>
        {' '}
        <SearchInput
          type='text'
          placeholder='Search collectibles'
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          onKeyDown={(e) => e.key === 'Enter' && handleSearch(e)}
        />
        <SearchIcon onClick={(e) => handleSearch(e)}>
          <MagnifyingGlass size={18} />
        </SearchIcon>
        <DropdownContainer>
          <CustomDropdown
            categories={CATEGORIES}
            selectedCategory={selectedCategory}
            onCategoryChange={handleCategoryChange}
            height='40px'
            width='140px'
            setIsFocused={setIsFocused}
            isFocused={isFocused}
          />
        </DropdownContainer>
      </SearchInputContainer>
    </SearchBarContainer>
  );
};

const CustomDropdown: React.FC<CustomDropdownProps> = ({
  categories,
  selectedCategory,
  onCategoryChange,
  height = '40px',
  width = '80px',
  setIsFocused,
  isFocused,
}) => {
  return (
    <Dropdown
      value={selectedCategory}
      onChange={(e) => onCategoryChange(e.target.value)}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      height={height}
      width={width}
      isFocused={isFocused}
    >
      <option value=''> All Categories</option>
      {categories.map((category, index) => (
        <option key={index} value={category}>
          {' '}
          {category}
        </option>
      ))}
    </Dropdown>
  );
};

const Dropdown = styled.select<{
  height: string;
  width: string;
  isFocused: boolean;
}>`
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  padding: 5px;
  border: 1px solid
    ${(props) =>
      props.isFocused
        ? props.theme.colors.border.focus
        : props.theme.colors.border.primary};
  border-radius: 0 ${(props) => props.theme.borderRadius.search}
    ${(props) => props.theme.borderRadius.search} 0;
  background: ${(props) => props.theme.colors.white};
  font-size: 14px;
  &:focus {
    border-color: ${(props) => props.theme.colors.border.focus};
    outline: none;
  }
`;

const SearchBarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
  flex-grow: 1;
`;

const SearchInputContainer = styled.div<{ isFocused: boolean }>`
  display: flex;
  position: relative;
  width: 100%;
  border: 1px solid
    ${(props) =>
      props.isFocused
        ? props.theme.colors.border.focus
        : props.theme.colors.border.primary};
  border-radius: ${(props) => props.theme.borderRadius.search};
`;

const SearchInput = styled.input`
  height: 38px;
  width: calc(100% - 140px); /* Adjust width to leave space for dropdown */
  padding-left: 35px;
  border: none;
  border-radius: inherit;
  background: ${(props) => props.theme.colors.white};
  transition: border-color 0.15s ease;
  ::placeholder {
    opacity: 0.7;
    color: ${(props) => props.theme.colors.text.placeholder};
  }
  &:focus {
    outline: none;
  }
`;

const SearchIcon = styled.div`
  position: absolute;
  left: 10px; /* Adjust to fit within the search input container */
  top: 55%;
  transform: translateY(-50%);
  cursor: pointer;
`;

const DropdownContainer = styled.div`
  position: absolute;
  right: -1px;
  top: -1px;
`;

export default SearchBar;
