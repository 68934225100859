export enum OrderStatuses {
  OrderCancelled = 'ORDER_CANCELLED',
  OrderCaptured = 'ORDER_CAPTURED',
  OrderCreated = 'ORDER_CREATED',
  Received = 'RECEIVED',
  Refunded = 'REFUNDED',
  Returning = 'RETURNING',
  Shipped = 'SHIPPED',
  Settled = 'SETTLED',
}

export interface OrderRecord {
  id: string;
  listing_id: string;
  feedback_id: string | null;
  buyer_id: string;
  seller_id: string;
  status: string;
  extension: string;
  listing_title: string;
  price: string;
  tax_amount: string;
  shipping_cost: string;
  full_name: string;
  address_1: string;
  address_2?: string;
  city: string;
  state: string;
  zip: string;
  returns: 'no_returns' | '14_days' | '30_days';
  seller_username: string;
  s3_prefix: string;
  signedS3Url: string;
  createdAt: string;
  captured_at: string;
}
