import { useQuery } from '@tanstack/react-query';

import useAPI from 'api';

import { ListingRecord } from './types';

interface SearchListingsParams {
  userId?: string;
  searchQuery: string;
  searchCategory: string;
  currentPage: number;
}

interface SearchResults {
  listings: ListingRecord[];
  totalPages: number;
  listingCount: number;
}

export const useSearchListings = ({
  userId,
  searchQuery,
  searchCategory,
  currentPage,
}: SearchListingsParams) => {
  const API = useAPI();

  const fetchListings = async () => {
    let paramsRaw;
    if (userId) {
      paramsRaw = {
        userId: userId,
        page: currentPage.toString(),
      };
    } else {
      paramsRaw = {
        q: searchQuery,
        category: searchCategory,
        page: currentPage.toString(),
      };
    }
    const params = new URLSearchParams(paramsRaw);
    const url = `searchListings?${params.toString()}`;
    return await API<SearchResults>(url.toString(), {
      method: 'GET',
    });
  };

  return useQuery({
    queryKey: [
      'searchListings',
      { userId, searchQuery, searchCategory, currentPage },
    ],
    queryFn: fetchListings,
  });
};
