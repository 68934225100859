import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';

import { Button } from 'components/buttons';
import { Container, Form, Row, Header } from 'components/myAccount';
import {
  phoneCodeValidation,
  phoneMask,
  phoneValidation,
} from 'components/validation';
import { Input } from 'components/input';
import {
  useDoCodeVerifyAndUpdate,
  useDoSendPhoneVerify,
} from 'hooks/verification';

import { PersonalFormProps } from './types';

export const PhoneForm: React.FC<PersonalFormProps> = ({ data }) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [codeErrorMsg, setCodeErrorMsg] = useState('');
  const { mutate: verifyPhone } = useDoSendPhoneVerify(setIsSubmitted);
  const { mutate: verifyCode } = useDoCodeVerifyAndUpdate(
    setCodeErrorMsg,
    setIsSubmitted
  );
  const methods = useForm<{ phone: string; phoneCode: string }>({
    defaultValues: {
      phone: '',
      phoneCode: '',
    },
  });

  useEffect(() => {
    methods.reset({
      phone: data.phone || '',
      phoneCode: '',
    });
  }, [data, methods]);

  const handleVerifyPhone = methods.handleSubmit((formData) => {
    verifyPhone({ phone: formData.phone, email: data.email });
    methods.reset(formData);
  });

  const handleVerifyPhoneCode = methods.handleSubmit((formData) => {
    const accountData = {
      user_id: data.user_id || '',
      username: data.username || '',
      first_name: data.first_name || '',
      middle_name: data.middle_name || '',
      last_name: data.last_name || '',
      address_1: data.address_1 || '',
      address_2: data.address_2 || '',
      city: data.city || '',
      state: data.state || '',
      zip: data.zip || '',
      email: data.email,
      phone: formData.phone,
      createdAt: data.createdAt,
    };
    const codeData = {
      code: formData.phoneCode,
      type: 'phone',
      value: formData.phone,
    };
    verifyCode(
      { codeData, accountData },
      {
        onSuccess: () => {
          methods.reset({
            phone: formData.phone,
            phoneCode: '',
          });
        },
      }
    );
  });

  return (
    <Container>
      <Header>Phone</Header>
      <Content>
        <FormProvider {...methods}>
          <Form onSubmit={(e) => e.preventDefault()} autoComplete='off'>
            {isSubmitted ? (
              <>
                <>
                  <Row>
                    <InputWrapper>
                      <Input {...phoneCodeValidation} width={'360px'} />
                    </InputWrapper>
                  </Row>
                  <VerificationMessage>
                    We've texted you a security code. Please enter it here.
                  </VerificationMessage>
                  <Button width={'240px'} onClick={handleVerifyPhoneCode}>
                    Verify
                  </Button>
                  {codeErrorMsg && (
                    <ValidationMessage>{codeErrorMsg}</ValidationMessage>
                  )}
                </>
              </>
            ) : (
              <>
                <Row>
                  <InputWrapper>
                    <Input
                      {...phoneValidation}
                      width={'360px'}
                      inputMask={phoneMask}
                    />
                  </InputWrapper>
                </Row>
                {methods.formState.isDirty ? (
                  <VerificationMessage>
                    We will text you a security code to verify this address. By
                    clicking this button, you agree to receive a security code
                    via SMS. Standard rates may apply.
                  </VerificationMessage>
                ) : (
                  <></>
                )}
                <Button
                  width={'240px'}
                  onClick={handleVerifyPhone}
                  disabled={!methods.formState.isDirty}
                >
                  Verify Phone
                </Button>
              </>
            )}
          </Form>
        </FormProvider>
      </Content>
    </Container>
  );
};

const VerificationMessage = styled.div`
  width: 400px;
  color: grey;
  margin: 10px 0;
  font-size: 12px;
`;

const ValidationMessage = styled.div`
  color: red;
  margin: 10px 0;
  font-size: 12pxem;
`;

const Content = styled.div`
  padding: 30px;
  border: 1px solid ${(props) => props.theme.colors.border.primary};
`;

const InputWrapper = styled.div`
  flex: 1;
  &:not(:last-child) {
    margin-right: 20px;
  }
`;
