import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';

import { RETURN_POLICY_MAP } from 'config';
import { Button } from 'components/buttons';
import { ProfilePicture } from 'components/profilePic';
import { useDoFindOrCreateConversation } from 'hooks/messaging';
import { formatNumber, formatShippingCost } from 'utils';

import { ListingRecordProps } from './types';

export const ListingInfo: React.FC<ListingRecordProps> = (props) => {
  const { mutate: doFindOrCreateConvo } = useDoFindOrCreateConversation();
  const navigate = useNavigate();
  const userId = localStorage.getItem('userId');
  const listing = props.listing;

  const handleCLickOnUser = (id: string) => {
    navigate(`/user/${id}`);
  };

  const handleClickBuy = (listingId: string) => {
    navigate(`/checkout/${listingId}`);
  };

  const handleContactSeller = () => {
    doFindOrCreateConvo({ otherUserId: listing.owner_id });
  };

  return (
    <>
      <ColumnContainer>
        <HeaderTitle>{listing.title}</HeaderTitle>
        <SellerInfo>
          <ProfilePicture
            profilePicUrl={listing.profile_pic_signed_s3_url}
            size={'small'}
            iconStatus={'static'}
          />
          <SellerDescription
            onClick={() => handleCLickOnUser(listing.owner_id)}
          >
            {listing.username} ({listing.items_bought}
            {listing.items_sold + listing.items_bought})
          </SellerDescription>
          {listing.owner_id !== userId && (
            <ContactSeller onClick={() => handleContactSeller()}>
              Contact Seller
            </ContactSeller>
          )}
        </SellerInfo>
        <PriceInfo>
          <PriceText>Price:</PriceText>
          <PriceValue>${formatNumber(listing.price)}</PriceValue>
        </PriceInfo>
        <ShippingText>
          Shipping: {formatShippingCost(listing.shipping_cost)}
        </ShippingText>
        <ListingDescription>{listing.description}</ListingDescription>
        <ReturnText>{RETURN_POLICY_MAP[listing.returns]}</ReturnText>
        <Button
          onClick={() => handleClickBuy(listing.id)}
          disabled={listing.owner_id === userId || !listing.isActive}
          width={'240px'}
        >
          Buy now
        </Button>
      </ColumnContainer>
    </>
  );
};

const ReturnText = styled.div`
  font-size: 16px;
  color: #777;
`;

const PriceInfo = styled.div`
  // margin-top: 10px;
  display: flex;
  align-items: flex-end;
`;

const PriceValue = styled.div`
  font-size: 22px;
  font-weight: bold;
`;

const PriceText = styled.div`
  font-size: 18px;
  margin-right: 10px;
`;

const SellerInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;

const ContactSeller = styled.div`
  font-size: 0.75rem;
  font-weight: bold;
  color: black;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const SellerDescription = styled.div`
  font-size: 16px;
  color: blue;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const ListingDescription = styled.div`
  font-size: 16px;
  max-width: 500px;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;
  word-break: break-word;
  white-space: pre-wrap;
`;

const ShippingText = styled.div`
  font-size: 18px;
  color: #666;
`;

const HeaderTitle = styled.h2`
  font-size: 1.5em;
  color: #333;
  margin-top: 0;
  word-break: break-word;
`;

const ColumnContainer = styled.div`
  padding: 0 20px;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  box-sizing: border-box;
`;
