import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';

import { Button } from 'components/buttons';
import { BlueLinkComponent, GreyLinkComponent } from 'components/links';
import { OrderStatus } from 'components/orderStatus';
import { OrderRecord } from 'types';
import { formattedDate } from 'utils';

interface ListingCardOrderProps {
  order: OrderRecord;
}

export const ListingCardOrder = ({ order }: ListingCardOrderProps) => {
  const navigate = useNavigate();
  const purchaseDate = formattedDate(order.createdAt);

  const styling = { fontSize: '14px', height: '40px' };

  return (
    <ItemBox>
      <Row>
        <ImageContainer>
          <Image src={order.signedS3Url} alt='Product Image' />
        </ImageContainer>
        <ItemInfoContainer>
          <Title>{order.listing_title}</Title>
          <OrderStatus status={order.status} isActive={false} size={'small'} />
          <Purchased>Purchased on {purchaseDate}</Purchased>
          <SoldByContainer>
            <SoldBy>Sold by</SoldBy>
            <BlueLinkComponent
              fontSize={'12px'}
              text={order.seller_username}
              onClick={() => navigate(`/user/${order.seller_id}`)}
            />
          </SoldByContainer>
          <GreyLinkComponent
            text={'View original listing'}
            onClick={() => navigate(`/listing/${order.listing_id}`)}
          />
        </ItemInfoContainer>
        <ButtonContainer>
          <Button
            {...styling}
            variant={'secondary'}
            onClick={() => navigate(`/myAccount/Purchases/${order.listing_id}`)}
          >
            Order details
          </Button>
          {order.feedback_id ? (
            <Button {...styling} variant={'primary'} disabled={true}>
              Feedback submitted
            </Button>
          ) : (
            <Button
              {...styling}
              variant={'primary'}
              onClick={() =>
                navigate(`/feedback/${order.listing_id}/forSeller`)
              }
            >
              Leave feedback
            </Button>
          )}
          <Button
            {...styling}
            variant={'secondary'}
            onClick={() =>
              navigate(`/user/${order.seller_id}?tab=CurrentListings`)
            }
          >
            View seller's items
          </Button>
        </ButtonContainer>
      </Row>
    </ItemBox>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  width: 200px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: auto;
  gap: 10px;
`;

const SoldByContainer = styled.div`
  display: flex;
  align-items: center;
`;

const SoldBy = styled.div`
  font-size: 14px;
  display: flex;
  margin-right: 5px;
`;

const Title = styled.h2`
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  margin: 0;
  overflow-wrap: break-word;
`;

const Row = styled.div`
  display: flex;
  align-items: top;
  flex-direction: row;
`;

const ImageContainer = styled.div`
  width: 180px;
  height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Image = styled.img`
  max-width: 180px;
  max-height: 180px;
  object-fit: cover;
`;

const ItemInfoContainer = styled.div`
  flex-direction: column;
  display: flex;
  align-items: left;
  text-align: left;
  margin-left: 30px;
  gap: 10px;
`;

const Purchased = styled.div`
  text-align: left;
  font-size: 14px;
`;

const ItemBox = styled.div`
  flex: 1;
  align-self: flex-start;
  width: 100%;
  padding: 16px;
  height auto;
  border: 1px solid ${(props) => props.theme.colors.border.primary};
`;
