import styled from '@emotion/styled';

import LoadingSpinner from 'components/loading';

import { useGetRecentListings } from './hooks';
import { RowListings } from './rowListings';

interface RecentListingsProps {
  category: string | null;
  topN: number;
  title: string;
}

export const RecentListings = ({
  category,
  topN,
  title,
}: RecentListingsProps) => {
  const { data, isLoading } = useGetRecentListings({
    category,
    topN,
  });

  if (isLoading)
    return (
      <Container>
        <LoadingSpinner />
      </Container>
    );

  return (
    <>
      {data?.listings && (
        <Container>
          <Title>{title}</Title>
          <RowListings listings={data.listings} />
        </Container>
      )}
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 900px;
`;

const Title = styled.h3`
  align-self: flex-start;
`;
