import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { MdError } from 'react-icons/md';

export const ValidationErrorMessage = ({
  message,
}: {
  message: string | undefined;
}) => {
  return (
    <motion.p {...framer_error}>
      <ErrorMessage>
        <MdError />
        {message}
      </ErrorMessage>
    </motion.p>
  );
};

const framer_error = {
  initial: { opacity: 0, y: 10 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 10 },
  transition: { duration: 0.2 },
};

const ErrorMessage = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  font-weight: 400;
  color: #ef4444;
  border-radius: 0.375rem;
`;
