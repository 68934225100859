import React from 'react';
import styled from '@emotion/styled';
import Pluralize from 'pluralize';

import { formattedDate } from 'utils';

import { FeedbackProps } from './types';

const feedbackIconText = (rating: string) => {
  const text = rating === 'positive' ? '+' : rating === 'negative' ? '-' : '';
  return text;
};

export const FeedbackTable: React.FC<FeedbackProps> = (props) => {
  const feedback = props.feedbackData?.feedback.filter(
    (f) => f.type === props.feedbackType
  );
  const titleSuffix =
    props.feedbackType === 'forBuyer' ? 'from buyers' : 'from sellers';

  return (
    <FeedbackContainer>
      <FeedbackTitle>
        {feedback.length} {Pluralize('piece', feedback.length)} of feedback
        received {titleSuffix}.
      </FeedbackTitle>
      {feedback.map((fdb) => (
        <FeedbackEntry key={fdb.id}>
          <FeedbackHeader>
            <FeedbackIcon type={fdb.rating}>
              <FeedbackIconText>
                {feedbackIconText(fdb.rating)}
              </FeedbackIconText>
            </FeedbackIcon>
            <span>From </span> <UserName> {fdb.other_party_username} </UserName>{' '}
            - {formattedDate(fdb.createdAt)}
          </FeedbackHeader>
          <FeedbackText>{fdb.feedback_text}</FeedbackText>
          <HorizontalLine />
        </FeedbackEntry>
      ))}
    </FeedbackContainer>
  );
};

const FeedbackTitle = styled.div`
  font-size: 16px;
  margin-bottom: 40px;
  color: ${(props) => props.theme.colors.text.darkGrey};
`;

const UserName = styled.span`
  color: #666666;
  font-size: 14px;
  font-weight: bold;
  margin: 0 5px 0 5px;
`;

const FeedbackContainer = styled.div`
  //   border: 1px solid #ccc;
  margin: 20px;
  // padding: 10px;
`;

const FeedbackEntry = styled.div`
  margin-bottom: 15px;
`;

const FeedbackHeader = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
`;

const FeedbackText = styled.div`
  font-size: 14px;
  margin: 10px 0;
`;

const HorizontalLine = styled.hr`
  border: 0.5px solid #eee;
`;

const FeedbackIconText = styled.div`
  color: white;
  line-height: 12px; /* Set line-height equal to the height of the icon */
`;

const FeedbackIcon = styled.span<{ type: string }>`
  height: 15px;
  width: 15px;
  border-radius: 50%;
  display: inline-flex; /* Change to inline-flex */
  justify-content: center; /* Center horizontally */
  //   align-items: center; /* Center vertically */
  margin-right: 10px;
  background-color: ${(props) =>
    props.type === 'positive'
      ? 'green'
      : props.type === 'neutral'
      ? 'yellow'
      : 'red'};
`;
