import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  max-width: 800px;
  margin-top: 20px;
  margin-bottom: 40px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Header = styled.h1`
  margin: 10px 0 0 0;
`;

export const Divider = styled.hr`
  align-self: stretch;
  height: 1px;
  background-color: #ccc;
  border: none;
  margin: 15px 0;
`;

export const Links = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
`;

export const Section = styled.section``;

export const Title = styled.h2`
  font-size: 26px;
  margin: 0;
`;

export const Paragraph = styled.p`
  font-size: 1em;
  line-height: 1.6;
  margin: 6px 0 10px 0;
`;

export const List = styled.ul`
  list-style-type: disc;
  padding-left: 20px;
  margin-bottom: 10px;
`;

export const ListItem = styled.li`
  margin-bottom: 5px;
`;

export const Updated = styled.p`
  font-size: 14px;
  margin: 10px 0 0 0;
`;

export const Subtitle = styled.h3`
  font-size: 20px;
  margin: 20px 0 0 0;
`;
