import React from 'react';
import styled from '@emotion/styled';

import { IMAGE_FILE_TYPES } from 'components/validation';
import { validateImageSize } from './validation';

import { PhotoGridInterface } from './types';
import {
  HeaderContainer,
  HeaderSubtitle,
  HeaderTitle,
  Section,
} from './shared';

const MAX_NUM_PHOTOS = 12;

export const PhotoGrid = React.forwardRef<HTMLInputElement, PhotoGridInterface>(
  (props, ref) => {
    const handleFileSelect = async (
      e: React.ChangeEvent<HTMLInputElement>,
      index?: number | null
    ) => {
      const files = e.target.files;
      if (files && files.length > 0) {
        const updatedPhotos = [...props.selectedPhotos];
        let startIndex = index ?? updatedPhotos.findIndex((photo) => !photo);
        const allowedFilesCount = MAX_NUM_PHOTOS - updatedPhotos.length;
        for (let i = 0; i < Math.min(files.length, allowedFilesCount); i++) {
          if (
            startIndex < MAX_NUM_PHOTOS &&
            (startIndex < updatedPhotos.length || startIndex === -1)
          ) {
            const file = files[i];
            const isInvalidSize = await validateImageSize(file);
            if (isInvalidSize) {
              alert(isInvalidSize);
            } else {
              if (updatedPhotos.length < MAX_NUM_PHOTOS) {
                updatedPhotos[
                  startIndex === -1 ? updatedPhotos.length : startIndex++
                ] = files[i];
              }
            }
          }
        }
        props.setSelectedPhotos(updatedPhotos);
        if (index === undefined) {
          props.updatePreviewPhoto(URL.createObjectURL(files[0]));
        }
      }
    };

    const handleRemovePhoto = (index: number) => {
      const updatedPhotos = [...props.selectedPhotos];
      if (index < props.selectedPhotos.length) {
        updatedPhotos.splice(index, 1);
        props.setSelectedPhotos(updatedPhotos);
      }
    };

    const renderPhotoSquares = () => {
      const totalPhotos = props.selectedPhotos;

      const squares = [];
      for (let i = 0; i < MAX_NUM_PHOTOS; i++) {
        const currentPhoto = totalPhotos[i];
        squares.push(
          <PhotoSquare
            key={i}
            isEmpty={!currentPhoto}
            onClick={() =>
              !currentPhoto &&
              document.getElementById(`fileInput-${i}`)?.click()
            }
          >
            {currentPhoto && (
              <>
                <RemoveIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    handleRemovePhoto(i);
                  }}
                >
                  X
                </RemoveIcon>
                <PhotoPreview
                  src={URL.createObjectURL(currentPhoto)}
                  onClick={(e) => {
                    e.stopPropagation();
                    props.updatePreviewPhoto(URL.createObjectURL(currentPhoto));
                  }}
                />
              </>
            )}
            <input
              type='file'
              id={`fileInput-${i}`}
              style={{ display: 'none' }}
              accept={IMAGE_FILE_TYPES}
              multiple
              onChange={(e) => handleFileSelect(e, null)}
            />
          </PhotoSquare>
        );
      }
      return squares;
    };

    return (
      <Section>
        <HeaderContainer>
          <HeaderTitle>Photos</HeaderTitle>
          <HeaderSubtitle>
            Upload at least one photo of your item(s) for sale.
          </HeaderSubtitle>
        </HeaderContainer>
        <Grid>
          <PhotoPreviewArea isPhotoSelected={Boolean(props.previewPhoto)}>
            {props.previewPhoto ? (
              <PhotoPreview src={props.previewPhoto} />
            ) : (
              <>
                <div>
                  Upload your photos via drag and drop or the button below
                </div>
                <AddButton
                  onClick={(e) => {
                    e.preventDefault();
                    document.getElementById('fileInput-main')?.click();
                  }}
                >
                  Add from computer
                </AddButton>
                <input
                  ref={ref}
                  type='file'
                  id='fileInput-main'
                  style={{ display: 'none' }}
                  accept={IMAGE_FILE_TYPES}
                  multiple
                  onChange={(e) => handleFileSelect(e, null)}
                />
              </>
            )}
          </PhotoPreviewArea>
          {renderPhotoSquares()}
        </Grid>
      </Section>
    );
  }
);

const RemoveIcon = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  padding: 5px;
  cursor: pointer;
  color: #ff0000;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 10px;
`;

const PhotoPreviewArea = styled.div<{ isPhotoSelected: boolean }>`
  border-radius: 15px;
  grid-column: span 3;
  grid-row: span 3;
  border: 1px dashed #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${(props) => (props.isPhotoSelected ? '#fff' : '#f0f0f0')};
  width: 100%;
  height: 300px;
  max-width: 400px;
  max-height: 300px;
`;

const PhotoSquare = styled.div<{ isEmpty: boolean }>`
  border-radius: 10px;
  background-color: ${(props) => (props.isEmpty ? '#f6f6f6' : '#eee')};
  &:hover {
    background-color: ${(props) => (props.isEmpty ? '#f0f0f0' : '#eee')};
  }
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  width: 100%;
  height: 100px;
  position: relative;
`;

const PhotoPreview = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
`;

const AddButton = styled.button`
  margin-top: 10px;
`;

export default PhotoGrid;
