import styled from '@emotion/styled';

interface LinkComponentInt {
  text: string;
  onClick: (e: React.MouseEvent) => void;
  fontSize?: string;
  color?: string;
  style?: React.CSSProperties;
}

interface LinkProps {
  fontSize?: string;
  color?: string;
  style?: React.CSSProperties;
}

export const BlueLinkComponent = ({
  onClick,
  text,
  fontSize,
  color,
  style,
}: LinkComponentInt) => {
  return (
    <BlueLink onClick={onClick} fontSize={fontSize} color={color} style={style}>
      {text}
    </BlueLink>
  );
};

export const BottomNavComponent = ({ onClick, text }: LinkComponentInt) => {
  return <BottomNavLink onClick={onClick}>{text}</BottomNavLink>;
};

export const GreyLinkComponent = ({
  onClick,
  text,
  fontSize,
  color,
  style,
}: LinkComponentInt) => {
  return (
    <GreyLink onClick={onClick} fontSize={fontSize} color={color} style={style}>
      {text}
    </GreyLink>
  );
};

export const BottomNavLink = styled.div`
  color: ${(props) => props.theme.colors.text.bottomNavLink};
  font-size: ${(props) => props.theme.fontSize.bottomNav};
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.colors.primary};
  }
`;

export const BlueLink = styled.span<LinkProps>`
  color: ${(props) => props.theme.colors.text.link};
  cursor: pointer;
  font-size: ${(props) => props.fontSize || '12px'};
  ${(props) =>
    props.style && {
      ...props.style,
    }};
  &:hover {
    text-decoration: underline;
  }
`;

export const GreyLink = styled.span<LinkProps>`
  color: ${(props) => props.theme.colors.text.bottomNavLink};
  font-size: ${(props) => props.fontSize || props.theme.fontSize.bottomNav};
  ${(props) =>
    props.style && {
      ...props.style,
    }};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;
