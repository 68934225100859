import { IconProps } from '@phosphor-icons/react';

interface StyledIconProps {
  icon: React.ComponentType<IconProps>;
  status?: 'static' | 'hover' | 'selected';
  theme: { [key: string]: IconProps };
}

const withIconProps =
  (
    IconComponent: React.ComponentType<IconProps>,
    status: 'static' | 'hover' | 'selected' = 'static',
    theme: { [key: string]: IconProps }
  ) =>
  (props: IconProps) => {
    const iconProps = theme[status];
    return <IconComponent {...iconProps} {...props} />;
  };

export const StyledIcon: React.FC<StyledIconProps> = ({
  icon,
  status,
  theme,
}) => {
  const IconWithProps = withIconProps(icon, status, theme);
  return <IconWithProps />;
};
