import { useMutation, useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { Toast } from 'components/toast';
import { FeedbackFormValues } from './types';

import {
  CheckFeedbackAPIResponse,
  FeedbackAPIResponse,
  OrderRecord,
  UseCheckFeedbackParams,
} from './types';
import useAPI from 'api';

interface SubmitFeedbackParams {
  feedbackData: FeedbackFormValues;
  feedbackType: string;
  orderData: OrderRecord;
}

const packageFeedbackData = (
  data: FeedbackFormValues,
  feedbackType: string,
  orderData: OrderRecord
) => {
  return {
    order_id: orderData.id,
    feedback_text: data.feedbackText,
    type: feedbackType,
    listing_id: orderData.listing_id,
    buyer_id: orderData.buyer_id,
    seller_id: orderData.seller_id,
    rating: data.rating,
    star_accuracy: data.starsAccuracy === 0 ? null : data.starsAccuracy,
    star_shipping_speed: data.starsSpeed === 0 ? null : data.starsSpeed,
    star_shipping_cost: data.starsCost === 0 ? null : data.starsCost,
    star_communication: data.starsComm === 0 ? null : data.starsComm,
  };
};

export const useSubmitFeedback = () => {
  const navigate = useNavigate();
  const API = useAPI();
  return useMutation({
    mutationFn: async ({
      feedbackData,
      feedbackType,
      orderData,
    }: SubmitFeedbackParams) => {
      const packagedData = packageFeedbackData(
        feedbackData,
        feedbackType,
        orderData
      );
      return await API('submitFeedback', {
        method: 'POST',
        body: JSON.stringify({ ...packagedData }),
      });
    },
    onSuccess: (data) => {
      navigate('/feedbackSubmitted');
    },
    onError: (err: Error) => {
      Toast.error(err.message);
    },
  });
};

export const useGetFeedback = (userId: string) => {
  const API = useAPI();
  const params = new URLSearchParams({ userId });
  const url = `userFeedback?${params.toString()}`;
  return useQuery({
    queryKey: ['userFeedback', userId],
    queryFn: async () => {
      return await API<FeedbackAPIResponse>(url.toString(), {
        method: 'GET',
      });
    },
  });
};

export const useCheckFeedback = ({
  userId,
  listingId,
}: UseCheckFeedbackParams) => {
  const API = useAPI();
  const params = new URLSearchParams({ userId, listingId });
  const url = `checkFeedback?${params.toString()}`;
  return useQuery({
    queryKey: ['checkFeedback', userId, listingId],
    queryFn: async () => {
      return await API<CheckFeedbackAPIResponse>(url.toString(), {
        method: 'GET',
      });
    },
  });
};
