import React from 'react';
import styled from '@emotion/styled';

import {
  Content,
  Divider,
  Header,
  List,
  ListItem,
  Paragraph,
  Section,
  Subtitle,
  Title,
  Updated,
} from 'components/helpShared';
import {
  PRIVACY_POLICY_DATE,
  PRODUCT_DOMAIN,
  PRODUCT_NAME,
  SUPPORT_EMAIL,
} from 'config';

export const PrivacyPolicyPage: React.FC = () => {
  return (
    <Container>
      <Header>Privacy Policy</Header>
      <Updated>This policy is effective as of {PRIVACY_POLICY_DATE}.</Updated>
      <Divider />
      <Content>
        <Section>
          <Paragraph>
            {PRODUCT_NAME}'s policy is to respect user privacy and strictly
            comply with all applicable laws and regulations regarding personal
            information we may collect about you across our website,{' '}
            {PRODUCT_DOMAIN}, and any other websites, services, or applications
            we own and operate.
          </Paragraph>
        </Section>
        <Section>
          <Title>Scope</Title>
          <Paragraph>
            This Privacy Policy applies to personal information we process,
            including on our websites and other online or offline offerings. We
            collectively refer to our websites, services, promotions, and other
            offerings as “Services” in this document.
          </Paragraph>
        </Section>
        <Section>
          <Title>Information We Collect</Title>
          <Paragraph>
            We may collect information you knowingly and actively provide us
            when using or participating in any of our Services and promotions.
            We may also collect any information automatically sent by your
            devices when accessing our products and services.
          </Paragraph>
          <Subtitle>Log Data</Subtitle>
          <Paragraph>
            When you visit our website, our servers may automatically log the
            standard data provided by your web browser. This may include:
          </Paragraph>
          <List>
            <ListItem>Your device's browser type and version</ListItem>
            <ListItem>Your device's Internet Protocol (IP) address</ListItem>
            <ListItem>Pages you visit while accessing our Services</ListItem>
            <ListItem>The time spent on each page or service</ListItem>
            <ListItem>The time and date of your visits</ListItem>
            <ListItem>
              Other details about your visit, including but not limited to
              elements you interact with
            </ListItem>
            <ListItem>
              Technical details that occur in conjunction with any errors you
              may encounter.
            </ListItem>
          </List>
          <Paragraph>
            While this information alone may not be sufficient to identify
            individuals, it may be possible to combine it with other data to
            identify someone personally.
          </Paragraph>
          <Subtitle>Personal Information</Subtitle>
          <Paragraph>
            We may ask for personal information, which may include one or more
            of the following:
          </Paragraph>
          <List>
            <ListItem>Full name</ListItem>
            <ListItem>Username</ListItem>
            <ListItem>Profile picture</ListItem>
            <ListItem>Email address</ListItem>
            <ListItem>Home address</ListItem>
            <ListItem>Mailing address</ListItem>
            <ListItem>Home and/or mobile phone number</ListItem>
            <ListItem>Tax identification number</ListItem>
            <ListItem>Business name</ListItem>
          </List>
          <Paragraph>
            In certain circumstances, we may access your communications with
            others users on the {PRODUCT_NAME} platform.
          </Paragraph>
        </Section>
        <Section>
          <Title>Reasons for Processing Personal Information</Title>
          <Paragraph>
            We collect and use personal information only when we have a valid
            reason. In such cases, we gather only the personal information
            necessary to provide our Services.
          </Paragraph>
        </Section>
        <Section>
          <Title>Collection of Information</Title>
          <Paragraph>
            We may collect personal information from you when you do any of the
            following:
          </Paragraph>
          <List>
            <ListItem>
              Use a web browser or similar software to access our content on any
              device.
            </ListItem>
            <ListItem>
              Contact us via physical mail, telephone, email, social media, or
              similar technologies or services.
            </ListItem>
            <ListItem>
              Mention us on social media or any similar technologies or
              services.
            </ListItem>
          </List>
        </Section>
        <Section>
          <Title>Use of Information</Title>
          <Paragraph>
            We may use your information for various business purposes, including
            to provide our Services and for administrative purposes as described
            below.
          </Paragraph>
          <Subtitle>Providing Services</Subtitle>
          <Paragraph>
            We use your information to fulfill our contract with you and provide
            you with our Services, such as:
          </Paragraph>
          <List>
            <ListItem>Providing customer support assistance</ListItem>
            <ListItem>
              Managing your information, accounts, and listings
            </ListItem>
            <ListItem>
              Communicating with you regarding your account, activities on our
              Services, policy changes, and important updates
            </ListItem>
            <ListItem>
              Providing access to certain areas, functionalities, and features
              of our Services
            </ListItem>
            <ListItem>Personalizing your experience on our Services</ListItem>
            <ListItem>Conducting surveys and feedback requests</ListItem>
            <ListItem>Analyzing usage trends and user behavior</ListItem>
            <ListItem>Sending promotional materials and offers</ListItem>
          </List>
          <Subtitle>Administrative Purposes</Subtitle>
          <Paragraph>
            We use your information for administrative purposes, including:
          </Paragraph>
          <List>
            <ListItem>
              Detecting security incidents, protecting against malicious,
              deceptive, fraudulent, or illegal activity, and working with law
              enforcement to prosecute these activities
            </ListItem>
            <ListItem>
              Pursuing our legitimate interests such as direct marketing,
              research and development (including marketing research), network
              and information security, and fraud prevention
            </ListItem>
            <ListItem>
              Measuring interest and engagement in our Services
            </ListItem>
            <ListItem>Ensuring internal quality control and safety</ListItem>
            <ListItem>
              Debugging to identify and repair errors with our Services
            </ListItem>
            <ListItem>Improving, upgrading, or enhancing our Services</ListItem>
            <ListItem>Developing new products and Services</ListItem>
            <ListItem>Complying with legal and regulatory obligations</ListItem>
            <ListItem>Enforcing our agreements and policies</ListItem>
            <ListItem>
              Authenticating and verifying individual identities, including
              requests to exercise your rights under this policy
            </ListItem>
            <ListItem>
              Auditing relating to interactions, transactions, and other
              compliance-related activities
            </ListItem>
          </List>
          <Paragraph>
            We may gather, store, view, use, and disclose information for the
            following purposes. Personal information will not be processed in a
            way that is inconsistent with these purposes:
          </Paragraph>
          <List>
            <ListItem>To comply with legal and regulatory obligations</ListItem>
            <ListItem>To enable access to our Services</ListItem>
            <ListItem>To resolve disputes that may arise</ListItem>
            <ListItem>To ensure security and fraud prevention</ListItem>
            <ListItem>
              To ensure that our Services are used per our{' '}
              <a href='/terms' target='_blank' rel='noopener noreferrer'>
                Terms of Service
              </a>{' '}
              and{' '}
              <a
                href='/sellerAgreement'
                target='_blank'
                rel='noopener noreferrer'
              >
                Seller Agreement
              </a>
              .
            </ListItem>
          </List>
          <Paragraph>
            We may combine information we collect about you with general
            information or research data we receive from other trusted sources.
          </Paragraph>
        </Section>
        <Section>
          <Title>Security</Title>
          <Paragraph>
            When we collect, process, and retain personal information, we
            protect it within commercially acceptable means to prevent loss and
            theft, as well as unauthorized access, modification, disclosure,
            copying, or use of your data We employ technical, organizational,
            and physical safeguards designed to protect the personal information
            we collect. However, security risk is inherent in all internet and
            information technologies, and we cannot guarantee absolute data
            security.
          </Paragraph>
          <Paragraph>
            It is your responsibility to select an acceptablely strong password
            to maximize the security of your information within the bounds of
            our services.
          </Paragraph>
        </Section>
        <Section>
          <Title>Length of Retention</Title>
          <Paragraph>
            Your personal information is stored only as long as necessary to
            provide our Services. The retention length may depend on the reasons
            for using your information in accordance with this privacy policy.
            If your personal information is no longer required, we will delete
            it or make it anonymous by removing all identifying details.
          </Paragraph>
          <Paragraph>
            If necessary, we may retain your personal information for legal
            obligations or as law enforcement requires.
          </Paragraph>
        </Section>
        <Section>
          <Title>Children's Privacy</Title>
          <Paragraph>
            The Services are not intended for anyone under 13 years of age. If
            you are a parent or guardian of a child from whom you believe we
            have collected personal information in a manner prohibited by law,
            please contact us. If we learn that we have collected personal
            information through the Services from a child without the consent of
            the child's parent or guardian as required by law, we will comply
            with applicable legal requirements to delete the information.
          </Paragraph>
        </Section>
        <Section>
          <Title>Your Rights and Control Over Your Personal Information</Title>
          <Paragraph>
            You have the right to withhold personal information from us, but
            this may affect your experience on our website. We will not
            discriminate against you for exercising your rights over your
            personal information. If you do provide personal information, it
            will be collected, stored, used, and disclosed in accordance with
            this privacy policy. You also have the right to request details of
            any personal information we hold about you.
          </Paragraph>
          <Paragraph>
            If we receive your personal information from a third party, we will
            protect it as described in this privacy policy. If you are a third
            party providing someone else's personal information, you must have
            their consent to share it with us.
          </Paragraph>
          <Paragraph>
            If you have previously consented to our use of your personal
            information for direct marketing, you can change your mind at any
            time. We will offer you the option to unsubscribe from our email
            database or opt out of communications. Please note that we may need
            to request specific information to confirm your identity.
          </Paragraph>
          <Paragraph>
            If you believe that any information we hold about you is incorrect,
            outdated, incomplete, irrelevant, or misleading, please contact us
            using the details provided in this privacy policy. We will take
            reasonable steps to correct any information found to be inaccurate,
            incomplete, misleading, or out of date.
          </Paragraph>
          <Paragraph>
            If you think we have breached a data protection law and wish to file
            a complaint, please contact us with full details of the alleged
            breach. We will promptly investigate and respond to your complaint
            in writing, detailing the outcome and steps to address your
            concerns. You also have the right to contact a regulatory body or
            data protection authority regarding your complaint.
          </Paragraph>
        </Section>
        <Section>
          <Title>Cookies</Title>
          <Paragraph>
            We may use "cookies" to gather information about you and your
            activity on our site. A cookie is a small data file that our website
            stores on your computer and accesses each time you visit. This
            allows us to understand how you interact with our site and helps us
            deliver content tailored to your specified preferences.
          </Paragraph>
        </Section>
        <Section>
          <Title>Limits to this Policy</Title>
          <Paragraph>
            We may update this Privacy Policy at our sole discretion. If there
            are significant changes, we will inform you as legally required. By
            continuing to use our Services after the new Privacy Policy becomes
            effective, you acknowledge and agree to the updated terms. If the
            law mandates, we will give you the option to opt in or out of any
            new uses of your personal information.
          </Paragraph>
        </Section>
        <Section>
          <Title>Changes to our Privacy Policy</Title>
          <Paragraph>
            We may update this Privacy Policy at our sole discretion. If there
            are significant changes, we will inform you as legally required. By
            continuing to use our Services after the new Privacy Policy becomes
            effective, you acknowledge and agree to the updated terms. If the
            law mandates, we will give you the option to opt in or out of any
            new uses of your personal information.
          </Paragraph>
        </Section>
        <Section>
          <Title>Contact Us</Title>
          <Paragraph>
            {PRODUCT_NAME} is the controller of personal information that is
            processed under this Privacy Policy. For any questions or concerns
            regarding your privacy or data, contact us at {SUPPORT_EMAIL}.
          </Paragraph>
        </Section>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  padding: 20px;
  max-width: 800px;
  margin: auto;
  margin-bottom: 40px;
`;
