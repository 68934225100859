import React from 'react';
import styled from '@emotion/styled';

import { Content, Divider, Header, Paragraph } from 'components/helpShared';
import { DISCORD_URL, SUPPORT_EMAIL } from 'config';

export const ContactUsPage: React.FC = () => {
  return (
    <Container>
      <Header>Contact Us</Header>
      <Divider />
      <Content>
        <Paragraph>
          Have a question, comment, concern? Got a feature you'd like us to
          build?
          <br />
          <br />
          We recommend sending us a message on{' '}
          <a href={DISCORD_URL} target='_blank' rel='noopener noreferrer'>
            Discord
          </a>{' '}
          as we check the server often. Alternately, please send our support
          team an <a href={`mailto:${SUPPORT_EMAIL}`}>email.</a>
        </Paragraph>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  padding: 20px;
  max-width: 800px;
  margin: auto;
  margin-bottom: 40px;
`;
