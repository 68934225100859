import React, { useEffect, useRef, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import styled from '@emotion/styled';

import { generateExtensionsArray } from 'utils';
import { ListingData } from 'types';
import { Button } from 'components/buttons';
import { ValidationErrorMessage } from 'components/validation';

import { CategoryComponent } from './category';
import { DescriptionComponent } from './Description';
import PhotoGrid from './Photos';
import { PricingComponent } from './Pricing';
import { ReturnsComponent } from './Returns';
import { ShippingComponent } from './Shipping';
import { TitleComponent } from './Title';
import { ListingFormProps } from './types';

export const ListingForm: React.FC<ListingFormProps> = ({
  submitListing,
  status,
  existingData,
}) => {
  const methods = useForm<ListingData>({
    defaultValues: {
      title: '',
      description: '',
      shippingCost: '',
      price: '',
      imageExtensions: [],
      returns: '',
    },
  });

  const {
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
    reset,
  } = methods;

  const [photos, setPhotos] = useState<File[]>([]);
  const [previewPhoto, setPreviewPhoto] = useState<string | null>(null);
  const photosInputRef = useRef<HTMLInputElement>(null);

  const updatePreviewPhoto = (url: any) => {
    setPreviewPhoto(url);
  };

  useEffect(() => {
    if (photos.length > 0) {
      clearErrors('photos');
    }
  }, [photos, clearErrors]);

  // Fetch and convert existing photos from URLs to File objects
  useEffect(() => {
    const fetchExistingPhotos = async () => {
      if (existingData?.signedS3Urls) {
        const fetchedPhotos = await Promise.all(
          existingData.signedS3Urls.map(async (url, index) => {
            const response = await fetch(url);
            const blob = await response.blob();
            const extension = 'jpg';
            const filename = `${index + 1}.${extension}`;
            return new File([blob], filename, { type: blob.type });
          })
        );
        setPhotos(() => [...fetchedPhotos]);
      }
    };

    fetchExistingPhotos();

    const existingDataFormatted = {
      title: existingData?.title,
      description: existingData?.description,
      category: existingData?.category,
      shippingCost: existingData?.shipping_cost.toString(),
      price: existingData?.price.toString(),
      imageExtensions: [],
      returns: existingData?.returns,
    };
    if (existingData) {
      reset(existingDataFormatted);
    }
  }, [existingData, reset]);

  const onSubmit = async (formData: ListingData) => {
    if (photos.length === 0) {
      setError('photos', {
        type: 'manual',
        message: 'At least one photo must be uploaded',
      });
      return;
    }
    clearErrors('photos');

    const dataToSubmit = {
      title: formData.title,
      description: formData.description,
      category: formData.category,
      shippingCost: formData.shippingCost,
      price: formData.price,
      imageExtensions: generateExtensionsArray(photos),
      returns: formData.returns,
    };

    submitListing({
      data: dataToSubmit,
      photos: photos,
      listingId: existingData?.id || '',
    });
  };

  return (
    <Listing>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Title>Create new listing</Title>
          <TitleComponent />
          <DescriptionComponent />
          <CategoryComponent existingChoice={existingData?.category} />
          <PricingComponent />
          <ShippingComponent />
          <ReturnsComponent />
          <PhotoGrid
            selectedPhotos={photos}
            previewPhoto={previewPhoto}
            setSelectedPhotos={setPhotos}
            updatePreviewPhoto={updatePreviewPhoto}
            ref={photosInputRef}
          />
          {errors.photos && (
            <ValidationErrorMessage
              message={errors.photos?.message as string}
            />
          )}
          <ButtonContainer>
            <Button
              type='submit'
              disabled={status === 'pending'}
              width={'340px'}
            >
              {status === 'pending' ? 'Listing Submitted' : 'Submit listing'}
            </Button>
          </ButtonContainer>
        </Form>
      </FormProvider>
    </Listing>
  );
};

const Title = styled.h1`
  margin: 0;
`;

const ButtonContainer = styled.div`
  margin-top: 60px;
  align-self: center;
`;

const Listing = styled.div`
  padding: 30px;
  margin: 0 auto 100px;
  max-width: 830px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export default ListingForm;
