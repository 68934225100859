import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';

import { Button } from 'components/buttons';
import { GreyLinkComponent } from 'components/links';
import { OrderStatus } from 'components/orderStatus';
import { formattedDate, formatNumber } from 'utils';
import { ListingRecord } from 'types';

import { useDelistListing, useRelistListing } from 'hooks/listing';

interface ListingCardListingProps {
  listing: ListingRecord;
}

export const ListingCardListing = ({ listing }: ListingCardListingProps) => {
  const [isActive, setIsActive] = useState(listing.isActive);
  const navigate = useNavigate();
  const { mutate: delistListing } = useDelistListing({ setIsActive });
  const { mutate: relistListing } = useRelistListing({ setIsActive });
  const listingDate = formattedDate(listing.createdAt);
  const isSold = !!listing.order_status;

  useEffect(() => {
    setIsActive(listing.isActive);
  }, [listing.isActive]);

  const handleDelist = () => {
    delistListing(listing.id);
  };
  const handleRelist = () => {
    relistListing(listing.id);
  };

  const styling = { fontSize: '14px', height: '40px' };

  return (
    <ItemBox>
      <Row>
        <ImageContainer>
          <Image src={listing.signedS3Url} alt='Product Image' />
        </ImageContainer>
        <ItemInfoContainer>
          <Title>{listing.title}</Title>
          <OrderStatus
            status={listing.order_status}
            size={'small'}
            isActive={listing.isActive}
          />
          <Purchased>Listed on {listingDate}</Purchased>
          <CostContainer>
            <CostRow>
              <TextLeft>Price:</TextLeft>
              <TextRight>${formatNumber(listing.price)}</TextRight>
            </CostRow>
            <CostRow>
              <TextLeft>Shipping Cost:</TextLeft>
              <TextRight>${formatNumber(listing.shipping_cost)}</TextRight>
            </CostRow>
          </CostContainer>
          <GreyLinkComponent
            text={'View listing'}
            onClick={() => navigate(`/listing/${listing.id}`)}
          />
        </ItemInfoContainer>
        {isActive ? (
          <ButtonContainer>
            <Button {...styling} variant={'secondary'} onClick={handleDelist}>
              Delist
            </Button>
            <Button
              {...styling}
              variant={'secondary'}
              onClick={() => navigate(`/editListing/${listing.id}`)}
            >
              Edit listing
            </Button>
          </ButtonContainer>
        ) : isSold ? (
          <ButtonContainer>
            <Button
              {...styling}
              variant={'primary'}
              onClick={() => navigate(`/myAccount/Listings/${listing.id}`)}
            >
              Manage Order
            </Button>
            {listing.feedback_id ? (
              <Button {...styling} variant={'primary'} disabled={true}>
                Feedback submitted
              </Button>
            ) : (
              <Button
                {...styling}
                variant={'primary'}
                onClick={() => navigate(`/feedback/${listing.id}/forBuyer`)}
              >
                Leave feedback
              </Button>
            )}
          </ButtonContainer>
        ) : (
          <ButtonContainer>
            <Button {...styling} variant={'primary'} onClick={handleRelist}>
              Relist
            </Button>
            <Button
              {...styling}
              variant={'secondary'}
              onClick={() => navigate(`/editListing/${listing.id}`)}
            >
              Edit listing
            </Button>
          </ButtonContainer>
        )}
      </Row>
    </ItemBox>
  );
};

const CostContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 5px;
`;

const CostRow = styled.div`
  display: flex;
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 200px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: auto;
  gap: 10px;
  flex-shrink: 0;
`;

const TextLeft = styled.div`
  font-size: 14px;
  text-align: left;
`;

const TextRight = styled.div`
  font-size: 14px;
  text-align: left;
  font-weight: 500;
  margin-left: 5px;
`;
const Title = styled.h2`
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  margin: 0;
  max-width: 400px;
  overflow-wrap: break-word;
  flex-shrink: 1;
`;

const Row = styled.div`
  display: flex;
  align-items: top;
  flex-direction: row;
`;

const ImageContainer = styled.div`
  width: 180px;
  height: 180px;
  min-width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Image = styled.img`
  max-width: 180px;
  max-height: 180px;
  object-fit: cover;
`;

const ItemInfoContainer = styled.div`
  flex-direction: column;
  display: flex;
  align-items: left;
  text-align: left;
  margin-left: 30px;
  gap: 5px;
`;

const Purchased = styled.div`
  text-align: left;
  font-size: 14px;
`;

const ItemBox = styled.div`
  flex: 1;
  align-self: flex-start;
  width: 100%;
  padding: 16px;
  height auto;
  border: 1px solid ${(props) => props.theme.colors.border.primary};
`;
