import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

import { LoginComponent } from 'components/login';
import { AccountCreationComponent } from 'components/login';

const LoginPage: React.FC = () => {
  const [message, setMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [showAccountCreation, setShowAccountCreation] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const params = new URLSearchParams(location.search);
  const verifyToken = params.get('verifyToken');

  // Redirect to the homepage if user is already logged in
  useEffect(() => {
    const token = localStorage.getItem('jwtToken');
    if (token) {
      const decoded = jwtDecode(token);
      const currentTime = Date.now() / 1000;

      if (decoded.exp && decoded.exp > currentTime) {
        navigate('/');
      } else {
        localStorage.removeItem('jwtToken');
      }
    }
  }, [navigate]);

  const toggleShowAccountCreation = () => {
    setShowAccountCreation(!showAccountCreation);
  };

  const toggleSetMessage = (message: string) => {
    setMessage(message);
  };

  const toggleSetSuccessMessage = (message: string) => {
    setSuccessMessage(message);
  };

  return (
    <>
      {showAccountCreation || verifyToken ? (
        <AccountCreationComponent
          message={message}
          setMessage={toggleSetMessage}
          setSuccessMessage={toggleSetSuccessMessage}
          onAccountCreationSuccess={() => setShowAccountCreation(false)}
        ></AccountCreationComponent>
      ) : (
        <LoginComponent
          message={message}
          successMessage={successMessage}
          setMessage={setMessage}
          onAccountCreationRequest={toggleShowAccountCreation}
        ></LoginComponent>
      )}
    </>
  );
};

export default LoginPage;
