import { useNavigate } from 'react-router-dom';

import { Breadcrumbs } from 'components/breadcrumbs';
import {
  Container,
  Content,
  Divider,
  Header,
  Paragraph,
  Section,
  Title,
} from 'components/helpShared';
import { BlueLinkComponent } from 'components/links';
import { PRICE_TRACKING_REQUIRED, PRODUCT_NAME } from 'config';

export const ReceivingYourOrder = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <Breadcrumbs
        items={[
          { path: '/help', label: 'Help Home' },
          { path: '/help/buyerResources', label: 'Buyer Resources' },
          {
            path: '/help/buyerResources/receivingYourOrder',
            label: 'Receiving Your Order',
          },
        ]}
      />
      <Header>Receiving Your Order</Header>
      <Divider />
      <Content>
        <Section>
          <Title>Item Delivery</Title>
          <Paragraph>
            After you purchase an item, both you and the seller will receive an
            email notification of the purchase. The next step will be for the
            seller to ship your item, which generally happens within 3 business
            days. You will also receive a notification email when the seller has
            marked the item as shipped. For items with a gross sales value over
            ${PRICE_TRACKING_REQUIRED}, a tracking number will also be provided.
          </Paragraph>
          <Paragraph>
            In rare cases the seller may contact you prior to shipping - for
            example, to confirm your shipping address. You can track the status
            of your order, communicate with the buyer, and leave feedback in the{' '}
            <BlueLinkComponent
              onClick={() => navigate('/myAccount/purchases')}
              text='My Purchases'
              fontSize='16px'
            />{' '}
            section of your account home. If you have questions or an issue
            arises after purchasing the item, please contact the seller.
          </Paragraph>
        </Section>
        <Section>
          <Title>After Receiving Your Item</Title>
          <Paragraph>
            Once you receive your item, please check to ensure the item matches
            the description in the original listing. If there are any
            discrepancies, please contact the seller to work out a solution.
            Buyers are always protected by the{' '}
            <BlueLinkComponent
              onClick={() => navigate('/guarantee')}
              text={`${PRODUCT_NAME} guarantee`}
              fontSize='16px'
            />{' '}
            , but we strongly encourage that you give sellers the chance to make
            things right. Sellers are incentivized to provide a great service to
            maintain their positive reputation on {PRODUCT_NAME}.
          </Paragraph>
          <Paragraph>
            After the transaction is settled, you may leave feedback for the
            seller. See the{' '}
            <BlueLinkComponent
              onClick={() => navigate('/help/buyerResources/leavingFeedback')}
              text={`Leaving Feedback`}
              fontSize='16px'
            />{' '}
            article for more details.
          </Paragraph>
        </Section>
      </Content>
    </Container>
  );
};
