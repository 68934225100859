import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import styled from '@emotion/styled';

import { CheckboxInput, Input } from 'components/input';
import { Button } from 'components/buttons';
import { BlueLinkComponent } from 'components/links';
import {
  emailValidation,
  passwordValidation,
  termsOfServiceValidation,
  usernameValidation,
} from 'components/validation';
import { useDoVerifyNewAccount } from 'hooks/verification';

import { useCreateAccount } from './hooks';

interface AccountCreationComponentInt {
  message: string;
  setMessage: (m: string) => void;
  setSuccessMessage: (m: string) => void;
  onAccountCreationSuccess: () => void;
}

const AccountCreationComponent = ({
  message,
  setMessage,
  setSuccessMessage,
  onAccountCreationSuccess,
}: AccountCreationComponentInt) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [canResend, setCanResend] = useState(true); // Add state to track resend button
  const [timer, setTimer] = useState(60); // Add state to track the countdown timer

  const { mutate: sendVerifyEmail } = useDoVerifyNewAccount(
    setMessage,
    setIsSubmitted
  );
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const verifyToken = params.get('verifyToken');
  const methods = useForm();

  const { mutate: doCreateAccount, status: createAccountStatus } =
    useCreateAccount(setMessage, setSuccessMessage, onAccountCreationSuccess);

  const handleCreateAccount = methods.handleSubmit((data) => {
    doCreateAccount({
      token: verifyToken,
      password: data.password,
    });
  });

  const handleVerifyEmail = methods.handleSubmit((data) => {
    sendVerifyEmail({ email: data.email, username: data.username });
  });

  const handleResendVerifyEmail = methods.handleSubmit((data) => {
    if (canResend) {
      sendVerifyEmail({ email: data.email, username: data.username });
      setCanResend(false);
      setTimer(60);

      const intervalId = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer <= 1) {
            clearInterval(intervalId);
            setCanResend(true);
            return 0;
          }
          return prevTimer - 1;
        });
      }, 1000);
    }
  });

  const watchTos = methods.watch('tos', false);
  useEffect(() => {
    setIsCheckboxChecked(watchTos);
  }, [watchTos]);

  return (
    <PageContainer>
      <BlueField></BlueField>
      <AccountCreationWrapper>
        <FormProvider {...methods}>
          <Form onSubmit={(e) => e.preventDefault()}>
            {verifyToken ? (
              <>
                <Input {...passwordValidation} />
                <VerificationMessage>
                  Please enter a password to complete account creation.
                </VerificationMessage>
                <ButtonContainer>
                  <Button
                    onClick={handleCreateAccount}
                    disabled={createAccountStatus === 'pending'}
                  >
                    Finish account creation
                  </Button>
                </ButtonContainer>
                {message && <Message>{message}</Message>}
              </>
            ) : isSubmitted ? (
              <>
                <VerificationMessage>
                  We've sent an email to the address you provided. Please follow
                  the link in the email to finish creating your account.
                </VerificationMessage>
                <ButtonContainer>
                  <Button
                    onClick={handleResendVerifyEmail}
                    disabled={!canResend}
                  >
                    Re-send verification link
                    {timer > 0 && ` (${timer}s)`} {/* Show countdown timer */}
                  </Button>
                </ButtonContainer>
                {message && <Message>{message}</Message>}
              </>
            ) : (
              <>
                <Header>Create an account</Header>
                <Input {...usernameValidation} />
                <Input {...emailValidation} />
                <CheckboxInput {...termsOfServiceValidation} />
                <ButtonContainer>
                  <Button
                    onClick={handleVerifyEmail}
                    disabled={!isCheckboxChecked}
                  >
                    Create account
                  </Button>
                </ButtonContainer>
                {message && <Message>{message}</Message>}
                <LogInSection>
                  {'Already have an account?'}
                  <BlueLinkComponent
                    text='Sign in here'
                    onClick={() => onAccountCreationSuccess()}
                  ></BlueLinkComponent>
                </LogInSection>
              </>
            )}
          </Form>
        </FormProvider>
      </AccountCreationWrapper>
    </PageContainer>
  );
};

const Header = styled.h2`
  text-align: left;
  margin-bottom: 10px;
`;

const BlueField = styled.div`
  width: 500px;
  background-color: ${(props) => props.theme.colors.primary};
  @media (max-width: 1200px) {
    display: none;
  }
`;

const LogInSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  text-align: center;
  gap: 5px;
`;

const AccountCreationWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Message = styled.div`
  color: red;
  margin-top: 20px;
  text-align: center;
`;

const PageContainer = styled.div`
  display: flex;
  height: 100vh;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 340px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const VerificationMessage = styled.div`
  width: 340px;
  color: grey;
  font-size: 12px;
`;

export default AccountCreationComponent;
