import { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';

import { Input } from 'components/input';
import { Button } from 'components/buttons';
import { BlueLinkComponent } from 'components/links';
import { emailValidation } from 'components/validation';

import { useSendPwRequest } from './hooks';

const message = `If that account exists, you'll receive an email explaining how to reset the password. If you don't receive it within a couple minutes, please check your spam folder.`;

const PwResetRequestComponent = () => {
  const navigate = useNavigate();
  const [submitted, setSubmitted] = useState(false);
  const { mutate: sendPwRequest, isSuccess } = useSendPwRequest();

  const methods = useForm({
    defaultValues: {
      email: '',
    },
  });

  // Reset form on mount
  useEffect(() => {
    methods.reset({
      email: '',
    });
  }, [methods]);

  useEffect(() => {
    if (isSuccess) setSubmitted(true);
  }, [isSuccess]);

  const handleResetPassword = methods.handleSubmit((data) => {
    sendPwRequest(data.email);
  });

  return (
    <PageContainer>
      <BlueField></BlueField>
      <LoginComponentWrapper>
        <FormProvider {...methods}>
          <Form onSubmit={(e) => e.preventDefault()} autoComplete='off'>
            <Header>Password Reset</Header>
            <Input {...emailValidation} />
            <ButtonContainer>
              {submitted ? (
                <Button onClick={() => navigate('/login')}>
                  Back to sign-in
                </Button>
              ) : (
                <Button onClick={handleResetPassword}>Reset password</Button>
              )}
            </ButtonContainer>
            {submitted ? <Message>{message}</Message> : <></>}
            <MakeAccountSection>
              <BlueLinkComponent
                text='Back to sign-in'
                onClick={() => navigate('/login')}
              ></BlueLinkComponent>
            </MakeAccountSection>
          </Form>
        </FormProvider>
      </LoginComponentWrapper>
    </PageContainer>
  );
};

const BlueField = styled.div`
  width: 500px;
  background-color: ${(props) => props.theme.colors.primary};
  @media (max-width: 1200px) {
    display: none;
  }
`;

const LoginComponentWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MakeAccountSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  text-align: center;
  margin-top: 10px;
`;

const Header = styled.h2`
  text-align: left;
  margin-bottom: 10px;
`;

const Message = styled.div`
  color: ${(props) => props.theme.colors.text.primary};
  margin-top: 10px;
  text-align: left;
`;

const PageContainer = styled.div`
  display: flex;
  height: 100vh;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 340px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

export default PwResetRequestComponent;
