import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';

import { BlueLinkComponent } from 'components/links';
import { Divider, Header, Paragraph, Section } from 'components/helpShared';

import {
  FINAL_VALUE_FEE,
  TRANSACTION_FEE_FLAT,
  TRANSACTION_FEE_PCT,
  SUPPORT_EMAIL,
} from 'config';

const faqs = [
  {
    question: 'Which locations are supported?',
    answer:
      'Currently, we only support buyers and sellers in the United States. While we aim to grow and provide our service worldwide, we are beginning with our home country and the market we know best.',
  },
  {
    question: 'What are your selling fees?',
    answer: `We charge a flat ${FINAL_VALUE_FEE}% fee for all sales. In addition, Stripe collects ${TRANSACTION_FEE_PCT}% and a $${TRANSACTION_FEE_FLAT} flat fee per transaction.`,
  },
  {
    question: 'Do you provide shipping labels for sellers?',
    answer: (navigate: ReturnType<typeof useNavigate>) => (
      <>
        Yes, sellers are able to create and print out shipping labels directly
        on the Near Mint website in the
        <BlueLinkComponent
          onClick={() => navigate('/myAccount/Listings')}
          text=' My Listings '
          fontSize='16px'
          color='blue'
          style={{ display: 'inline' }}
        />
        section.
      </>
    ),
  },
  {
    question: 'Do you provide authentication services?',
    answer:
      'Currently, we do not. However, this is on our roadmap. For now, we protect buyers through our strict fraud prevention techniques (see below).',
  },
  {
    question: 'Are my purchases protected?',
    answer: (navigate: ReturnType<typeof useNavigate>) => (
      <>
        Yes, all purchases are protected by the{' '}
        <BlueLinkComponent
          onClick={() => navigate('/guarantee')}
          text='Near Mint Guarantee'
          fontSize='16px'
          color='blue'
          style={{ display: 'inline' }}
        />
        . If the item(s) you receive are not as specified in the original
        listing, you are entitled to a full refund upon return to the seller.
      </>
    ),
  },
  {
    question: 'How do you protect against fraud?',
    answer: `First, we rely on our custom tiered approach for sellers, which requires ID verification and building up a history of positive transactions before one can sell high-value items. <br/><br/> Second, we exhaustively investigate all dispute claims. A trained human will review every single dispute and ascertain all of the facts before resolving them. If a prior judgment was found to be incorrect, we will remedy the situation and refund all affected parties. <br/><br/> Third, we have a zero tolerance policy for fraud and abuse of our system. Any buyers or sellers found to be abusing the system will be permanently banned from the platform.`,
  },
  {
    question: 'Which payment methods do you accept?',
    answer:
      'Currently, Stripe. We have plans to add additional payment methods in the future.',
  },
  {
    question: 'What is your refund policy?',
    answer:
      'You are fully protected when your order has not arrived or is not as described. If there is an issue, we strongly recommend contacting the seller first to allow them a chance to fix the issue. Sellers are incentivized to fix issues to maintain a high feedback rating.',
  },
  {
    question: 'When will I receive my order?',
    answer: (navigate: ReturnType<typeof useNavigate>) => (
      <>
        If the seller shipped with tracking, an estimated delivery date will be
        provided in your
        <BlueLinkComponent
          onClick={() => navigate('/myAccount/Purchases')}
          text=' purchase history.'
          fontSize='16px'
          color='blue'
          style={{ display: 'inline' }}
        />
      </>
    ),
  },
  {
    question: 'How do I contact customer support?',
    answer: `Please send us an email at ${SUPPORT_EMAIL} or visit our Discord server. We'll respond as soon as we can.`,
  },
];

export const FAQPage: React.FC = () => {
  const [openIndices, setOpenIndices] = useState<number[]>([]);
  const navigate = useNavigate();

  const toggleOpen = (index: number) => {
    setOpenIndices((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
    );
  };

  return (
    <Container>
      <Section>
        <Header>FAQ</Header>
        <StyledDivider />
        {faqs.map((faq, index) => (
          <div key={index}>
            <Question onClick={() => toggleOpen(index)}>
              {faq.question}
            </Question>
            <Answer isOpen={openIndices.includes(index)}>
              {typeof faq.answer === 'string' ? (
                <Paragraph dangerouslySetInnerHTML={{ __html: faq.answer }} />
              ) : (
                <Paragraph>{faq.answer(navigate)}</Paragraph>
              )}
            </Answer>
          </div>
        ))}
        <Paragraph>
          For more specific information, see our Buyer Guide and Seller Guide.
        </Paragraph>
      </Section>
    </Container>
  );
};

const StyledDivider = styled(Divider)`
  margin-bottom: 30px;
`;

const Container = styled.div`
  padding: 20px;
  max-width: 800px;
  margin: auto;
`;

const Question = styled.div`
  font-size: 18px;
  cursor: pointer;
  margin-bottom: 5px;
  background: #f0f0f0;
  padding: 10px;
  border-radius: 5px;
  &:hover {
    background: #e0e0e0;
  }
`;

const Answer = styled.div<{ isOpen: boolean }>`
  max-height: ${({ isOpen }) => (isOpen ? '500px' : '0')};
  overflow: hidden;
  transition: max-height 0.3s ease;
  padding: ${({ isOpen }) => (isOpen ? '10px' : '0 10px')};
  background: #f9f9f9;
  border-radius: 5px;
  margin-bottom: 10px;
`;
