import { useMutation, useQuery } from '@tanstack/react-query';

import useAPI from 'api';
import { Toast } from 'components/toast';

import { ShippingEstimateData, ShippingRatesData } from './types';

export const useUpdateShippingStatus = (
  setStatus: (status: string) => void
) => {
  const API = useAPI();
  return useMutation({
    mutationFn: async ({
      listingId,
      status,
      tracking,
    }: {
      listingId: string;
      status: string;
      tracking: string | null;
    }) => {
      await API('updateOrderStatus', {
        method: 'POST',
        body: JSON.stringify({ listingId, status, tracking }),
      });
      return status;
    },
    onSuccess: (status) => {
      Toast.success('Updated shipping status.');
      setStatus(status);
    },
    onError: (err: Error) => {
      Toast.error(err.message);
    },
  });
};

export const useCreateShippingLabel = (
  setLabelExists: (value: boolean) => void,
  setTrigger: React.Dispatch<React.SetStateAction<number>>
) => {
  const API = useAPI();
  return useMutation({
    mutationFn: async ({
      listingId,
      objectId,
      shipment,
      provider,
    }: {
      listingId: string;
      objectId: string;
      shipment: string;
      provider: string;
    }) => {
      await API('createShippingLabel', {
        method: 'POST',
        body: JSON.stringify({ listingId, objectId, shipment, provider }),
      });
    },
    onSuccess: () => {
      Toast.success('Created shipping label.');
      setLabelExists(true);
      setTrigger((prev) => prev + 1);
    },
    onError: (err: Error) => {
      Toast.error(err.message);
    },
  });
};

export const useGetShippingRates = (shippingInfo: ShippingRatesData) => {
  const API = useAPI();
  const url = `getShippingRates`;
  return useQuery<ShippingEstimateData[]>({
    queryKey: [url],
    queryFn: async () => {
      return await API(url, {
        method: 'POST',
        body: JSON.stringify(shippingInfo),
        headers: {
          'Content-Type': 'application/json',
        },
      });
    },
    enabled: !!shippingInfo,
  });
};

export const useVoidShippingLabel = (
  setTrigger: React.Dispatch<React.SetStateAction<number>>
) => {
  const API = useAPI();
  return useMutation({
    mutationFn: async ({ labelId }: { labelId: string }) => {
      await API('voidShippingLabel', {
        method: 'POST',
        body: JSON.stringify({ labelId }),
      });
    },
    onSuccess: () => {
      Toast.success('Submitted refund request.');
      setTrigger((prev) => prev + 1);
    },
    onError: (err: Error) => {
      Toast.error(err.message);
    },
  });
};
