import styled from '@emotion/styled';

import { STATES_ABBRV } from 'config';

export const IMAGE_FILE_TYPES = '.jpg,.jpeg,.png,.avif,.webp';
export const GENERIC_ERR_MSG = 'An error occurred. Please try again shortly.';

export const ValidationMessage = styled.div`
  color: red;
  margin-top: 4px;
  margin-bottom: 4px;
  font-size: 0.875em; // Example size, adjust as needed
`;

export const emailCodeValidation = {
  label: 'One-Time Passcode',
  name: 'emailCode',
  type: 'text',
  id: 'emailCode',
  placeholder: 'Enter your one-time passcode',
  validation: {
    required: {
      value: true,
      message: 'One-time passcode is required.',
    },
    pattern: {
      value: /^\d{6}$/,
      message: 'Must be exactly 6 digits.',
    },
  },
};

export const phoneCodeValidation = {
  label: 'One-Time Passcode',
  name: 'phoneCode',
  type: 'text',
  id: 'phoneCode',
  placeholder: 'Enter your one-time passcode',
  validation: {
    required: {
      value: true,
      message: 'One-time passcode is required.',
    },
    pattern: {
      value: /^\d{4}$/,
      message: 'Must be exactly 4 digits.',
    },
  },
};

export const emailValidation = {
  label: 'email',
  name: 'email',
  type: 'email',
  id: 'email',
  placeholder: 'Email address',
  validation: {
    required: 'Email is required',
    pattern: {
      value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      message: 'Invalid email address.',
    },
  },
};

export const phoneValidation = {
  label: 'Phone Number',
  name: 'phone',
  type: 'text',
  id: 'phone',
  placeholder: 'Enter your phone number',
  validation: {
    required: {
      value: true,
      message: 'Phone number is required.',
    },
    maxLength: {
      value: 12,
      message: 'Phone must be 10 digits',
    },
    pattern: {
      value: /^\d{3}-\d{3}-\d{4}$/,
      message: 'Must be of form XXX-XXX-XXXX',
    },
  },
};

export const phoneMask = (value: string) => {
  value = value.replace(/\D/g, ''); // Remove all non-digit characters
  if (value.length > 6) {
    value = value.replace(/(\d{3})(\d{3})(\d{0,4})/, '$1-$2-$3'); // Format as XXX-XXX-XXXX
  } else if (value.length > 3) {
    value = value.replace(/(\d{3})(\d{0,3})/, '$1-$2'); // Format as XXX-XXX
  }
  return value.substring(0, 12); // Ensure the maximum length is 12 (10 digits + 2 dashes)
};

export const passwordValidation = {
  label: 'password',
  name: 'password',
  type: 'password',
  id: 'password',
  placeholder: 'Enter password',
  validation: {
    required: {
      value: true,
      message: 'Password is required.',
    },
    minLength: {
      value: 8,
      message: 'Password must be at least 8 characters',
    },
    maxLength: {
      value: 30,
      message: 'Password must be at most 30 characters',
    },
  },
};

export const passwordValidationLogin = {
  label: 'password',
  name: 'password',
  type: 'password',
  id: 'password',
  placeholder: 'Enter password',
  validation: {},
};

export const passwordUpdateValidation = {
  label: 'Password Update',
  name: 'passwordUpdate',
  type: 'passwordUpdate',
  id: 'passwordUpdate',
  placeholder: 'Enter new password',
  validation: {
    required: {
      value: true,
      message: 'Password is required.',
    },
    minLength: {
      value: 8,
      message: 'Password must be at least 8 characters',
    },
    maxLength: {
      value: 30,
      message: 'Password must be at most 30 characters',
    },
  },
};

export const passwordConfirmValidation = (getValues: any) => ({
  label: 'Password Confirm',
  name: 'passwordConfirm',
  type: 'passwordConfirm',
  id: 'passwordConfirm',
  placeholder: 'Confirm password',
  validation: {
    required: {
      value: true,
      message: 'Password is required.',
    },
    minLength: {
      value: 8,
      message: 'Password must be at least 8 characters',
    },
    maxLength: {
      value: 30,
      message: 'Password must be at most 30 characters',
    },
    validate: {
      match: (value: string) =>
        value === getValues('passwordUpdate') || 'Passwords do not match',
    },
  },
});

export const usernameValidation = {
  label: 'Username',
  name: 'username',
  type: 'username',
  id: 'username',
  placeholder: 'Username',
  validation: {
    required: {
      value: true,
      message: 'Username is required.',
    },
    minLength: {
      value: 4,
      message: 'Username must be at least 4 characters',
    },
    maxLength: {
      value: 30,
      message: 'Username must be at most 30 characters',
    },
    pattern: {
      value: /^[a-zA-Z0-9_]+$/,
      message:
        'Username must only contain alphanumeric and underscore characters',
    },
  },
};

export const address1Validation = {
  label: 'Address 1',
  name: 'address1',
  type: 'text',
  id: 'address1',
  placeholder: '',
  validation: {
    required: {
      value: true,
      message: 'Address 1 is required.',
    },
    maxLength: {
      value: 100,
      message: 'Address 1 must be at most 100 characters',
    },
  },
};

export const address2Validation = {
  label: 'Address 2',
  name: 'address2',
  type: 'text',
  id: 'address2',
  placeholder: '',
  validation: {
    maxLength: {
      value: 100,
      message: 'Address 2 must be at most 100 characters',
    },
  },
};

export const cityValidation = {
  label: 'City',
  name: 'city',
  type: 'text',
  id: 'city',
  placeholder: '',
  validation: {
    required: {
      value: true,
      message: 'City is required.',
    },
    maxLength: {
      value: 50,
      message: 'City must be at most 50 characters',
    },
  },
};

export const stateValidation = {
  label: 'State',
  name: 'state',
  type: 'text',
  id: 'state',
  placeholder: 'State',
  defaultValue: 'Select state',
  validation: {
    required: {
      value: true,
      message: 'State is required.',
    },
    validate: {
      value: (value: string) =>
        STATES_ABBRV.includes(value) ||
        'Must be a 2-letter state abbreviation.',
    },
  },
  choices: STATES_ABBRV,
};

export const zipMask = (value: string) => {
  value = value.replace(/\D/g, '');
  if (value.length > 5) {
    value = value.replace(/(\d{5})(\d{0,4})/, '$1-$2');
  }
  return value.substring(0, 10);
};

export const zipValidation = {
  label: 'Zip',
  name: 'zip',
  type: 'text',
  id: 'zip',
  placeholder: '',
  maxLength: 10,
  validation: {
    required: {
      value: true,
      message: 'Zip Code is required.',
    },
    pattern: {
      value: /^\d{5}(-\d{4})?$/,
      message: 'Format should be XXXXX or XXXXX-XXXX.',
    },
  },
};

const nameValidation = {
  label: 'Name',
  name: 'name',
  type: 'text',
  id: 'name',
  placeholder: 'Enter your name',
  validation: {
    required: {
      value: true,
      message: 'Name is required.',
    },
    minLength: {
      value: 1,
      message: 'Name must be at least 1 character',
    },
    maxLength: {
      value: 50,
      message: 'Name must be at most 50 characters',
    },
    pattern: {
      value: /^[a-zA-Z-' ]+$/,
      message: 'Only letters, hyphens, apostrophes, and spaces are allowed.',
    },
  },
};

export const fullNameValidation = {
  ...nameValidation,
  label: 'Full Name',
  name: 'full_name',
  id: 'full_name',
  placeholder: '',
};

export const firstNameValidation = {
  ...nameValidation,
  label: 'First Name',
  name: 'first_name',
  id: 'first_name',
  placeholder: '',
};

export const middleNameValidation = {
  ...nameValidation,
  label: 'Middle Name',
  name: 'middle_name',
  id: 'middle_name',
  placeholder: '',
  validation: {
    ...nameValidation.validation,
    required: false,
  },
};

export const lastNameValidation = {
  ...nameValidation,
  label: 'Last Name',
  name: 'last_name',
  id: 'last_name',
  placeholder: '',
};
