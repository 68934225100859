import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import useAPI from 'api';
import { Toast } from 'components/toast';

export const useStripeCheckout = () => {
  const API = useAPI();
  const navigate = useNavigate();

  const fetchClientSecret = useMutation({
    mutationFn: async (listingId: string) => {
      const apiRes = await API<{ clientSecret: string; sessionId: string }>(
        'createOrder',
        {
          method: 'POST',
          body: JSON.stringify({ listingId }),
        }
      );
      return apiRes; // Returns both clientSecret and sessionId
    },
    onError: (error: any) => {
      Toast.error(error.message || 'Failed to initialize checkout.');
    },
  });

  const captureOrder = useMutation({
    mutationFn: async (sessionId: string) => {
      await API('captureOrder', {
        method: 'POST',
        body: JSON.stringify({ sessionId }),
      });
    },
    onSuccess: () => {
      Toast.success('Order captured successfully.');
      navigate('/checkoutSuccessful');
    },
    onError: (error: any) => {
      Toast.error(error.message || 'Failed to capture order.');
    },
  });

  return { fetchClientSecret, captureOrder };
};
