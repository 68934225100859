import React, { createContext, ReactNode, useContext, useState } from 'react';

interface TopnavContextType {
  searchQuery: string;
  setSearchQuery: (query: string) => void;
  searchCategory: string;
  setSearchCategory: (query: string) => void;
  unreadMsgCount: number;
  setUnreadMsgCount: (count: number) => void;
  profilePicUrl: string;
  setProfilePicUrl: (url: string) => void;
}

const TopnavContext = createContext<TopnavContextType | undefined>(undefined);

interface TopnavProviderProps {
  children: ReactNode;
}

export const TopnavProvider: React.FC<TopnavProviderProps> = ({ children }) => {
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [searchCategory, setSearchCategory] = useState<string>('');
  const [unreadMsgCount, setUnreadMsgCount] = useState<number>(0);
  const [profilePicUrl, setProfilePicUrl] = useState<string>('');

  return (
    <TopnavContext.Provider
      value={{
        searchQuery,
        setSearchQuery,
        searchCategory,
        setSearchCategory,
        unreadMsgCount,
        setUnreadMsgCount,
        profilePicUrl,
        setProfilePicUrl,
      }}
    >
      {children}
    </TopnavContext.Provider>
  );
};

export const useTopnav = () => {
  const context = useContext(TopnavContext);
  if (context === undefined) {
    throw new Error('useTopnav must be used within a TopnavProvider');
  }
  return context;
};
