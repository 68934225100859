import { useNavigate, useParams } from 'react-router-dom';

import {
  FeedbackForSellerForm,
  FeedbackForBuyerForm,
} from 'components/feedback';

const FeedbackPage = () => {
  const { feedbackType } = useParams();
  const navigate = useNavigate();

  if (feedbackType !== 'forBuyer' && feedbackType !== 'forSeller') {
    navigate('/home');
  }

  return (
    <>
      {feedbackType === 'forBuyer' ? (
        <FeedbackForBuyerForm></FeedbackForBuyerForm>
      ) : (
        <FeedbackForSellerForm></FeedbackForSellerForm>
      )}
      ;
    </>
  );
};

export default FeedbackPage;
