import React, { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import styled from '@emotion/styled';
import { useLocation, useNavigate } from 'react-router-dom';

import { PRODUCT_NAME_FULL } from 'config';
import { Input } from 'components/input';
import { Button } from 'components/buttons';
import { BlueLinkComponent } from 'components/links';
import {
  emailValidation,
  passwordValidationLogin,
} from 'components/validation';

import { useDoLogin } from './hooks';

interface LoginComponentInt {
  message: string;
  successMessage: string;
  setMessage: (m: string) => void;
  onAccountCreationRequest: () => void;
}

const LoginComponent = ({
  message,
  successMessage,
  setMessage,
  onAccountCreationRequest,
}: LoginComponentInt) => {
  const [redirectMsg, setRedirectMsg] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const { mutate: doLogin, status: loginStatus } = useDoLogin();

  const handleCreateAccount = (e: React.MouseEvent) => {
    onAccountCreationRequest();
    setMessage('');
  };

  const methods = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  // Reset form on mount
  useEffect(() => {
    methods.reset({
      email: '',
      password: '',
    });
    // Parse query parameters to set the message
    const params = new URLSearchParams(location.search);
    const reason = params.get('reason');
    if (reason === 'noToken') {
      if (params.toString().includes('checkout')) {
        setRedirectMsg(
          'You must sign in or create an account to purchase an item.'
        );
      } else {
        setRedirectMsg(
          'You need to sign in or create an account to access this.'
        );
      }
    } else if (reason === 'tokenInvalid') {
      setRedirectMsg('Your session has expired. Please sign in again.');
    }
  }, [methods, location.search, setRedirectMsg]);

  const handleLogin = methods.handleSubmit((data) => {
    doLogin({ email: data.email, password: data.password });
  });

  return (
    <PageContainer>
      <BlueField></BlueField>
      <LoginComponentWrapper>
        <FormProvider {...methods}>
          <Form onSubmit={(e) => e.preventDefault()} autoComplete='off'>
            <Header>
              Sign in to{' '}
              <span style={{ display: 'block' }}>{PRODUCT_NAME_FULL}</span>
            </Header>
            {redirectMsg && <RedirectMessage>{redirectMsg}</RedirectMessage>}
            <Input {...emailValidation} />
            <Input {...passwordValidationLogin} />
            <BlueLinkComponent
              text='Reset password'
              onClick={() => navigate('/passwordReset')}
            ></BlueLinkComponent>
            <ButtonContainer>
              <Button
                onClick={handleLogin}
                disabled={loginStatus === 'pending'}
              >
                Sign in
              </Button>
            </ButtonContainer>
            {message && <Message>{message}</Message>}
            {successMessage && (
              <SuccessMessage>{successMessage}</SuccessMessage>
            )}
            <MakeAccountSection>
              {'Need to make an account?'}
              <BlueLinkComponent
                text='Make one here'
                onClick={handleCreateAccount}
              ></BlueLinkComponent>
            </MakeAccountSection>
          </Form>
        </FormProvider>
      </LoginComponentWrapper>
    </PageContainer>
  );
};

const BlueField = styled.div`
  width: 500px;
  background-color: ${(props) => props.theme.colors.primary};
  @media (max-width: 1200px) {
    display: none;
  }
`;

const LoginComponentWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MakeAccountSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  text-align: center;
  gap: 5px;
`;

const Header = styled.h2`
  text-align: left;
  margin-bottom: 10px;
`;

const RedirectMessage = styled.div`
  color: red;
  text-align: center;
`;

const Message = styled.div`
  color: red;
  margin-top: 10px;
  text-align: center;
`;

const SuccessMessage = styled.div`
  color: green;
  margin-top: 10px;
  text-align: center;
`;

const PageContainer = styled.div`
  display: flex;
  height: 100vh;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 340px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 0px;
`;

export default LoginComponent;
