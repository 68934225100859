import { useEffect } from 'react';
import styled from '@emotion/styled';
import { useLocation } from 'react-router-dom';

import { Container } from 'components/myAccount';

import { EmailForm } from './emailForm';
import { PasswordForm } from './passwordForm';

export const EmailAndPassword = () => {
  const location = useLocation();

  useEffect(() => {
    const sectionId = location.hash.replace('#', '');
    const sectionElement = document.getElementById(sectionId);
    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location]);

  return (
    <>
      <Container>
        <Section id='email'>
          <EmailForm />
        </Section>
        <Section id='password'>
          <PasswordForm />
        </Section>
      </Container>
    </>
  );
};

const Section = styled.div``;
