import { useEffect } from 'react';
import styled from '@emotion/styled';
import { useLocation } from 'react-router-dom';

import LoadingSpinner from 'components/loading';
import { Container } from 'components/myAccount';

import { AddressForm } from './addressForm';
import { NameForm } from './nameForm';
import { PhoneForm } from './phoneForm';
import { useGetAccountInfo } from 'hooks/account';

export const PersonalInfo = () => {
  const { data, isLoading } = useGetAccountInfo();
  const location = useLocation();

  useEffect(() => {
    const sectionId = location.hash.replace('#', '');
    const sectionElement = document.getElementById(sectionId);
    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location]);

  if (isLoading) {
    return <LoadingSpinner />;
  }
  if (!data) {
    return <div>Error: Account information is not available.</div>;
  }
  return (
    <>
      <Container>
        <Section id='address'>
          <AddressForm data={data.accountInfo} />
        </Section>
        <Section id='name'>
          <NameForm data={data.accountInfo} />
        </Section>
        <Section id='phone'>
          <PhoneForm data={data.accountInfo} />
        </Section>
      </Container>
    </>
  );
};

const Section = styled.div``;
