import { useNavigate } from 'react-router-dom';

import { Breadcrumbs } from 'components/breadcrumbs';
import {
  Container,
  Content,
  Divider,
  Header,
  Paragraph,
  Section,
  Title,
} from 'components/helpShared';
import { BlueLinkComponent } from 'components/links';
import { PRODUCT_NAME } from 'config';

export const CommunityGuidelines = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <Breadcrumbs
        items={[
          { path: '/help', label: 'Help Home' },
          { path: '/help/communityGuidelines', label: 'Community Guidelines' },
        ]}
      />
      <Header>Community Guidelines</Header>
      <Divider />
      <Content>
        <Section>
          <Paragraph>
            We're thrilled that you've decided to join the {PRODUCT_NAME}{' '}
            community, or even if you're just here to check us out! To maintain
            a positive atmosphere, we abide by and enforce a few rules for all
            users to follow.
          </Paragraph>
          <Title>Conduct</Title>
          <Paragraph>
            Our community thrives when members maintain honest, constructive
            communication and resolve disputes through mutual understanding and
            patience. At any time where your actions may be seen or felt by
            other users or {PRODUCT_NAME} staff, or when your actions are
            associated with {PRODUCT_NAME} in any way, we ask you to communicate
            and act respectfully. Avoid offensive language, threats, or
            inappropriate comments, and treat every other user with kindness and
            fairness.
          </Paragraph>
          <Paragraph>
            This applies to situations including but not limited to: leaving
            feedback, messaging users, generating a listing, communicating with
            support, posting on our Discord server, and interacting with our X
            account.
          </Paragraph>
          <Title>Everyone's Community</Title>
          <Paragraph>
            We believe this marpketplace should be a welcoming and inclusive
            space for everyone. Respect and camaraderie are at the core of our
            community — every collector deserves to be treated with dignity and
            kindness. Remember: "Character is how you treat others who can do
            nothing for you."
          </Paragraph>
          <Title>Feedback to Us</Title>
          <Paragraph>
            Our top goal is to become the best peer-to-peer marketplace for
            buyers and sellers of collectibles. The only way we can achieve that
            goal is by listening to and implementing suggestions from our users.
            Please at any time{' '}
            <BlueLinkComponent
              onClick={() => navigate('/contact')}
              text='contact us'
              fontSize='16px'
            />{' '}
            with your ideas. We read every single comment, suggestion, and
            request.
          </Paragraph>
        </Section>
      </Content>
    </Container>
  );
};
