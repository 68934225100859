import React from 'react';
import styled from '@emotion/styled';

import { GridListingCard } from './gridListingCard';
import { ListingRecord } from './types';

interface GridProps {
  listings: ListingRecord[];
}

export const SearchResultsGrid: React.FC<GridProps> = (props) => {
  return (
    <>
      <GridContainer>
        {props.listings.map((listing) => (
          <GridListingCard listing={listing} key={listing.id} />
        ))}
      </GridContainer>
    </>
  );
};

const GridContainer = styled.div`
  display: grid;
  margin: 20px 40px;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 30px 20px;
  justify-content: center;
`;
