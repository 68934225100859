import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';

import { GreyLink } from 'components/links';

export const ListingCanNotEditLiveOrder = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <Text>This listing has been purchased and cannot be modified.</Text>
      <GreyLink onClick={() => navigate('/')}>← Back to home</GreyLink>
      <GreyLink onClick={() => navigate(`/myAccount/Listings`)}>
        Manage listings
      </GreyLink>
    </Container>
  );
};

const Text = styled.h2``;

const Container = styled.div`
  margin-top: 120px;
  padding: 0 10px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  & > *:not(:last-child) {
    margin-bottom: 30px;
  }
`;
