import { useEffect } from 'react';
import { useForm, FormProvider, useWatch } from 'react-hook-form';
import styled from '@emotion/styled';
import { Info } from '@phosphor-icons/react';

import { theme } from 'styles';
import { Container, Form, Row, Header } from 'components/myAccount';
import { StyledIcon } from 'components/icons';
import { CheckboxInput, Dropdown, Input, RadioInput } from 'components/input';
import { Button } from 'components/buttons';
import LoadingSpinner from 'components/loading';
import * as val from 'components/validation';

import { TaxClassifications } from 'types';

import { useGetW9TaxForm, useUpdateW9TaxForm } from './hooks';
import { defaultValues, packageFormData, resetW9TaxForm } from './helpers';

export const W9TaxForm: React.FC = () => {
  const { data, isLoading } = useGetW9TaxForm();
  const { mutate: submitForm } = useUpdateW9TaxForm();
  const userId = localStorage.getItem('userId') || '';
  const methods = useForm({
    defaultValues,
  });

  const showDbaInput = useWatch({
    control: methods.control,
    name: 'dbaFlag',
    defaultValue: false,
  });

  const showExemptInput = useWatch({
    control: methods.control,
    name: 'exemptFlag',
    defaultValue: false,
  });

  const selectedTaxIdType = useWatch({
    control: methods.control,
    name: 'tax_id_number_type',
    defaultValue: 'SSN',
  });

  const selectedTaxClassification = useWatch({
    control: methods.control,
    name: 'tax_classification',
    defaultValue: '',
  });

  useEffect(() => {
    if (data && data.w9TaxForm) {
      resetW9TaxForm(data, methods);
    }
  }, [data, methods]);

  useEffect(() => {
    if (!showDbaInput) {
      methods.setValue('doing_business_as', '');
    }
  }, [showDbaInput, methods]);

  if (isLoading) return <LoadingSpinner />;

  const handleFormSubmit = methods.handleSubmit((formData) => {
    const w9TaxFormData = packageFormData(userId, formData);
    submitForm(w9TaxFormData);
    methods.reset(formData);
  });

  const smallWidth = '140px';
  const inputWidth = '360px';
  const fullWidth = '100%';

  return (
    <Container>
      <Header>W9 Tax Form</Header>
      <Content>
        <FormProvider {...methods}>
          <Form onSubmit={(e) => e.preventDefault()} autoComplete='off'>
            <Subtitle>
              Select the appropriate federal tax classification before entering
              all required fields below.
            </Subtitle>
            <Row>
              <InputWrapper>
                <Dropdown
                  {...val.taxClassificationValidation}
                  choices={val.taxClassifications}
                  width={fullWidth}
                />
              </InputWrapper>
            </Row>
            {selectedTaxClassification && (
              <>
                {[
                  TaxClassifications.Business,
                  TaxClassifications.Trust,
                ].includes(selectedTaxClassification as TaxClassifications) ? (
                  <Row>
                    <InputWrapper>
                      <Input {...val.entityNameValidation} width={fullWidth} />
                    </InputWrapper>
                  </Row>
                ) : (
                  <>
                    <Row>
                      <InputWrapper>
                        <Input
                          {...val.firstNameValidation}
                          width={inputWidth}
                        />
                      </InputWrapper>
                      <InputWrapper>
                        <Input
                          {...val.middleInitialValidation}
                          width={smallWidth}
                        />
                      </InputWrapper>
                    </Row>
                    <Row>
                      <InputWrapper>
                        <Input {...val.lastNameValidation} width={inputWidth} />
                      </InputWrapper>
                      <InputWrapper>
                        <Input {...val.suffixValidation} width={smallWidth} />
                      </InputWrapper>
                    </Row>
                  </>
                )}
                <Row>
                  <InputWrapper>
                    <CheckboxInput {...val.dbaFlagValidation} />
                  </InputWrapper>
                </Row>
                {showDbaInput && (
                  <Row>
                    <InputWrapper>
                      <Input
                        {...val.doingBusinessAsValidation}
                        width={fullWidth}
                      />
                    </InputWrapper>
                  </Row>
                )}
                <Row>
                  <InputWrapper>
                    <Input {...val.address1TaxValidation} width={inputWidth} />
                  </InputWrapper>
                  <InputWrapper>
                    <Input {...val.address2TaxValidation} width={inputWidth} />
                  </InputWrapper>
                </Row>
                <Row>
                  <InputWrapper>
                    <Input {...val.cityValidation} width={inputWidth} />
                  </InputWrapper>
                  <InputWrapper>
                    <Dropdown {...val.stateValidation} />
                  </InputWrapper>
                </Row>
                <Row>
                  <InputWrapper>
                    <Input
                      {...val.zipValidation}
                      inputMask={val.zipMask}
                      width={inputWidth}
                    />
                  </InputWrapper>
                </Row>
                <Title>Tax Identification Number (TIN)</Title>
                {[
                  TaxClassifications.Individual,
                  TaxClassifications.Trust,
                ].includes(selectedTaxClassification as TaxClassifications) ? (
                  <>
                    <Row>
                      <InputWrapper>
                        <RadioInput
                          name='tax_id_number_type'
                          choices={[
                            {
                              value: 'ssn',
                              label: 'Social Security Number (SSN)',
                            },
                            {
                              value: 'itin',
                              label:
                                'Individual Taxpayer Identification Number (ITIN)',
                            },
                            {
                              value: 'ein',
                              label: 'Employee Identification Number (EIN)',
                            },
                          ]}
                          width={fullWidth}
                          validation={{ required: 'Please select a TIN type' }}
                        />
                      </InputWrapper>
                    </Row>
                    {selectedTaxIdType === 'ssn' && (
                      <Row>
                        <InputWrapper>
                          <Input
                            {...val.ssnValidation}
                            width='fullWidth'
                            inputMask={val.ssnMask}
                          />
                        </InputWrapper>
                      </Row>
                    )}
                    {selectedTaxIdType === 'itin' && (
                      <Row>
                        <InputWrapper>
                          <Input
                            {...val.itinValidation}
                            width='fullWidth'
                            inputMask={val.itinMask}
                          />
                        </InputWrapper>
                      </Row>
                    )}
                    {selectedTaxIdType === 'ein' && (
                      <Row>
                        <InputWrapper>
                          <Input
                            {...val.einValidation}
                            width='fullWidth'
                            inputMask={val.einMask}
                          />
                        </InputWrapper>
                      </Row>
                    )}
                  </>
                ) : (
                  <Row>
                    <InputWrapper>
                      <Input
                        {...val.einValidation}
                        width='fullWidth'
                        inputMask={val.einMask}
                      />
                    </InputWrapper>
                  </Row>
                )}
                <Title>Withholdings</Title>
                <Row>
                  <InputWrapper>
                    <CheckboxInput {...val.exemptFlagValidation} />
                  </InputWrapper>
                </Row>
                {showExemptInput && (
                  <>
                    <CheckboxInput {...val.exemption1Validation} />
                    <CheckboxInput {...val.exemption2Validation} />
                    <CheckboxInput {...val.exemption3Validation} />
                    <CheckboxInput {...val.exemption4Validation} />
                    <CheckboxInput {...val.exemption5Validation} />
                    <CheckboxInput {...val.exemption6Validation} />
                    <CheckboxInput {...val.exemption7Validation} />
                    <CheckboxInput {...val.exemption8Validation} />
                    <CheckboxInput {...val.exemption9Validation} />
                    <CheckboxInput {...val.exemption10Validation} />
                    <CheckboxInput {...val.exemption11Validation} />
                    <CheckboxInput {...val.exemption12Validation} />
                    <CheckboxInput {...val.exemption13Validation} />
                  </>
                )}
                <Title>Certification Agreement</Title>
                <CertificationText>
                  By submitting this form, you agree that the information
                  provided above fully, accurately and uniquely identifies you
                  in our database. You furthermore agree that your submission of
                  this form via the "Confirm" button shall constitute the
                  exaction of this document in exactly the same manner as if you
                  had signed by hand a paper version of this form. Furthermore,
                  you certify that under penalties of perjury that:
                  <br />
                  <br />
                  1. The number shown on this form is my correct taxpayer
                  identification number (or I am waiting for a number to be
                  issued to me); and
                  <br />
                  <br />
                  <CertificationRow>
                    <span>2.</span>
                    <CheckboxWrapper>
                      <CheckboxInput
                        name='subject_to_backup_witholdings'
                        id='subject_to_backup_witholdings'
                        label=''
                      />
                    </CheckboxWrapper>
                    <CertificationText>
                      I am not subject to backup withholding because (a) I am
                      exempt from backup withholding or (b) I have not been
                      notified by the Internal Revenue Service (IRS) that I am
                      subject to backup withholding as a result of failure to
                      report all interest or dividends or (c) the IRS has
                      notified me that I am no longer subject to backup
                      withholding,
                    </CertificationText>
                  </CertificationRow>
                  <br />
                  3. I am a U.S. citizen or other U.S. person.
                  <br />
                  <InfoBox>
                    <StyledIcon icon={Info} theme={theme.icon.info} />
                    <TextContainer>
                      You must uncheck item 2 above if you have been notified by
                      the IRS that you are currently subject to backup
                      withholding because you have failed to report all income
                      and dividends on your tax return.
                      <br />
                      <br />
                      For real estate transactions, item 2 does not apply. For
                      mortgage interest paid, acquisition or abandonment of
                      secured property, cancellation of debt, contributions to
                      an individual retirement arrangement (IRA) and generally,
                      payment other than interest and dividends, you are not
                      required to sign the certification, but you must provide
                      your correct TIN.
                    </TextContainer>
                  </InfoBox>
                  <br />
                </CertificationText>
                <ButtonContainer>
                  <Button
                    width={'240px'}
                    onClick={handleFormSubmit}
                    disabled={!methods.formState.isDirty}
                  >
                    Submit W-9
                  </Button>
                </ButtonContainer>
              </>
            )}
          </Form>
        </FormProvider>
      </Content>
    </Container>
  );
};

const Content = styled.div`
  padding: 20px;
  border: 1px solid ${(props) => props.theme.colors.border.primary};
`;

const InputWrapper = styled.div`
  flex: 1;
  &:not(:last-child) {
    margin-right: 20px;
  }
`;

const ButtonContainer = styled.div`
  margin-top: 10px;
`;

const Title = styled.h3`
  margin: 0 0 10px 0;
`;

const Subtitle = styled.h4`
  margin: 0 0 20px 0;
  color: #666;
`;

const CertificationRow = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 6px;
  margin-bottom: 10px;
`;

const CheckboxWrapper = styled.div`
  flex-shrink: 0;
`;

const CertificationText = styled.span`
  flex-grow: 1;
`;

const InfoBox = styled.div`
  background-color: #e0f7ff;
  border: 1px solid #0077b6;
  border-radius: 10px;
  padding: 16px;
  margin-top: 16px;
  font-size: 16px;
  color: #333;
  display: flex;
  align-items: flex-start;
  gap: 8px;
`;

const TextContainer = styled.div`
  flex-grow: 0;
`;
