import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from '@emotion/styled';

import LoadingSpinner from 'components/loading';
import { Container, Header } from 'components/myAccount';
import { useTopnav } from 'components/topnavContext';

import Conversation from './Conversation';
import { useGetConversations, useMarkConversationRead } from './hooks';
import {
  AllConversationsProps,
  ConversationRecord,
  ConversationRowProps,
} from './types';

const AllConversations: React.FC<AllConversationsProps> = (props) => {
  return (
    <>
      {props.conversations.map((c) => (
        <ConversationRow
          key={c.id}
          unread={c.unread}
          onClick={() => props.onSelectConversation(c)}
        >
          <FromText>{c.other_party_username}</FromText>
          <LastMessage>{c.last_message}</LastMessage>
        </ConversationRow>
      ))}
    </>
  );
};

const MessagingPortal: React.FC = () => {
  const { unreadMsgCount, setUnreadMsgCount } = useTopnav();
  const userId = localStorage.getItem('userId') || '';
  const [conversations, setConversations] = useState<ConversationRecord[]>([]);
  // const [conversationId, setConversationId] = useState<string | null>(null);
  const { mutate: doMarkConversationRead } = useMarkConversationRead();
  const { data, isLoading } = useGetConversations();
  const navigate = useNavigate();
  const { conversationId } = useParams();

  useEffect(() => {
    if (data?.conversationData && !isLoading) {
      setConversations(data.conversationData);
    }
  }, [data, isLoading]);

  const handleSelectConversation = (convo: ConversationRecord) => {
    // Update unreadMsgCount context
    if (convo.user_id_1 === userId && convo.unseen_count_user_1 > 0) {
      setUnreadMsgCount(Math.max(0, unreadMsgCount - 1));
    }
    // Navigate to the conversation
    navigate(`/myAccount/Messages/${convo.id}`);
    // Mark the conversation as unread
    doMarkConversationRead({
      conversationId: convo.id,
    });
  };

  if (isLoading) return <LoadingSpinner />;

  const renderContent = () => {
    if (conversationId) {
      const selectedConversation = conversations.find(
        (c) => c.id === conversationId
      );
      if (selectedConversation) {
        return (
          <Conversation
            conversationId={selectedConversation.id}
            userId={userId.toString()}
            otherPartyName={selectedConversation.other_party_username}
          />
        );
      }
    }
    return (
      <>
        <Header>All conversations</Header>
        {conversations.length > 0 ? (
          <AllConversations
            conversations={conversations}
            onSelectConversation={handleSelectConversation}
          ></AllConversations>
        ) : (
          <Placeholder>
            You haven't messaged other users yet. When you do, they'll show up
            here.
          </Placeholder>
        )}
      </>
    );
  };

  return (
    <PageWrapper>
      <Container>
        <MainView>{renderContent()}</MainView>
      </Container>
    </PageWrapper>
  );
};

const Placeholder = styled.div`
  margin-top: 20px;
  max-width: 800px;
`;

const FromText = styled.div`
  font-size: 0.8rem;
`;

const LastMessage = styled.div`
  font-size: 0.8rem;
  font-style: italic;
  margin-top: 5px;
`;

const PageWrapper = styled.div`
  // display: flex;
  flex-direction: column;
  // align-items: flex-start;
`;

const MainView = styled.div`
  flex-grow: 1;
  overflow: auto;
`;

const createShouldForwardProp = (excludedProps: string[]) => (prop: string) =>
  !excludedProps.includes(prop);

const ConversationRow = styled('div', {
  shouldForwardProp: createShouldForwardProp(['unread']),
})<ConversationRowProps>`
  padding: 10px;
  border-bottom: 1px solid #ccc;
  font-weight: ${({ unread }) => (unread ? 'bold' : 'normal')};
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }
`;

export default MessagingPortal;
