const baseURL = process.env.REACT_APP_API_HOST;

export class APIError extends Error {
  status: number;

  constructor(message: string, status: number) {
    super(message);
    this.status = status;
  }
}

const parse401Reason = (message: string) => {
  let reason;
  if (message === 'No token provided') {
    reason = 'noToken';
  } else if (message === 'Invalid token') {
    reason = 'tokenInvalid';
  }
  return reason;
};

export async function API<T>(
  endpoint: string,
  options?: RequestInit,
  logout?: () => void,
  setIsRedirecting?: (value: boolean) => void // Add this parameter
): Promise<T> {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
  };

  const response: any = await fetch(`${baseURL}/${endpoint}`, {
    ...options,
    headers: {
      ...headers,
      ...options?.headers,
    },
  });

  // Handle 404
  if (response.status === 404) {
    throw new APIError('Not Found', response.status); // Handle 404 error
  }

  const jsonResponse = await response.json();

  if (!response.ok) {
    // Handle 401
    if (response.status === 401) {
      const originalUrl = window.location.href;
      if (logout) {
        logout();
      }
      const reason = parse401Reason(jsonResponse.message);
      if (reason && setIsRedirecting) {
        setIsRedirecting(true);
        window.location.href = `/login?redirect=${encodeURIComponent(
          originalUrl
        )}&reason=${reason}`;
      }
      throw new APIError(
        jsonResponse.message || 'Unauthorized',
        response.status
      );
    } else {
      throw new APIError(jsonResponse.message, response.status);
    }
  }

  return jsonResponse;
}

export type APIType = typeof API;
