import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { loadConnectAndInitialize } from '@stripe/connect-js';
import {
  ConnectAccountOnboarding,
  ConnectComponentsProvider,
} from '@stripe/react-connect-js';

import LoadingSpinner from 'components/loading';
import { Container, Header } from 'components/myAccount';
import { Toast } from 'components/toast';

import { useGetStripeAccount } from './hooks';

const baseApiUrl = process.env.REACT_APP_API_HOST;
const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY || '';
const headers = {
  'Content-Type': 'application/json',
  Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
};

export const StripeAccountSetup = () => {
  const { data, isLoading } = useGetStripeAccount();
  const accountIdRef = useRef<string | null>(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const showToast = params.get('successfulUpdate');

    if (showToast === 'true') {
      Toast.success('Successfully updated Stripe information.');
      params.delete('successfulUpdate');
      navigate(`/myAccount/SellerSetup?${params.toString()}`, {
        replace: true,
      });
    }
  }, [location, navigate]);

  const [stripeConnectInstance] = useState(() => {
    const fetchClientSecret = async () => {
      // Fetch the AccountSession client secret
      const response = await fetch(`${baseApiUrl}/createAccountSession`, {
        method: 'POST',
        headers,
      });
      const { accountId, clientSecret } = await response.json();
      accountIdRef.current = accountId;
      return clientSecret;
    };

    return loadConnectAndInitialize({
      publishableKey: stripePublicKey,
      fetchClientSecret: fetchClientSecret,
    });
  });

  const handleExit = async () => {
    await fetch(`${baseApiUrl}/maybeSaveStripeAccount`, {
      method: 'POST',
      headers,
      body: JSON.stringify({ accountId: accountIdRef.current }),
    });
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Container>
      <Header>Seller Setup</Header>
      {data && !data.isActive && stripeConnectInstance ? (
        <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
          <ConnectAccountOnboarding onExit={handleExit} />
        </ConnectComponentsProvider>
      ) : (
        <p>
          Your Stripe account is already active. To update your information,
          such as linked bank account, please visit Stripe's{' '}
          <a
            href={data?.accountLink?.url}
            target='_blank'
            rel='noopener noreferrer'
          >
            account update page
          </a>
          .
        </p>
      )}
    </Container>
  );
};
