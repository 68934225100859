import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useParams, useNavigate } from 'react-router-dom';

import LoadingSpinner from 'components/loading';
import { GreyLink } from 'components/links';
import { ListingClosed, ListingNonexistent } from 'components/listingView';
import { useGetListing } from 'hooks/listing';

import { PhotoGalleryComponent } from './photoGallery';
import { ListingInfo } from './listingInfo';
import { OwnerPanel } from './ownerPanel';
import { ListingData } from './types';

// TODO: don't need store listingData as state?
export const ViewListingComponent: React.FC = () => {
  const { listingId } = useParams<{ listingId: string }>();
  const [listingData, setListingData] = useState<ListingData | null>(null);
  const { data, isLoading, isError } = useGetListing(listingId || '');
  const navigate = useNavigate();
  const userId = localStorage.getItem('userId');
  const currentUserIsOwner = userId === listingData?.listing.owner_id;

  useEffect(() => {
    if (data && !isLoading) {
      setListingData(data);
    }
  }, [data, isLoading]);

  if (isError) {
    return <ListingNonexistent />;
  }

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      {listingData && !listingData?.listing.deletedAt ? (
        <PageContainer>
          {currentUserIsOwner ? (
            <OwnerPanel listing={listingData.listing} />
          ) : (
            <></>
          )}
          {!listingData.listing.isActive && !currentUserIsOwner && (
            <ListingClosedAnnouncement>
              This listing is closed.
            </ListingClosedAnnouncement>
          )}
          <LinkComponent>
            <GreyLink onClick={() => navigate(-1)}>
              ← Back to search results
            </GreyLink>
          </LinkComponent>
          <ListingContainer>
            <PhotoGalleryComponent
              imageUrls={listingData.listing.signedS3Urls}
            ></PhotoGalleryComponent>
            <ListingInfo listing={listingData.listing}></ListingInfo>
          </ListingContainer>
        </PageContainer>
      ) : (
        <ListingClosed></ListingClosed>
      )}
    </>
  );
};

const PageContainer = styled.div`
  display; flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 1200px;
  margin: 0 auto;
`;

const LinkComponent = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  margin-top: 40px;
  padding: 0 20px;
`;

const ListingClosedAnnouncement = styled.h2`
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  margin-bottom: 0px;
  width: 100%;
  text-align: center;
`;

const ListingContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px 20px 40px 20px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  gap: 40px;
  width: calc(100% - 40px);
  max-width: calc(1200px - 40px);
  min-height: 600px;
  border: 1px solid ${(props) => props.theme.colors.border.primary};
  border-radius: 10px;

  @media (max-width: ${(props) => props.theme.minViewSize}) {
    flex-direction: column;
    padding-left: 20px;
    padding-right: 20px;
    gap: 40px;
  }
`;
