import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';

import { GreyLinkComponent } from 'components/links';

const CheckoutSuccessfulPage: React.FC = () => {
  const navigate = useNavigate();

  // TODO: Link to "what next?" information section.

  return (
    <CheckoutContainer>
      <Title>Your checkout was successful. Thank you for your purchase.</Title>
      <GreyLinkComponent
        fontSize={'16px'}
        text={"What's the next step?"}
        onClick={() => navigate(`/help/buyerResources/receivingYourOrder`)}
      />
      <GreyLinkComponent
        fontSize={'16px'}
        text={'View my orders.'}
        onClick={() => navigate(`/myAccount/Purchases`)}
      />
    </CheckoutContainer>
  );
};

const CheckoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 60px auto;
  gap: 30px;
  max-width: 1000px;
  text-align: center;
`;

const Title = styled.h2`
  width: 540px;
  text-align: center;
  margin: 20px 0;
`;

export default CheckoutSuccessfulPage;
