import styled from '@emotion/styled';

export const HeaderContainer = styled.div`
  text-align: center;
  // margin: 20px 0;
`;

export const HeaderTitle = styled.h1`
  font-size: 28px;
  text-align: left;
  color: #222;
`;

export const HeaderSubtitle = styled.h2`
  font-size: 18px;
  text-align: left;
  color: #666;
  font-weight: normal;
`;
export const HeaderSubsubtitle = styled.div`
  font-size: 14px;
  text-align: left;
  color: #666;
  font-weight: normal;
`;

export const Section = styled.div``;
