// Names
export const PRODUCT_NAME = 'Near Mint';
export const PRODUCT_NAME_FULL = 'Near Mint Marketplace';
export const COMPANY_NAME_OFFICIAL = 'Near Mint Marketplace, LLC';
// export const SUPPORT_EMAIL = 'support@nearmint.co';
export const SUPPORT_EMAIL = 'kpcomarket@gmail.com';
export const PRODUCT_DOMAIN = 'nearmint.co';
export const PRODUCT_WEBSITE = 'https://www.nearmint.co';
export const IRS_W9_FORM_URL = 'https://www.irs.gov/pub/irs-pdf/fw9.pdf';

// Social links
export const DISCORD_URL = 'https://discord.gg/SBd7p3HRcv';
export const TWITTER_URL = 'https://x.com/nearmintmarket';

// Dates
export const PRIVACY_POLICY_DATE = 'November 16, 2024';
export const ROADMAP_UPDATE_DATE = 'November 16, 2024';
export const SELLER_AGREEMENT_DATE = 'November 16, 2024';
export const TERMS_OF_SERVICE_DATE = 'November 16, 2024';

// Miscellaneous
// TODO: get Categories from an endpoint, not hard-coded.
export const RETURN_POLICIES = ['no_returns', '14_days', '30_days'];
export const RETURN_POLICY_MAP = {
  no_returns: 'Seller does not accept returns.',
  '14_days': 'Seller accepts returns within 14 days of receipt.',
  '30_days': 'Seller accepts returns within 30 days of receipt.',
};
export const CATEGORIES = [
  // 'Antiques',
  // 'Books',
  // 'Comic Books',
  'Figurines',
  // 'Memorabilia',
  'Miscellaneous',
  'Models',
  // 'Music',
  'Sports',
  'Tabletop RPGs',
  'Trading Card Games',
  'Video Games',
  'Vintage Items',
];
export const PRICE_TRACKING_REQUIRED = 20.0;
export const PRICE_SIGNATURE_REQUIRED = 250.0;
export const W9_SALES_THRESHOLD = 600.0;
export const DAYS_MUST_SHIP_BY = 5;
export const FINAL_VALUE_FEE = 6.95;
export const TRANSACTION_FEE_PCT = 2.9;
export const TRANSACTION_FEE_FLAT = '0.30';
export const STATES_ABBRV = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
];

export const STATES = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
];
