import { createContext, useContext, useState, ReactNode, FC } from 'react';

import { AddressData, DimensionsData } from './types';

interface ShippingContextType {
  toAddress: AddressData | null;
  setToAddress: (data: AddressData) => void;
  fromAddress: AddressData | null;
  setFromAddress: (data: AddressData) => void;
  dimensions: DimensionsData | null;
  setDimensions: (data: DimensionsData) => void;
  options: AddressData | null;
  setOptions: (data: AddressData) => void;
}

interface ShippingProviderProps {
  children: ReactNode;
}

const ShippingContext = createContext<ShippingContextType | undefined>(
  undefined
);

export const useShippingContext = () => {
  const context = useContext(ShippingContext);
  if (!context) {
    throw new Error(
      'useShippingContext must be used within a ShippingProvider'
    );
  }
  return context;
};

export const ShippingProvider: FC<ShippingProviderProps> = ({ children }) => {
  const [toAddress, setToAddress] = useState<AddressData | null>(null);
  const [fromAddress, setFromAddress] = useState<AddressData | null>(null);
  const [dimensions, setDimensions] = useState<DimensionsData | null>(null);
  const [options, setOptions] = useState<AddressData | null>(null);

  return (
    <ShippingContext.Provider
      value={{
        toAddress,
        setToAddress,
        fromAddress,
        setFromAddress,
        dimensions,
        setDimensions,
        options,
        setOptions,
      }}
    >
      {children}
    </ShippingContext.Provider>
  );
};
