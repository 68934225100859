import { useState } from 'react';
import styled from '@emotion/styled';

import LoadingSpinner from 'components/loading';
import { Container, Header } from 'components/myAccount';
import { useGetFeedback } from 'hooks/feedback';
import { useGetUserInfo } from 'hooks/user';
import { FeedbackTable } from './feedbackTable';

export const FeedbackTabs = () => {
  const userId = localStorage.getItem('userId') || '';
  const [activeTab, setActiveTab] = useState<'forSeller' | 'forBuyer'>(
    'forSeller'
  );
  const { data: feedbackData } = useGetFeedback(userId || '');
  const { data: userData, isLoading } = useGetUserInfo(userId || '');

  if (isLoading) return <LoadingSpinner />;

  if (!userData || !feedbackData) {
    return <div>Error loading data</div>;
  }

  return (
    <Container>
      <Header>My Feedback</Header>
      <TabContainer>
        <TabButton
          isActive={activeTab === 'forSeller'}
          onClick={() => setActiveTab('forSeller')}
        >
          Purchases
        </TabButton>
        <TabButton
          isActive={activeTab === 'forBuyer'}
          onClick={() => setActiveTab('forBuyer')}
        >
          Sales
        </TabButton>
      </TabContainer>
      <TabContent>
        {activeTab === 'forSeller' ? (
          <FeedbackTable
            userInfo={userData.userInfo}
            feedbackData={feedbackData}
            feedbackType={'forSeller'}
          />
        ) : (
          <FeedbackTable
            userInfo={userData.userInfo}
            feedbackData={feedbackData}
            feedbackType={'forBuyer'}
          />
        )}
      </TabContent>
    </Container>
  );
};

const TabContainer = styled.div`
  display: flex;
  border-bottom: 1px solid ${(props) => props.theme.colors.border.primary};
`;

const TabButton = styled.button<{ isActive: boolean }>`
  padding: 10px 20px;
  cursor: pointer;
  width: 140px;
  background: ${(props) =>
    props.isActive ? props.theme.colors.background.focus : 'none'};
  border: none;
  border-bottom: ${(props) => (props.isActive ? '2px solid' : '1px solid')};
  border-color: ${(props) =>
    props.isActive
      ? props.theme.colors.primary
      : props.theme.colors.border.primary};
  color: ${(props) => props.theme.colors.text.primary};
  //   font-weight: ${(props) => (props.isActive ? 'bold' : 'normal')};
  font-weight: ${(props) => props.theme.fonts.leftNav.weight};
  &:hover {
    background: ${(props) => props.theme.colors.background.grey};
  }
`;

const TabContent = styled.div`
  padding: 20px;
`;
