import { useParams } from 'react-router-dom';

import LoadingSpinner from 'components/loading';
import {
  ListingNonexistent,
  ListingOwnerIsCurrentUser,
} from 'components/listingView';
import { useGetListing } from 'hooks/listing';
import { useRedirect } from 'contexts';

import { Stripe } from './stripePayment';

const CheckoutPage = () => {
  const { userId } = useParams<{ userId: string }>();
  const { listingId } = useParams<{ listingId: string }>();
  const { data: listingData, isLoading, isError } = useGetListing(listingId!);
  const { isRedirecting } = useRedirect();

  const isOwner = listingData?.listing
    ? listingData?.listing.owner_id === userId
    : false;

  if (isOwner) {
    return <ListingOwnerIsCurrentUser listingId={listingId} />;
  }

  if (isLoading || isRedirecting) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return <ListingNonexistent />;
  }

  return <Stripe />;
};

export default CheckoutPage;
