import { useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';

import useAPI from 'api';
import { AccountInfoMinimal } from 'types';
import { Toast } from 'components/toast';

import { SellerAgreementCheckResponse, UserInfoAPIResponse } from './types';

export const useCheckLogin = () => {
  const API = useAPI();
  const location = useLocation();
  const url = `checkLogin`;
  return useQuery({
    queryKey: ['page', location.pathname],
    queryFn: async () => {
      return await API<UserInfoAPIResponse>(url.toString(), {
        method: 'GET',
      });
    },
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    staleTime: 0,
  });
};

export const useCheckSellerPrereqs = (userId: string) => {
  const API = useAPI();
  const url = `checkSellerPrereqs`;

  return useQuery({
    queryKey: ['page', userId],
    queryFn: async () => {
      return await API<SellerAgreementCheckResponse>(url.toString(), {
        method: 'GET',
      });
    },
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    staleTime: 0,
  });
};

export const useUpdateSellerAgreement = (
  setRefresh: (arg0: boolean) => void
) => {
  const API = useAPI();
  return useMutation({
    mutationFn: async () => {
      await API('updateSellerAgreement', {
        method: 'POST',
      });
    },
    onSuccess: () => {
      setRefresh(true);
    },
    onError: (err: Error) => {
      Toast.error(err.message);
    },
  });
};

export const useGetUserInfo = (userId: string) => {
  const API = useAPI();
  const params = new URLSearchParams({ id: userId });
  const url = `getUserInfo?${params.toString()}`;
  return useQuery({
    queryKey: ['userInfo', userId],
    queryFn: async () => {
      return await API<UserInfoAPIResponse>(url.toString(), {
        method: 'GET',
      });
    },
    enabled: true,
  });
};

export const useUpdateAccountInfo = () => {
  const [error, setError] = useState(false);
  const API = useAPI();

  const mutation = useMutation({
    mutationFn: async (data: AccountInfoMinimal) => {
      await API('updateAccountInfo', {
        method: 'POST',
        body: JSON.stringify(data),
      });
    },
    onSuccess: () => {
      Toast.success('Successfully updated.');
      setError(false);
    },
    onError: (err: Error) => {
      Toast.error(err.message);
      setError(true);
    },
  });

  return { ...mutation, error };
};
