import styled from '@emotion/styled';
import { UserInfoProps } from './types';

import { ProfilePicture } from 'components/profilePic';

const buildFeedbackMessage = (
  num_total_feedback: number,
  num_positive_feedback: number
) => {
  if (num_total_feedback === 0) {
    return 'N/A';
  }
  const percentage = (100 * num_positive_feedback) / num_total_feedback;
  return percentage.toFixed(1) + '%'; // Rounds to 1 decimal place
};

const UserSummary: React.FC<UserInfoProps> = (props) => {
  const profilePicUrl = props.userInfo.profile_pic_signed_s3_url;
  return (
    <>
      <UserSummaryContainer>
        {profilePicUrl && (
          <ProfilePicture
            profilePicUrl={profilePicUrl}
            size={'medium'}
            iconStatus={'static'}
          />
        )}
        <UserSummaryText>
          <UserTitle>{props.userInfo.username}</UserTitle>
          <SummaryInfoContainer>
            {props.userInfo.num_total_feedback > 0 ? (
              <SummaryInfoElement>
                Positive Feedback:{' '}
                {buildFeedbackMessage(
                  props.userInfo.num_total_feedback,
                  props.userInfo.num_positive_feedback
                )}
              </SummaryInfoElement>
            ) : (
              <></>
            )}
            <SummaryInfoElement>
              <b>{props.userInfo.items_sold}</b> items sold
            </SummaryInfoElement>
            <SummaryInfoElement>
              <b>{props.userInfo.items_bought}</b> items bought
            </SummaryInfoElement>
          </SummaryInfoContainer>
        </UserSummaryText>
      </UserSummaryContainer>
    </>
  );
};

const UserSummaryContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px;
  gap: 20px;
  background-color: #eeeeee;
`;

const UserSummaryText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-left: 20px;
`;

const UserTitle = styled.div`
  font-size: 1.6rem;
  margin-top: 20px;
`;

const SummaryInfoElement = styled.div`
  font-size: 0.8rem;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 10px 0;
  margin-right: 35px; // Adjust space between each element to 10 pixels
  white-space: nowrap; // Prevent text wrapping

  &:hover {
    border-bottom: 2px solid black;
  }
`;

const SummaryInfoContainer = styled.div`
  display: flex;
  flex-wrap: wrap; /* Allows tabs to wrap in smaller screens */
  margin-bottom: 20px;
`;

export default UserSummary;
