import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from '@emotion/styled';

import { Button } from 'components/buttons';
import { Header } from 'components/myAccount';
import { IMAGE_FILE_TYPES } from 'components/validation';
import { useTopnav } from 'components/topnavContext';
import { ProfilePicture } from 'components/profilePic';

import { PersonalFormProps } from './types';
import { useUpdateProfilePic } from './hooks';

type FormData = {
  profilePicture: FileList;
};

export const ProfilePicForm: React.FC<PersonalFormProps> = ({ data }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<FormData>({
    mode: 'onChange',
  });
  const { setProfilePicUrl: setProfilePicUrlContext } = useTopnav();
  const [profilePicUrlState, setProfilePicUrlState] = useState<
    string | undefined
  >(data.profile_pic_signed_s3_url);

  const { mutate: updateProfilePic } = useUpdateProfilePic(
    setProfilePicUrlState,
    setProfilePicUrlContext
  );

  const validateImageDimensions = (files: FileList) => {
    return new Promise<boolean>((resolve) => {
      if (files.length === 0) {
        resolve(false);
        return;
      }

      const file = files[0];
      const img = new Image();
      const objectUrl = URL.createObjectURL(file);
      img.src = objectUrl;
      img.onload = () => {
        URL.revokeObjectURL(objectUrl);
        if (
          img.width < 100 ||
          img.height < 100 ||
          img.width > 1000 ||
          img.height > 1000
        ) {
          resolve(false);
        } else {
          resolve(true);
        }
      };
      img.onerror = () => {
        URL.revokeObjectURL(objectUrl);
        resolve(false);
      };
    });
  };

  const onSubmit = handleSubmit(async (formData) => {
    const isValidDimensions = await validateImageDimensions(
      formData.profilePicture
    );
    if (isValidDimensions) {
      updateProfilePic(formData.profilePicture[0]);
    } else {
      alert('Image dimensions must be between 100x100px and 1000x1000px.');
    }
  });

  return (
    <Container>
      <Header>Profile Picture</Header>
      <Form onSubmit={onSubmit}>
        {profilePicUrlState && (
          <ProfilePicture
            profilePicUrl={profilePicUrlState}
            size={'large'}
            iconStatus={'static'}
          />
        )}
        <Input
          type='file'
          accept={IMAGE_FILE_TYPES}
          {...register('profilePicture', {
            required: 'Please select a profile picture to upload.',
            validate: async (files) => {
              const isValidDimensions = await validateImageDimensions(files);
              return (
                isValidDimensions ||
                'Image width and height must both be between 100 and 1000 pixels.'
              );
            },
          })}
        />
        {errors.profilePicture && (
          <ErrorMessage>{errors.profilePicture.message}</ErrorMessage>
        )}
        <Button type='submit' width={'240px'} disabled={!isValid}>
          Upload
        </Button>
      </Form>
    </Container>
  );
};

const Container = styled.div`
  width: 800px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: 20px;
  padding: 20px;
  margin: 0 auto;
  border: 1px solid ${(props) => props.theme.colors.border.primary};
  border-radius: 4px;
`;

const Input = styled.input`
  padding: 10px;
  width: 240px;
  cursor: pointer;
`;

const ErrorMessage = styled.span`
  color: red;
  font-size: 0.9em;
`;
