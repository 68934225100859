import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';

interface Item {
  label: string;
  path: string;
}

interface BreadcrumbsProps {
  items: Item[];
}

export const Breadcrumbs = ({ items }: BreadcrumbsProps) => {
  const navigate = useNavigate();

  const handleNavigation = (path: string) => {
    navigate(path);
  };

  return (
    <BreadcrumbContainer>
      <BreadcrumbList>
        {items.map((item, index) => {
          const isLast = index === items.length - 1;

          return (
            <BreadcrumbItem key={item.path}>
              {!isLast ? (
                <BreadcrumbLink onClick={() => handleNavigation(item.path)}>
                  {item.label}
                </BreadcrumbLink>
              ) : (
                <LastItem>{item.label}</LastItem>
              )}
              {!isLast && <Separator>{'>'}</Separator>}
            </BreadcrumbItem>
          );
        })}
      </BreadcrumbList>
    </BreadcrumbContainer>
  );
};

const BreadcrumbContainer = styled.nav`
  margin: 0;
`;

const BreadcrumbList = styled.ol`
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
`;

const BreadcrumbItem = styled.li`
  display: flex;
  align-items: center;
  margin: 0;
`;

const BreadcrumbLink = styled.span`
  color: #888;
  font-size: 12px;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const LastItem = styled.span`
  font-size: 12px;
`;

const Separator = styled.span`
  margin: 0 8px;
  color: #777;
`;
