import React, { createContext, useContext, useState, ReactNode } from 'react';

interface RedirectContextProps {
  isRedirecting: boolean;
  setIsRedirecting: (value: boolean) => void;
}

const RedirectContext = createContext<RedirectContextProps | undefined>(
  undefined
);

export const useRedirect = () => {
  const context = useContext(RedirectContext);
  if (!context) {
    throw new Error('useRedirect must be used within a RedirectProvider');
  }
  return context;
};

export const RedirectProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isRedirecting, setIsRedirecting] = useState(false);

  return (
    <RedirectContext.Provider value={{ isRedirecting, setIsRedirecting }}>
      {children}
    </RedirectContext.Provider>
  );
};
