import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';

import useAPI from 'api';
import { Toast } from 'components/toast';

import { AccountInfoMinimal } from 'types';

const generateExtension = (image: File) => {
  const match = image.name.match(/\.(.+)$/);
  return match ? match[1] : 'Unknown';
};

const uploadToS3 = async (signedUrl: string, extension: string, file: File) => {
  const response = await fetch(signedUrl, {
    method: 'PUT',
    headers: {
      'Content-Type': `image/${extension}`,
    },
    body: file,
  });
  return response.status === 200;
};

export const useUpdateProfilePic = (
  setProfilePicUrlState: React.Dispatch<
    React.SetStateAction<string | undefined>
  >,
  setProfilePicUrlContext: (url: string) => void
) => {
  const API = useAPI();
  return useMutation({
    mutationFn: async (image: File) => {
      const extension = generateExtension(image);
      const apiRes = await API<{ s3SignedUrl: string; s3SignedUrlGet: string }>(
        'updateProfilePic',
        {
          method: 'POST',
          body: JSON.stringify({ extension }),
        }
      );
      await uploadToS3(apiRes.s3SignedUrl, extension, image);
      return apiRes.s3SignedUrlGet;
    },
    onSuccess: (s3SignedUrl) => {
      setProfilePicUrlState(s3SignedUrl);
      setProfilePicUrlContext(s3SignedUrl);
      Toast.success('Successfully updated profile picture.');
    },
    onError: (error: any) => {
      Toast.error(error.message);
    },
  });
};

export const useUpdatePassword = (reset: () => void) => {
  const API = useAPI();
  return useMutation({
    mutationFn: async (password: string) => {
      await API('updatePassword', {
        method: 'POST',
        body: JSON.stringify({ password }),
      });
    },
    onSuccess: (data) => {
      Toast.success('Successfully updated password.');
      reset();
    },
    onError: (err: Error) => {
      Toast.error(err.message);
    },
  });
};

export const useUpdateAccountInfo = () => {
  const [error, setError] = useState(false);
  const API = useAPI();

  const mutation = useMutation({
    mutationFn: async (data: AccountInfoMinimal) => {
      await API('updateAccountInfo', {
        method: 'POST',
        body: JSON.stringify(data),
      });
    },
    onSuccess: () => {
      Toast.success('Successfully updated.');
      setError(false);
    },
    onError: (err: Error) => {
      Toast.error(err.message);
      setError(true);
    },
  });

  return { ...mutation, error };
};
