import styled from '@emotion/styled';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from 'components/buttons';
import { ListingRecord } from './types';
import { useDelistListing, useRelistListing } from 'hooks/listing';

interface ListingCardListingProps {
  listing: ListingRecord;
}

export const OwnerPanel: React.FC<ListingCardListingProps> = (props) => {
  const [isActive, setIsActive] = useState(props.listing.isActive);
  const { mutate: delistListing } = useDelistListing({ setIsActive });
  const { mutate: relistListing } = useRelistListing({ setIsActive });
  const hasBeenPurchased = !!props.listing.order_status;
  const navigate = useNavigate();

  const handleDelist = () => {
    delistListing(props.listing.id);
  };
  const handleRelist = () => {
    relistListing(props.listing.id);
  };

  // TODO: differentiate between "inactive" and "sold".
  const buttonWidth = '150px';
  const buttonHeight = '30px';
  const buttonFontSize = '14px';

  return (
    <Panel>
      <Title>
        {' '}
        {isActive
          ? 'This listing is live.'
          : hasBeenPurchased
          ? 'This listing was purchased.'
          : 'This listing is not live.'}
      </Title>
      {hasBeenPurchased ? (
        <></>
      ) : (
        <ButtonContainer>
          {' '}
          <Button
            fontSize={buttonFontSize}
            height={buttonHeight}
            width={buttonWidth}
            variant={'secondary'}
            onClick={() => navigate(`/editListing/${props.listing.id}`)}
          >
            Edit listing
          </Button>
          {isActive ? (
            <Button
              fontSize={buttonFontSize}
              height={buttonHeight}
              width={buttonWidth}
              variant={'secondary'}
              onClick={handleDelist}
            >
              Delist item
            </Button>
          ) : (
            <Button
              fontSize={buttonFontSize}
              height={buttonHeight}
              width={buttonWidth}
              variant={'secondary'}
              onClick={handleRelist}
            >
              Relist item
            </Button>
          )}
        </ButtonContainer>
      )}
    </Panel>
  );
};

const Panel = styled.div`
  width: 100%;
  height: 50px;
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${(props) => props.theme.colors.background.focus};
`;

const Title = styled.span`
  margin: 0 0 0 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-right: 20px;
  gap: 20px;
`;
