import styled from '@emotion/styled';

import {
  HeaderContainer,
  HeaderSubtitle,
  HeaderTitle,
  Section,
} from './shared';
import { Dropdown } from 'components/input';
import { returnsOptionValidation } from 'components/validation';

export const ReturnsComponent = () => {
  return (
    <Section>
      <HeaderContainer>
        <HeaderTitle>Return Policy</HeaderTitle>
        <HeaderSubtitle>Please choose your return policy.</HeaderSubtitle>
      </HeaderContainer>
      <ReturnsContainer>
        <ReturnDropdownContainer>
          <Dropdown {...returnsOptionValidation} width={'340px'} />
        </ReturnDropdownContainer>
      </ReturnsContainer>
    </Section>
  );
};

const ReturnsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
`;

const ReturnDropdownContainer = styled.div`
  width: 50%;
`;
