import { Breadcrumbs } from 'components/breadcrumbs';
import {
  Container,
  Content,
  Divider,
  Header,
  Paragraph,
  Section,
  Title,
} from 'components/helpShared';
import { PRODUCT_NAME } from 'config';

// TODO: replace lists with tables
export const SellerTiers = () => {
  return (
    <Container>
      <Breadcrumbs
        items={[
          { path: '/help', label: 'Help Home' },
          { path: '/help/sellerResources', label: 'Seller Resources' },
          {
            path: '/help/sellerResources/sellerTiers',
            label: 'Seller Tiers',
          },
        ]}
      />
      <Header>Seller Tiers</Header>
      <Divider />
      <Content>
        <Section>
          <Title>Overview</Title>
          <Paragraph>
            {PRODUCT_NAME} uses a seller tier program to introduce new sellers
            to the market and to protect participants. In short, new sellers are
            subject to restrictions on the maximum item value they may list on{' '}
            {PRODUCT_NAME}. As they successfully complete sales and gain
            positive feedback from buyers, these restrictions are lifted. Tiers
            also affect payout schedules.
          </Paragraph>
          <Title>Tier Requirements</Title>
          <Paragraph>
            The requirements for each tier follow. Note that requirements are
            cumulative. For example, to achieve Tier 3, you must meet the
            requirements for Tiers 1, 2, and 3.
          </Paragraph>
          <Paragraph>Tier 1: Phone and email address verified.</Paragraph>
          <Paragraph>
            Tier 2: Identity verified, address verified, and W9 tax form
            completed.
          </Paragraph>
          <Paragraph>Tier 3: $1,000 in total gross sales.</Paragraph>
          <Paragraph>Tier 4: $10,000 in total gross sales.</Paragraph>
          <Paragraph>Tier 5: $50,000 in total gross sales.</Paragraph>
          <Title>Listing Price Maximums per Tier</Title>
          <Paragraph>
            Sellers may only list with price lower than the maximum specified
            for their seller Tier. For example, a Tier 3 seller can not create a
            listing with price greater than $2,000.
          </Paragraph>
          <Paragraph>Tier 1: $100 item maximum</Paragraph>
          <Paragraph>Tier 2: $500 item maximum</Paragraph>
          <Paragraph>Tier 3: $2,000 item maximum.</Paragraph>
          <Paragraph>Tier 4: $10,000 item maximum.</Paragraph>
          <Paragraph>Tier 5: No limit.</Paragraph>
          <Paragraph></Paragraph>
          <Title>Payout Schedules per Tier</Title>
          <Paragraph>
            When sellers are paid out for their successful sales depends on
            their Tier. For example, a Tier 3 seller is paid out every Sunday.
          </Paragraph>
          <Paragraph>
            Tier 1: Paid out 30 days after the buyer receives the item.
          </Paragraph>
          <Paragraph>
            Tier 2: Paid out 10 business days after the buyer receives the item.
          </Paragraph>
          <Paragraph>Tier 3: Paid out weekly every Sunday.</Paragraph>
          <Paragraph>
            Tier 4: Paid out immediately upon verified shipment of sold item.
          </Paragraph>
          <Paragraph>
            Tier 5: Paid out immediately upon verified shipment of sold item.
          </Paragraph>
        </Section>
      </Content>
    </Container>
  );
};
