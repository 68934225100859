import { PRODUCT_NAME } from 'config';

export const termsOfServiceValidation = {
  label: `I agree to the ${PRODUCT_NAME} <a href="/terms" target="_blank" rel="noopener noreferrer">Terms of Service</a> and <a href="/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>`,
  name: 'tos',
  id: 'tos',
  type: 'checkbox',
};

export const sellerAgreementValidation = {
  label: `I agree to the ${PRODUCT_NAME} <a href="/sellerAgreement" target="_blank" rel="noopener noreferrer">Seller Agreement</a>`,
  name: 'tos',
  id: 'tos',
  type: 'checkbox',
  centered: true,
};
