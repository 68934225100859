import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { isEmpty } from 'lodash';

import styled from '@emotion/styled';
import LoadingSpinner from 'components/loading';
import { FilterDropdown } from 'components/input';
import { ListingCardListing } from 'components/listingCard';
import { Container, Header, HeaderContainer } from 'components/myAccount';
import { useGetMyListings } from 'hooks/listing';
import { ListingRecord, OrderStatuses } from 'types';

const sortOptions: Record<
  string,
  (a: ListingRecord, b: ListingRecord) => number
> = {
  'Listing date: newest first': (a, b) =>
    new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
  'Listing date: oldest first': (a, b) =>
    new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
};

const filterOptions: Record<string, (listing: ListingRecord) => boolean> = {
  All: () => true,
  Active: (listing) => !!listing.isActive,
  Inactive: (listing) => !listing.isActive && !listing.order_status,
  'Awaiting Shipment': (listing) =>
    listing.order_status === OrderStatuses.OrderCaptured,
  Shipped: (listing) => listing.order_status === OrderStatuses.Shipped,
  Settled: (listing) => listing.order_status === OrderStatuses.Settled,
};

export const MyListings = () => {
  const { data, isLoading } = useGetMyListings();
  const [sortedListings, setSortedListings] = useState<ListingRecord[]>([]);

  const [searchParams, setSearchParams] = useSearchParams();

  const sortOption = searchParams.get('sort') || 'Listing date: newest first';
  const filterOption = searchParams.get('filter') || 'All';

  useEffect(() => {
    if (data?.listings) {
      const filteredListings = data.listings.filter(
        filterOptions[filterOption]
      );
      setSortedListings(filteredListings.sort(sortOptions[sortOption]));
    }
  }, [data?.listings, sortOption, filterOption]);

  const handleSortOptionSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    searchParams.set('sort', e.target.value);
    setSearchParams(searchParams);
  };

  const handleFilterOptionSelect = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    searchParams.set('filter', e.target.value);
    setSearchParams(searchParams);
  };

  if (isLoading) return <LoadingSpinner />;

  return (
    <StyledContainer>
      <HeaderContainer>
        <Header>My Listings</Header>
        <DropdownContainers>
          <DropdownContainer>
            <FilterText>Status:</FilterText>
            <FilterDropdown
              id='filterDropdown'
              value={filterOption}
              onChange={handleFilterOptionSelect}
            >
              {Object.keys(filterOptions).map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </FilterDropdown>
          </DropdownContainer>
          <DropdownContainer>
            <SortByText>Sort by:</SortByText>
            <FilterDropdown
              id='sortDropdown'
              value={sortOption}
              onChange={handleSortOptionSelect}
            >
              {Object.keys(sortOptions).map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </FilterDropdown>
          </DropdownContainer>
        </DropdownContainers>
      </HeaderContainer>
      <ActivityList>
        {!isEmpty(sortedListings) ? (
          sortedListings.map((listing: ListingRecord) => (
            <ListingCardListing key={listing.id} listing={listing} />
          ))
        ) : filterOption === 'All' ? (
          <h4>You have no listings</h4>
        ) : (
          <h4>No listings match these filters</h4>
        )}
      </ActivityList>
    </StyledContainer>
  );
};

const StyledContainer = styled(Container)`
  max-width: 1000px;
`;

const FilterText = styled.div`
  font-size: 12px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  margin-right: 10px;
`;

const DropdownContainers = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const DropdownContainer = styled.div`
  display: flex;
  align-items: center;
`;

const SortByText = styled.div`
  font-size: 12px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  margin-right: 10px;
`;

const ActivityList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
`;
