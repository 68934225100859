import { useMutation, useQuery } from '@tanstack/react-query';
import useAPI from 'api';
import { TaxFormAPIResponse, TaxFormAPISubmission } from './types';

import { Toast } from 'components/toast';

export const useGetW9TaxForm = () => {
  const API = useAPI();
  const url = `getW9TaxForm`;
  return useQuery<TaxFormAPIResponse>({
    queryKey: [url],
    queryFn: async () => {
      return await API<TaxFormAPIResponse>(url, {
        method: 'GET',
      });
    },
  });
};

export const useUpdateW9TaxForm = () => {
  const API = useAPI();
  return useMutation({
    mutationFn: async (taxFormData: TaxFormAPISubmission) => {
      await API('submitW9TaxForm', {
        method: 'POST',
        body: JSON.stringify({ taxFormData }),
      });
    },
    onSuccess: (data) => {
      Toast.success('Successfully submitted form.');
    },
    onError: (err: Error) => {
      Toast.error(err.message);
    },
  });
};
