import '@emotion/react';

type IconWeight = 'thin' | 'light' | 'regular' | 'bold' | 'fill';

export const theme = {
  colors: {
    // primary: '#0935da',
    primary: '#2C2761',
    primary_hover: '#5c55a3',
    secondary: '#82cfff',
    secondary_hover: '#efeffb',
    grey_hover: '#f5f5f5',
    background: {
      primary: '#e7f0fe',
      focus: '#e9e9fb',
      grey: '#f3f4f6',
    },
    white: '#FFFFFF',
    border: {
      primary: '#dee3e7',
      focus: '#254DDC',
    },
    error: '#ff0000',
    text: {
      primary: '#0f0f0f',
      placeholder: '#9e9e9e',
      grey: '#f3f4f6',
      darkGrey: '#444',
      blueGrey: '#375152',
      hx: '#1a1c29',
      body: '#3e3e45',
      inverted: '#ffffff',
      link: '#0935da',
      bottomNavLink: '#7a7a7a',
    },
  },
  fonts: {
    leftNav: {
      size: '14px',
      weight: '500',
      color: '#111826',
    },
  },
  fontSize: {
    bottomNav: '14px',
    button: '18px',
    buttonSmall: '14px',
    dropdownMenu: '12px',
    input: '16px',
    leftNav: '14px',
  },
  borderRadius: {
    input: '8px',
    button: '5px',
    dropdownMenu: '5px',
    search: '5px',
  },
  minViewSize: '800px',
  help: {
    static: {
      size: '80px',
      color: '#5c55a3',
      weight: 'light' as IconWeight,
    },
    hover: {
      size: '80px',
      color: '#5c55a3',
      weight: 'light' as IconWeight,
    },
    selected: {
      size: '80px',
      color: '#5c55a3',
      weight: 'light' as IconWeight,
    },
  },
  icon: {
    bottomNav: {
      static: {
        size: '22px',
        color: '#999999',
        weight: 'bold' as IconWeight,
      },
      hover: {
        size: '22px',
        color: '#2C2761',
        weight: 'bold' as IconWeight,
      },
      selected: {
        size: '22px',
        color: '#2C2761',
        weight: 'bold' as IconWeight,
      },
    },
    dropdownMenu: {
      static: {
        size: '22px',
        color: '#a1a1a1',
        weight: 'regular' as IconWeight,
      },
      hover: {
        size: '22px',
        color: '#515151',
        weight: 'regular' as IconWeight,
      },
      selected: {
        size: '22px',
        color: '#a1a1a1',
        weight: 'regular' as IconWeight,
      },
    },
    leftNav: {
      static: {
        size: '22px',
        color: '#818181',
        weight: 'regular' as IconWeight,
      },
      hover: {
        size: '22px',
        color: '#515151',
        weight: 'regular' as IconWeight,
      },
      selected: {
        size: '22px',
        color: '#5c55a3',
        weight: 'regular' as IconWeight,
      },
    },
    topNav: {
      static: {
        size: '28px',
        color: '#666666',
        weight: 'regular' as IconWeight,
      },
      hover: {
        size: '28px',
        color: '#2C2761',
        weight: 'regular' as IconWeight,
      },
      selected: {
        size: '28px',
        color: '#2C2761',
        weight: 'regular' as IconWeight,
      },
    },
    info: {
      static: {
        size: '88px',
        color: '#666666',
        weight: 'regular' as IconWeight,
      },
    },
    hoverInfo: {
      static: {
        size: '16px',
        color: '#666666',
        weight: 'regular' as IconWeight,
      },
    },
  },
};

export type ThemeType = typeof theme;

declare module '@emotion/react' {
  export interface Theme extends ThemeType {}
}
