import { useMutation, useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { Toast } from 'components/toast';
import { generateExtensionsArray, uploadFileToS3 } from 'utils';
import { ListingData, ListingRecord } from 'types';
import useAPI from 'api';

import { ChangeListingParams, ListingsAPIResponse } from './types';

export const useCreateListing = () => {
  const navigate = useNavigate();
  const API = useAPI();

  return useMutation({
    mutationFn: async ({
      data,
      photos,
    }: {
      data: ListingData;
      photos: File[];
    }) => {
      const apiRes = await API<{ s3SignedUrls: string[]; listingId: string }>(
        'createListing',
        {
          method: 'POST',
          body: JSON.stringify(data),
        }
      );
      return { ...apiRes, photos };
    },
    onSuccess: async (apiRes) => {
      const { s3SignedUrls, listingId, photos } = apiRes;
      const extensions = generateExtensionsArray(photos);
      const uploadStatuses = await Promise.all(
        s3SignedUrls.map((url, i) =>
          uploadFileToS3(url, extensions[i], photos[i])
        )
      );
      if (!uploadStatuses.every((status) => status)) {
        Toast.error('Some photos failed to upload');
      } else {
        navigate(`/listingSuccessful/${listingId}`);
      }
    },
    onError: (error: any) => {
      Toast.error('Something went wrong. Please try again.');
    },
  });
};

export const useEditListing = () => {
  const navigate = useNavigate();
  const API = useAPI();

  return useMutation({
    mutationFn: async ({
      data,
      photos,
      listingId,
    }: {
      data: ListingData;
      photos: File[];
      listingId: string;
    }) => {
      const apiRes = await API<{ s3SignedUrls: string[]; listingId: string }>(
        'editListing',
        {
          method: 'POST',
          body: JSON.stringify({ ...data, listingId }),
        }
      );
      return { ...apiRes, photos };
    },
    onSuccess: async (apiRes) => {
      const { s3SignedUrls, listingId, photos } = apiRes;
      const extensions = generateExtensionsArray(photos);
      const uploadStatuses = await Promise.all(
        s3SignedUrls.map((url, i) =>
          uploadFileToS3(url, extensions[i], photos[i])
        )
      );
      if (!uploadStatuses.every((status) => status)) {
        Toast.error('Some photos failed to upload');
      } else {
        navigate(`/listingUpdated/${listingId}`);
      }
    },
    onError: (error: any) => {
      Toast.error('Something went wrong. Please try again.');
    },
  });
};

/*
Note: the below needs `useCase` because if you navigate from viewListing
to editListing, it attempts to use the same signedS3Urls for images because
the query key here is not
*/
export const useGetListing = (
  listingId: string,
  useCase: string = 'default'
) => {
  const API = useAPI();
  return useQuery({
    queryKey: ['getListing', listingId, useCase],
    queryFn: async () => {
      const params = new URLSearchParams({ id: listingId });
      const url = `getListing?${params.toString()}`;
      return await API<{ listing: ListingRecord }>(url.toString(), {
        method: 'GET',
      });
    },
    retry: false,
    retryOnMount: false,
  });
};

export const useDelistListing = ({ setIsActive }: ChangeListingParams) => {
  const API = useAPI();
  return useMutation({
    mutationFn: async (listing_id: string) => {
      await API('delistListing', {
        method: 'POST',
        body: JSON.stringify({ listing_id }),
      });
    },
    onSuccess: (data) => {
      Toast.success('Successfully deactivated listing.');
      setIsActive(false);
    },
    onError: (err: Error) => {
      Toast.error(err.message);
    },
  });
};

export const useRelistListing = ({ setIsActive }: ChangeListingParams) => {
  const API = useAPI();
  return useMutation({
    mutationFn: async (listing_id: string) => {
      await API('relistListing', {
        method: 'POST',
        body: JSON.stringify({ listing_id }),
      });
    },
    onSuccess: (data) => {
      Toast.success('Successfully reactivated listing.');
      setIsActive(true);
    },
    onError: (err: Error) => {
      Toast.error(err.message);
    },
  });
};

export const useGetMyListings = (id?: string) => {
  const API = useAPI();
  const url = id ? `getSelfListings?id=${id}` : `getSelfListings`;
  return useQuery<ListingsAPIResponse>({
    queryKey: [url],
    queryFn: async () => {
      return await API<ListingsAPIResponse>(url, {
        method: 'GET',
      });
    },
  });
};
