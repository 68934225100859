import styled from '@emotion/styled';

import { Input } from 'components/input';
import {
  lengthValidation,
  heightValidation,
  widthValidation,
  weightOzValidation,
} from 'components/validation';

export const ShippingDimensionForm: React.FC = () => {
  const inputWidth = '200px';
  const inputHeight = '36px';

  return (
    <Container>
      <Header>Package Dimensions</Header>
      <Content>
        <Row>
          <InputWrapper>
            <Input
              {...lengthValidation}
              width={inputWidth}
              height={inputHeight}
            />
          </InputWrapper>
          <InputWrapper>
            <Input
              {...weightOzValidation}
              width={inputWidth}
              height={inputHeight}
            />
          </InputWrapper>
        </Row>
        <Row>
          <InputWrapper>
            <Input
              {...widthValidation}
              width={inputWidth}
              height={inputHeight}
            />
          </InputWrapper>
        </Row>
        <Row>
          <InputWrapper>
            <Input
              {...heightValidation}
              width={inputWidth}
              height={inputHeight}
            />
          </InputWrapper>
        </Row>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  width: 800px;
`;

const Content = styled.div`
  padding: 20px;
  border: 1px solid ${(props) => props.theme.colors.border.primary};
`;

const Header = styled.h4`
  text-align: left;
`;

const Row = styled.div`
  display: flex;
  width: 0;
  justify-content: space-between;
  margin-bottom: 26px;
`;

const InputWrapper = styled.div`
  flex: 1;
  &:not(:last-child) {
    margin-right: 20px;
  }
`;
