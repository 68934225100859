import { useNavigate } from 'react-router-dom';

import { Breadcrumbs } from 'components/breadcrumbs';
import {
  Container,
  Content,
  Divider,
  Header,
  Paragraph,
  Section,
  Title,
} from 'components/helpShared';
import { BlueLinkComponent } from 'components/links';
import {
  DAYS_MUST_SHIP_BY,
  PRICE_TRACKING_REQUIRED,
  PRICE_SIGNATURE_REQUIRED,
  PRODUCT_NAME,
} from 'config';

export const ShippingPolicy = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <Breadcrumbs
        items={[
          { path: '/help', label: 'Help Home' },
          { path: '/help/sellerResources', label: 'Seller Resources' },
          {
            path: '/help/sellerResources/shippingPolicy',
            label: 'Shipping Policy',
          },
        ]}
      />
      <Header>Shipping Policy</Header>
      <Divider />
      <Content>
        <Section>
          <Title>Overview</Title>
          <Paragraph>
            Maintaining a fast and efficient shipping cadence is key to having a
            good reputation on {PRODUCT_NAME}. Here we will cover requirements
            and best practices to help you succeed as a seller.
          </Paragraph>
          <Title>Shipping After a Sale</Title>
          <Paragraph>
            Upon successful purchase of your listing, you must make a reasonable
            effort to ship your items and update the order status as "Shipped"
            within {DAYS_MUST_SHIP_BY} business days. {PRODUCT_NAME} will send
            daily email reminders to you for each day up until this point. After{' '}
            {DAYS_MUST_SHIP_BY} business days, your order may be canceled and
            the buyer fully refunded. In the case of multiple instances of order
            cancellation due to non-shipment, {PRODUCT_NAME} may suspend your
            account.
          </Paragraph>
          <Title>Shipping Detail Requirements</Title>
          <Paragraph>
            We recommend that you ship all orders with tracking. Without
            tracking, you waive your ability to win any disputes resulting from
            a buyer claiming they did not receive the package. Tracking is
            required for orders exceeding ${PRICE_TRACKING_REQUIRED}. Shipping
            with signature for delivery is required for items exceeding $
            {PRICE_SIGNATURE_REQUIRED}, but is strongly recommended for any item
            exceeding $100. Purchasing insurance is not required, but may be a
            good option for high-value items.
          </Paragraph>
          <Title>Printing Shipping Labels</Title>
          <Paragraph>
            We offer the ability to create and print a shipping label on the{' '}
            {PRODUCT_NAME} website itself. This is accessible in the order
            management page, found by navigating to{' '}
            <BlueLinkComponent
              onClick={() => navigate('/myAccount/Listings')}
              text='My Listings'
              fontSize='16px'
            />{' '}
            and clicking on "Manage Order" for the relevant order.
          </Paragraph>
          <Title>Disputes</Title>
          <Paragraph>
            In the event of a dispute, we strongly encourage buyers and sellers
            to communicate with each other and attempt to resolve their issue.
            If a dispute is escalated, we at {PRODUCT_NAME} will do everything
            in our power to learn all relevant facts of the transaction before
            making a judgment. Part of the dispute will be taking into
            consideration all of the previous buying and selling history of the
            parties involved, as well as communication between the parties.
          </Paragraph>
          <Title>Safety Recommendations</Title>
          <Paragraph>
            While we commit much effort to minimizing buyer-side fraud, we do
            recommend recording aspects of your shipping process in the unlikely
            event that you need to prove that you shipped the item(s) timely,
            correctly, and according to your listing. This is particularly
            applicable for high-value items. For peace of mind, you may want to
            take high-quality pictures and videos of the items(s) before
            packaging, as well as a picture or video of you packaging the
            item(s), all the way up to adding the shipping label onto the
            package. In particular, highlight imperfections or other anomalies
            that make your item easy to identify. Keep this evidence 180 days
            after the shipment arrival date.
          </Paragraph>
          <Paragraph>
            As mentioned above, it is also recommended to require signature upon
            delivery even if your item does not meet the price threshold
            requirement for this. Shipments with tracking and signature
            requirements are much easier to defend against fraudulent
            chargebacks.
          </Paragraph>
        </Section>
      </Content>
    </Container>
  );
};
