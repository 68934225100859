import styled from '@emotion/styled';

export const Row = styled.div`
  display: flex;
  align-items: top;
  flex-direction: row;
`;

export const ItemBox = styled.div`
  flex: 1;
  align-self: flex-start;
  width: 1200px;
  padding: 22px;
  height auto;
`;

export const Left = styled.div`
  width: 400px;
  display: flex;
  align-items: top;
  flex-direction: row;
  margin-right: 40px;
`;

export const Right = styled.div`
  width: 800px;
`;

export const Title = styled.p`
  font-size: 22px;
  font-weight: 500;
  margin: 0 0 20px 0;
  text-align: left;
`;

export const Description = styled.p`
  font-size: 14px;
`;
