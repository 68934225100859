import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useParams } from 'react-router-dom';
import { FormProvider, useForm, useWatch } from 'react-hook-form';

import { Button } from 'components/buttons';
import LoadingSpinner from 'components/loading';
import { Toast } from 'components/toast';
import { CheckboxInput, Input } from 'components/input';
import {
  trackingNumberValidation,
  useLabelTrackingValidation,
} from 'components/validation';
import { useGetAccountInfo } from 'hooks/account';
import { OrderStatuses } from 'types';
import { PRICE_TRACKING_REQUIRED } from 'config';

import { ShippingProvider } from './context';
import { CreateShippingLabel } from './shippingLabel';
import { useUpdateShippingStatus, useVoidShippingLabel } from './hooks';
import { ShippingSectionProps } from './types';
import { Thumbnail } from './thumbnail';
import { VoidConfirmationModal } from './voidConfirmation';

export const ShippingSection: React.FC<ShippingSectionProps> = ({
  orderData,
  status,
  setStatus,
  setTrigger,
}) => {
  // Hooks
  const { data: accountData, isLoading: accountDataLoading } =
    useGetAccountInfo();
  const [tempStatus, setTempStatus] = useState(status);
  const { listingId } = useParams<{ listingId: string }>();
  const { mutate: updateShippingStatus } = useUpdateShippingStatus(setStatus);
  const { mutate: voidShippingLabel } = useVoidShippingLabel(setTrigger);
  const methods = useForm();

  // State
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [labelExists, setLabelExists] = useState(false);
  const [blobUrl, setBlobUrl] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Constants
  const { control, handleSubmit, register, setValue } = methods;
  const trackingRequired =
    orderData.price >= PRICE_TRACKING_REQUIRED &&
    tempStatus === OrderStatuses.Shipped;

  useEffect(() => {
    const fetchFile = async () => {
      try {
        const response = await fetch(orderData.signedS3Url || '');
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const blob = await response.blob();
        const objectUrl = URL.createObjectURL(blob);
        setBlobUrl(objectUrl);
      } catch (err: any) {
        Toast.error(err.message);
      }
    };
    fetchFile();
    setLabelExists(!!orderData.signedS3Url);
  }, [orderData]);

  const useLabelTracking = useWatch({
    control,
    name: 'useLabelTracking',
  });

  useEffect(() => {
    if (useLabelTracking) {
      setValue('tracking', orderData.tracking_number);
    }
  }, [useLabelTracking, setValue, orderData.tracking_number]);

  if (accountDataLoading || !accountData) return <LoadingSpinner />;

  const handleUpdateStatus = (status: string, tracking: string | null) => {
    if (listingId) {
      updateShippingStatus({ listingId, status, tracking });
      setStatus(status);
    }
  };

  const handleVoidShippingLabel = () => {
    setIsModalOpen(true);
  };

  const confirmVoidShippingLabel = () => {
    voidShippingLabel({ labelId: orderData.label_id });
    setIsModalOpen(false);
  };

  const onSubmit = handleSubmit((formData) => {
    console.log(`formData.status: ${formData.status}`);
    console.log({ tempStatus });
    console.log({ status });
    handleUpdateStatus(formData.status, formData.tracking);
    setStatus(formData.status);
  });

  const refundPending = ['QUEUED', 'PENDING'].includes(orderData.refund_status);

  return (
    <Container>
      <Header>Shipping</Header>
      <Content>
        {labelExists && blobUrl && !refundPending && (
          <>
            <Thumbnail blobUrl={blobUrl} />
          </>
        )}
        <>
          {showCreateForm && !labelExists ? (
            <ShippingProvider>
              <CreateShippingLabel
                accountData={accountData.accountInfo}
                shippingData={orderData}
                setLabelExists={setLabelExists}
                setTrigger={setTrigger}
              ></CreateShippingLabel>
            </ShippingProvider>
          ) : (
            <>
              <ShippingText>
                {refundPending
                  ? 'The refund for your shipping label is pending.'
                  : labelExists
                  ? 'Print label by downloading the image above.'
                  : 'No label has been created for this order.'}
              </ShippingText>
              {labelExists && !refundPending && (
                <Button
                  width={'200px'}
                  height={'40px'}
                  onClick={() => handleVoidShippingLabel()}
                >
                  Void shipping label
                </Button>
              )}
              <>
                {!labelExists && (
                  <Button
                    width={'200px'}
                    height={'40px'}
                    onClick={() => setShowCreateForm(true)}
                    disabled={status === OrderStatuses.Shipped}
                  >
                    Create label
                  </Button>
                )}
                <FormProvider {...methods}>
                  <Form onSubmit={onSubmit}>
                    <Row>
                      <StatusSelect
                        defaultValue={status}
                        {...register('status')}
                        onChange={(e) => {
                          setTempStatus(e.target.value);
                          setValue('status', e.target.value);
                        }}
                        disabled={status === OrderStatuses.Settled}
                      >
                        <option value={OrderStatuses.OrderCaptured}>
                          Awaiting Shipment
                        </option>
                        <option value={OrderStatuses.Shipped}>Shipped</option>
                        <option value={OrderStatuses.Received}>Received</option>
                        <option value={OrderStatuses.Returning}>
                          Returning
                        </option>
                        <option value={OrderStatuses.Refunded}>Refunded</option>
                      </StatusSelect>
                      <Input
                        {...trackingNumberValidation(trackingRequired)}
                        height={'36px'}
                        width={'280px'}
                      />
                      <Button
                        width={'200px'}
                        height={'40px'}
                        type='submit'
                        disabled={status === tempStatus}
                      >
                        Update Status
                      </Button>
                    </Row>
                    {labelExists && (
                      <CheckboxInput {...useLabelTrackingValidation} />
                    )}
                  </Form>
                </FormProvider>
              </>
            </>
          )}
        </>
      </Content>
      {isModalOpen && (
        <VoidConfirmationModal
          onClose={() => setIsModalOpen(false)}
          onConfirm={confirmVoidShippingLabel}
        />
      )}
    </Container>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 40px;
  padding: 40px 0 0 0;
`;

const StatusSelect = styled.select`
  padding: 8px;
  font-size: 1em;
  cursor: pointer;
  /* Add other styles as needed */
  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

const ShippingText = styled.div`
  font-size: 16px;
  padding: 0 0 10px 0;
`;

const Container = styled.div`
  width: 800px;
`;

const Content = styled.div`
  // border: 1px solid ${(props) => props.theme.colors.border.primary};
`;

const Header = styled.h2`
  text-align: left;
  margin-bottom: 0;
`;
