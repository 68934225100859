import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';

import { useCheckFeedback } from 'hooks/feedback';
import { Button } from 'components/buttons';
import LoadingSpinner from 'components/loading';

export const FeedbackSection = ({ listingId }: { listingId: string }) => {
  const userId = localStorage.getItem('userId') || '';
  const { data, isLoading } = useCheckFeedback({
    userId,
    listingId,
  });
  const navigate = useNavigate();

  if (isLoading) return <LoadingSpinner />;

  return (
    <Container>
      <Header>Leave feedback</Header>
      <Content>
        {data?.exists && 'Feedback already left.'}
        <ButtonContainer>
          <Button
            width={'200px'}
            height={'40px'}
            onClick={() => navigate(`/feedback/${listingId}/forBuyer`)}
            disabled={data?.exists}
          >
            Leave feedback
          </Button>
        </ButtonContainer>
      </Content>
    </Container>
  );
};

const ButtonContainer = styled.div`
  margin-top: 10px;
`;

const Container = styled.div`
  width: 800px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: 1px solid ${(props) => props.theme.colors.border.primary};
`;

const Header = styled.h3`
  text-align: left;
`;
