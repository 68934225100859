import styled from '@emotion/styled';

import { ProfilePictureProps } from './types';

import { DefaultProfilePic } from 'assets/images';

const sizeLookup = {
  small: '38px',
  medium: '120px',
  large: '180px',
};

export const ProfilePicture: React.FC<ProfilePictureProps> = ({
  profilePicUrl,
  size,
  iconStatus,
}) => {
  return (
    <ProfilePicContainer size={size}>
      {profilePicUrl ? (
        <ProfilePic src={profilePicUrl} alt='User Profile Picture' />
      ) : (
        <DefaultProfilePicDiv size={size} />
      )}
    </ProfilePicContainer>
  );
};

const DefaultProfilePicDiv = styled.div<{ size: 'small' | 'medium' | 'large' }>`
  color: #2c2761;
  background-image: url(${DefaultProfilePic});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: ${({ size }) => sizeLookup[size]};
  aspect-ratio: 3 / 1;
`;

const ProfilePicContainer = styled.div<{ size: 'small' | 'medium' | 'large' }>`
  width: ${({ size }) => sizeLookup[size]};
  height: ${({ size }) => sizeLookup[size]};
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
`;

const ProfilePic = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
`;
