import styled from '@emotion/styled';

export const Pagination: React.FC<{
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}> = ({ currentPage, totalPages, onPageChange }) => {
  const getVisiblePages = () => {
    if (totalPages <= 5) {
      return Array.from({ length: totalPages }, (_, i) => i + 1);
    } else if (currentPage <= 3) {
      return [1, 2, 3, 4, 5];
    } else if (currentPage > totalPages - 3) {
      return [
        totalPages - 4,
        totalPages - 3,
        totalPages - 2,
        totalPages - 1,
        totalPages,
      ];
    } else {
      return [
        currentPage - 2,
        currentPage - 1,
        currentPage,
        currentPage + 1,
        currentPage + 2,
      ];
    }
  };

  return (
    <PaginationContainer>
      {totalPages >= 6 && (
        <PaginationButton
          onClick={() => onPageChange(1)}
          disabled={currentPage === 1}
          isSelected={currentPage === 1}
        >
          First
        </PaginationButton>
      )}
      {getVisiblePages().map((page) => (
        <PaginationButton
          key={page}
          onClick={() => onPageChange(page)}
          disabled={page === currentPage}
          isSelected={page === currentPage}
        >
          {page}
        </PaginationButton>
      ))}
      {totalPages >= 6 && (
        <PaginationButton
          onClick={() => onPageChange(totalPages)}
          disabled={currentPage === totalPages}
          isSelected={currentPage === totalPages}
        >
          Last
        </PaginationButton>
      )}
    </PaginationContainer>
  );
};

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;
`;

const PaginationButton = styled.button<{ isSelected?: boolean }>`
  margin: 0 5px;
  padding: 5px 10px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: ${(props) => props.theme.fonts.leftNav.size};
  font-weight: ${(props) => props.theme.fonts.leftNav.weight};
  color: ${(props) => props.theme.fonts.leftNav.color};
  text-align: left;
  position: relative;

  &::after {
    content: '';
    display: ${(props) => (props.isSelected ? 'block' : 'none')};
    width: 100%;
    height: 3px;
    background-color: ${(props) => props.theme.fonts.leftNav.color};
    position: absolute;
    bottom: -2px;
    left: 0;
  }

  &:hover:not(:disabled) {
    color: ${(props) => props.theme.colors.primary};
  }

  &:hover:not(:disabled)::after {
    display: block;
    background-color: ${(props) => props.theme.colors.primary};
  }

  &:disabled {
    cursor: auto;
    opacity: 50%;
  }
`;
