import styled from '@emotion/styled';
import { useFormContext } from 'react-hook-form';
import { AnimatePresence } from 'framer-motion';
import { useState } from 'react';
import { Eye, EyeSlash } from '@phosphor-icons/react';

import { ValidationErrorMessage } from 'components/validation';

import { InputInt } from './types';
import { findInputError, isFormInvalid } from './errors';

interface ExtendedInputInt extends InputInt {
  labelFontSize?: string;
  maxLength?: number;
  lines?: number;
  capitalize?: boolean;
  inputMask?: (value: string) => string;
}

export const Input = ({
  label,
  type,
  id,
  placeholder,
  validation,
  name,
  width,
  height,
  labelFontSize,
  maxLength = 500,
  lines = 1,
  capitalize = false,
  inputMask,
}: ExtendedInputInt) => {
  const [isVisible, setIsVisible] = useState(!type.includes('password'));
  const {
    register,
    formState: { errors },
    setValue,
  } = useFormContext();

  const inputError = findInputError(errors, name);
  const isInvalid = isFormInvalid(inputError);
  const toggleVisibility = () => setIsVisible((prev) => !prev);
  const { ref, onChange } = register(name, validation);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    const originalValue = value; // Store the original value for comparison
    if (inputMask) {
      value = inputMask(value);
    }
    // Check if the value has changed after applying the mask
    if (value !== originalValue) {
      // Use setValue from react-hook-form to set the masked value without showing the masked characters
      setValue(name, value, { shouldValidate: true }); // Update form state without triggering the onChange event
    } else {
      // Call the default onChange handler from react-hook-form if the value remains the same
      onChange(e);
    }
  };

  return (
    <Container width={width}>
      <Header>
        <StyledLabel htmlFor={id} labelFontSize={labelFontSize}>
          {label}{' '}
        </StyledLabel>
        {type === 'password' && (
          <ToggleVisibilityButton type='button' onClick={toggleVisibility}>
            {type === 'password' && isVisible ? (
              <EyeSlash size={22} />
            ) : (
              <Eye size={22} />
            )}
          </ToggleVisibilityButton>
        )}
      </Header>
      {lines > 1 ? (
        <StyledTextarea
          id={id}
          placeholder={placeholder}
          {...register(name, validation)}
          maxLength={maxLength}
          rows={lines}
          capitalize={capitalize}
        />
      ) : (
        <StyledInput
          id={id}
          height={height}
          type={isVisible ? 'text' : 'password'}
          placeholder={placeholder}
          maxLength={maxLength}
          capitalize={capitalize}
          ref={ref}
          onChange={inputMask ? handleChange : onChange}
          name={name}
        />
      )}
      <AnimatePresence mode='wait' initial={false}>
        {isInvalid && inputError && (
          <ValidationErrorMessage
            message={inputError.message}
            key={inputError.message}
          />
        )}
      </AnimatePresence>
    </Container>
  );
};

const StyledLabel = styled.label<{ labelFontSize?: string }>`
  font-size: ${(props) => props.labelFontSize || '14px'};
  text-transform: capitalize;
`;

const StyledInput = styled.input<{ height?: string; capitalize: boolean }>`
  text-transform: ${(props) => (props.capitalize ? 'capitalize' : 'none')};
  height: ${(props) => props.height || '48px'};
  padding: 0 1.25rem;
  font-size: ${(props) => props.theme.fontSize.input};
  font-weight: 500;
  border: 1px solid ${(props) => props.theme.colors.border.primary};
  border-radius: ${(props) => props.theme.borderRadius.input};
  background: ${(props) => props.theme.colors.white};
  transition: border-color 0.3s ease;
  ::placeholder {
    opacity: 0.7;
    color: ${(props) => props.theme.colors.text.placeholder};
  }
  &:hover {
    box-shadow: 0 0 5px #eeeeee;
  }
  &:focus {
    border-color: ${(props) => props.theme.colors.border.focus};
    outline: none;
  }
`;

// StyledTextarea to handle multiple lines
const StyledTextarea = styled.textarea<{ capitalize: boolean }>`
  text-transform: ${(props) => (props.capitalize ? 'capitalize' : 'none')};
  padding: 0.5rem 1.25rem;
  font-size: ${(props) => props.theme.fontSize.input};
  font-weight: 500;
  border: 1px solid ${(props) => props.theme.colors.border.primary};
  border-radius: ${(props) => props.theme.borderRadius.input};
  background: ${(props) => props.theme.colors.white};
  transition: border-color 0.3s ease;
  resize: none;
  ::placeholder {
    opacity: 0.7;
    color: ${(props) => props.theme.colors.text.placeholder};
  }
  &:hover {
    box-shadow: 0 0 5px #eeeeee;
  }
  &:focus {
    border-color: ${(props) => props.theme.colors.border.focus};
    outline: none;
  }
`;

const Container = styled.div<{ width?: string }>`
  display: flex;
  flex-direction: column;
  max-width: ${(props) => props.width || '100%'};
  gap: 8px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ToggleVisibilityButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: ${(props) => props.theme.colors.text.primary};
  display: flex;
  align-items: center;
  padding: 0;
  font-size: 1.25rem;
`;
