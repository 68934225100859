import { useState } from 'react';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import { DiscordLogo, XLogo } from '@phosphor-icons/react';

import { StyledIcon } from 'components/icons';
import { BottomNavComponent } from 'components/links';
import { theme } from 'styles';
import { DISCORD_URL, TWITTER_URL } from 'config';

type IconWrapperProps = React.HTMLAttributes<HTMLDivElement> & {
  as?: 'a';
  href?: string;
  target?: string;
  rel?: string;
};

const BottomNav = () => {
  const [hoveredComponent, setHoveredComponent] = useState<string | null>(null);
  const navigate = useNavigate();

  const getIconStatus = (component: string) => {
    if (component === hoveredComponent) return 'hover';
    return 'static';
  };

  const iconTheme = theme.icon.bottomNav;

  return (
    <BottomNavBar>
      <BottomNavContent>
        <Left>
          <BottomNavComponent text='About' onClick={() => navigate(`/about`)} />
          <BottomNavComponent text='FAQ' onClick={() => navigate(`/faq`)} />
          <BottomNavComponent
            text='Roadmap'
            onClick={() => navigate(`/roadmap`)}
          />
          <BottomNavComponent text='Help' onClick={() => navigate(`/help`)} />
        </Left>
        <Middle>
          <IconWrapper
            as='a'
            href={DISCORD_URL}
            target='_blank'
            rel='noopener noreferrer'
            onMouseEnter={() => setHoveredComponent('Discord')}
            onMouseLeave={() => setHoveredComponent(null)}
          >
            <StyledIcon
              icon={DiscordLogo}
              theme={iconTheme}
              status={getIconStatus('Discord')}
            />
          </IconWrapper>
          <IconWrapper
            as='a'
            href={TWITTER_URL}
            target='_blank'
            rel='noopener noreferrer'
            onMouseEnter={() => setHoveredComponent('X')}
            onMouseLeave={() => setHoveredComponent(null)}
          >
            <StyledIcon
              icon={XLogo}
              theme={iconTheme}
              status={getIconStatus('X')}
            />
          </IconWrapper>
        </Middle>
        <Right>
          <BottomNavComponent
            text='Privacy'
            onClick={() => navigate('/privacy')}
          />
          <BottomNavComponent text='Terms' onClick={() => navigate('/terms')} />
          <BottomNavComponent
            text='Contact'
            onClick={() => navigate(`/contact`)}
          />
        </Right>
      </BottomNavContent>
    </BottomNavBar>
  );
};

const IconWrapper = styled.div<IconWrapperProps>`
  cursor: pointer;
  &[href] {
    text-decoration: none;
    display: inline-flex;
  }
`;

const BottomNavBar = styled.div`
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  bottom: 0;
  width: 100%;
  min-height: 50px;
  padding: 0 40px;
  background: ${(props) => props.theme.colors.white};
  border-top: 1px solid ${(props) => props.theme.colors.border.primary};
  @media (max-width: ${(props) => props.theme.minViewSize}) {
    min-height: 120px;
  }
`;

const BottomNavContent = styled.div`
  max-width: 1000px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  @media (max-width: ${(props) => props.theme.minViewSize}) {
    flex-direction: column;
    justify-items: center;
    align-items: center;
    gap: 10px;
  }
`;

const Left = styled.div`
  display: flex;
  justify-content: flex-start;
  flex: 1;
  gap: 25px;
  @media (max-width: ${(props) => props.theme.minViewSize}) {
    order: 2;
    justify-content: center;
  }
`;

const Middle = styled.div`
  display: flex;
  flex-direction: row;
  width: 200px;
  justify-content: center;
  gap: 20px;
  @media (max-width: ${(props) => props.theme.minViewSize}) {
    order: 1;
  }
`;

const Right = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 1;
  gap: 25px;
  @media (max-width: ${(props) => props.theme.minViewSize}) {
    order: 3;
    justify-content: center;
`;

export default BottomNav;
