import { useParams } from 'react-router-dom';

import LoadingSpinner from 'components/loading';
import { ListingForm } from 'components/listingCreate';
import {
  ListingCanNotEditLiveOrder,
  ListingCanNotEditNotOwner,
} from 'components/listingView';
import { useEditListing, useGetListing } from 'hooks/listing';

export const EditListingForm = () => {
  const { listingId } = useParams<{ listingId: string }>();
  const userId = localStorage.getItem('userId') || '';
  const { data, isLoading } = useGetListing(listingId || '', 'edit');
  const { mutate: createListing, status } = useEditListing();

  if (isLoading) return <LoadingSpinner />;

  if (userId !== data?.listing.owner_id) return <ListingCanNotEditNotOwner />;

  if (data?.listing.order_status) return <ListingCanNotEditLiveOrder />;

  return (
    <ListingForm
      submitListing={createListing}
      status={status}
      existingData={data?.listing}
    />
  );
};
