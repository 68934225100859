import { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import styled from '@emotion/styled';
import UserSummary from './UserSummary';
import UserInfo from './AboutTab';
import UserListings from './ListingsTab';

import { FeedbackTable } from 'components/feedback';
import { useGetFeedback } from 'hooks/feedback';
import { useGetUserInfo } from 'hooks/user';
import { UserInfoRecord } from './types';

const defaultUserInfo: UserInfoRecord = {
  user_id: '',
  username: '',
  items_bought: 0,
  items_sold: 0,
  createdAt: '',
  num_positive_feedback: 0,
  num_neutral_feedback: 0,
  num_negative_feedback: 0,
  num_total_feedback: 0,
};

const UserPage = () => {
  const { userId } = useParams<{ userId: string }>();
  const location = useLocation();
  const navigate = useNavigate();

  const [userInfo, setUserInfo] = useState<UserInfoRecord>(defaultUserInfo);
  const [activeTab, setActiveTab] = useState<
    'About' | 'Feedback' | 'CurrentListings'
  >('About');
  const { data: feedbackData } = useGetFeedback(userId || '');
  const { data: userData, isLoading } = useGetUserInfo(userId || '');

  // Extract the active tab from the query parameter on every render
  const getActiveTabFromURL = () => {
    const params = new URLSearchParams(location.search);
    return (
      (params.get('tab') as 'About' | 'Feedback' | 'CurrentListings') || 'About'
    );
  };

  useEffect(() => {
    const tab = getActiveTabFromURL();
    if (tab !== activeTab) {
      setActiveTab(tab); // Synchronize state with URL
    }
  }, [location.search]);

  const handleTabClick = (tab: 'About' | 'Feedback' | 'CurrentListings') => {
    const params = new URLSearchParams(location.search);
    params.set('tab', tab);
    navigate({ search: params.toString() }, { replace: true }); // Use replace to avoid history clutter
  };

  useEffect(() => {
    if (userData && !isLoading) {
      const updatedUserInfo = {
        ...userData.userInfo,
        items_sold: Number(userData.userInfo.items_sold),
        items_bought: Number(userData.userInfo.items_bought),
        num_positive_feedback: Number(userData.userInfo.num_positive_feedback),
        num_neutral_feedback: Number(userData.userInfo.num_neutral_feedback),
        num_negative_feedback: Number(userData.userInfo.num_negative_feedback),
        num_total_feedback: Number(userData.userInfo.num_total_feedback),
      };
      setUserInfo(updatedUserInfo);
    }
  }, [userData, isLoading]);

  const renderContent = () => {
    switch (activeTab) {
      case 'About':
        return <UserInfo userInfo={userInfo}></UserInfo>;
      case 'Feedback':
        if (!feedbackData) {
          return <div>No feedback data available.</div>;
        }
        return (
          <FeedbackTable
            userInfo={userInfo}
            feedbackData={feedbackData}
            feedbackType={'forBuyer'}
          />
        );
      case 'CurrentListings':
        return <UserListings userInfo={userInfo}></UserListings>;
      default:
        return null;
    }
  };

  return (
    <>
      <TabContainer>
        <UserSummary userInfo={userInfo}></UserSummary>
        <TabButtonsContainer>
          <TabButton
            isActive={activeTab === 'About'}
            onClick={() => handleTabClick('About')}
          >
            About
          </TabButton>
          <TabButton
            isActive={activeTab === 'Feedback'}
            onClick={() => handleTabClick('Feedback')}
          >
            Feedback
          </TabButton>
          <TabButton
            isActive={activeTab === 'CurrentListings'}
            onClick={() => handleTabClick('CurrentListings')}
          >
            Listings
          </TabButton>
        </TabButtonsContainer>
        <Content>{renderContent()}</Content>
      </TabContainer>
    </>
  );
};

const Content = styled.div`
  margin-top: 20px;
`;

const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  max-width: 100%;
  margin: 40px auto;
`;

const TabButtonsContainer = styled.div`
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
`;

const TabButton = styled.button<{ isActive: boolean }>`
  width: 160px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 10px 0;
  background: ${(props) =>
    props.isActive ? props.theme.colors.background.focus : 'none'};
  border-bottom: ${(props) =>
    props.isActive ? `2px solid ${props.theme.colors.primary}` : 'none'};

  &:hover {
    background-color: ${(props) =>
      !props.isActive && props.theme.colors.secondary_hover};
  }

  @media (max-width: ${(props) => props.theme.minViewSize}) {
    width: 120px;
  }
`;

export default UserPage;
