import styled from '@emotion/styled';
import { useNavigate, useParams } from 'react-router-dom';

import { Breadcrumbs } from 'components/breadcrumbs';
import { BlueLinkComponent } from 'components/links';
import LoadingSpinner from 'components/loading';
import { Container, Header } from 'components/myAccount';
import { OrderStatus } from 'components/orderStatus';
import { RETURN_POLICY_MAP } from 'config';
import { useDoFindOrCreateConversation } from 'hooks/messaging';
import { useGetOrders } from 'hooks/orders';
import { formatDateTime, formattedDate, formatNumber } from 'utils';

export const OrderDetails = () => {
  const navigate = useNavigate();
  const { listingId } = useParams<{ listingId: string }>();
  const { data, isLoading } = useGetOrders(listingId);
  const { mutate: doFindOrCreateConvo } = useDoFindOrCreateConversation();

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (!data) {
    return <div>Order not found.</div>;
  }

  const order = data.orders[0];
  const total = formatNumber(
    Number(order.price) + Number(order.shipping_cost) + Number(order.tax_amount)
  );

  return (
    <Container>
      <Header>Order Details</Header>
      <Breadcrumbs
        items={[
          { path: '/myAccount/Purchases', label: 'My purchases' },
          { path: '/myAccount/Purchases/ID', label: 'Order details' },
        ]}
      />
      <ItemBox>
        <Row>
          <Title>Item Info</Title>
          <Details>
            <DetailRow>
              <ImageContainer>
                <Image src={order.signedS3Url} alt='Product Image' />
              </ImageContainer>
              <ItemInfoContainer>
                <ListingTitle>{order.listing_title}</ListingTitle>
                <OrderStatus
                  status={order.status}
                  isActive={false}
                  size={'large'}
                />
                <Purchased>
                  Purchased on {formattedDate(order.createdAt)}
                </Purchased>
                <ReturnText>{RETURN_POLICY_MAP[order.returns]}</ReturnText>
                <BlueLinkComponent
                  text={'View original listing'}
                  onClick={() => navigate(`/listing/${order.listing_id}`)}
                />
              </ItemInfoContainer>
            </DetailRow>
          </Details>
        </Row>
        <Row>
          <Title>Order Info</Title>
          <Details>
            <ItemInfoContainer>
              <InfoRow>
                <TextLeft>Time Placed:</TextLeft>
                <TextRight>{formatDateTime(order.captured_at)}</TextRight>
              </InfoRow>
              <InfoRow>
                <TextLeft>Order number:</TextLeft>
                <TextRight>{order.id}</TextRight>
              </InfoRow>
              <InfoRow>
                <TextLeft>Sold by:</TextLeft>
                <TextRight>{order.seller_username}</TextRight>
              </InfoRow>
              <InfoRow>
                <TextLeft></TextLeft>
                <TextRight>
                  <BlueLinkComponent
                    text={'Contact Seller'}
                    onClick={() =>
                      doFindOrCreateConvo({ otherUserId: order.seller_id })
                    }
                  />
                </TextRight>
              </InfoRow>
            </ItemInfoContainer>
          </Details>
        </Row>
        <Row>
          <Title>Shipped To</Title>
          <Details>
            <ItemInfoContainer>
              <TextLeft>{order.full_name}</TextLeft>
              <TextLeft>{order.address_1}</TextLeft>
              {order.address_2 && <TextLeft>{order.address_2}</TextLeft>}
              <TextLeft>{`${order.city}, ${order.state} ${order.zip}`}</TextLeft>
            </ItemInfoContainer>
          </Details>
        </Row>
        <Row>
          <Title>Payment Info</Title>
          <Details>
            <CostContainer>
              <CostRow>
                <TotalLeft>Total:</TotalLeft>
                <TotalRight>${total}</TotalRight>
              </CostRow>
              <CostRow>
                <TextLeft>Price:</TextLeft>
                <TextRight>${formatNumber(Number(order.price))}</TextRight>
              </CostRow>
              <CostRow>
                <TextLeft>Shipping Cost:</TextLeft>
                <TextRight>
                  ${formatNumber(Number(order.shipping_cost))}
                </TextRight>
              </CostRow>
              <CostRow>
                <TextLeft>Sales tax:</TextLeft>
                <TextRight>${formatNumber(Number(order.tax_amount))}</TextRight>
              </CostRow>
            </CostContainer>
          </Details>
        </Row>
      </ItemBox>
    </Container>
  );
};

const ListingTitle = styled.h4`
  margin: 0;
`;

const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 500px;
`;

const TextLeft = styled.div`
  font-size: 14px;
  text-align: left;
  display: flex;
  align-items: center;
  gap: 5px;
  min-width: 140px;
`;

const TextRight = styled.div`
  font-size: 14px;
  text-align: left;
  flex: 1;
`;

const TotalLeft = styled(TextLeft)`
  font-weight: bold;
`;

const TotalRight = styled(TextLeft)`
  font-weight: bold;
`;

const CostRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Row = styled.div`
  display: flex;
  padding: 20px 0;
`;

const Title = styled.div`
  width: 160px;
  font-weight: bold;
  color: #333;
`;

const Details = styled.div`
  flex: 1;
  color: #555;
`;

const DetailRow = styled.div`
  display: flex;
  align-items: top;
  flex-direction: row;
  gap: 60px;
`;

const ItemBox = styled.div`
  flex: 1;
  align-self: flex-start;
  width: 100%;
  padding: 16px;
  height auto;
  border: 1px solid ${(props) => props.theme.colors.border.primary};
`;

const ImageContainer = styled.div`
  width: 140px;
  height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eee;
`;

const CostContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 240px;
  gap: 4px;
`;

const Image = styled.img`
  max-width: 140px;
  max-height: 140px;
  object-fit: cover;
`;

const ItemInfoContainer = styled.div`
  flex-direction: column;
  display: flex;
  align-items: left;
  text-align: left;
  gap: 6px;
`;

const Purchased = styled.div`
  text-align: left;
  font-size: 14px;
`;

const ReturnText = styled.div`
  font-size: 14px;
  color: #111;
`;
