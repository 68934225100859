import React, { useEffect, useState, useRef } from 'react';
import styled from '@emotion/styled';
import { useNavigate, useLocation } from 'react-router-dom';
import { PlusCircle, SignOut, UserCircle } from '@phosphor-icons/react';

import { theme } from 'styles';
import { StyledIcon } from 'components/icons';
import { useAuth } from 'components/auth';
import { ProfilePicture } from 'components/profilePic';

interface UserMenuProps {
  profilePicUrl?: string;
}

export const UserMenu: React.FC<UserMenuProps> = ({ profilePicUrl }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [hoveredComponent, setHoveredComponent] = useState<string | null>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { logout } = useAuth();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const userIconRef = useRef<HTMLDivElement>(null);
  const username = localStorage.getItem('username') || '';

  const toggleDropdown = () => {
    setShowDropdown((prev) => !prev);
  };

  useEffect(() => {
    setShowDropdown(false);
  }, [location.pathname]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        userIconRef.current &&
        !userIconRef.current.contains(event.target as Node)
      ) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  const generateHandlers = (component: string) => ({
    onClick: () => {
      navigate(`/${component}`);
      if (component === 'login') {
        logout();
        setShowDropdown(false);
      }
    },
    onMouseEnter: () => setHoveredComponent(component),
    onMouseLeave: () => setHoveredComponent(null),
  });

  const getIconStatus = (component: string) => {
    if (component === hoveredComponent) return 'hover';
    return 'static';
  };
  const itemIconTheme = theme.icon.dropdownMenu;

  return (
    <MenuContainer>
      <UserIcon ref={userIconRef} onClick={toggleDropdown}>
        <ProfilePictureWrapper showHalo={showDropdown}>
          <ProfilePicture
            size={'small'}
            profilePicUrl={profilePicUrl}
            iconStatus={'static'}
          />
        </ProfilePictureWrapper>
      </UserIcon>
      {showDropdown && (
        <DropdownMenu ref={dropdownRef}>
          <UsernameDisplayDiv>
            <UsernameDisplay>{username}</UsernameDisplay>
          </UsernameDisplayDiv>
          <MenuSelection {...generateHandlers('myAccount/AccountHome')}>
            <IconWrapper>
              <StyledIcon
                icon={UserCircle}
                theme={itemIconTheme}
                status={getIconStatus('myAccount/AccountHome')}
              />
              <Entry>My account</Entry>
            </IconWrapper>
          </MenuSelection>
          <MenuSelection {...generateHandlers('newListing')}>
            <IconWrapper>
              <StyledIcon
                icon={PlusCircle}
                theme={itemIconTheme}
                status={getIconStatus('newListing')}
              />
              <Entry>Create new listing</Entry>
            </IconWrapper>
          </MenuSelection>
          <MenuSelection {...generateHandlers('login')}>
            <IconWrapper>
              <StyledIcon
                icon={SignOut}
                theme={itemIconTheme}
                status={getIconStatus('login')}
              />
              <Entry>Log out</Entry>
            </IconWrapper>
          </MenuSelection>
        </DropdownMenu>
      )}
    </MenuContainer>
  );
};

const ProfilePictureWrapper = styled.div<{ showHalo: boolean }>`
  position: relative;
  display: inline-flex;

  ${({ showHalo, theme }) =>
    showHalo &&
    `&::before {
      content: '';
      position: absolute;
      top: -6px;
      left: -6px;
      right: -6px;
      bottom: -6px;
      border-radius: 50%;
      border: 2px solid ${theme.colors.primary_hover};
      pointer-events: none;
    }`};
`;

const Entry = styled.span`
  font-size: 14px;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  span {
    margin-left: 10px;
  }
`;

const MenuContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const UserIcon = styled.div`
  cursor: pointer;
`;

const DropdownMenu = styled.div`
  position: absolute;
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.border.primary};
  border-radius: ${(props) => props.theme.borderRadius.dropdownMenu};
  min-width: 200px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px, rgba(0, 0, 0, 0.05) 0px 2px 4px;
  z-index: 1;
  right: 0;
  top: calc(100% + 5px);
`;

const MenuSelection = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
  width: 100%;
  font-size: ${(props) => props.theme.fontSize.dropdownMenu};
  color: ${(props) => props.theme.colors.text.blueGrey};
  text-align: left;

  &:hover {
    background-color: ${(props) => props.theme.colors.secondary_hover};
  }
`;

const UsernameDisplayDiv = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors.border.primary};
`;

const UsernameDisplay = styled.div`
  font-weight: semi-bold;
  padding: 10px;
  margin-left: 30px;
  font-size: 14px;
  text-align: left;
  color: ${(props) => props.theme.colors.text.primary};
`;
