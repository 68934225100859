import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';

import { GreyLinkComponent } from 'components/links';

export const FeedbackSubmittedPage = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <DefaultContainer>
        <PageTitleDefault>
          Your feedback has been received.
          <br />
          Thank you!
        </PageTitleDefault>
        <GreyLinkComponent
          text={'← Back to My Account'}
          onClick={() => navigate(`/myAccount/AccountHome`)}
        />
      </DefaultContainer>
    </Container>
  );
};

const Container = styled.div`
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
`;

export const StarTitle = styled.p`
  font-size: 18px;
  font-weight: 600;
  margin: 0 0 -20px 22px;
  text-align: left;
`;

const DefaultContainer = styled.div`
  margin-top: 120px;
  width: 600px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  & > *:not(:last-child) {
    margin-bottom: 10px;
  }
`;

const PageTitleDefault = styled.h2`
  text-align: center;
  margin: 0 0 0 0;
  padding: 16px;
`;
