import styled from '@emotion/styled';

import { Dropdown } from 'components/input';
import { categoryValidation } from 'components/validation';

import {
  HeaderContainer,
  HeaderSubtitle,
  HeaderTitle,
  Section,
} from './shared';

export const CategoryComponent = ({
  existingChoice,
}: {
  existingChoice?: string;
}) => {
  return (
    <Section>
      <HeaderContainer>
        <HeaderTitle>Category</HeaderTitle>
        <HeaderSubtitle>Help users find your listing.</HeaderSubtitle>
      </HeaderContainer>
      <Dropdown
        {...categoryValidation}
        height='40px'
        width='340px'
        existingChoice={existingChoice}
      />
      <HelpText>
        Not seeing your category?{' '}
        <a href='/contact' target='_blank' rel='noopener noreferrer'>
          Let us know.
        </a>
      </HelpText>
    </Section>
  );
};

const HelpText = styled.p`
  font-size: 11px;
  a {
    color: blue;
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline;
  }
`;
