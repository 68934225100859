import styled from '@emotion/styled';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EnvelopeSimple } from '@phosphor-icons/react';

import { LogoProps } from 'types';
import { theme } from 'styles';
import { StyledIcon } from 'components/icons';

const MessageIcon: React.FC<{ numUnreadMsg: number }> = ({ numUnreadMsg }) => {
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/myAccount/Messages');
  };

  return (
    <MessageIconDiv
      height='20px'
      onClick={handleClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <IconWrapper>
        <StyledIcon
          icon={EnvelopeSimple}
          theme={theme.icon.topNav}
          status={isHovered ? 'hover' : 'static'}
        ></StyledIcon>
        {numUnreadMsg > 0 && <UnreadBadge>{numUnreadMsg}</UnreadBadge>}
      </IconWrapper>
    </MessageIconDiv>
  );
};

const UnreadBadge = styled.div`
  position: absolute;
  top: -5px;
  left: -5px;
  background-color: #ad3434;
  color: white;
  border-radius: 20%;
  padding: 2px 6px;
  font-size: 10px;
  font-weight: bold;
`;

const IconWrapper = styled.div`
  cursor: pointer;
  position: relative;
`;

const MessageIconDiv = styled.div<LogoProps>`
  margin-right: 30px;
`;

export default MessageIcon;
