import { Errors } from './types';

export const findInputError = (errors: Errors, name: string) => {
  if (errors[name]) {
    return errors[name];
  }
  return null;
};

export const isFormInvalid = (err: any) => {
  return !!err;
};
