import { useNavigate } from 'react-router-dom';

import { Breadcrumbs } from 'components/breadcrumbs';
import {
  Container,
  Content,
  Divider,
  Header,
  Paragraph,
  Section,
  Title,
} from 'components/helpShared';
import { BlueLinkComponent } from 'components/links';

export const Payouts = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <Breadcrumbs
        items={[
          { path: '/help', label: 'Help Home' },
          { path: '/help/sellerResources', label: 'Seller Resources' },
          {
            path: '/help/sellerResources/payouts',
            label: 'Payouts',
          },
        ]}
      />
      <Header>Payouts</Header>
      <Divider />
      <Content>
        <Section>
          <Title>Payout Schedule</Title>
          <Paragraph>
            Upon settlement of orders, your payout will be eligible according to
            the schedule set by your{' '}
            <BlueLinkComponent
              onClick={() => navigate('/help/sellerResources/sellerTiers')}
              text='Seller Tier'
              fontSize='16px'
            />
            . Upon eligibility, you will be payed out to your bank account at
            the next payment date. Payment dates occur weekly on Mondays from
            approximately 4 to 5pm UTC.
          </Paragraph>
          <Paragraph>
            In the future, you will be able to receive a payment directly to a
            connected bank account. See our{' '}
            <BlueLinkComponent
              onClick={() => navigate('/help/roadmap')}
              text='Roadmap'
              fontSize='16px'
            />{' '}
            for details.
          </Paragraph>
        </Section>
      </Content>
    </Container>
  );
};
