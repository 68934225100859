import { useQuery } from '@tanstack/react-query';

import useAPI from 'api';

import { AccountInfoMinimal } from 'types';

export const useGetAccountInfo = () => {
  const API = useAPI();
  const url = 'getAccountInfo';
  return useQuery({
    queryKey: [url],
    queryFn: async () => {
      return await API<{ accountInfo: AccountInfoMinimal }>(url.toString(), {
        method: 'GET',
      });
    },
  });
};
