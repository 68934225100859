import { useAuth } from 'components/auth';
import { useRedirect } from 'contexts';
import { API, APIType } from './api';

export const useAPI = (): APIType => {
  const { logout } = useAuth();
  const { setIsRedirecting } = useRedirect();

  const callAPI = async <T>(
    endpoint: string,
    options?: RequestInit
  ): Promise<T> => {
    return API<T>(endpoint, options, logout, setIsRedirecting);
  };

  return callAPI as APIType;
};
