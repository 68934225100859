import { useState, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import styled from '@emotion/styled';

import { Button } from 'components/buttons';
import { Dropdown, Input } from 'components/input';
import {
  fullNameValidation,
  address1Validation,
  address2Validation,
  cityValidation,
  stateValidation,
  zipValidation,
} from 'components/validation';

import { useShippingContext } from './context';
import { AddressComponentProps } from './types';
import { STATES_ABBRV } from 'config';

export const AddressComponent: React.FC<AddressComponentProps> = ({
  toOrFrom,
  defaultValue,
  setCreateIsDisabled,
}) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const { toAddress, setToAddress, fromAddress, setFromAddress } =
    useShippingContext();

  const address = toOrFrom === 'to' ? toAddress : fromAddress;
  const setAddress = toOrFrom === 'to' ? setToAddress : setFromAddress;

  const methods = useForm();

  useEffect(() => {
    if (isEditMode) {
      methods.reset({
        full_name: address?.full_name || '',
        street1: address?.street1 || '',
        street2: address?.street2 || '',
        city: address?.city || '',
        state: address?.state || '',
        zip: address?.zip || '',
      });
    }
  }, [isEditMode, address, methods]);

  const handleSave = (data: any) => {
    setAddress({
      full_name: data.full_name,
      street1: data.street1,
      street2: data.street2,
      city: data.city,
      state: data.state,
      zip: data.zip,
    });
    toggleEditMode(false);
  };

  const toggleEditMode = (value: boolean) => {
    setIsEditMode(value);
    setCreateIsDisabled(value);
  };

  const inputWidth = '360px';
  const inputHeight = '36px';

  return (
    <Container>
      {isEditMode ? (
        <FormProvider {...methods}>
          <Header>{toOrFrom === 'to' ? 'To Address' : 'From Address'}</Header>
          <Form autoComplete='off' onSubmit={methods.handleSubmit(handleSave)}>
            <Row>
              <InputWrapper>
                <Input
                  {...fullNameValidation}
                  {...methods.register('full_name')}
                  width={inputWidth}
                  height={inputHeight}
                />
              </InputWrapper>
            </Row>
            <Row>
              <InputWrapper>
                <Input
                  {...address1Validation}
                  {...methods.register('street1')}
                  width={inputWidth}
                  height={inputHeight}
                />
              </InputWrapper>
            </Row>
            <Row>
              <InputWrapper>
                <Input
                  {...address2Validation}
                  {...methods.register('street2')}
                  width={inputWidth}
                  height={inputHeight}
                />
              </InputWrapper>
            </Row>
            <Row>
              <InputWrapper>
                <Input
                  {...cityValidation}
                  {...methods.register('city')}
                  width={inputWidth}
                  height={inputHeight}
                />
              </InputWrapper>
            </Row>
            <Row>
              <Dropdown
                label='State'
                validation={stateValidation}
                choices={STATES_ABBRV}
                width={inputWidth}
                height={inputHeight}
                {...methods.register('state')}
              />
            </Row>
            <Row>
              <InputWrapper>
                <Input
                  {...zipValidation}
                  {...methods.register('zip')}
                  width={inputWidth}
                  height={inputHeight}
                />
              </InputWrapper>
            </Row>
            <Button type='submit'>Save</Button>
          </Form>
        </FormProvider>
      ) : (
        <div>
          <p>{address?.full_name}</p>
          <p>{address?.street1}</p>
          <p>{address?.street2}</p>
          <p>
            {address?.city}, {address?.state} {address?.zip}
          </p>
          <Button
            width='200px'
            height='40px'
            onClick={() => toggleEditMode(true)}
          >
            Edit
          </Button>
        </div>
      )}
    </Container>
  );
};

const Header = styled.h4`
  text-align: center;
  margin-top: 0;
`;

const Container = styled.div`
  width: 400px;
  min-height: 240px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const InputWrapper = styled.div`
  flex: 1;
  &:not(:last-child) {
    margin-right: 20px;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;
