export enum TaxClassifications {
  Individual = 'Individual / Sole Proprietor or Single Member LLC',
  Business = 'Business (C Corporation, S Corporation, Partnership, LLC)',
  Trust = 'Trust/Estate',
}

export type TaxClassificationType = TaxClassifications | '';

export const TaxClassificationsMap: Record<string, string> = {
  'Individual / Sole Proprietor or Single Member LLC': 'individual',
  'Business (C Corporation, S Corporation, Partnership, LLC)': 'business',
  'Trust/Estate': 'trust',
};

export const TaxClassificationsMapReverse: Record<string, string> = {
  individual: 'Individual / Sole Proprietor or Single Member LLC',
  business: 'Business (C Corporation, S Corporation, Partnership, LLC)',
  trust: 'Trust/Estate',
};
