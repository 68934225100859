import { useGetAccountInfo } from 'hooks/account';
import LoadingSpinner from 'components/loading';
import { Container } from 'components/myAccount';

import { ProfilePicForm } from './profilePicForm';
import { UsernameForm } from './usernameForm';

export const UsernameAndPicForm = () => {
  const { data, isLoading } = useGetAccountInfo();

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (!data) {
    return <div>Error: Account information is not available.</div>;
  }

  return (
    <>
      <Container>
        <UsernameForm data={data.accountInfo} />
        <ProfilePicForm data={data.accountInfo} />
      </Container>
    </>
  );
};
