import { useQuery } from '@tanstack/react-query';
import useAPI from 'api';
import { OrdersAPIResponse } from './types';

export const useGetOrders = (listingId?: string) => {
  const API = useAPI();
  const url = `getSelfPurchases${listingId ? `?listingId=${listingId}` : ''}`;

  return useQuery<OrdersAPIResponse>({
    queryKey: [url],
    queryFn: async () => {
      return await API<OrdersAPIResponse>(url, {
        method: 'GET',
      });
    },
  });
};
