import React, { useState } from 'react';
import styled from 'styled-components';

import { PhotoGalleryProps, PhotoProps } from './types';

const PhotoThumbnailComponent: React.FC<PhotoProps> = ({
  src,
  onSelect,
  isSelected,
}) => (
  <PhotoThumbnailArea onClick={onSelect} isselected={isSelected.toString()}>
    <img src={src} alt='thumbnail' />
  </PhotoThumbnailArea>
);

export const PhotoGalleryComponent: React.FC<PhotoGalleryProps> = (props) => {
  const [selectedPhoto, setSelectedPhoto] = useState<string>(
    props.imageUrls[0]
  );

  return (
    <PhotoGallery>
      <ThumbnailGallery>
        {props.imageUrls.map((url: string) => (
          <PhotoThumbnailComponent
            key={url}
            src={url}
            onSelect={() => setSelectedPhoto(url)}
            isSelected={url === selectedPhoto}
          />
        ))}
      </ThumbnailGallery>
      <PhotoPreviewArea>
        <PrimaryPhotoPreview
          src={selectedPhoto}
          alt='primary'
        ></PrimaryPhotoPreview>
      </PhotoPreviewArea>
    </PhotoGallery>
  );
};

const PhotoGallery = styled.div`
  display: flex;
  max-width: 100%;
  max-width: 650px;
  height: 460px;
`;

const ThumbnailGallery = styled.div`
  flex: 0 0 120px;
  width: 120px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  margin-right: 10px;
  align-items: center;
`;

const PhotoPreviewArea = styled.div`
  flex-grow: 1;
  aspect-ratio: 1 / 1;
  border-radius: 15px;
  width: 100%;
  max-width: 70vw;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #f2f2f2;
  overflow: hidden; // Ensure content respects the container's bounds

  @media (max-width: ${(props) => props.theme.minViewSize}) {
    max-width: 100%; // Ensure it takes full width on smaller screens
    aspect-ratio: auto; // Disable aspect ratio on smaller screens
  }
`;

const PrimaryPhotoPreview = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`;

const PhotoThumbnailArea = styled.div<{ isselected: string }>`
  width: 86px;
  height: 86px;
  min-height: 86px;
  min-width: 86px;
  margin-top: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  border-radius: 10px;
  background: #f2f2f3;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  border: ${(props) =>
    props.isselected === 'true' ? '1.5px solid grey' : '0px '};
  &:hover {
    border-width: 1px solid grey;
  }
  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
`;
