export const generateExtensionsArray = (photos: (File | string)[]) => {
  return photos.map((photo) => {
    if (typeof photo === 'string') {
      const match = photo.match(/\.([^.]*)$/);
      return match ? match[1] : 'Unknown';
    } else {
      const match = photo.name.match(/\.([^.]*)$/);
      return match ? match[1] : 'Unknown';
    }
  });
};

export const uploadFileToS3 = async (
  signedUrl: string,
  extension: string,
  file: File
) => {
  const response = await fetch(signedUrl, {
    method: 'PUT',
    headers: {
      'Content-Type': `image/${extension}`,
    },
    body: file,
  });
  return response.status === 200;
};
