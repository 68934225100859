import { Breadcrumbs } from 'components/breadcrumbs';
import {
  Container,
  Content,
  Divider,
  Header,
  Paragraph,
  Section,
  Title,
} from 'components/helpShared';
import {
  PRICE_TRACKING_REQUIRED,
  PRICE_SIGNATURE_REQUIRED,
  PRODUCT_NAME,
} from 'config';

export const ReturnsAndRefunds = () => {
  return (
    <Container>
      <Breadcrumbs
        items={[
          { path: '/help', label: 'Help Home' },
          { path: '/help/buyerResources', label: 'Buyer Resources' },
          {
            path: '/help/buyerResources/returnsAndRefunds',
            label: 'Returns and Refunds',
          },
        ]}
      />
      <Header>Returns and Refunds</Header>
      <Divider />
      <Content>
        <Section>
          <Title>Returning Orders</Title>
          <Paragraph>
            If you've changed your mind about a purchase, the ability to return
            the item depends on the seller's return policy, which is specified
            in the listing and visible in the order details of your listing.
            Some sellers may accept returns within a certain timeframe, while
            others may not accept returns at all.
          </Paragraph>
          <Paragraph>
            Per the {PRODUCT_NAME} guarantee, for items that don't match the
            listing description, arrive damaged, or are faulty, you can request
            a return even if the seller's policy states they don't accept
            returns. In such cases, you must initiate the return within 30
            calendar days of the estimated or actual delivery date.
          </Paragraph>
          <Title>Shipping Back Returns</Title>
          <Paragraph>
            If you return an item due to damage or it not matching the listing,
            the seller will cover the cost of the return shipping and will send
            you a shipping label. If you are returning because you changed your
            mind about the item, you must cover the cost of the return shipping.
            The same shipping requirements from the original shipment apply: a
            tracking requirement for item values exceeding $
            {PRICE_TRACKING_REQUIRED}, and a signature requirement for items
            exceeding ${PRICE_SIGNATURE_REQUIRED}. If tracking is required, you
            must send the seller the return tracking ID.
          </Paragraph>
          <Title>Receiving a Refund</Title>
          <Paragraph>
            When an item is returned and confirmed to be correct, a refund -
            minus any applicable shipping costs - will be issued to your
            relevant payment method.
          </Paragraph>
        </Section>
      </Content>
    </Container>
  );
};
