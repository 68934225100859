import { useQuery } from '@tanstack/react-query';

import useAPI from 'api';

import { ShippingInfo } from 'types';

export interface ShippingInfoData {
  info: ShippingInfo;
}

export const useGetShippingLabel = (listingId: string, trigger: number) => {
  const API = useAPI();
  const url = `getShippingLabel?listingId=${listingId}`;
  return useQuery<ShippingInfoData>({
    queryKey: [url, trigger],
    queryFn: async () => {
      return await API(url, {
        method: 'GET',
      });
    },
  });
};
