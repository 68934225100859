import { css } from '@emotion/react';
import { theme } from './themes';

import GDSherpaWoff from 'assets/fonts/gdsherpa.woff';
import GDSherpaWoff2 from 'assets/fonts/gdsherpa.woff2';

const fontFamily = `-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
      'Helvetica Neue', Arial, 'Apple Color Emoji', 'Segoe UI Emoji',
      'Segoe UI Symbol', 'Noto Color Emoji', sans-serif`;

export const globalStyles = css`
  @font-face {
    font-family: 'GDSherpa';
    src: url(${GDSherpaWoff2}) format('woff2'),
      url(${GDSherpaWoff}) format('woff');
    font-style: normal;
    font-display: swap;
  }

  * {
    box-sizing: border-box;
  }

  body {
    color: ${theme.colors.text.body};
    margin: 0;
    padding: 0;
    font-weight: 500;
    font-family: 'GDSherpa';
  }

  button {
    margin: 0;
    padding: 0;
    font-family: 'GDSherpa';
  }

  textarea {
    margin: 0;
    padding: 12px;
    font-family: 'GDSherpa';
  }

  h1 {
    color: ${theme.colors.text.hx};
    font-size: 36px;
    font-weight: 500;
  }

  h2 {
    color: ${theme.colors.text.hx};
    font-size: 28px;
    font-weight: 500;
  }

  h3 {
    color: ${theme.colors.text.hx};
    font-size: 22px;
    font-weight: 500;
  }

  h4 {
    color: ${theme.colors.text.hx};
    font-size: 18px;
    font-weight: 500;
  }

  h5 {
    color: ${theme.colors.text.hx};
    font-size: 14px;
    font-weight: 500;
  }
`;
