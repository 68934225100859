import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useForm, FormProvider } from 'react-hook-form';

import { Button } from 'components/buttons';
import LoadingSpinner from 'components/loading';

import { useShippingContext } from './context';
import { AddressComponent } from './shippingAddress';
import { ShippingDimensionForm } from './shippingDimensions';
import { ShippingEstimates } from './shippingEstimates';
import { ShippingLabelProps, ShippingRatesData } from './types';
import { useGetShippingRates } from './hooks';

export const CreateShippingLabel: React.FC<ShippingLabelProps> = ({
  accountData,
  shippingData,
  setLabelExists,
  setTrigger,
}) => {
  const [createIsDisabled, setCreateIsDisabled] = useState(false);
  const [shipmentData, setShipmentData] = useState<ShippingRatesData | null>(
    null
  );
  const { toAddress, setToAddress, fromAddress, setFromAddress } =
    useShippingContext();
  const { data: rates } = useGetShippingRates(
    shipmentData as ShippingRatesData
  );

  const methods = useForm();
  const { handleSubmit } = methods;

  useEffect(() => {
    if (shippingData) {
      const toAddress = {
        full_name: shippingData.full_name,
        street1: shippingData.address_1,
        street2: shippingData.address_2,
        city: shippingData.city,
        state: shippingData.state,
        zip: shippingData.zip,
      };
      setToAddress(toAddress);
    }
    if (accountData) {
      const fromAddress = {
        full_name: `${accountData.first_name} ${accountData.last_name}`,
        street1: accountData.address_1,
        street2: accountData.address_2,
        city: accountData.city,
        state: accountData.state,
        zip: accountData.zip,
      };
      setFromAddress(fromAddress);
    }
  }, [shippingData, accountData, setToAddress, setFromAddress]);

  if (!fromAddress || !toAddress) return <LoadingSpinner />;

  const onSubmit = handleSubmit((data) => {
    setShipmentData({
      address_from: fromAddress,
      address_to: {
        ...toAddress,
        buyerEmail: shippingData.buyer_email,
      },
      dimensions: data,
    });
  });

  return (
    <>
      <Header>Addresses</Header>
      <AddressContainer>
        <AddressComponent
          toOrFrom={'to'}
          defaultValue={toAddress}
          setCreateIsDisabled={setCreateIsDisabled}
        />
        <AddressComponent
          toOrFrom={'from'}
          defaultValue={fromAddress}
          setCreateIsDisabled={setCreateIsDisabled}
        />
      </AddressContainer>
      <FormProvider {...methods}>
        <Form onSubmit={onSubmit}>
          <ShippingDimensionForm />
          {/* <ShippingOptions /> */}
          {rates && (
            <ShippingEstimates
              rates={rates}
              setLabelExists={setLabelExists}
              setTrigger={setTrigger}
            />
          )}
          <Gap />
          <Button
            width={'200px'}
            height={'40px'}
            type='submit'
            disabled={createIsDisabled}
          >
            Create estimates
          </Button>
        </Form>
      </FormProvider>
    </>
  );
};

const Gap = styled.div`
  margin: 10px;
`;

const Header = styled.h4`
  text-align: left;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const AddressContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 0 20px 0;
  gap: 10px;
`;
