import React from 'react';
import styled from '@emotion/styled';

import {
  Divider,
  Header,
  List,
  ListItem,
  Paragraph,
  Section,
  Title,
} from 'components/helpShared';

export const AboutUsPage: React.FC = () => {
  return (
    <Container>
      <Header>About Near Mint</Header>
      <Divider />
      <Section>
        <Title>Who we are</Title>
        <Paragraph>
          We are passionate collectors and traders with interests across many
          spaces: gaming, comics, models, music, technology, and sports. We grew
          up in a world where the burgeoning internet made it possible to
          connect with a world of buyers and sellers, where the potential to
          trade exploded with the increase in the market size made possible by
          connecting individuals.
        </Paragraph>
        <Paragraph>
          We set out to build the ideal marketplace for collectors who share our
          passion.
        </Paragraph>
      </Section>
      <Section>
        <Title>Our vision</Title>
        <Paragraph>
          Our vision is to create a best-in-class marketplace built by and
          designed for collectors and enthusiasts.
        </Paragraph>
        <Paragraph>We want to provide:</Paragraph>
        <List>
          <ListItem>The best inventory and selection for buyers</ListItem>
          <ListItem>The best reach for sellers</ListItem>
          <ListItem>The lowest fees possible</ListItem>
          <ListItem>The safest marketplace out there</ListItem>
        </List>
      </Section>
      <Section>
        <Title>Why us?</Title>
        <Paragraph>
          The top peer-to-peer marketplaces are plagued by fraud, predatory
          fees, bloat, and corporate incentives that prioritize profits over
          user experience. We're building a new marketplace without these issues
          by focusing on our customers and their needs.
        </Paragraph>
        <Paragraph>Our plan is simple:</Paragraph>
        <List>
          <Paragraph>
            Minimize outside investment so we're not beholden to shareholders.
          </Paragraph>
          <Paragraph>
            Prioritize the customer over profits; organic expansion over growth
            at all costs.
          </Paragraph>
          <Paragraph>
            Use the latest technology to aggressively protect buyers and sellers
            from fraud.
          </Paragraph>
          <Paragraph>
            Listen to buyers and sellers and provide them with the features they
            need to succeed.
          </Paragraph>
        </List>
        <Paragraph>
          These are early days, and achieving our vision will take time.
          However, we are laser-focused on our path and will remain so. We hope
          you will join us as we build a new community for collecting
          enthusiasts.
        </Paragraph>
      </Section>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  padding: 20px;
  max-width: 800px;
  margin: auto;
  margin-bottom: 40px;
  gap: 10px;
`;
