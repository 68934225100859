import { useNavigate } from 'react-router-dom';

import { Breadcrumbs } from 'components/breadcrumbs';
import { Container, Divider, Header, Links } from 'components/helpShared';
import { BlueLinkComponent } from 'components/links';

const baseUrl = `/help/buyerResources`;

export const BuyerResources = () => {
  const navigate = useNavigate();
  const linkFontSize = '18px';

  return (
    <Container>
      <Breadcrumbs
        items={[
          { path: '/help', label: 'Help Home' },
          { path: '/help/buyerResources', label: 'Buyer Resources' },
        ]}
      />
      <Header>Buyer Resources</Header>
      <Divider />
      <Links>
        <BlueLinkComponent
          text='Buyer protections'
          fontSize={linkFontSize}
          onClick={() => navigate(`${baseUrl}/buyerProtections`)}
        />
        <BlueLinkComponent
          text='How to buy on Near Mint'
          fontSize={linkFontSize}
          onClick={() => navigate(`${baseUrl}/howToBuy`)}
        />
        <BlueLinkComponent
          text='Receiving your order'
          fontSize={linkFontSize}
          onClick={() => navigate(`${baseUrl}/receivingYourOrder`)}
        />
        <BlueLinkComponent
          text='Leaving Feedback'
          fontSize={linkFontSize}
          onClick={() => navigate(`${baseUrl}/leavingFeedback`)}
        />
        <BlueLinkComponent
          text='Returns and Refunds'
          fontSize={linkFontSize}
          onClick={() => navigate(`${baseUrl}/returnsAndRefunds`)}
        />
      </Links>
    </Container>
  );
};
