import { useNavigate } from 'react-router-dom';

import { Breadcrumbs } from 'components/breadcrumbs';
import {
  Container,
  Content,
  Divider,
  Header,
  Paragraph,
  Section,
  Title,
} from 'components/helpShared';
import { BlueLinkComponent } from 'components/links';
import {
  PRICE_TRACKING_REQUIRED,
  PRICE_SIGNATURE_REQUIRED,
  PRODUCT_NAME,
} from 'config';

export const BuyerProtections = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <Breadcrumbs
        items={[
          { path: '/help', label: 'Help Home' },
          { path: '/help/buyerResources', label: 'Buyer Resources' },
          {
            path: '/help/buyerResources/BuyerProtections',
            label: 'Buyer Protections',
          },
        ]}
      />
      <Header>Buyer Protections</Header>
      <Divider />
      <Content>
        <Section>
          <Title>Guarantee</Title>
          <Paragraph>
            As a buyer at {PRODUCT_NAME}, your purchase is always protected by
            the{' '}
            <BlueLinkComponent
              onClick={() => navigate('/guarantee')}
              text={`${PRODUCT_NAME} guarantee`}
              fontSize='16px'
            />{' '}
            . This means that if any item you purchase did not arrive, is
            damaged, or does not match the listing, you are entitled to a full
            refund upon return of the item to the seller.
          </Paragraph>
          <Title>Safeguards</Title>
          <Paragraph>
            In addition to this guarantee, we have several safeguards in place
            to promote safety in the marketplace. First among these is our{' '}
            <BlueLinkComponent
              onClick={() => navigate('/sellerResources/sellerTiers')}
              text='Seller Tier'
              fontSize='16px'
            />{' '}
            program, which places limits on the item types and totals that
            sellers can list based on their reputation, selling history, and
            identity verification status. This ensures that sellers have earned
            their ability to sell high-value items.
          </Paragraph>
          <Paragraph>
            We also enforce our{' '}
            <BlueLinkComponent
              onClick={() => navigate('/sellerResources/shippingGuidelines')}
              text='Shipping Guidelines'
              fontSize='16px'
            />{' '}
            to ensure safe and consistent delivery. This includes required
            tracking for item values exceeding ${PRICE_TRACKING_REQUIRED}, and
            signature requirements for items exceeding $
            {PRICE_SIGNATURE_REQUIRED}.{' '}
          </Paragraph>
          <Paragraph>
            In the event of a dispute, a {PRODUCT_NAME} team member manually
            reviews each case by gathering information from the buyer and the
            seller. We will always strive to be fair to all parties involved. If
            a party is caught engaging in fraudulent activity, they will be
            permanently banned from our platform and reported to the appropriate
            authorities, and the other party will be reimbursed fully if
            applicable.
          </Paragraph>
        </Section>
      </Content>
    </Container>
  );
};
