import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';

import { GreyLinkComponent } from 'components/links';
import { OrderStatus } from 'components/orderStatus';
import { formattedDate } from 'utils';

import { ListingRecord } from 'types';

interface ListingCardProps {
  listing: ListingRecord;
  status: string;
}

// TODO: improve how this looks for mobile
export const ListingCardManageOrder = ({
  listing,
  status,
}: ListingCardProps) => {
  const navigate = useNavigate();
  const purchaseDate = formattedDate(listing.createdAt);

  return (
    <ItemBox>
      <Row>
        <ImageContainer>
          <Image src={listing.signedS3Urls[0]} alt='Product Image' />
        </ImageContainer>
        <ItemInfoContainer>
          <Title>{listing.title}</Title>
          <OrderStatus
            status={status}
            isActive={listing.isActive}
            size={'large'}
          />
          <Purchased>Purchased on {purchaseDate}</Purchased>
          <GreyLinkComponent
            text={'View original listing'}
            onClick={() => navigate(`/listing/${listing.id}`)}
          />
        </ItemInfoContainer>
      </Row>
    </ItemBox>
  );
};

const Title = styled.h2`
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  margin: 0 0 10px 0;
  overflow-wrap: break-word;
`;

const Row = styled.div`
  display: flex;
  align-items: top;
  flex-direction: row;
`;

const ImageContainer = styled.div`
  width: 180px;
  height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Image = styled.img`
  max-width: 180px;
  max-height: 180px;
  object-fit: cover;
`;

const ItemInfoContainer = styled.div`
  flex-direction: column;
  display: flex;
  align-items: left;
  text-align: left;
  margin-left: 30px;
  gap: 10px;
`;

const Purchased = styled.div`
  text-align: left;
  font-size: 14px;
`;

const ItemBox = styled.div`
  flex: 1;
  align-self: flex-start;
  width: 100%;
  padding: 16px;
  height auto;
  border: 1px solid ${(props) => props.theme.colors.border.primary};
`;
