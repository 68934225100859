import React from 'react';
import styled from '@emotion/styled';

import { SelectButton } from 'components/buttons';

import { Left, Right, Row, ItemBox, Title } from './shared';
import { ListingData } from './listingData';
import { RatingComponentProps } from './types';

export const RatingForm: React.FC<RatingComponentProps> = ({
  rating,
  onChange,
  order,
}) => {
  const buttonStyle = {
    width: '180px',
    height: '32px',
    borderRadius: '20px',
    fontSize: '14px',
  };

  return (
    <ItemBox>
      <Row>
        <Left>
          <ListingData order={order}></ListingData>
        </Left>
        <Right>
          <Container>
            <Title>How was your experience?</Title>
            <ButtonGroup>
              <SelectButton
                isSelected={rating === 'positive'}
                onClick={(e) => {
                  e.preventDefault();
                  onChange('positive');
                }}
                {...buttonStyle}
              >
                Positive
              </SelectButton>
              <SelectButton
                isSelected={rating === 'neutral'}
                onClick={(e) => {
                  e.preventDefault();
                  onChange('neutral');
                }}
                {...buttonStyle}
              >
                Neutral
              </SelectButton>
              <SelectButton
                isSelected={rating === 'negative'}
                onClick={(e) => {
                  e.preventDefault();
                  onChange('negative');
                }}
                {...buttonStyle}
              >
                Negative
              </SelectButton>
            </ButtonGroup>
          </Container>
        </Right>
      </Row>
    </ItemBox>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
`;
