import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';

import LoadingSpinner from 'components/loading';
import { Button } from 'components/buttons';
import { Container, Form, Row, Header } from 'components/myAccount';
import { emailCodeValidation, emailValidation } from 'components/validation';
import { Input } from 'components/input';
import {
  useDoCodeVerifyAndUpdate,
  useDoSendEmailVerify,
} from 'hooks/verification';

import { useGetAccountInfo } from 'hooks/account';

export const EmailForm = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [codeErrorMsg, setCodeErrorMsg] = useState('');
  const { data, isLoading } = useGetAccountInfo();
  const { mutate: verifyEmail } = useDoSendEmailVerify(setIsSubmitted);
  const { mutate: verifyCodeAndUpdate } = useDoCodeVerifyAndUpdate(
    setCodeErrorMsg,
    setIsSubmitted
  );

  const methods = useForm<{ email: string; emailCode: string }>({
    defaultValues: {
      email: '',
      emailCode: '',
    },
  });

  // Reset form values when data changes, only if email is not dirty
  useEffect(() => {
    if (data?.accountInfo && !methods.formState.dirtyFields.email) {
      methods.reset({
        email: data.accountInfo.email || '',
        emailCode: '',
      });
    }
  }, [data, methods]);

  const handleVerifyEmail: SubmitHandler<{
    email: string;
    emailCode: string;
  }> = (formData) => {
    verifyEmail({ email: formData.email });
  };

  const handleVerifyEmailCode: SubmitHandler<{
    email: string;
    emailCode: string;
  }> = (formData) => {
    const accountData = {
      user_id: data?.accountInfo.user_id || '',
      email: formData.email,
      phone: data?.accountInfo.phone || '',
      username: data?.accountInfo.username || '',
      first_name: data?.accountInfo.first_name || '',
      middle_name: data?.accountInfo.middle_name || '',
      last_name: data?.accountInfo.last_name || '',
      address_1: data?.accountInfo.address_1 || '',
      address_2: data?.accountInfo.address_2 || '',
      city: data?.accountInfo.city || '',
      state: data?.accountInfo.state || '',
      zip: data?.accountInfo.zip || '',
      createdAt: data?.accountInfo.createdAt || '',
    };
    const codeData = {
      code: formData.emailCode,
      type: 'email',
      value: formData.email,
    };
    verifyCodeAndUpdate(
      { codeData, accountData },
      {
        onSuccess: () => {
          methods.reset({
            email: formData.email,
            emailCode: '',
          });
          setIsSubmitted(false);
        },
      }
    );
  };

  if (isLoading) return <LoadingSpinner />;

  return (
    <>
      <StyledContainer>
        <Header>Email</Header>
        <Content>
          <FormProvider {...methods}>
            <Form
              onSubmit={methods.handleSubmit(
                isSubmitted ? handleVerifyEmailCode : handleVerifyEmail
              )}
              autoComplete='off'
            >
              {isSubmitted ? (
                <>
                  <Row>
                    <InputWrapper>
                      <Input {...emailCodeValidation} width={'360px'} />
                    </InputWrapper>
                  </Row>
                  <VerificationMessage>
                    We've emailed you a security code. Please enter it here.
                  </VerificationMessage>
                  <Button width={'240px'} type='submit'>
                    Verify
                  </Button>
                  {codeErrorMsg && (
                    <ValidationMessage>{codeErrorMsg}</ValidationMessage>
                  )}
                </>
              ) : (
                <>
                  <Row>
                    <InputWrapper>
                      <Input {...emailValidation} width={'360px'} />
                    </InputWrapper>
                  </Row>
                  {methods.formState.isDirty && (
                    <VerificationMessage>
                      We will email you a security code to verify this address.
                    </VerificationMessage>
                  )}
                  <Button
                    width={'240px'}
                    type='submit'
                    disabled={!methods.formState.isDirty}
                  >
                    Verify Email
                  </Button>
                </>
              )}
            </Form>
          </FormProvider>
        </Content>
      </StyledContainer>
    </>
  );
};

const StyledContainer = styled(Container)`
  margin-bottom: 0;
`;

const VerificationMessage = styled.div`
  width: 400px;
  color: grey;
  margin: 10px 0;
  font-size: 12px;
`;

const ValidationMessage = styled.div`
  color: red;
  margin: 10px 0;
  font-size: 12pxem;
`;

const Content = styled.div`
  padding: 30px;
  border: 1px solid ${(props) => props.theme.colors.border.primary};
`;

const InputWrapper = styled.div`
  flex: 1;
  &:not(:last-child) {
    margin-right: 20px;
  }
`;
