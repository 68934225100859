import styled from '@emotion/styled';

import { RETURN_POLICY_MAP } from 'config';
import { ListingRecord } from 'types';

interface ListingCardCheckoutInt {
  listing: ListingRecord;
}

// TODO: improve how this looks for mobile
export const ListingCardCheckout = ({ listing }: ListingCardCheckoutInt) => {
  return (
    <ItemBox>
      <Title>Review Item</Title>
      <Row>
        <ImageContainer>
          <Image src={listing.signedS3Urls[0]} alt='Product Image' />
        </ImageContainer>
        <ItemInfoContainer>
          <ItemTitle>{listing.title}</ItemTitle>
          <ReturnText>{RETURN_POLICY_MAP[listing.returns]}</ReturnText>
        </ItemInfoContainer>
      </Row>
    </ItemBox>
  );
};

const ReturnText = styled.div`
  font-size: 16px;
  color: #777;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  margin: 0 0 20px 0;
  overflow-wrap: break-word;
`;

const Row = styled.div`
  display: flex;
  align-items: top;
  flex-direction: row;
`;

const ImageContainer = styled.div`
  width: 100px;
  height: 100px;
  background-color: #eee;
`;

const Image = styled.img`
  max-width: 100px;
  max-height: 100px;
  object-fit: cover;
`;

const ItemInfoContainer = styled.div`
  flex-direction: column;
  display: flex;
  align-items: left;
  text-align: left;
  margin-left: 30px;
  gap: 20px;
  max-width: 300px;
`;

const ItemTitle = styled.div`
  text-align: left;
  overflow-wrap: break-word;
  white-space: normal;
  overflow: hidden;
`;

const ItemBox = styled.div`
  flex: 1;
  align-self: flex-start;
  max-width: 600px;
  padding: 24px;
  height auto;
  border: 1px solid ${(props) => props.theme.colors.border.primary};
`;
