import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';

import { GreyLink } from 'components/links';

import { CurrentOwnerProps } from './types';

// TODO: Add graphic here.

export const ListingOwnerIsCurrentUser: React.FC<CurrentOwnerProps> = (
  props
) => {
  const navigate = useNavigate();

  return (
    <Container>
      <Text>Sadly, you can't purchase your own listing.</Text>
      <GreyLink onClick={() => navigate(`/listing/${props.listingId}`)}>
        Back to listing
      </GreyLink>
    </Container>
  );
};

const Text = styled.h2``;

const Container = styled.div`
  margin-top: 120px;
  padding: 0 10px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  & > *:not(:last-child) {
    margin-bottom: 30px;
  }
`;
