import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { isEmpty } from 'lodash';

import LoadingSpinner from 'components/loading';
import { FilterDropdown } from 'components/input';
import { ListingCardOrder } from 'components/listingCard';
import { Container, Header, HeaderContainer } from 'components/myAccount';
import { useGetOrders } from 'hooks/orders';
import { OrderRecord, OrderStatuses } from 'types';

const sortOptions: Record<string, (a: OrderRecord, b: OrderRecord) => number> =
  {
    'Purchase date: newest first': (a, b) =>
      new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
    'Purchase date: oldest first': (a, b) =>
      new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
  };

const filterOptions: Record<string, (order: OrderRecord) => boolean> = {
  All: () => true,
  'Feedback left': (order) => !!order.feedback_id,
  'Feedback not left': (order) => !order.feedback_id,
  'Awaiting Shipment': (order) => order.status === OrderStatuses.OrderCaptured,
  Shipped: (order) => order.status === OrderStatuses.Shipped,
};

export const MyOrders = () => {
  const { data, isLoading } = useGetOrders();
  const [sortedOrders, setSortedOrders] = useState<OrderRecord[]>([]);

  const [searchParams, setSearchParams] = useSearchParams();

  const sortOption = searchParams.get('sort') || 'Purchase date: newest first';
  const filterOption = searchParams.get('filter') || 'All';

  useEffect(() => {
    if (data?.orders) {
      const filteredOrders = data.orders.filter(filterOptions[filterOption]);
      setSortedOrders(filteredOrders.sort(sortOptions[sortOption]));
    }
  }, [data?.orders, sortOption, filterOption]);

  const handleSortOptionSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    searchParams.set('sort', e.target.value);
    setSearchParams(searchParams);
  };

  const handleFilterOptionSelect = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    searchParams.set('filter', e.target.value);
    setSearchParams(searchParams);
  };

  if (isLoading) return <LoadingSpinner />;

  return (
    <StyledContainer>
      <HeaderContainer>
        <Header>My Purchases</Header>
        <DropdownContainers>
          <DropdownContainer>
            <FilterText>Status:</FilterText>
            <FilterDropdown
              id='filterDropdown'
              value={filterOption}
              onChange={handleFilterOptionSelect}
            >
              {Object.keys(filterOptions).map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </FilterDropdown>
          </DropdownContainer>
          <DropdownContainer>
            <SortByText>Sort by:</SortByText>
            <FilterDropdown
              id='sortDropdown'
              value={sortOption}
              onChange={handleSortOptionSelect}
            >
              {Object.keys(sortOptions).map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </FilterDropdown>
          </DropdownContainer>
        </DropdownContainers>
      </HeaderContainer>
      <ActivityList>
        {!isEmpty(sortedOrders) ? (
          sortedOrders.map((order: OrderRecord) => (
            <ListingCardOrder key={order.id} order={order} />
          ))
        ) : filterOption === 'All' ? (
          <h4>You have no purchases</h4>
        ) : (
          <h4>No purchases match these filters</h4>
        )}
      </ActivityList>
    </StyledContainer>
  );
};

const StyledContainer = styled(Container)`
  max-width: 1000px;
`;

const FilterText = styled.div`
  font-size: 12px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  margin-right: 10px;
`;

const DropdownContainers = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const DropdownContainer = styled.div`
  display: flex;
  align-items: center;
`;

const SortByText = styled.div`
  font-size: 12px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  margin-right: 10px;
`;

const ActivityList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
`;
