import { useNavigate } from 'react-router-dom';

import { Breadcrumbs } from 'components/breadcrumbs';
import { Container, Divider, Header, Links } from 'components/helpShared';
import { BlueLinkComponent } from 'components/links';

const baseUrl = `/help/sellerResources`;

export const SellerResources = () => {
  const navigate = useNavigate();
  const linkFontSize = '18px';

  return (
    <Container>
      <Breadcrumbs
        items={[
          { path: '/help', label: 'Help Home' },
          { path: '/help/sellerResources', label: 'Seller Resources' },
        ]}
      />
      <Header>Seller Resources</Header>
      <Divider />
      <Links>
        <BlueLinkComponent
          text='How to Sell'
          fontSize={linkFontSize}
          onClick={() => navigate(`${baseUrl}/howToSell`)}
        />
        <BlueLinkComponent
          text='Seller Tiers'
          fontSize={linkFontSize}
          onClick={() => navigate(`${baseUrl}/sellerTiers`)}
        />
        <BlueLinkComponent
          text='Seller Protections'
          fontSize={linkFontSize}
          onClick={() => navigate(`${baseUrl}/sellerProtections`)}
        />
        <BlueLinkComponent
          text='Tax forms'
          fontSize={linkFontSize}
          onClick={() => navigate(`${baseUrl}/taxForms`)}
        />
        <BlueLinkComponent
          text='Seller fees'
          fontSize={linkFontSize}
          onClick={() => navigate(`${baseUrl}/sellerFees`)}
        />
        <BlueLinkComponent
          text='Shipping Policy'
          fontSize={linkFontSize}
          onClick={() => navigate(`${baseUrl}/shippingPolicy`)}
        />
        <BlueLinkComponent
          text='Payouts'
          fontSize={linkFontSize}
          onClick={() => navigate(`${baseUrl}/payouts`)}
        />
      </Links>
    </Container>
  );
};
