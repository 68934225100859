import React from 'react';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';

import { LogoMainPage } from 'assets/images';
import { BlueLinkComponent } from 'components/links';
import { PRODUCT_NAME } from 'config';
import { LogoProps } from 'types';

import { RecentListings } from 'components/recentListings';

const topNRecent = 10;

const Home: React.FC = () => {
  const logoHeight = '160px';
  const navigate = useNavigate();
  return (
    <>
      <MainPageContainer>
        <Logo height={logoHeight} />
        <Subwelcome>
          Welcome to {PRODUCT_NAME}. We're a peer-to-peer marketplace for
          collectors and traders.
        </Subwelcome>
        <ComponentDiv>
          <BlueLinkComponent
            text={'Come see what sets us apart.'}
            onClick={() => navigate('/about')}
            fontSize={'14px'}
          />
        </ComponentDiv>
        <RecentListings
          title={'Newest Listings'}
          category={null}
          topN={topNRecent}
        />
        <RecentListings
          title={'Video Games'}
          category={'Video Games'}
          topN={topNRecent}
        />
      </MainPageContainer>
    </>
  );
};

const Subwelcome = styled.h3`
  margin-top: 10px;
  margin-bottom: 10px;
`;

const MainPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
  margin-bottom: 60px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 20px;
  max-width: 900px;
  text-align: center;
`;

const Logo = styled.div<LogoProps>`
  background-image: url(${LogoMainPage});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: ${({ height }) => height};
  max-width: calc(100vw - 40px);
  aspect-ratio: 3 / 1; /* Maintain the aspect ratio */
`;

const ComponentDiv = styled.div`
  margin: 20px 0;
`;

export default Home;
