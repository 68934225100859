import { useState, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import {
  FeedbackFormValues,
  useCheckFeedback,
  useSubmitFeedback,
} from 'hooks/feedback';
import LoadingSpinner from 'components/loading';
import { GreyLinkComponent } from 'components/links';
import { Button } from 'components/buttons';

import { RatingForm } from './ratingForm';
import { TextFeedbackForm } from './textForm';

export const FeedbackForBuyerForm = () => {
  const [rating, setRating] = useState<string | undefined>(undefined);
  const [textFeedback, setTextFeedback] = useState<string | undefined>(
    undefined
  );
  const { listingId } = useParams<{ listingId: string }>();
  const userId = localStorage.getItem('userId') || '';
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FeedbackFormValues>();
  const { mutate: submitFeedback } = useSubmitFeedback();

  // Get data
  const { data, isLoading, isError } = useCheckFeedback({
    userId,
    listingId: listingId || '',
  });

  // Auto-scroll to validation errors
  const ratingRef = useRef<HTMLDivElement>(null);
  const feedbackTextRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (errors.rating) {
      ratingRef.current?.scrollIntoView({ behavior: 'smooth' });
    } else if (errors.feedbackText) {
      feedbackTextRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [errors]);

  // Methods for children forms
  const handleRatingChange = (ratingInput: string) => {
    setRating(ratingInput);
  };
  const handleTextFeedbackChange = (textInput: string) => {
    setTextFeedback(textInput);
  };
  // Submit data
  const onSubmit = (feedbackData: FeedbackFormValues) => {
    if (data) {
      submitFeedback({
        feedbackData,
        feedbackType: 'forBuyer',
        orderData: data.order,
      });
    }
  };

  if (isLoading) return <LoadingSpinner />;

  // Early exit
  if (!data || isError) {
    return (
      <Container>
        <PageTitle>
          You do not have permission to leave feedback for this item.
        </PageTitle>
        <GreyLinkComponent
          text={'← Back to My Account'}
          onClick={() => navigate(`/myAccount/AccountHome`)}
        />
      </Container>
    );
  }

  return (
    <Container>
      {data?.exists ? (
        <DefaultContainer>
          <PageTitleDefault>
            Feedback has already been left for this listing.
          </PageTitleDefault>
          <GreyLinkComponent
            text={'← Back to My Account'}
            onClick={() => navigate(`/myAccount/AccountHome`)}
          />
        </DefaultContainer>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <PageTitle>Leave Feedback</PageTitle>
          <Content>
            <div ref={ratingRef}>
              <Controller
                name='rating'
                control={control}
                defaultValue=''
                rules={{ required: 'Rating is required' }}
                render={({ field }) => (
                  <RatingForm
                    rating={rating}
                    order={data.order}
                    onChange={(value) => {
                      field.onChange(value);
                      handleRatingChange(value);
                    }}
                  />
                )}
              />
              {errors.rating && (
                <ErrorMessage>{errors.rating.message}</ErrorMessage>
              )}
            </div>
            <div ref={feedbackTextRef}>
              <Controller
                name='feedbackText'
                control={control}
                defaultValue=''
                rules={{
                  required: 'Feedback is required',
                  maxLength: {
                    value: 1000,
                    message: 'Feedback cannot exceed 1000 characters',
                  },
                }}
                render={({ field }) => (
                  <TextFeedbackForm
                    textFeedback={textFeedback}
                    onChange={(value) => {
                      field.onChange(value);
                      handleTextFeedbackChange(value);
                    }}
                    forBuyer={true}
                  />
                )}
              />
              {errors.feedbackText && (
                <ErrorMessage>{errors.feedbackText.message}</ErrorMessage>
              )}
            </div>
            <ButtonWrapper>
              <Button type='submit' width='220px'>
                Submit
              </Button>
            </ButtonWrapper>
          </Content>
        </form>
      )}
    </Container>
  );
};

const ButtonWrapper = styled.div`
  padding: 0 22px 22px 22px;
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
`;

export const StarTitle = styled.p`
  font-size: 18px;
  font-weight: 600;
  margin: 0 0 -20px 22px;
  text-align: left;
`;

const DefaultContainer = styled.div`
  margin-top: 120px;
  width: 600px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  & > *:not(:last-child) {
    margin-bottom: 10px;
  }
`;

const PageTitle = styled.h2`
  text-align: left;
  margin: 0 0 0 0;
  padding: 16px;
`;

const PageTitleDefault = styled.h2`
  text-align: center;
  margin: 0 0 0 0;
  padding: 16px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  border: 1px solid ${(props) => props.theme.colors.border.primary};
`;

const ErrorMessage = styled.span`
  color: red;
  font-size: 14px;
  font-weight: 600;
  margin: -16px 0px 16px 22px;
  text-align: center;
`;
