import styled from '@emotion/styled';

import {
  HeaderContainer,
  HeaderSubtitle,
  HeaderTitle,
  Section,
} from './shared';
import { Input } from 'components/input';
import { priceMask, shippingCostValidation } from 'components/validation';

export const ShippingComponent = () => {
  return (
    <Section>
      <HeaderContainer>
        <HeaderTitle>Shipping</HeaderTitle>
        <HeaderSubtitle>
          Items with a lower shipping costs sell faster.
        </HeaderSubtitle>
      </HeaderContainer>
      <ShippingContainer>
        <ShippingCostContainer>
          <Input
            {...shippingCostValidation}
            inputMask={priceMask}
            width={'100%'}
          />
        </ShippingCostContainer>
      </ShippingContainer>
    </Section>
  );
};

const ShippingContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
`;

const ShippingCostContainer = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
`;
