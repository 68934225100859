import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from '@emotion/styled';

import { useGetListing } from 'hooks/listing';
import { GreyLinkComponent } from 'components/links';
import LoadingSpinner from 'components/loading';
import { ListingNonexistent } from 'components/listingView';

// TODO: merge this with successfulListingCreated.tsx

export const ListingUpdateSuccessPage: React.FC = () => {
  const navigate = useNavigate();
  const { listingId } = useParams();
  const { isLoading, isError } = useGetListing(listingId || '');

  if (isError) {
    return <ListingNonexistent />;
  }

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <CheckoutContainer>
      <Title>Your listing has been updated.</Title>
      <GreyLinkComponent
        fontSize={'16px'}
        text={'View this listing'}
        onClick={() => navigate(`/listing/${listingId}`)}
      />
      <GreyLinkComponent
        fontSize={'16px'}
        text={'Manage my listings.'}
        onClick={() => navigate(`/myAccount/listings`)}
      />
    </CheckoutContainer>
  );
};

const CheckoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 60px auto;
  gap: 20px;
  max-width: 1000px;
  text-align: center;
`;

const Title = styled.h1`
  width: 520px;
  text-align: center;
  margin: 20px 0;
`;
