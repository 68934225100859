import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';

import { BlueLinkComponent } from 'components/links';
import {
  Divider,
  Header,
  List,
  ListItem,
  Paragraph,
  Section,
  Updated,
} from 'components/helpShared';
import { ROADMAP_UPDATE_DATE } from 'config';

export const Roadmap = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <Header>Roadmap</Header>
      <Updated>Updated {ROADMAP_UPDATE_DATE}.</Updated>
      <Divider />
      <Section>
        <Paragraph>
          Our vision is to create a best-in-class marketplace for collectors.
          That means that our roadmap is driven by the wants and needs of our
          users.{' '}
          <BlueLinkComponent
            onClick={() => navigate('/contact')}
            text='Send us'
            fontSize='16px'
          />{' '}
          your feedback and feature requests - we're always listening.
        </Paragraph>
        <Paragraph>
          The largest upcoming efforts in priority order are:
          <List>
            <ListItem>Item authentication services</ListItem>
            <ListItem>Seller dashboard for managing listings</ListItem>
            <ListItem>Automated listing tools</ListItem>
            <ListItem>Favorites</ListItem>
            <ListItem>Email notification preferences</ListItem>
            <ListItem>Wish lists & item notifications</ListItem>
            <ListItem>Expanding outside of the U.S.</ListItem>
          </List>
        </Paragraph>
      </Section>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  padding: 20px;
  max-width: 800px;
  margin: auto;
  margin-bottom: 40px;
`;
