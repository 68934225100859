import { useNavigate } from 'react-router-dom';

import { Breadcrumbs } from 'components/breadcrumbs';
import {
  Container,
  Content,
  Divider,
  Header,
  Paragraph,
  Section,
  Title,
} from 'components/helpShared';
import { BlueLinkComponent } from 'components/links';
import { PRODUCT_NAME } from 'config';

export const SellerProtections = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <Breadcrumbs
        items={[
          { path: '/help', label: 'Help Home' },
          { path: '/help/sellerResources', label: 'Seller Resources' },
          {
            path: '/help/sellerResources/sellerProtections',
            label: 'Seller Protections',
          },
        ]}
      />
      <Header>Seller Protections</Header>
      <Divider />
      <Content>
        <Section>
          <Title>Buyer Request After the Sale</Title>
          <Paragraph>
            If a buyer requests any changes to the transaction or item(s) in
            your listing after they have paid, it's up to you if you would like
            to accommodate the request. If you do not want to abide by the
            request, you may cancel the order without penalty.
          </Paragraph>
          <Title>Abusive Buyers</Title>
          <Paragraph>
            We have a zero tolerance policy towards abuse and fraud. If{' '}
            {PRODUCT_NAME} finds a buyer to be abusive or engaging in fraudulent
            behavior, we will take immediate disciplinary action on the buyer up
            to and including suspension and permanent bans.
          </Paragraph>
          <Title>Used or Damaged Returns</Title>
          <Paragraph>
            If a buyer initiates a return, but you find that the item(s) have
            been used or damaged, you can deduct up to 50% of the refund to
            recover the lost value of the item(s). {PRODUCT_NAME} may require
            proof of reduced value in some cases.
          </Paragraph>
          <Title>Feedback</Title>
          <Paragraph>
            When something happens outside of your control, such as delays due
            to weather or carrier issues, we will ensure you do not receive
            neutral or negative feedback. If an issue pops up that you are
            responsible for, but it becomes apparent that you have done
            everything in your power to right the issue, we will also provide
            feedback protection.
          </Paragraph>
          <Title>Recommendations</Title>
          <Paragraph>
            While we commit much effort to minimizing buyer-side fraud, we do
            recommend recording aspects of your shipping process in the unlikely
            event that you need to prove that you shipped the item(s) timely,
            correctly, and according to your listing. This is particularly
            applicable for high-value items. For peace of mind, you may want to
            take high-quality pictures and videos of the items(s) before
            packaging, as well as a picture or video of you packaging the
            item(s). In particular, highlight imperfections or other anomalies
            that make your item easy to identify. Keep this evidence up to 180
            days after the shipment arrival date.
          </Paragraph>
          <Paragraph>
            Always abide by our{' '}
            <BlueLinkComponent
              onClick={() => navigate('/help/sellerResources/shippingPolicy')}
              text='Shipping Policy'
              fontSize='16px'
            />{' '}
            . It is recommended to require signature upon delivery even if your
            item does not meet the price threshold requirement for this.
            Shipments with tracking and signature requirements are much easier
            to defend against fraudulent chargebacks.
          </Paragraph>
          <Paragraph>
            Finally, for high value items, be wary of buyers with new accounts,
            or those with a low feedback total or percent of positive reviews.
            If such a buyer purchases your listing, we recommend waiting at
            least 24 hours before shipping your item. Stripe has automated fraud
            detection software that can flag likely fraudulent charges. If
            you're ever unsure or suspicious of a buyer, please{' '}
            <BlueLinkComponent
              onClick={() => navigate('/contact')}
              text='contact us'
              fontSize='16px'
            />{' '}
            and we will assist you with next steps, up to and including
            cancellation of the order with no penalty for yourself.
          </Paragraph>
          <Title>Customer Service</Title>
          <Paragraph>
            Our doors are always open to listen to you. Please{' '}
            <BlueLinkComponent
              onClick={() => navigate('/contact')}
              text='contact us'
              fontSize='16px'
            />{' '}
            at any time and we will do our best to support you. Plus, we
            generally enjoy hearing from our sellers.
          </Paragraph>
        </Section>
      </Content>
    </Container>
  );
};
