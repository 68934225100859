import React from 'react';
import styled from '@emotion/styled';
import { Description, Left, Right, Row, ItemBox, Title } from './shared';
import { TextFeedbackFormProps } from './types';

export const TextFeedbackForm: React.FC<TextFeedbackFormProps> = ({
  textFeedback,
  onChange,
  forBuyer,
}) => {
  return (
    <ItemBox>
      <Row>
        <Left>
          <Column>
            <Title>Tell us about your experience.</Title>
            <Description>
              Was the item as expected? How were interactions with the{' '}
              {forBuyer ? 'buyer' : 'seller'}?
            </Description>
          </Column>
        </Left>
        <Right>
          <DescriptionInput
            value={textFeedback}
            onChange={(e) => onChange(e.target.value)}
            maxLength={1000}
            placeholder='Share your feedback here.'
          />
        </Right>
      </Row>
    </ItemBox>
  );
};

const Column = styled.div`
  align-items: left;
  flex-direction: column;
`;

const DescriptionInput = styled.textarea`
  height: 10em;
  width: 80%;
  overflow-y: auto;
  line-height: 1.5;
  border: 1px solid #ccc;
  border-radius: 10px;
  background: #f7f7f7;
  resize: none;
  &:focus {
    background: #fff;
    outline: none;
    border: 1px solid ${(props) => props.theme.colors.primary};
  }
`;
