import styled from '@emotion/styled';

import { RowListingCard } from './rowListingCard';
import { ListingRecord } from 'types';

export const RowListings = ({ listings }: { listings: ListingRecord[] }) => {
  return (
    <RowContainer>
      {listings.map((listing) => (
        <RowListingCard key={listing.id} listing={listing} />
      ))}
    </RowContainer>
  );
};

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  overflow-x: auto;
  scroll-behavior: smooth;
  margin: 0 auto;
  width: 100%;

  /* Prevent shrinking */
  & > div {
    flex-shrink: 0;
  }

  /* Hide scrollbar initially */
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For IE and Edge */

  &::-webkit-scrollbar {
    display: none; /* For Webkit browsers */
  }

  /* Show scrollbar on hover or while scrolling */
  &:hover {
    scrollbar-width: thin; /* For Firefox */
  }

  &:hover::-webkit-scrollbar {
    display: block; /* For Webkit browsers */
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;
