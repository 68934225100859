import { useQuery } from '@tanstack/react-query';

import useAPI from 'api';

interface AccountLink {
  url: string;
}

interface GetStripeAccount {
  isActive: boolean;
  accountLink: AccountLink;
  clientSecret: string;
}

export const useGetStripeAccount = () => {
  const API = useAPI();
  const getStripeAccount = async () => {
    const url = `getStripeAccount`;
    return await API<GetStripeAccount>(url.toString(), {
      method: 'GET',
    });
  };
  return useQuery({
    queryKey: ['getStripeAccount'],
    queryFn: getStripeAccount,
    enabled: true,
  });
};
