import styled from '@emotion/styled';
import {
  toast,
  ToastOptions,
  ToastContainer,
  ToastContent,
} from 'react-toastify';
import { Check, WarningCircle, X } from '@phosphor-icons/react';
import 'react-toastify/dist/ReactToastify.css';

const DEFAULT_OPTIONS: Partial<ToastOptions> = {
  autoClose: 3000,
  theme: 'colored' as 'colored',
};

interface ToastAPI {
  success: (content: ToastContent | string, options?: ToastOptions) => void;
  error: (content: ToastContent | string, options?: ToastOptions) => void;
}

export const Toast: ToastAPI = Object.freeze({
  success: (content: ToastContent | string, options?: ToastOptions) => {
    toast.success(content, {
      icon: <Check size={22}></Check>,
      ...DEFAULT_OPTIONS,
      ...options,
    });
  },
  error: (content: ToastContent | string, options?: ToastOptions) => {
    toast.error(content, {
      icon: <WarningCircle size={22}></WarningCircle>,
      ...DEFAULT_OPTIONS,
      ...options,
    });
  },
});

const ToastCloseIcon = ({ closeToast }: { closeToast: () => void }) => {
  return (
    <ToastCloseContainer onClick={closeToast}>
      <X size={22}></X>
    </ToastCloseContainer>
  );
};

export const ToastWrapper = (props: any) => (
  <StyledToastContainer
    closeButton={ToastCloseIcon}
    theme={'colored'}
    hideProgressBar={true}
    {...props}
  />
);

const StyledToastContainer = styled(ToastContainer)`
  --toastify-toast-width: 340px;
  --toastify-toast-min-height: 36px;
  --toastify-color-success: #2c2761;
  --toastify-color-error: #a15754;
  --toastify-font-family: 'GDSherpa', Helvetica, Arial, sans-serif;

  & .Toastify__toast-container {
    font-size: 13;
    font-weight: 500;
    line-height: 150%;
  }

  & .Toastify__toast {
    box-shadow: 0px 4px 20px rgba(11, 72, 61, 0.1);
    border-radius: 4px;
    padding: 11px;
  }

  & .Toastify__toast-body {
    font-size: 14;
    font-weight: 400;
    line-height: 150%;
    align-items: flex-start;
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    margin: 0;
    max-width: 278px;
    padding: 0 4px 0 0;
  }

  & .Toastify__toast-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 18px;
    width: 18px;
  }
`;

const ToastCloseContainer = styled.div`
  width: 18px;
  margin-left: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
