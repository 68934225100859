import { useNavigate } from 'react-router-dom';

import { Breadcrumbs } from 'components/breadcrumbs';
import {
  Container,
  Content,
  Divider,
  Header,
  Paragraph,
  Section,
  Title,
} from 'components/helpShared';
import { BlueLinkComponent } from 'components/links';

export const LeavingSellerFeedback = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <Breadcrumbs
        items={[
          { path: '/help', label: 'Help Home' },
          { path: '/help/buyerResources', label: 'Buyer Resources' },
          {
            path: '/help/buyerResources/leavingFeedback',
            label: 'Leaving Feedback',
          },
        ]}
      />
      <Header>Leaving Feedback</Header>
      <Divider />
      <Content>
        <Section>
          <Paragraph>
            Feedback is an important part of a peer-to-peer marketplace as it
            generates the reputation of buyers and sellers on the platform. We
            encourage all users to leave feedback after an order has been
            settled.
          </Paragraph>
          <Title>When to Leave Feedback</Title>
          <Paragraph>
            The best time to leave feedback is after receiving and verifying
            your purchased item. If there happen to be delays or other
            unforeseen issues with your order, it's best to wait until the order
            has been fully settled before leaving feedback.
          </Paragraph>
          <Title>How Leave Feedback</Title>
          <Paragraph>
            {' '}
            After an order is settled, you can leave feedback by navigating to{' '}
            <BlueLinkComponent
              onClick={() => navigate('/myAccount/Purchases')}
              text='My Purchases'
              fontSize='16px'
            />{' '}
            , finding the order, and clicking the Leave Feedback button. we
            encourage you to leave feedback for your order. You can leave an
            overall rating, a text review, and others ratings on different
            aspects, such as item accuracy and shipping speed.
          </Paragraph>
          <Title>Feedback Guidelines</Title>
          <Paragraph>
            We encourage you to be honest in your review, but also to rate the
            seller based on their effort.
          </Paragraph>
          <Paragraph>
            A positive rating is appropriate if the deal went through as
            expected, or if the seller made an effort to fix any issues that
            arose. In general, you should only leave a negative rating if the
            seller did not attempt to address any problems.
          </Paragraph>
          <Paragraph>
            How the order ended is oftentimes more important than how it
            started. For example, if there was a shipping snafu that was beyond
            the seller's control, but they then did everything they could to
            make things right, that should be reflected in a positive rating.
            It's important to remember that accidents happen, often through no
            fault of our own.
          </Paragraph>
          <Paragraph>
            Feedback reviews are also subject to our{' '}
            <BlueLinkComponent
              onClick={() => navigate('/help/communityGuidelines')}
              text='Community Guidelines'
              fontSize='16px'
            />{' '}
            . Please be professional and respectful in your reviews.
          </Paragraph>
        </Section>
      </Content>
    </Container>
  );
};
