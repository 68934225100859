import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';

import { formatNumber, formatShippingCost } from 'utils';

import { ListingRecord } from './types';

export const GridListingCard = ({ listing }: { listing: ListingRecord }) => {
  const navigate = useNavigate();

  const handleNavigateToListing = (id: string) => {
    navigate(`/listing/${id}`);
  };

  return (
    <ListingItem
      key={listing.id}
      onClick={() => handleNavigateToListing(listing.id)}
    >
      <SquareContainer>
        <Image src={listing.signedS3Url} alt={listing.title} />
      </SquareContainer>
      <TextArea>
        <Title>{listing.title}</Title>
        <Cost>${formatNumber(listing.price)}</Cost>
        <ShippingCost>
          Shipping: {formatShippingCost(listing.shipping_cost)}
        </ShippingCost>
      </TextArea>
    </ListingItem>
  );
};

const ListingItem = styled.div`
  width: 100%;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const SquareContainer = styled.div`
  width: 100%;
  padding-top: 100%; /* 1:1 Aspect Ratio */
  background-color: #eeeeee;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
`;

const Image = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: contain;
  transform: translate(-50%, -50%);
`;

const TextArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 10px;
`;

const Title = styled.div`
  font-size: 1rem;
  margin-bottom: 5px;
  overflow-wrap: break-word;
`;

const Cost = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
`;

const ShippingCost = styled.div`
  font-size: 0.8rem;
`;
