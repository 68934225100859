import { useNavigate } from 'react-router-dom';

import { Breadcrumbs } from 'components/breadcrumbs';
import {
  Container,
  Content,
  Divider,
  Header,
  Paragraph,
  Section,
  Title,
} from 'components/helpShared';
import { BlueLinkComponent } from 'components/links';
import {
  PRICE_TRACKING_REQUIRED,
  PRICE_SIGNATURE_REQUIRED,
  PRODUCT_NAME,
} from 'config';

export const ReturnsAndRefundsPolicy = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <Breadcrumbs
        items={[
          { path: '/help', label: 'Help Home' },
          {
            path: '/help/returnsAndRefundsPolicy',
            label: 'Return & Refunds Policy',
          },
        ]}
      />
      <Header>Returns and Refunds</Header>
      <Divider />
      <Content>
        <Section>
          <Title>Buyer Returns</Title>
          <Paragraph>
            If you as a buyer have changed your mind about a purchase, the
            ability to return the item depends on the seller's return policy,
            which is specified in the listing and visible in the order details
            of your listing. Some sellers may accept returns within a certain
            timeframe, while others may not accept returns at all.
          </Paragraph>
          <Paragraph>
            Per the {PRODUCT_NAME} guarantee, for items that don't match the
            listing description, arrive damaged, or are faulty, you can request
            a return even if the seller's policy states they don't accept
            returns. In such cases, you must initiate the return within 30
            calendar days of the estimated or actual delivery date.
          </Paragraph>
          <Paragraph>
            For detailed information on returns as a buyer, please visit{' '}
            <BlueLinkComponent
              onClick={() => navigate('/help/buyerResources/returnsAndRefunds')}
              text='this article'
              fontSize='16px'
            />{' '}
            .
          </Paragraph>
          <Title>Buyer Refunds</Title>
          <Paragraph>
            When an item is returned and confirmed to be correct, a refund -
            minus any applicable shipping costs - will be issued to your bank
            account. Note that if you return items used or damaged, the seller
            may deduct up to 50% of the refund to recover any lost value.
          </Paragraph>
          <Title>Sellers</Title>
          <Paragraph>
            When creating a listing, you may specify that you allow returns for
            any reason up to 14 days or 30 days, or that you do not allow
            returns at all. Generally, listings with a return policy are more
            likely to result in a successful sale. In addition, per the{' '}
            {PRODUCT_NAME} guarantee, you must accept returns for items that
            arrive damaged or are not as described in your listing.
          </Paragraph>
          <Paragraph>
            You are also responsible for items that can not be proven to have
            arrived at the buyer's shipping address. It is for this reason that
            we strongly recommend you ship all orders with tracking. Note that
            we require tracking for orders with values exceeding $
            {PRICE_TRACKING_REQUIRED} and a signature for items exceeding $
            {PRICE_SIGNATURE_REQUIRED}.
          </Paragraph>
          <Title>Seller Refunds</Title>
          <Paragraph>
            When you have received a return and verified the item(s), please
            update the order status of the item to "Return Accepted". If you
            have already been payed out for this, your bank account will be
            charged the appropriate amount and the funds will be returned to the
            buyer.
          </Paragraph>
          <Paragraph>
            {' '}
            If a buyer initiates a return, but you find that the item(s) have
            been used or damaged, you can deduct up to 50% of the refund to
            recover the lost value of the item(s). {PRODUCT_NAME} may require
            proof of reduced value in some cases.
          </Paragraph>
        </Section>
      </Content>
    </Container>
  );
};
