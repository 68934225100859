import styled from '@emotion/styled';

import { Content, Header, Paragraph, Section } from 'components/helpShared';
import { PRODUCT_NAME } from 'config';

// TODO: link to returns/refunds policy
export const Guarantee = () => {
  return (
    <Container>
      <Header>{PRODUCT_NAME} Guarantee</Header>
      <Content>
        <Section>
          <Paragraph>
            Buyers of items on {PRODUCT_NAME} have their purchases guaranteed.
            This means that if part or all of an order is not received, an item
            is damaged, or an item received is not as described or pictured in
            the listing, the buyer is entitled to a full refund after returning
            the item(s) back to the seller, provided a return is possible.
          </Paragraph>
        </Section>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  margin-top: 40px;
  gap: 20px;
`;
