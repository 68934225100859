import { useQuery } from '@tanstack/react-query';

import useAPI from 'api';

import { ListingRecord } from 'types';

interface SearchListingsParams {
  category: string | null;
  topN: number;
}

export const useGetRecentListings = ({
  category,
  topN,
}: SearchListingsParams) => {
  const API = useAPI();

  const fetchListings = async () => {
    const paramsRaw: Record<string, string> = {
      topN: topN.toString(),
    };
    if (category) {
      paramsRaw.category = category;
    }

    const params = new URLSearchParams(paramsRaw);
    const url = `recentListings?${params.toString()}`;
    return await API<{ listings: ListingRecord[] }>(url.toString(), {
      method: 'GET',
    });
  };

  return useQuery({
    queryKey: ['recentListings', { category, topN }],
    queryFn: fetchListings,
  });
};
