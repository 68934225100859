import { TaxClassifications, TaxClassificationType } from 'types';

export const taxClassifications = [
  TaxClassifications.Individual,
  TaxClassifications.Business,
  TaxClassifications.Trust,
];

export const address1TaxValidation = {
  label: 'Address 1',
  name: 'address_1',
  type: 'text',
  id: 'address_1',
  placeholder: '',
  validation: {
    required: {
      value: true,
      message: 'Address 1 is required.',
    },
    maxLength: {
      value: 100,
      message: 'Address 1 must be at most 100 characters',
    },
  },
};

export const address2TaxValidation = {
  label: 'Address 2',
  name: 'address_2',
  type: 'text',
  id: 'address_2',
  placeholder: '',
  validation: {
    maxLength: {
      value: 100,
      message: 'Address 2 must be at most 100 characters',
    },
  },
};

export const dbaFlagValidation = {
  label:
    'I am doing business under a name other than the legal name entered (DBA)',
  name: 'dbaFlag',
  id: 'dbaFlag',
  type: 'checkbox',
};

export const exemptFlagValidation = {
  label:
    'I am exempt from backup withholdings (codes apply only to certain entities, not individuals):',
  name: 'exemptFlag',
  id: 'exemptFlag',
  type: 'checkbox',
};

export const doingBusinessAsValidation = {
  label: 'Business Name / Disregarded Entity Name',
  name: 'doing_business_as',
  type: 'text',
  id: 'doing_business_as',
  placeholder: '',
  validation: {
    maxLength: {
      value: 100,
      message: 'DBA must be at most 100 characters',
    },
  },
};

export const entityNameValidation = {
  label: 'Name',
  name: 'entity_name',
  id: 'entity_name',
  type: 'text',
  placeholder: '',
  validation: {
    required: {
      value: true,
      message: 'Name is required.',
    },
    minLength: {
      value: 1,
      message: 'Name must be at least 1 character',
    },
    maxLength: {
      value: 50,
      message: 'Name must be at most 50 characters',
    },
  },
};

export const suffixValidation = {
  label: 'Suffix',
  name: 'suffix',
  type: 'text',
  id: 'suffix',
  placeholder: '',
  validation: {
    maxLength: {
      value: 20,
      message: 'Name must be at most 20 characters',
    },
  },
};

export const taxClassificationValidation = {
  label: 'Tax Classification',
  name: 'tax_classification',
  type: 'text',
  id: 'tax_classification',
  defaultValue: 'Select classification',
  placeholder: '',
  choices: { taxClassifications },
  validation: {
    validate: {
      value: (value: TaxClassificationType) =>
        value === '' ||
        taxClassifications.includes(value as TaxClassifications) ||
        'Must choose one of the options.',
    },
  },
};

export const middleInitialValidation = {
  label: 'Middle Initial',
  name: 'middle_name',
  type: 'text',
  id: 'middle_name',
  placeholder: '',
  maxLength: 1,
  capitalize: true,
  validation: {
    required: {
      value: false,
    },
    maxLength: {
      value: 1,
      message: '1 character maximum ',
    },
    pattern: {
      value: /^[a-zA-Z]+$/,
      message: 'Only letters allowed.',
    },
  },
};

export const ssnValidation = {
  label: 'Social Security Number (SSN)',
  name: 'ssn',
  id: 'ssn',
  type: 'text',
  placeholder: 'XXX-XX-XXXX',
  maxLength: 11,
  validation: {
    required: {
      value: true,
      message: 'This field is required.',
    },
    maxLength: {
      value: 11,
      message: 'SSN must be 9 digits',
    },
    pattern: {
      value: /^\d{3}-\d{2}-\d{4}$/,
      message: 'Invalid SSN format',
    },
  },
};

export const ssnMask = (value: string) => {
  value = value.replace(/\D/g, '');
  if (value.length > 3 && value.length <= 5) {
    value = value.replace(/(\d{3})(\d+)/, '$1-$2');
  } else if (value.length > 5) {
    value = value.replace(/(\d{3})(\d{2})(\d+)/, '$1-$2-$3');
  }
  return value;
};

export const itinValidation = {
  label: 'Individual Taxpayer Identification Number (ITIN)',
  name: 'itin',
  id: 'itin',
  type: 'text',
  placeholder: '9XX-XX-XXXX',
  maxLength: 11,
  validation: {
    required: {
      value: true,
      message: 'This field is required.',
    },
    maxLength: {
      value: 11,
      message: 'ITIN must be 9 digits',
    },
    pattern: {
      value: /^9\d{2}-\d{2}-\d{4}$/,
      message: 'Invalid ITIN format',
    },
  },
};

export const itinMask = (value: string) => {
  value = value.replace(/\D/g, '');
  if (value.length > 3 && value.length <= 5) {
    value = value.replace(/(\d{3})(\d+)/, '$1-$2');
  } else if (value.length > 5) {
    value = value.replace(/(\d{3})(\d{2})(\d+)/, '$1-$2-$3');
  }
  return value;
};

export const einValidation = {
  label: 'Employer Identification Number (EIN)',
  name: 'ein',
  id: 'ein',
  type: 'text',
  placeholder: 'XX-XXXXXXX',
  maxLength: 10,
  validation: {
    required: {
      value: true,
      message: 'This field is required.',
    },
    maxLength: {
      value: 10,
      message: 'EIN must be 9 digits',
    },
    pattern: {
      value: /^\d{2}-\d{7}$/,
      message: 'Invalid EIN format',
    },
  },
};

export const einMask = (value: string) => {
  value = value.replace(/\D/g, '');
  if (value.length > 2) {
    value = value.replace(/(\d{2})(\d+)/, '$1-$2');
  }
  return value;
};

export const exemption1Validation = {
  label:
    '1 — An organization exempt from tax under section 501(a), any IRA, or a custodial account under section 403(b)(7) if the account satisfies the requirements of section 401(f)(2)',
  name: 'exemption_1',
  id: 'exemption_1',
  type: 'checkbox',
};

export const exemption2Validation = {
  label: '2 — The United States or any of its agencies or instrumentalities',
  name: 'exemption_2',
  id: 'exemption_2',
  type: 'checkbox',
};

export const exemption3Validation = {
  label:
    '3 — A state, the District of Columbia, a U.S. commonwealth or possession, or any of their political subdivisions or instrumentalities',
  name: 'exemption_3',
  id: 'exemption_3',
  type: 'checkbox',
};

export const exemption4Validation = {
  label:
    '4 — A foreign government or any of its political subdivisions, agencies, or instrumentalities',
  name: 'exemption_4',
  id: 'exemption_4',
  type: 'checkbox',
};

export const exemption5Validation = {
  label: '5 — A corporation',
  name: 'exemption_5',
  id: 'exemption_5',
  type: 'checkbox',
};

export const exemption6Validation = {
  label:
    '6 — A dealer in securities or commodities required to register in the United States, the District of Columbia, or a U.S. commonwealth or possession',
  name: 'exemption_6',
  id: 'exemption_6',
  type: 'checkbox',
};

export const exemption7Validation = {
  label:
    '7 — A futures commission merchant registered with the Commodity Futures Trading Commission',
  name: 'exemption_7',
  id: 'exemption_7',
  type: 'checkbox',
};

export const exemption8Validation = {
  label: '8 — A real estate investment trust',
  name: 'exemption_8',
  id: 'exemption_8',
  type: 'checkbox',
};

export const exemption9Validation = {
  label:
    '9 — An entity registered at all times during the tax year under the Investment Company Act of 1940',
  name: 'exemption_9',
  id: 'exemption_9',
  type: 'checkbox',
};

export const exemption10Validation = {
  label: '10 — A common trust fund operated by a bank under section 584(a)',
  name: 'exemption_10',
  id: 'exemption_10',
  type: 'checkbox',
};

export const exemption11Validation = {
  label: '11 — A financial institution',
  name: 'exemption_11',
  id: 'exemption_11',
  type: 'checkbox',
};

export const exemption12Validation = {
  label:
    '12 — A middleman known in the investment community as a nominee or custodian',
  name: 'exemption_12',
  id: 'exemption_12',
  type: 'checkbox',
};

export const exemption13Validation = {
  label:
    '13 — A trust exempt from tax under section 664 or described in section 4947',
  name: 'exemption_13',
  id: 'exemption_13',
  type: 'checkbox',
};
