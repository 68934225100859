import { Breadcrumbs } from 'components/breadcrumbs';
import {
  Container,
  Content,
  Divider,
  Header,
  Paragraph,
  Section,
  Title,
} from 'components/helpShared';

import {
  FINAL_VALUE_FEE,
  PRODUCT_NAME,
  TRANSACTION_FEE_FLAT,
  TRANSACTION_FEE_PCT,
} from 'config';

export const SellerFees = () => {
  return (
    <Container>
      <Breadcrumbs
        items={[
          { path: '/help', label: 'Help Home' },
          { path: '/help/sellerResources', label: 'Seller Resources' },
          {
            path: '/help/sellerResources/sellerFees',
            label: 'Seller Fees',
          },
        ]}
      />
      <Header>Seller Fees</Header>
      <Divider />
      <Content>
        <Section>
          <Title>{PRODUCT_NAME}</Title>
          <Paragraph>
            We only charge a {FINAL_VALUE_FEE}% final value fee on the item
            price for successful sales.
            <br />
            <br />
            We do not take a fee on the shipping or taxes portion.
            <br />
            <br />
            We do not charge a listing fee.
          </Paragraph>
          <Title>Stripe</Title>
          <Paragraph>
            Stripe also takes a transaction fee of {TRANSACTION_FEE_PCT}% of the
            total amount, plus a flat charge of ${TRANSACTION_FEE_FLAT}.
          </Paragraph>
          <Title>Refunds</Title>
          <Paragraph>
            In the event of a refund, {PRODUCT_NAME} will return the final value
            fee. Stripe, however, does not return the transaction fee.
          </Paragraph>
        </Section>
      </Content>
    </Container>
  );
};
