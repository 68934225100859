import { useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import styled from '@emotion/styled';

import { Input } from 'components/input';
import { Button } from 'components/buttons';
import { usernameValidation } from 'components/validation';
import { Header } from 'components/myAccount';
import { PersonalFormProps, UsernamePicInputs } from './types';
import { useUpdateAccountInfo } from './hooks';

export const UsernameForm: React.FC<PersonalFormProps> = ({ data }) => {
  const { mutate: updateAccountInfo, error } = useUpdateAccountInfo();
  const methods = useForm<UsernamePicInputs>({
    defaultValues: {
      username: '',
    },
  });

  useEffect(() => {
    methods.reset({
      username: data.username || '',
    });
  }, [data, methods]);

  const handleFormSubmit = methods.handleSubmit(async (formData) => {
    const accountInfo = {
      user_id: data.user_id,
      email: data.email,
      phone: data.phone,
      username: formData.username,
      first_name: data.first_name,
      middle_name: data.middle_name,
      last_name: data.last_name,
      address_1: data.address_1,
      address_2: data.address_2,
      city: data.city,
      state: data.state,
      zip: data.zip,
      createdAt: data.createdAt,
    };
    updateAccountInfo(accountInfo);
    methods.reset(formData);
  });

  const inputWidth = '360px';

  return (
    <Container>
      <Header>Username</Header>
      <Content>
        <FormProvider {...methods}>
          <Form onSubmit={(e) => e.preventDefault()} autoComplete='off'>
            <Row>
              <InputWrapper>
                <Input {...usernameValidation} width={inputWidth} />
              </InputWrapper>
            </Row>
            <ButtonContainer>
              <Button
                width={'240px'}
                onClick={handleFormSubmit}
                disabled={!methods.formState.isDirty && !error}
              >
                Update Username
              </Button>
            </ButtonContainer>
          </Form>
        </FormProvider>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  width: 800px;
`;

const Content = styled.div`
  padding: 20px;
  border: 1px solid ${(props) => props.theme.colors.border.primary};
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  autocomplete: 'off';
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 26px;
`;

const InputWrapper = styled.div`
  flex: 1;
  &:not(:last-child) {
    margin-right: 20px;
  }
`;

const ButtonContainer = styled.div`
  margin-top: 10px;
`;
