import { PRICE_TRACKING_REQUIRED } from 'config';

export const lengthValidation = {
  label: 'Length (inches)',
  name: 'length',
  type: 'text',
  id: 'length',
  placeholder: 'Enter length',
  validation: {
    required: {
      value: true,
      message: 'Length is required.',
    },
    pattern: {
      value: /^\d+(\.\d+)?$/,
      message: 'Invalid length. Must be a number.',
    },
  },
};

export const widthValidation = {
  label: 'Width (inches)',
  name: 'width',
  type: 'text',
  id: 'width',
  placeholder: 'Enter width',
  validation: {
    required: {
      value: true,
      message: 'Width is required.',
    },
    pattern: {
      value: /^\d+(\.\d+)?$/,
      message: 'Invalid width. Must be a number.',
    },
  },
};

export const heightValidation = {
  label: 'Height (inches)',
  name: 'height',
  type: 'text',
  id: 'height',
  placeholder: 'Enter height',
  validation: {
    required: {
      value: true,
      message: 'Height is required.',
    },
    pattern: {
      value: /^\d+(\.\d+)?$/,
      message: 'Invalid height. Must be a number.',
    },
  },
};

export const weightOzValidation = {
  label: 'Weight (oz)',
  name: 'weightOz',
  type: 'text',
  id: 'weightOz',
  placeholder: 'Enter weight',
  validation: {
    required: {
      value: true,
      message: 'Weight is required.',
    },
    pattern: {
      value: /^\d+(\.\d+)?$/,
      message: 'Must be a number.',
    },
  },
};

export const trackingNumberValidation = (isRequired: boolean) => {
  return {
    label: 'Tracking Number',
    name: 'tracking',
    type: 'text',
    id: 'tracking',
    placeholder: 'Tracking number',
    validation: {
      required: isRequired
        ? {
            value: true,
            message: `Tracking number is required for orders over $${PRICE_TRACKING_REQUIRED}.`,
          }
        : undefined, // Ensure it is undefined if not required
      pattern: {
        value: /^[A-Za-z0-9]+$/,
        message: 'Tracking number must be alphanumeric.',
      },
      minLength: {
        value: 10,
        message: 'Tracking number must be at least 10 characters long.',
      },
      maxLength: {
        value: 30,
        message: 'Tracking number cannot be more than 30 characters long.',
      },
    },
  };
};

export const useLabelTrackingValidation = {
  label: 'Use tracking from label',
  name: 'useLabelTracking',
  id: 'useLabelTracking',
  type: 'checkbox',
};
