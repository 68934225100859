import { Input } from 'components/input';
import { priceMask, priceValidation } from 'components/validation';

import {
  HeaderContainer,
  HeaderSubtitle,
  HeaderTitle,
  Section,
} from './shared';

export const PricingComponent = () => {
  return (
    <Section>
      <HeaderContainer>
        <HeaderTitle>Price</HeaderTitle>
        <HeaderSubtitle>
          Buyers can purchase immediately at this price.
        </HeaderSubtitle>
      </HeaderContainer>
      <Input
        {...priceValidation}
        inputMask={priceMask}
        label=''
        width={'100%'}
      />{' '}
    </Section>
  );
};
