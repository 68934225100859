import { useNavigate } from 'react-router-dom';

import { Breadcrumbs } from 'components/breadcrumbs';
import {
  Container,
  Content,
  Divider,
  Header,
  Paragraph,
  Section,
  Title,
} from 'components/helpShared';
import { BlueLinkComponent } from 'components/links';
import { PRODUCT_NAME, W9_SALES_THRESHOLD } from 'config';

export const TaxForms = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <Breadcrumbs
        items={[
          { path: '/help', label: 'Help Home' },
          { path: '/help/sellerResources', label: 'Seller Resources' },
          {
            path: '/help/sellerResources/taxForms',
            label: 'Tax Forms',
          },
        ]}
      />
      <Header>Tax Forms</Header>
      <Divider />
      <Content>
        <Section>
          <Title>Requirements</Title>
          <Paragraph>
            Per the U.S. Internal Revenue Service (IRS) rules, sellers are
            required to complete a W9 tax form after selling $
            {W9_SALES_THRESHOLD} in annual gross sales. Payouts will be withheld
            to sellers who meet this threshold until they fill out the W9 tax
            form on the {PRODUCT_NAME} website. You can access this form in your{' '}
            <BlueLinkComponent
              onClick={() => navigate('/myAccount/SellerForms')}
              text='Seller Forms'
              fontSize='16px'
            />{' '}
            account page. Sellers are encouraged to proactively submit this form
            to prevent any disruption in order payouts.{' '}
          </Paragraph>
          <Paragraph>
            Sellers who reach this threshold will also be issued a 1099-K form
            from {PRODUCT_NAME} at the end of the tax year. You can change your
            preference for receiving an electronic or a physical copy of the
            1099-K form in your{' '}
            <BlueLinkComponent
              onClick={() => navigate('/myAccount/SellerForms')}
              text='Seller Forms'
              fontSize='16px'
            />{' '}
            account page.
          </Paragraph>
          <Paragraph>
            A copy of each 1099-K will be submitted by {PRODUCT_NAME} to the
            IRS. It is the responsibility of applicable sellers to report their
            income using the information on the received 1099-K form when they
            file their personal or business tax returns
          </Paragraph>
        </Section>
      </Content>
    </Container>
  );
};
