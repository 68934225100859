import styled from '@emotion/styled';
import { useFormContext, FieldError } from 'react-hook-form';

export const RadioInput: React.FC<{
  name: string;
  choices: { value: string; label: string }[];
  width: string;
  validation?: object;
}> = ({ name, choices, width, validation }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const error = errors[name] as FieldError | undefined;

  return (
    <div style={{ width }}>
      <RadioGroup>
        {choices.map((choice) => (
          <RadioLabel key={choice.value}>
            <RadioButton
              type='radio'
              value={choice.value}
              {...register(name, validation)}
            />
            {choice.label}
          </RadioLabel>
        ))}
      </RadioGroup>
      {error && <ErrorMessage>{error.message}</ErrorMessage>}
    </div>
  );
};

const RadioGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const RadioButton = styled.input`
  margin-right: 8px;
`;

const ErrorMessage = styled.div`
  color: red;
  margin-top: 8px;
  font-size: 0.875rem;
`;
