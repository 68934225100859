import styled from '@emotion/styled';

interface ButtonProps {
  height?: string;
  width?: string;
  fontSize?: string;
  variant?: 'primary' | 'secondary';
}

interface SelectButtonProps {
  height?: string;
  width?: string;
  fontSize?: string;
  borderRadius?: string;
  variant?: 'primary' | 'secondary';
  isSelected?: boolean;
}

export const Button = styled.button<ButtonProps>`
  width: ${(props) => props.width || '100%'};
  padding: 0 1.25rem;
  font-weight: 500;
  cursor: pointer;
  height: ${(props) => props.height || '48px'};
  font-size: ${(props) => props.fontSize || props.theme.fontSize.button};
  // border-radius: ${(props) => props.theme.borderRadius.button};
  border: solid 1px
    ${(props) =>
      props.variant === 'secondary'
        ? props.theme.colors.primary
        : props.theme.colors.primary};
  color: ${(props) =>
    props.variant === 'secondary'
      ? props.theme.colors.primary
      : props.theme.colors.text.inverted};
  background: ${(props) =>
    props.variant === 'secondary'
      ? props.theme.colors.white
      : props.theme.colors.primary};
  &:hover {
    background-color: ${(props) =>
      props.variant === 'secondary'
        ? props.theme.colors.secondary_hover
        : props.theme.colors.primary_hover};
  }
  &:disabled {
    background: #bbb;
    border: none;
    cursor: not-allowed;
    color: #fff;
  }
`;

export const SelectButton = styled.button<SelectButtonProps>`
  padding: 0 1.25rem;
  font-weight: 500;
  cursor: pointer;
  width: ${(props) => props.width || '180px'};
  height: ${(props) => props.height || '48px'};
  font-size: ${(props) => props.fontSize || props.theme.fontSize.button};
  border-radius: ${(props) => props.borderRadius || '0px'};
  border: solid 1px
    ${(props) =>
      !props.isSelected
        ? props.theme.colors.primary
        : props.theme.colors.primary};
  color: ${(props) =>
    !props.isSelected
      ? props.theme.colors.primary
      : props.theme.colors.text.inverted};
  background: ${(props) =>
    !props.isSelected ? props.theme.colors.white : props.theme.colors.primary};
  &:hover {
    background-color: ${(props) =>
      !props.isSelected
        ? props.theme.colors.grey_hover
        : props.theme.colors.primary_hover};
  }
  &:disabled {
    background: #bbb;
    border: none;
    cursor: not-allowed;
    color: #fff;
  }
`;

export const LoginButton = styled(Button)`
  width: 110px;
  height: 40px;
  font-size: ${(props) => props.theme.fontSize.buttonSmall};
  font-weight: 500;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: ${(props) => props.theme.minViewSize}) {
  margin-right: 10px;
`;

export const SaveButton = styled.button`
  padding: 10px 20px;
  width: 200px;
  height: 40px;
  margin-right: 20px;
  border-radius: 20px;
  border: none;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  cursor: pointer;
  &:hover {
    background-color: #0056b3;
  }
  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

export const CancelButton = styled.button`
  padding: 10px 20px;
  width: 200px;
  margin-right: 20px;
  border-radius: 20px;
  border: 1px solid #007bff;
  background-color: white;
  color: #007bff;
  font-size: 16px;
  cursor: pointer;
  &:hover {
    background-color: #0056b3;
  }
  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;
