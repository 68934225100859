import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';

import { BlueLinkComponent, GreyLinkComponent } from 'components/links';
import { formattedDate } from 'utils';

import { ListingDataProps } from './types';

export const ListingData: React.FC<ListingDataProps> = (props) => {
  const purchaseDate = formattedDate(props.order.createdAt || '');
  const navigate = useNavigate();

  return (
    <>
      <ImageContainer>
        <Image src={props.order.signedS3Url} alt='Product Image' />
      </ImageContainer>
      <ItemInfoContainer>
        <ListingTitle>{props.order.listing_title}</ListingTitle>
        <Purchased>Purchased on {purchaseDate}</Purchased>
        {props.order.other_party_id && (
          <SoldByContainer>
            <SoldBy>Sold by</SoldBy>
            <BlueLinkComponent
              fontSize={'12px'}
              text={props.order.other_username}
              onClick={() => navigate(`/user/${props.order.other_party_id}`)}
            />
          </SoldByContainer>
        )}
        <GreyLinkComponent
          text={'View original listing'}
          onClick={() => navigate(`/listing/${props.order.listing_id}`)}
        />
      </ItemInfoContainer>
    </>
  );
};

const SoldByContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const SoldBy = styled.div`
  font-size: 14px;
  display: flex;
  margin-right: 5px;
`;

const ListingTitle = styled.h2`
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  margin: 0 0 22px 0;
`;

const ImageContainer = styled.div`
  width: 120px;
  height: 120px;
  display: flex;
  justify-content: left;
  align-items: left;
`;

const Image = styled.img`
  max-width: 120px;
  max-height: 120px;
  object-fit: cover;
`;

const ItemInfoContainer = styled.div`
  flex-direction: column;
  display: flex;
  align-items: left;
  text-align: left;
  margin-left: 30px;
  gap: 5px;
`;

const Purchased = styled.div`
  text-align: left;
  font-size: 14px;
`;
