import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Breadcrumbs } from 'components/breadcrumbs';
import { ListingCardManageOrder } from 'components/listingCard';
import LoadingSpinner from 'components/loading';
import { Container, Header } from 'components/myAccount';
import { ShippingSection } from 'components/shipping';
import { useGetListing } from 'hooks/listing';

import { FeedbackSection } from './manageFeedback';
import { CommsSection } from './manageComms';
import { useGetShippingLabel } from './hooks';

export const ManageListingOrder = () => {
  const navigate = useNavigate();
  const { listingId } = useParams<{ listingId: string }>();
  const { userId } = useParams<{ userId: string }>();
  const [trigger, setTrigger] = useState<number>(0);
  const { data, isLoading } = useGetShippingLabel(listingId || '', trigger);
  const { data: listing, isLoading: listingLoading } = useGetListing(
    listingId!
  );
  const [status, setStatus] = useState(data?.info?.order_status || '');

  // If user is somehow trying to manage the order of a listing they bought, redirect to myAccount
  if (
    userId &&
    listing?.listing?.owner_id &&
    userId === listing?.listing?.owner_id
  ) {
    console.log(`userId: ${userId}`);
    console.log(`ownerId: ${listing?.listing?.owner_id}`);
    navigate(`/myAccount`);
  }

  useEffect(() => {
    if (data?.info && data.info.order_status) {
      setStatus(data.info.order_status);
    }
  }, [data, isLoading, navigate]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Navigate away if someone tried to manage this order but no order exists.
  // TODO: fix console error that pops up when this happens.
  if (!isLoading && !data?.info) {
    navigate('/myAccount/listings');
    return null;
  }

  if (isLoading || listingLoading) return <LoadingSpinner />;
  if (!data || !listing) return <>Order not found.</>;

  return (
    <Container>
      <Header>Manage Order</Header>
      <Breadcrumbs
        items={[
          { path: '/myAccount/Listings', label: 'My listings' },
          { path: '/myAccount/Listings/ID', label: 'Manage order' },
        ]}
      />
      <ListingCardManageOrder listing={listing.listing} status={status} />
      <ShippingSection
        orderData={data.info}
        status={status}
        setStatus={setStatus}
        setTrigger={setTrigger}
      />
      <CommsSection buyer_id={data.info.buyer_id} />
      <FeedbackSection listingId={listingId || ''} />
    </Container>
  );
};
