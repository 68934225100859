import styled from '@emotion/styled';

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const Header = styled.h2`
  text-align: left;
  margin-top: 0;
  margin-bottom: 0;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 800px;
  gap: 10px;
  margin-bottom: 80px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 26px;
`;
