import styled from '@emotion/styled';

export const FilterDropdown = styled.select`
  padding: 8px;
  font-size: 12px;
  cursor: pointer;
  background-color: #ffffff;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.colors.border.primary};

  &:hover {
    border: 1px solid ${(props) => props.theme.colors.border.focus};
    outline: none;
  }

  option {
    background-color: white;
    &:hover {
      background-color: #eeeeee;
    }
  }

  &:focus {
    outline: none;
    border-radius: 8px;
    // box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }
`;
