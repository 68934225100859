import styled from '@emotion/styled';

import { Button } from 'components/buttons';
import { useDoFindOrCreateConversation } from 'hooks/messaging';

export const CommsSection: React.FC<{ buyer_id: string }> = ({ buyer_id }) => {
  const { mutate: findConvo } = useDoFindOrCreateConversation();

  return (
    <Container>
      <Header>Communicate with Buyer</Header>
      <Content>
        <Button
          width={'200px'}
          height={'40px'}
          onClick={() => findConvo({ otherUserId: buyer_id })}
        >
          Message buyer
        </Button>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  width: 800px;
`;

const Content = styled.div`
  padding: 20px;
  border: 1px solid ${(props) => props.theme.colors.border.primary};
`;

const Header = styled.h3`
  text-align: left;
`;
