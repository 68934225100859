import { TaxFormAPIResponse, TaxFormInterface } from './types';

import {
  TaxClassificationsMap,
  TaxClassificationsMapReverse,
  TaxClassificationType,
} from 'types';

export const resetW9TaxForm = (
  data: TaxFormAPIResponse | undefined,
  methods: any
) => {
  if (data) {
    const existingData = data.w9TaxForm;
    const ssn =
      existingData.tax_id_number_type === 'ssn'
        ? existingData.tax_id_number
        : '';
    const ein =
      existingData.tax_id_number_type === 'ein'
        ? existingData.tax_id_number
        : '';
    const itin =
      existingData.tax_id_number_type === 'itin'
        ? existingData.tax_id_number
        : '';
    const exemptions = existingData.exemptions;
    methods.reset({
      user_id: existingData.user_id || '',
      tax_classification:
        TaxClassificationsMapReverse[existingData.tax_classification] || '',
      entity_name: existingData.entity_name || '',
      first_name: existingData.first_name || '',
      middle_name: existingData.middle_name || '',
      last_name: existingData.last_name || '',
      suffix: existingData.suffix || '',
      doing_business_as: existingData.doing_business_as || '',
      address_1: existingData.address_1 || '',
      address_2: existingData.address_2 || '',
      city: existingData.city || '',
      state: existingData.state || '',
      zip: existingData.zip || '',
      tax_id_number_type: existingData.tax_id_number_type || '',
      ssn,
      ein,
      itin,
      exemption_1: exemptions?.[0] || false,
      exemption_2: exemptions?.[1] || false,
      exemption_3: exemptions?.[2] || false,
      exemption_4: exemptions?.[3] || false,
      exemption_5: exemptions?.[4] || false,
      exemption_6: exemptions?.[5] || false,
      exemption_7: exemptions?.[6] || false,
      exemption_8: exemptions?.[7] || false,
      exemption_9: exemptions?.[8] || false,
      exemption_10: exemptions?.[9] || false,
      exemption_11: exemptions?.[10] || false,
      exemption_12: exemptions?.[11] || false,
      exemption_13: exemptions?.[12] || false,
      subject_to_backup_witholdings:
        existingData.subject_to_backup_witholdings || false,
      dbaFlag: !!existingData.doing_business_as,
      exemptFlag: exemptions?.some((value) => value),
    });
  }
};

export const defaultValues = {
  user_id: '',
  tax_classification: '' as TaxClassificationType,
  entity_name: '',
  first_name: '',
  middle_name: '',
  last_name: '',
  suffix: '',
  doing_business_as: '',
  address_1: '',
  address_2: '',
  city: '',
  state: '',
  zip: '',
  tax_id_number_type: '',
  ssn: '',
  ein: '',
  itin: '',
  exemption_1: false,
  exemption_2: false,
  exemption_3: false,
  exemption_4: false,
  exemption_5: false,
  exemption_6: false,
  exemption_7: false,
  exemption_8: false,
  exemption_9: false,
  exemption_10: false,
  exemption_11: false,
  exemption_12: false,
  exemption_13: false,
  subject_to_backup_witholdings: false,
  dbaFlag: false,
  exemptFlag: false,
};

export const packageFormData = (
  user_id: string,
  formData: Omit<TaxFormInterface, 'tax_id_number'>
) => {
  const exemptions = [
    formData.exemption_1,
    formData.exemption_2,
    formData.exemption_3,
    formData.exemption_4,
    formData.exemption_5,
    formData.exemption_6,
    formData.exemption_7,
    formData.exemption_8,
    formData.exemption_9,
    formData.exemption_10,
    formData.exemption_11,
    formData.exemption_12,
    formData.exemption_13,
  ];
  const tax_id_number_type = formData.itin
    ? 'itin'
    : formData.ssn
    ? 'ssn'
    : 'ein';
  const tax_id_number = formData.itin || formData.ssn || formData.ein;
  return {
    user_id,
    tax_classification: TaxClassificationsMap[formData.tax_classification],
    entity_name: formData.entity_name,
    first_name: formData.first_name,
    middle_name: formData.middle_name,
    last_name: formData.last_name,
    suffix: formData.suffix,
    doing_business_as: formData.doing_business_as,
    address_1: formData.address_1,
    address_2: formData.address_2,
    city: formData.city,
    state: formData.state,
    zip: formData.zip,
    tax_id_number_type,
    tax_id_number,
    exemptions,
    subject_to_backup_witholdings: formData.subject_to_backup_witholdings,
  };
};
