import React from 'react';
import { Global, ThemeProvider } from '@emotion/react';
import {
  Navigate,
  BrowserRouter as Router,
  Route,
  Routes,
} from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

// Pages
import AboutUsPage from 'pages/aboutUs';
import AccountPage from 'pages/myAccount';
import { CheckoutPage, CheckoutSuccessfulPage } from 'pages/checkout';
import { ContactUsPage } from 'pages/contact';
import Home from 'pages/home';
import FaqPage from 'pages/faq';
import FeedbackPage from 'pages/feedback';
import FeedbackSubmittedPage from 'pages/feedbackSubmitted';
import {
  CreateListingPage,
  ListingCreationSuccessPage,
} from 'pages/createListing';
import { EditListingPage, ListingUpdateSuccessPage } from 'pages/editListing';
import Guarantee from 'pages/guarantee';
import HelpPage from 'pages/help';
import LoginPage from 'pages/auth/login';
import NotFoundPage from 'pages/notFound404';
import PasswordResetCompletePage from 'pages/auth/passwordResetComplete';
import PasswordResetRequestPage from 'pages/auth/passwordResetRequest';
import PrivacyPolicyPage from 'pages/privacyPolicy';
import RoadmapPage from 'pages/roadmap';
import SearchPage from 'pages/search';
import SellerAgreementPage from 'pages/sellerAgreement';
import TermsOfServicePage from 'pages/termsOfService';
import UserPage from 'pages/user';
import ViewListing from 'pages/viewListing';

// Components
import { Layout } from 'components/layout';
import { AuthProvider } from 'components/auth';
import { RedirectProvider } from 'contexts';
import { ScrollToTop } from 'components/scrollToTop';
import { ToastWrapper } from 'components/toast';
import { TopnavProvider } from 'components/topnavContext';

// Styles
import { theme } from 'styles';
import { globalStyles } from 'styles';

import './App.css';

const queryClient = new QueryClient();

const App: React.FC = () => {
  return (
    <>
      <Global styles={globalStyles} />
      <RedirectProvider>
        <ThemeProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            <TopnavProvider>
              <AuthProvider>
                <Router>
                  <ScrollToTop />
                  <Routes>
                    <Route path='/login' element={<LoginPage />} />
                    <Route
                      path='/passwordReset'
                      element={<PasswordResetRequestPage />}
                    />
                    <Route
                      path='/resetPassword'
                      element={<PasswordResetCompletePage />}
                    />
                    <Route
                      path='/*'
                      element={
                        <Layout>
                          <Routes>
                            <Route path='/' element={<Home />} />
                            <Route path='/search' element={<SearchPage />} />
                            <Route path='/about' element={<AboutUsPage />} />
                            <Route path='/roadmap' element={<RoadmapPage />} />
                            <Route
                              path='/contact'
                              element={<ContactUsPage />}
                            />
                            <Route path='/faq' element={<FaqPage />} />
                            <Route
                              path='/privacy'
                              element={<PrivacyPolicyPage />}
                            />
                            <Route
                              path='/terms'
                              element={<TermsOfServicePage />}
                            />
                            <Route
                              path='/sellerAgreement'
                              element={<SellerAgreementPage />}
                            />
                            <Route
                              path='/newListing'
                              element={<CreateListingPage />}
                            />
                            <Route
                              path='/editListing/:listingId'
                              element={<EditListingPage />}
                            />
                            <Route
                              path='/listingSuccessful/:listingId'
                              element={<ListingCreationSuccessPage />}
                            />
                            <Route
                              path='/listingUpdated/:listingId'
                              element={<ListingUpdateSuccessPage />}
                            />
                            <Route
                              path='/listing/:listingId'
                              element={<ViewListing />}
                            />
                            <Route
                              path='/checkout/:listingId'
                              element={<CheckoutPage />}
                            />
                            <Route
                              path='/checkoutSuccessful'
                              element={<CheckoutSuccessfulPage />}
                            />
                            <Route
                              path='/feedback/:listingId/:feedbackType'
                              element={<FeedbackPage />}
                            />
                            <Route
                              path='/feedbackSubmitted'
                              element={<FeedbackSubmittedPage />}
                            />
                            <Route
                              path='/user/:userId'
                              element={<UserPage />}
                            />
                            <Route
                              path='/myAccount'
                              element={<Navigate to='/myAccount/accountHome' />}
                            />
                            <Route
                              path='/myAccount/*'
                              element={<AccountPage />}
                            />
                            <Route path='/help/*' element={<HelpPage />} />
                            <Route path='/guarantee' element={<Guarantee />} />
                            <Route path='*' element={<NotFoundPage />} />
                          </Routes>
                        </Layout>
                      }
                    />
                    <Route path='*' element={<NotFoundPage />} />
                  </Routes>
                </Router>
              </AuthProvider>
            </TopnavProvider>
          </QueryClientProvider>
        </ThemeProvider>
      </RedirectProvider>
      <ToastWrapper />
    </>
  );
};

export default App;
