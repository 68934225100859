import {
  useMutation,
  UseMutationResult,
  useQuery,
} from '@tanstack/react-query';

import useAPI from 'api';

import {
  ConversationRecord,
  MarkConversationRead,
  MessageRecord,
  SubmitMessageResponse,
} from './types';

export const useGetMessages = (conversationId: string) => {
  const API = useAPI();
  const getMessages = async (id: string) => {
    const params = new URLSearchParams({ conversationId: id });
    const url = `getMessages?${params.toString()}`;
    return await API<{ messageData: MessageRecord[] }>(url, {
      method: 'GET',
    });
  };
  return useQuery({
    queryKey: ['getMessages', conversationId],
    queryFn: () => getMessages(conversationId),
    enabled: !!conversationId,
  });
};

export const useSubmitMessage = (
  conversationId: string,
  userId: string,
  setMessages: React.Dispatch<React.SetStateAction<MessageRecord[]>>
): UseMutationResult<SubmitMessageResponse, Error, { message: string }> => {
  const API = useAPI();

  const submitMessage = async ({
    message,
    conversationId,
  }: {
    message: string;
    conversationId: string;
  }): Promise<SubmitMessageResponse> => {
    return API<SubmitMessageResponse>('submitMessage', {
      method: 'POST',
      body: JSON.stringify({
        conversationId,
        message,
      }),
    });
  };

  return useMutation<SubmitMessageResponse, Error, { message: string }>({
    mutationFn: (message) =>
      submitMessage({ message: message.message, conversationId }),
    onSuccess: (data) => {
      const newMessage = {
        id: data.newMsg.id,
        conversation_id: data.newMsg.conversation_id,
        message: data.newMsg.message,
        sender_id: data.newMsg.sender_id,
        seen: data.newMsg.seen,
        unread: !data.newMsg.seen,
        fromSelf: data.newMsg.sender_id === userId,
        createdAt: data.newMsg.createdAt,
      };

      setMessages((prevMessages) => [...prevMessages, newMessage]);
    },
    onError: (error: any) => {
      console.error('Error submitting message:', error);
    },
  });
};

export const useMarkConversationRead = () => {
  const API = useAPI();

  const markConversationRead = async (input: MarkConversationRead) => {
    return API('markConversationRead', {
      method: 'POST',
      body: JSON.stringify(input),
    });
  };

  return useMutation({
    mutationFn: markConversationRead,
    onSuccess: () => {
      // Handle success, e.g., invalidate queries or update local state
    },
    onError: (error: any) => {
      console.error('Error: markConversationRead', error);
    },
  });
};

export const useGetConversations = () => {
  const API = useAPI();

  const getConversations = async () => {
    const url = `getConversations`;
    return await API<{
      conversationData: ConversationRecord[];
      userId: string;
    }>(url.toString(), {
      method: 'GET',
    });
  };

  return useQuery({
    queryKey: ['getConversations'],
    queryFn: getConversations,
    enabled: true,
  });
};
