import styled from '@emotion/styled';
import { useFormContext } from 'react-hook-form';

import { CheckdownInputProps } from './types';

export const CheckboxInput = ({
  label,
  id,
  validation,
  name,
  centered = false,
}: CheckdownInputProps) => {
  const { register } = useFormContext();

  return (
    <CheckboxContainer centered={centered}>
      <Input type='checkbox' id={id} {...register(name, validation)} />
      <CheckboxLabel
        htmlFor={id}
        dangerouslySetInnerHTML={{ __html: label }}
      />{' '}
    </CheckboxContainer>
  );
};

const CheckboxContainer = styled.div<{ centered: boolean }>`
  display: flex;
  align-items: center;
  justify-content: ${({ centered }) =>
    centered ? 'center' : 'flex-start'}; // Conditionally apply centering
  width: 100%;
  margin-bottom: 10px;
`;

const Input = styled.input`
  margin-right: 10px;
  width: 16px;
  height: 16px;
`;

const CheckboxLabel = styled.label`
  font-size: 1em;
  color: #333;
`;
