const MSG_IMG_FAIL_PREFIX = `One or more image uploads failed:\n`;
const MSG_IMG_TOO_SMALL = `${MSG_IMG_FAIL_PREFIX}Images must be at least 500 pixels on the longest side.`;
const MSG_IMG_TOO_LARGE = `${MSG_IMG_FAIL_PREFIX}Images must be maximum 8000 pixels on both sides.`;

export const validateImageSize = async (file: File): Promise<string> => {
  return new Promise((resolve) => {
    const img = new Image();
    img.src = URL.createObjectURL(file);
    img.onload = () => {
      const width = img.naturalWidth;
      const height = img.naturalHeight;
      URL.revokeObjectURL(img.src);
      if (width < 500 && height < 500) {
        resolve(MSG_IMG_TOO_SMALL);
      } else if (width > 8000 || height > 8000) {
        resolve(MSG_IMG_TOO_LARGE);
      } else {
        resolve('');
      }
    };
  });
};
