import { CATEGORIES, RETURN_POLICIES, RETURN_POLICY_MAP } from 'config';

export const titleValidation = {
  label: 'Title',
  name: 'title',
  type: 'text',
  id: 'title',
  placeholder: 'Enter the title of your listing',
  maxLength: 80,
  validation: {
    required: {
      value: true,
      message: 'Title is required.',
    },
    minLength: {
      value: 20,
      message: 'Title must be at least 20 characters.',
    },
    maxLength: {
      value: 80,
      message: 'Title must be at most 80 characters.',
    },
  },
};

export const descriptionValidation = {
  label: 'Description',
  name: 'description',
  type: 'text',
  id: 'description',
  placeholder: 'Enter description here',
  maxLength: 1500,
  validation: {
    required: {
      value: true,
      message: 'Description is required.',
    },
    minLength: {
      value: 50,
      message: 'Description must be at least 50 characters.',
    },
    maxLength: {
      value: 1500,
      message: 'Description must be at most 1,500 characters.',
    },
  },
};

export const priceMask = (value: string) => {
  // Remove any non-numeric characters except for the first period
  value = value.replace(/[^\d.]/g, '');

  // Prevent more than one period
  const firstPeriodIndex = value.indexOf('.');
  if (firstPeriodIndex !== -1) {
    // Only allow one period
    value =
      value.substring(0, firstPeriodIndex + 1) +
      value.substring(firstPeriodIndex + 1).replace(/\./g, '');
  }

  // Limit the decimal places to two if a period exists
  const parts = value.split('.');
  if (parts[1]) {
    parts[1] = parts[1].substring(0, 2); // Limit decimal places to 2
  }

  // Return the formatted value
  return parts.join('.');
};

export const priceValidation = {
  label: 'Price',
  name: 'price',
  type: 'number',
  id: 'price',
  placeholder: '0.00',
  validation: {
    required: {
      value: true,
      message: 'Price is required.',
    },
    validate: {
      maxValue: (value: number) =>
        value <= 10000 || 'The current listing price cap is $10,000.00',
    },
  },
};

const shippingOptions = ['standard', 'priority', 'overnight'];

export const shippingOptionValidation = {
  label: 'Shipping Option',
  name: 'shippingOption',
  type: 'text',
  id: 'shippingOption',
  validation: {
    required: {
      value: true,
      message: 'Shipping option is required.',
    },
    validate: {
      value: (value: string) =>
        shippingOptions.includes(value) || 'Shipping option is required.',
    },
  },
  choices: shippingOptions,
};

export const shippingCostValidation = {
  label: 'Shipping Cost',
  name: 'shippingCost',
  type: 'number',
  id: 'shippingCost',
  placeholder: '0.00',
  validation: {
    required: {
      value: true,
      message: 'Shipping cost is required.',
    },
    validate: {
      maxValue: (value: number) =>
        value <= 200 || 'Shipping must cost no more than $200.00',
    },
  },
};

export const returnsOptionValidation = {
  label: '',
  name: 'returns',
  id: 'returns',
  type: 'text',
  defaultValue: 'Select policy',
  validation: {
    required: {
      value: true,
      message: 'Return policy is required.',
    },
    validate: {
      value: (value: string) =>
        RETURN_POLICIES.includes(value) || 'Return policy is required.',
    },
  },
  choices: RETURN_POLICIES,
  mapping: RETURN_POLICY_MAP,
};

export const categoryValidation = {
  label: '',
  name: 'category',
  id: 'category',
  type: 'text',
  placeholder: 'Choose category',
  defaultValue: 'Select category',
  validation: {
    required: {
      value: true,
      message: 'Category is required.',
    },
  },
  choices: CATEGORIES,
};
