import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';

import { BlueLink } from 'components/links';
import { PRODUCT_NAME } from 'config';

interface PrereqBlockProps {
  stripe_missing: boolean;
}

export const CreateListingPrereqBlock = ({
  stripe_missing,
}: PrereqBlockProps) => {
  const navigate = useNavigate();

  return (
    <Container>
      <Text>
        You must register with Stripe before you can sell on {PRODUCT_NAME}.
      </Text>
      <Links>
        <BlueLink
          onClick={() => navigate(`/myAccount/SellerSetup`)}
          fontSize={'18px'}
        >
          Register with Stripe.
        </BlueLink>
      </Links>
    </Container>
  );
};

const Text = styled.h2``;

const Links = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Container = styled.div`
  margin-top: 120px;
  padding: 0 10px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
`;
