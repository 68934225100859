import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import {
  HandCoins,
  ShoppingCart,
  Tag,
  UsersThree,
} from '@phosphor-icons/react';

import { BlueLinkComponent } from 'components/links';
import { StyledIcon } from 'components/icons';
import { theme } from 'styles';
import { PRODUCT_NAME } from 'config';

export const HelpMain = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <Header>Help Section</Header>
      <IntroText>
        Welcome to the {PRODUCT_NAME} Help page. Please choose a section to
        proceed. If you have a question that isn't covered here, please{' '}
        <BlueLinkComponent
          text='contact support'
          fontSize='16px'
          onClick={() => navigate('/contact')}
        />
        .
      </IntroText>
      <IconGrid>
        <HelpSection onClick={() => navigate('/help/buyerResources')}>
          <IconPlaceholder>
            <StyledIcon icon={ShoppingCart} theme={theme.help} />
          </IconPlaceholder>
          <SectionText>Buyer Resources</SectionText>
        </HelpSection>
        <HelpSection onClick={() => navigate('/help/sellerResources')}>
          <IconPlaceholder>
            <StyledIcon icon={Tag} theme={theme.help} />
          </IconPlaceholder>
          <SectionText>Seller Resources</SectionText>
        </HelpSection>
        <HelpSection onClick={() => navigate('/help/communityGuidelines')}>
          <IconPlaceholder>
            <StyledIcon icon={UsersThree} theme={theme.help} />
          </IconPlaceholder>
          <SectionText>Community Guidelines</SectionText>
        </HelpSection>
        <HelpSection onClick={() => navigate('/help/returnsAndRefundsPolicy')}>
          <IconPlaceholder>
            <StyledIcon icon={HandCoins} theme={theme.help} />
          </IconPlaceholder>
          <SectionText>Returns and Refund Policy</SectionText>
        </HelpSection>
      </IconGrid>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
`;

const Header = styled.h1`
  text-align: center;
  margin-bottom: 20px;
`;

const IntroText = styled.p`
  text-align: center;
  margin-bottom: 50px;
  max-width: 600px;
`;

const IconGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr); // changed to 4 columns
  gap: 40px;

  @media (max-width: 800px) {
    grid-template-columns: repeat(
      2,
      1fr
    ); // changed to 2 columns for mid-sized screens
  }

  @media (max-width: 400px) {
    grid-template-columns: 1fr;
  }
`;

const HelpSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`;

const IconPlaceholder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 120px;
  background-color: ${(props) => props.theme.colors.secondary_hover};
  border-radius: 10px;
  margin-bottom: 5px;
`;

const SectionText = styled.p`
  font-size: 1rem;
  text-align: center;
  cursor: pointer;
  margin: 0;
`;
