import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from 'react';

interface AuthContextType {
  login: (params: LoginParams) => void;
  logout: () => void;
  isAuthLoading: boolean;
}

interface LoginParams {
  jwtToken: string;
  userId: string;
  username: string;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isAuthLoading, setIsAuthLoading] = useState<boolean>(true);

  useEffect(() => {
    setIsAuthLoading(false);
  }, []);

  const login = ({ jwtToken, userId, username }: LoginParams) => {
    localStorage.setItem('jwtToken', jwtToken);
    localStorage.setItem('userId', userId);
    localStorage.setItem('username', username);
  };

  const logout = () => {
    localStorage.removeItem('jwtToken');
    localStorage.removeItem('userId');
    localStorage.removeItem('username');
  };

  // Ensure the provided value matches the AuthContextType
  const value = { login, logout, isAuthLoading };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

// Custom hook for using context ensures return type matches AuthContextType
export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
