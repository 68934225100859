import React from 'react';
import styled from '@emotion/styled';

const NotFound: React.FC = () => {
  return (
    <NotFoundWrapper>
      <NotFoundTitle>404</NotFoundTitle>
      <NotFoundMessage>Page not found :(</NotFoundMessage>
    </NotFoundWrapper>
  );
};

export default NotFound;

const NotFoundWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50vh;
  text-align: center;
  color: #333;
`;

const NotFoundTitle = styled.h3`
  font-size: 3rem;
  margin: 0;
`;

const NotFoundMessage = styled.p`
  font-size: 28px;
`;
