import styled from '@emotion/styled';
import { useFormContext } from 'react-hook-form';

import { Input } from 'components/input';
import { titleValidation } from 'components/validation';

import {
  HeaderContainer,
  HeaderSubtitle,
  HeaderTitle,
  Section,
} from './shared';

const MIN_TITLE_CHARS = 20;
const MID_TITLE_CHARS = 50;
const MAX_TITLE_CHARS = 80;

const getMessageAndBar = (length: number) => {
  const width = (100 * length) / MAX_TITLE_CHARS;
  let message;
  let color;
  if (length < MIN_TITLE_CHARS) {
    message = 'Too short';
    color = 'red';
  } else if (length >= MIN_TITLE_CHARS && length < MID_TITLE_CHARS) {
    message = 'Good - make sure to add descriptive words.';
    color = 'orange';
  } else if (length >= MID_TITLE_CHARS && length < MAX_TITLE_CHARS) {
    message = 'Great!';
    color = 'green';
  } else if (length === MAX_TITLE_CHARS) {
    message = `Titles have a maximum of ${MAX_TITLE_CHARS} characters.`;
    color = 'green';
  } else {
    return { message: '', width: 0, color: 'transparent' };
  }
  return { message, width, color };
};

export const TitleComponent = () => {
  const { watch } = useFormContext();
  const titleValue = watch('title');
  const { message, width, color } = getMessageAndBar(titleValue.length);

  return (
    <Section>
      <HeaderContainer>
        <HeaderTitle>Title</HeaderTitle>
        <HeaderSubtitle>
          Use words people would search for when seeking your item.
        </HeaderSubtitle>
      </HeaderContainer>
      <Input {...titleValidation} label='' width={'100%'} />
      <MessageContainer>
        <MessageTextContainer>
          <LoadingBarContainer>
            <LoadingBar color={color} width={width} />
          </LoadingBarContainer>
          <Message>{message}</Message>
        </MessageTextContainer>
      </MessageContainer>
    </Section>
  );
};

const MessageTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px 0 20px 20px;
`;

const MessageContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Message = styled.div`
  font-size: 13px;
`;

const LoadingBarContainer = styled.div`
  height: 10px;
  border-radius: 5px;
  width: 50px;
  background-color: #eee;
  border-radius: 5px;
  margin-top: 4px;
  margin-right: 10px;
`;

const LoadingBar = styled.div<{ color: string; width: number }>`
  width: ${({ width }) => width}%;
  border-radius: 5px;
  max-width: 50px;
  height: 10px;
  background-color: ${({ color }) => color};
  margin-right: 18px;
`;
