import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import Pluralize from 'pluralize';

import LoadingSpinner from 'components/loading';
import { FilterDropdown } from 'components/input';
import { useTopnav } from 'components/topnavContext';

import { Pagination } from './pagination';
import { SearchResultsGrid } from './grid';
import { buildSortOptions } from './sortOptions';
import { ListingRecord, SearchResultProps } from './types';
import { useSearchListings } from './hooks';

const getQueryParamValue = (queryString: string, param: string) => {
  const urlParams = new URLSearchParams(queryString);
  const value = urlParams.get(param);
  return value;
};

export const SearchResults: React.FC<SearchResultProps> = (props) => {
  const defaultSortOption = props.userId ? 'Time: newly listed' : 'Best match';
  const [sortOption, setSortOption] = useState(defaultSortOption);
  const { searchQuery, setSearchQuery } = useTopnav();
  const { searchCategory, setSearchCategory } = useTopnav();
  const [listings, setListings] = useState<ListingRecord[] | []>([]);
  const [listingCount, setListingCount] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const location = useLocation();
  const navigate = useNavigate();
  const sortOptions = buildSortOptions(props.userId);

  const { data, isLoading } = useSearchListings({
    userId: props.userId,
    searchQuery,
    searchCategory,
    currentPage,
  });

  // Update state based on query results
  useEffect(() => {
    if (data) {
      setListings(data.listings);
      setTotalPages(data.totalPages);
      setListingCount(data.listingCount);
    }
  }, [data]);

  const handleSortOptionSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const option = e.target.value;
    setSortOption(option);
    const sortFunction = sortOptions[option];
    if (listings) {
      const sortedListings = listings.sort(sortFunction);
      setListings(sortedListings);
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    const params = new URLSearchParams(location.search);
    params.set('page', page.toString());
    navigate({ search: params.toString() });
  };

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    if (query) {
      const searchQueryFromParams = query.get('q') || '';
      setSearchQuery(decodeURIComponent(searchQueryFromParams));
      const searchCategoryFromParams = query.get('category') || '';
      setSearchCategory(decodeURIComponent(searchCategoryFromParams));
      const page = parseInt(getQueryParamValue(location.search, 'page') || '1');
      setCurrentPage(page);
    }
  }, [location.search, setSearchCategory, setSearchQuery]);

  if (isLoading)
    return (
      <SpinnerContainer>
        <LoadingSpinner />
      </SpinnerContainer>
    );

  return (
    <>
      <GridContainer>
        <SearchResultsHeader>
          <SearchResultsSummary>
            <b>{listingCount}</b> {Pluralize('listings', listingCount)} found{' '}
            {searchQuery.length > 0 && (
              <>
                for: "<b>{searchQuery}</b>"
              </>
            )}{' '}
          </SearchResultsSummary>
          {listings.length > 0 && (
            <SortByContainer>
              <SortByText>Sort by:</SortByText>
              <FilterDropdown
                id='sortDropdown'
                value={sortOption}
                onChange={(e) => handleSortOptionSelect(e)}
              >
                {Object.keys(sortOptions).map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </FilterDropdown>
            </SortByContainer>
          )}
        </SearchResultsHeader>
        {listings.length > 0 ? (
          <>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
            <SearchResultsGrid listings={listings}></SearchResultsGrid>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </>
        ) : (
          <NoResultsFound>
            No listings found. Please try a different search term or category.
          </NoResultsFound>
        )}
      </GridContainer>
    </>
  );
};

const NoResultsFound = styled.h3`
  text-align: center;
  margin-top: 40px;
  padding: 40px;
`;

const SpinnerContainer = styled.div`
  width: 100%;
  height: 400px;
`;

const SortByContainer = styled.div`
  display: flex;
  margin-right: 30px;
`;

const SortByText = styled.div`
  font-size: 12px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  margin-right: 10px;
`;

const SearchResultsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  height: 60px;
  align-items: center;
  padding: 10px;
  background: ${(props) => props.theme.colors.background.grey};
  border-bottom: 1px solid ${(props) => props.theme.colors.border.primary};
`;

const GridContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
`;

const SearchResultsSummary = styled.div`
  font-size: 14px;
  width: 100%;
  text-align: left;
  margin-left: 30px;
`;
