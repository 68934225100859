import React, { ReactNode } from 'react';
import styled from '@emotion/styled';

import { BottomNav } from 'components/nav/bottomNav';
import { TopNav } from 'components/nav/topNav';

interface LayoutProps {
  children: ReactNode;
}

export const Layout: React.FC<LayoutProps> = ({ children }) => (
  <PageContainer>
    <TopNav />
    <MainContent>{children}</MainContent>
    <BottomNav />
  </PageContainer>
);

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const MainContent = styled.div`
  flex-grow: 1;
  // overflow-y: auto;
`;
