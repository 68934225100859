import { ListingRecord } from './types';

export const buildSortOptions = (userId?: string) => {
  let sortOptions: Record<
    string,
    (a: ListingRecord, b: ListingRecord) => number
  > = {
    'Time: newly listed': (a: ListingRecord, b: ListingRecord) => {
      return a.createdAt.localeCompare(b.createdAt);
    },
    'Price: lowest first': (a: ListingRecord, b: ListingRecord) => {
      return a.price - b.price;
    },
    'Price: highest first': (a: ListingRecord, b: ListingRecord) => {
      return b.price - a.price;
    },
  };

  if (!userId) {
    sortOptions = {
      'Best match': (a: ListingRecord, b: ListingRecord) => {
        return a.match_order - b.match_order;
      },
      ...sortOptions,
    };
  }
  return sortOptions;
};
