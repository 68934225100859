import React from 'react';
import styled from '@emotion/styled';
import { SearchResults } from 'components/searchResults';

const SearchPage: React.FC = () => {
  return (
    <>
      <SearchResultsContainer>
        <SearchResults></SearchResults>
      </SearchResultsContainer>
    </>
  );
};

const SearchResultsContainer = styled.div`
  // margin-top: 40px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
`;

export default SearchPage;
