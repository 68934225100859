import styled from '@emotion/styled';
import { useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';

import { Button } from 'components/buttons';
import { Container, Form, Row, Header } from 'components/myAccount';
import {
  passwordConfirmValidation,
  passwordUpdateValidation,
} from 'components/validation';
import { Input } from 'components/input';

import { useUpdatePassword } from './hooks';

const defaultFormValues = { passwordUpdate: '', passwordConfirm: '' };

export const PasswordForm = () => {
  const { mutate: updatePassword } = useUpdatePassword(() =>
    methods.reset(defaultFormValues)
  );
  const methods = useForm<{ passwordUpdate: string; passwordConfirm: string }>({
    defaultValues: defaultFormValues,
  });

  useEffect(() => {
    methods.reset(defaultFormValues);
  }, [methods]);

  const handleSubmitPassword = methods.handleSubmit((formData) => {
    updatePassword(formData.passwordUpdate);
  });

  return (
    <>
      <StyledContainer>
        <Header>Password</Header>
        <Content>
          <FormProvider {...methods}>
            <Form onSubmit={(e) => e.preventDefault()} autoComplete='off'>
              <Row>
                <InputWrapper>
                  <Input {...passwordUpdateValidation} width={'360px'} />
                </InputWrapper>
              </Row>
              <Row>
                <InputWrapper>
                  <Input
                    {...passwordConfirmValidation(methods.getValues)}
                    width={'360px'}
                  />
                </InputWrapper>
              </Row>
              <Button
                width={'240px'}
                onClick={handleSubmitPassword}
                disabled={!methods.formState.isDirty}
              >
                Update
              </Button>
            </Form>
          </FormProvider>
        </Content>
      </StyledContainer>
    </>
  );
};

const StyledContainer = styled(Container)`
  margin-bottom: 0;
`;

const Content = styled.div`
  padding: 30px;
  border: 1px solid ${(props) => props.theme.colors.border.primary};
`;

const InputWrapper = styled.div`
  flex: 1;
  &:not(:last-child) {
    margin-right: 20px;
  }
`;
