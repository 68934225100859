import styled from '@emotion/styled';

import LoadingSpinner from 'components/loading';
import { Container, Header, HeaderContainer } from 'components/myAccount';
import { useGetAccountInfo } from 'hooks/account';
import { formattedDate } from 'utils';
import { ProfilePicture } from 'components/profilePic';

export const AccountHome = () => {
  const { data, isLoading } = useGetAccountInfo();

  if (isLoading) {
    return <LoadingSpinner />;
  }
  if (!data) {
    return <div>Error: Account information is not available.</div>;
  }

  const profilePicUrl = data.accountInfo.profile_pic_signed_s3_url;

  return (
    <Container>
      <HeaderContainer>
        <Header>Welcome back, {data.accountInfo.username}.</Header>
      </HeaderContainer>
      <Content>
        <ProfilePicture
          profilePicUrl={profilePicUrl}
          size={'large'}
          iconStatus={'static'}
        />
        <Row>
          <Left>Member since:</Left>
          <Right> {formattedDate(data.accountInfo.createdAt)}</Right>
        </Row>
        <Row>
          <Left>Items sold:</Left>
          <Right> {data.accountInfo.items_sold}</Right>
        </Row>
        <Row>
          <Left>Items purchased:</Left>
          <Right> {data.accountInfo.items_bought}</Right>
        </Row>
      </Content>
    </Container>
  );
};

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  border: 1px solid ${(props) => props.theme.colors.border.primary};
  gap: 10px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: flex-end;
`;

const Left = styled.span`
  font-size: 16px;
`;

const Right = styled.span`
  font-size: 14px;
`;
