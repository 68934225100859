import { useState, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import styled from '@emotion/styled';

import { sellerAgreementValidation } from 'components/validation';
import { Button } from 'components/buttons';
import { CheckboxInput } from 'components/input';
import { useUpdateSellerAgreement } from 'hooks/user';

export const SellerAgreementForm = ({
  setRefresh,
}: {
  setRefresh: (value: boolean) => void;
}) => {
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const { mutate: updateSellerAgreement } =
    useUpdateSellerAgreement(setRefresh);
  const methods = useForm();

  const watchTos = methods.watch('tos', false);

  useEffect(() => {
    setIsCheckboxChecked(watchTos);
  }, [watchTos]);

  const handleUpdate = () => {
    updateSellerAgreement();
  };

  return (
    <Container>
      <FormProvider {...methods}>
        <Form onSubmit={(e) => e.preventDefault()}>
          <Header>
            Last step before selling on Near Mint: please read and accept the
            Seller Agreement.
          </Header>
          <CheckboxInput {...sellerAgreementValidation} />
          <Button
            onClick={() => handleUpdate()}
            disabled={!isCheckboxChecked}
            width={'340px'}
          >
            Continue to new listing
          </Button>
        </Form>
      </FormProvider>
    </Container>
  );
};

const Header = styled.h2``;

const Container = styled.div`
  display: flex;
  padding: 0 10px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  justify-content: center;
  max-width: 600px;
  margin: 80px auto;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
  max-width: 600px;
`;
