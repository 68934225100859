import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { format, isSameMinute } from 'date-fns';

import { Breadcrumbs } from 'components/breadcrumbs';

import { useGetMessages, useSubmitMessage } from './hooks';
import * as Types from './types';

const Message: React.FC<Types.MessageProps> = ({
  text,
  timestamp,
  fromSelf,
  showTimestamp,
}) => (
  <MessageContainer fromSelf={fromSelf}>
    <MessageBubble fromSelf={fromSelf} showTimestamp={showTimestamp}>
      {text}
    </MessageBubble>
    {showTimestamp && <Timestamp fromSelf={fromSelf}>{timestamp}</Timestamp>}
  </MessageContainer>
);

const MessageForm: React.FC<Types.MessageFormProps> = (props) => {
  const [message, setMessage] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    props.doSubmitMessage({ message });
    setMessage('');
  };

  return (
    <MessageFormContainer onSubmit={handleSubmit}>
      <MessageInput
        type='text'
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        placeholder='Write message'
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        isFocused={isFocused}
        maxLength={1000}
      />
      <SubmitButton type='submit' disabled={message.trim().length === 0}>
        Send
      </SubmitButton>
    </MessageFormContainer>
  );
};

const Conversation: React.FC<Types.ConversationProps> = (props) => {
  const [messages, setMessages] = useState<Types.MessageRecord[]>([]);
  const { data, isLoading } = useGetMessages(props.conversationId);
  const { mutate: doSubmitMessage } = useSubmitMessage(
    props.conversationId,
    props.userId,
    setMessages
  );

  useEffect(() => {
    if (data?.messageData && !isLoading) {
      const enrichedMessages = data.messageData.map((m) => {
        return {
          ...m,
          fromSelf: m.sender_id === props.userId,
          unread: m.unread && m.sender_id !== props.userId,
        };
      });
      setMessages(enrichedMessages);
    }
  }, [data, isLoading, props.userId]);

  return (
    <ConversationContainer>
      <ConversationTitle>{`Conversation with ${props.otherPartyName}`}</ConversationTitle>
      <Breadcrumbs
        items={[
          { path: '/myAccount/Messages', label: 'All conversations' },
          { path: '/myAccount/Messages/ID', label: `${props.otherPartyName}` },
        ]}
      />
      {messages.map((message, index) => {
        const showTimestamp =
          index === messages.length - 1 ||
          !isSameMinute(message.createdAt, messages[index + 1].createdAt);
        return (
          <Message
            key={message.id}
            text={message.message}
            timestamp={format(message.createdAt, 'p')}
            fromSelf={message.fromSelf}
            showTimestamp={showTimestamp}
          />
        );
      })}
      <MessageForm doSubmitMessage={doSubmitMessage} />
    </ConversationContainer>
  );
};

const ConversationContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 660px;
  width: 100%;
  gap: 10px;
`;

const MessageFormContainer = styled.form`
  display: flex;
  margin-top: 20px;
`;

const SubmitButton = styled.button`
  margin-left: 10px;
  padding: 10px 20px;
  border-radius: 20px;
  border: none;
  background-color: ${({ disabled }) => (disabled ? '#F0F0F0' : '#4caf50')};
  color: ${({ disabled }) => (disabled ? '#666666' : 'white')};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  &:hover {
    background-color: ${({ disabled }) => (disabled ? '#F0F0F0' : '#45a049')};
  }
`;

const ConversationTitle = styled.div`
  font-size: 1.2rem;
  font-weight: bold;
  text-align: middle;
`;

const createShouldForwardProp = (excludedProps: string[]) => (prop: string) =>
  !excludedProps.includes(prop);

const MessageInput = styled('input', {
  shouldForwardProp: createShouldForwardProp(['isFocused']),
})<Types.MessageInputProps>`
  flex-grow: 1;
  padding: 10px;
  border-radius: 20px;
  background-color: ${({ isFocused }) => (isFocused ? '#FFFFFF' : '#F0F0F0')};
  border: ${({ isFocused }) => (isFocused ? '1px' : 'none')};
  &:focus {
    outline: none;
    border: 0.5px solid #ddd;
  }
`;

const MessageContainer = styled('div', {
  shouldForwardProp: createShouldForwardProp(['fromSelf']),
})<Types.MessageContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: ${({ fromSelf }) => (fromSelf ? 'flex-end' : 'flex-start')};
  margin-bottom: 10px;
`;

const MessageBubble = styled('div', {
  shouldForwardProp: createShouldForwardProp(['fromSelf', 'showTimestamp']),
})<Types.MessageBubbleProps>`
  background-color: ${({ fromSelf }) => (fromSelf ? '#DCF8C6' : '#f1f1f1')};
  padding: 10px;
  border-radius: 20px;
  max-width: 60%;
  margin-bottom: ${({ showTimestamp }) => (showTimestamp ? '4px' : '0')};
`;

const Timestamp = styled('div', {
  shouldForwardProp: createShouldForwardProp(['fromSelf']),
})<Types.TimestampProps>`
  font-size: 12px;
  color: #999999;
  text-align: ${({ fromSelf }) => (fromSelf ? 'right' : 'left')};
`;

export default Conversation;
