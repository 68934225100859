import styled from '@emotion/styled';

import { Button } from 'components/buttons';

export const VoidConfirmationModal = ({
  onClose,
  onConfirm,
}: {
  onClose: () => void;
  onConfirm: () => void;
}) => (
  <ModalOverlay>
    <ModalContent>
      <CloseButton onClick={onClose}>X</CloseButton>
      <ModalHeader>
        Are you sure you want to void the shipping label?
      </ModalHeader>
      <ModalBody>
        <Text>Note that refunds take up to 14 days to process.</Text>
        <ButtonContainer>
          <Button onClick={onConfirm} width='120px' height='40px'>
            Confirm
          </Button>
          <Button
            onClick={onClose}
            width='120px'
            height='40px'
            variant='secondary'
          >
            Cancel
          </Button>
        </ButtonContainer>
      </ModalBody>
    </ModalContent>
  </ModalOverlay>
);

const Text = styled.p`
  margin: 0;
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  max-width: 400px;
  width: 100%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
`;

const ModalHeader = styled.h4`
  margin-top: 0;
  margin-bottom: 0;
  padding: 10px;
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 20px;
`;
