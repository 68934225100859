import styled from '@emotion/styled';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';

export const Thumbnail = ({ blobUrl }: { blobUrl: string }) => {
  if (!blobUrl) {
    return <LoadingText>Loading...</LoadingText>;
  }

  const docs = [{ uri: blobUrl }];

  return (
    <ThumbnailContainer>
      <DocViewer
        documents={docs}
        pluginRenderers={DocViewerRenderers}
        config={{
          header: {
            disableHeader: true,
            disableFileName: true,
            retainURLParams: true,
          },
          csvDelimiter: ',',
          pdfZoom: {
            defaultZoom: 1.0,
            zoomJump: 0.2,
          },
          pdfVerticalScrollByDefault: true,
        }}
      />
    </ThumbnailContainer>
  );
};

const ThumbnailContainer = styled.div`
  width: 200px;
  height: 300px;
  overflow: hidden;
`;

const LoadingText = styled.div`
  color: blue;
`;
