import { useNavigate } from 'react-router-dom';

import { Breadcrumbs } from 'components/breadcrumbs';
import {
  Container,
  Content,
  Divider,
  Header,
  Paragraph,
  Section,
  Title,
} from 'components/helpShared';
import { BlueLinkComponent } from 'components/links';
import { PRODUCT_NAME } from 'config';

export const HowToBuy = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <Breadcrumbs
        items={[
          { path: '/help', label: 'Help Home' },
          { path: '/help/buyerResources', label: 'Buyer Resources' },
          { path: '/help/buyerResources/howToBuy', label: 'How to Buy' },
        ]}
      />
      <Header>How to Buy</Header>
      <Divider />
      <Content>
        <Section>
          <Title>Buying</Title>
          <Paragraph>
            You can browse listings on {PRODUCT_NAME} at any time even as a
            guest. Search for a word or phrase in the search bar at the top of
            every page, and/or choose a category from the dropdown to refine
            your search.
          </Paragraph>
          <Paragraph>
            Purchasing an item on {PRODUCT_NAME} requires that you create an
            account. You will need to use an email address that you have access
            to and that hasn't been used to create a {PRODUCT_NAME} account
            before.
          </Paragraph>
        </Section>
        <Section>
          <Title>Check Out</Title>
          <Paragraph>
            {PRODUCT_NAME} uses Stripe to complete orders. To purchase an item,
            you'll need to use a credit card or other supported payment method.
            You will also need to provide a full, valid shipping address with a
            recipient name.
          </Paragraph>
          <Paragraph>
            After checking out, you will receive an email confirming the
            purchase was successful, along with next steps. You can track the
            status of your order, communicate with the buyer, and leave feedback
            in the{' '}
            <BlueLinkComponent
              onClick={() => navigate('/myAccount/purchases')}
              text='My Purchases'
              fontSize='16px'
            />{' '}
            section of your account home.
          </Paragraph>
          <Paragraph>
            For information on item delivery, please look at the{' '}
            <BlueLinkComponent
              onClick={() =>
                navigate('/help/buyerResources/receivingYourOrder')
              }
              text='Receiving Your Order'
              fontSize='16px'
            />{' '}
            article.
          </Paragraph>
        </Section>
      </Content>
    </Container>
  );
};
