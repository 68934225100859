import { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';

import { Input } from 'components/input';
import { Button } from 'components/buttons';
import { BlueLinkComponent } from 'components/links';
import { emailValidation, passwordValidation } from 'components/validation';

import { useResetPassword } from './hooks';

const PwResetCompleteComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [updated, setUpdated] = useState(false);
  const [hasIssue, setHasIssue] = useState(false);
  const { mutate: resetPassword, isSuccess, isError } = useResetPassword();

  const methods = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  // Reset form on mount
  useEffect(() => {
    methods.reset({
      email: '',
      password: '',
    });
  }, [methods]);

  const { watch } = methods;
  const email = watch('email');
  const password = watch('password');

  useEffect(() => {
    if (isError) setHasIssue(true);
  }, [isError]);

  useEffect(() => {
    if (isSuccess) setUpdated(true);
  }, [isSuccess]);

  const handleResetPassword = methods.handleSubmit((data) => {
    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    resetPassword({ email, password, token });
  });

  return (
    <>
      <PageContainer>
        <BlueField></BlueField>
        <AccountCreationWrapper>
          {updated ? (
            <Form>
              <Title>Password successfully updated.</Title>
              <BlueLinkComponent
                text='Back to sign-in'
                onClick={() => navigate('/login')}
              ></BlueLinkComponent>
            </Form>
          ) : (
            <FormProvider {...methods}>
              {hasIssue ? (
                <Messages>
                  <Message>
                    Invalid token. Please request a new password reset.
                  </Message>
                  <BlueLinkComponent
                    text='Request password reset'
                    onClick={() => navigate('/passwordReset')}
                  ></BlueLinkComponent>
                </Messages>
              ) : (
                <Form onSubmit={(e) => e.preventDefault()}>
                  <Header>Reset your password</Header>
                  <Input {...emailValidation} />
                  <Input {...passwordValidation} label='New Password' />
                  <ButtonContainer>
                    <Button onClick={handleResetPassword}>
                      Update password
                    </Button>
                  </ButtonContainer>
                </Form>
              )}
            </FormProvider>
          )}
        </AccountCreationWrapper>
      </PageContainer>
    </>
  );
};

const Title = styled.h2`
  margin-bottom: 0;
`;

const Header = styled.h2`
  text-align: left;
  margin-bottom: 10px;
`;

const BlueField = styled.div`
  width: 500px;
  background-color: ${(props) => props.theme.colors.primary};
  @media (max-width: 1200px) {
    display: none;
  }
`;

const AccountCreationWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Messages = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Message = styled.div`
  color: ${(props) => props.theme.colors.text.primary};
  margin-top: 0px;
  text-align: center;
`;

const PageContainer = styled.div`
  display: flex;
  height: 100vh;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 20px;
  width: 100%;
  max-width: 340px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default PwResetCompleteComponent;
