import { useMutation } from '@tanstack/react-query';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAuth } from 'components/auth';
import { Toast } from 'components/toast';
import { GENERIC_ERR_MSG } from 'components/validation';
import useAPI from 'api';

import { AccountCreationCredentials, LoginCredentials } from './types';

interface ResetPasswordArgs {
  email: string;
  password: string;
  token: string | null;
}

interface LoginOrCreateUser {
  token: string;
  userId: string;
  username: string;
}

export const useCreateAccount = (
  setMessage: (m: string) => void,
  setSuccessMessage: (m: string) => void,
  onAccountCreationSuccess: () => void
) => {
  const { login } = useAuth();
  const navigate = useNavigate();
  const API = useAPI();

  return useMutation({
    mutationFn: async ({ token, password }: AccountCreationCredentials) => {
      return API<LoginOrCreateUser>('createUser', {
        method: 'POST',
        body: JSON.stringify({ token, password }),
      });
    },
    onSuccess: (data) => {
      setSuccessMessage(`Account creation successful. Please sign in.`);
      login({
        jwtToken: data.token,
        userId: data.userId.toString(),
        username: data.username,
      });
      onAccountCreationSuccess();
      navigate('/login');
    },
    onError: (error: any) => {
      setMessage(error.message ? error.message : GENERIC_ERR_MSG);
    },
  });
};

export const useSendPwRequest = () => {
  const API = useAPI();
  return useMutation({
    mutationFn: async (email: string) => {
      return API('passwordResetRequest', {
        method: 'POST',
        body: JSON.stringify({ email }),
      });
    },
    onSuccess: (data) => {
      Toast.success(`Password request successfully sent.`);
    },
    onError: (error: any) => {
      // Toast.error(error.message);
    },
  });
};

export const useResetPassword = () => {
  const API = useAPI();
  return useMutation({
    mutationFn: async ({ email, password, token }: ResetPasswordArgs) => {
      return API('resetPassword', {
        method: 'POST',
        body: JSON.stringify({ email, password, token }),
      });
    },
    onSuccess: (data) => {
      Toast.success(`Password successfully updated.`);
    },
    onError: (error: any) => {
      // Toast.error(error.message);
    },
  });
};

export const useDoLogin = () => {
  const API = useAPI();
  const { login } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  return useMutation({
    mutationFn: async ({ email, password }: LoginCredentials) => {
      return API<LoginOrCreateUser>('login', {
        method: 'POST',
        body: JSON.stringify({ email, password }),
      });
    },
    onSuccess: (data) => {
      login({
        jwtToken: data.token,
        userId: data.userId.toString(),
        username: data.username,
      });
      const searchParams = new URLSearchParams(location.search);
      const redirectTo = new URL(
        searchParams.get('redirect') || '/',
        window.location.origin
      ).pathname;
      navigate(redirectTo, { replace: true });
    },
    onError: (error: any) => {
      Toast.error(error.message);
    },
  });
};
