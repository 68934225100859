import { useQuery } from '@tanstack/react-query';
import useAPI from 'api';

export const useNumUnreadMsg = (userId: string) => {
  const API = useAPI();
  const params = new URLSearchParams({ userId });
  const url = `getNumUnreadMessagesAndProfilePic?${params.toString()}`;

  return useQuery({
    queryKey: [url],
    queryFn: async () => {
      return API<{ numUnread: number; profile_pic_signed_s3_url: string }>(
        url.toString(),
        {
          method: 'GET',
        }
      );
    },
    enabled: !!userId,
    refetchInterval: 60000,
    refetchIntervalInBackground: false,
  });
};
