import React from 'react';
import styled from '@emotion/styled';

import {
  Content,
  Divider,
  Header,
  Paragraph,
  Section,
  Subtitle,
  Title,
  Updated,
} from 'components/helpShared';
import {
  COMPANY_NAME_OFFICIAL,
  PRODUCT_DOMAIN,
  PRODUCT_NAME,
  PRODUCT_NAME_FULL,
  TERMS_OF_SERVICE_DATE,
} from 'config';

export const TermsOfServicePage: React.FC = () => {
  return (
    <Container>
      <Header>Terms of Service</Header>
      <Updated>
        This document is effective as of {TERMS_OF_SERVICE_DATE}.
      </Updated>
      <Divider />
      <Content>
        <Section>
          <Title>Agreement to Terms of Service</Title>
          <Paragraph>
            By accessing {PRODUCT_DOMAIN} or any associated sites and pages
            (collectively referred to as {PRODUCT_NAME}), you agree to comply
            with the following terms and abide by our{' '}
            <a href='/privacy' target='_blank' rel='noopener noreferrer'>
              Privacy Policy
            </a>{' '}
            . These terms may be updated periodically, and your continued use of
            the websites signifies your acceptance of the responsibility to stay
            informed of and adhere to the terms and Privacy Policy as they
            evolve.
          </Paragraph>
        </Section>
        <Section>
          <Title>{PRODUCT_NAME} User Accounts</Title>
          <Paragraph>
            To sign up, buy, or sell on {PRODUCT_DOMAIN} and its affiliated
            websites, you must be at least 18 years of age. While {PRODUCT_NAME}{' '}
            offers products intended for individuals aged 13 and older, these
            products are sold exclusively to adults aged 18 and above who can
            complete purchases using Stripe, credit card, or other approved
            payment methods. If you are under 18, you may only use{' '}
            {PRODUCT_NAME} under the supervision of a parent or guardian.
            <br />
            <br />
            To use {PRODUCT_DOMAIN} as a buyer or seller, you must reside within
            the United States of America. Shipping to or from locations outside
            of the United States is strictly prohibited. Any requests for
            shipping to locations outside of the United States are also
            prohibited. Violations of these restrictions may result in the
            suspension or termination of your account. {PRODUCT_DOMAIN} shall
            not be liable for any losses incurred as a result of engaging in
            these prohibited actions. Please refer to our{' '}
            <a href='/faq' target='_blank' rel='noopener noreferrer'>
              FAQ
            </a>{' '}
            for information regarding plans for users outside of the United
            States.
            <br />
            <br />
            You are required to provide truthful information. All details you
            submit must be accurate and truthful. It is strictly prohibited to
            use false information or impersonate another individual or entity in
            connection with your account.
            <br />
            <br />
            You agree not to use our platform to engage in illegal activities.
            You agree to comply with all applicable local, state, federal, and
            international laws in connection with your use of the {PRODUCT_NAME}
            platform. As a seller, this includes the obligation to obtain any
            necessary permits or licenses for your store. You further agree not
            to engage in fraud, theft, anti-competitive behavior, threatening
            conduct, or any other unlawful acts or crimes against {PRODUCT_NAME}
            , other
            {PRODUCT_NAME} users, or third parties.
            <br />
            <br />
            You must select an appropriate account name. If you choose not to
            use your full name as the name associated with your account, you may
            not use language that is offensive, vulgar, infringes on
            intellectual property rights, or otherwise violates our Terms of
            Service. This requirement also applies to your seller name. Should
            you select a seller name that is deemed offensive, vulgar, or
            infringing on intellectual property rights, {PRODUCT_NAME} reserves
            the right to request that you choose a new name or face account
            suspension.
            <br />
            <br />
            You are responsible for your account security. When you create an
            account to use {PRODUCT_NAME} services, you are responsible for
            maintaining the confidentiality of your account information,
            including your password, and for limiting access to your account.
            You also accept responsibility for all activities conducted under
            your account or password, including any payments due for purchases
            made using your account.
            <br />
            <br />
            You consent to receive one-time passwords (OTPs) via SMS text
            messages, which may incur carrier fees, for the purpose of verifying
            your phone number, as well as OTPs via email for the purpose of
            verifying your email address.
          </Paragraph>
        </Section>
        <Section>
          <Title>Community Guidelines</Title>
          <Paragraph>
            {PRODUCT_NAME} is a community that welcomes all individuals. Whether
            you are a buyer, seller, or simply exploring content, watching
            videos, or researching prices, we expect you to follow our Community
            Guidelines. For more details, please review our Community
            Guidelines.
            <br />
            <br />
            When you post products or content directly to the{' '}
            {PRODUCT_NAME_FULL}, such as listings with photos, you must ensure
            that any images, descriptions, or text used do not contain
            offensive, vulgar, or pornographic material, and that they comply
            fully with our Community Guidelines.
          </Paragraph>
        </Section>
        <Section>
          <Title>Products and Pricing</Title>
          <Paragraph>
            {PRODUCT_NAME} strives to ensure sellers maintain accuracy in all
            aspects of listing pricing and availability. However, {PRODUCT_NAME}{' '}
            does not guarantee that product listings, prices, descriptions,
            available quantities, shipping times, or release dates are accurate,
            complete, reliable, current, or free of errors. If a product you
            purchase from a sellerdoes not match its description, your remedy is
            to return the product or request a partial refund by contacting the
            seller. Details on resolving such disputes are available in our help
            center.
            <br />
            <br />
            Unless otherwise stated, the price displayed for products listed by
            individual sellers on {PRODUCT_NAME} reflects the current price of
            the product, excluding any applicable shipping charges, taxes, or
            processing fees.
            <br />
            <br />
            Your relevant payment method will be charged immediately upon
            submitting your order. In some cases, an authorization challenge may
            be triggered, requiring you to confirm a small refund amount on your
            account or provide a phone number for identity verification. During
            this process, your payment will still be processed. If you do not
            pass the authorization challenge, your payment will be refunded
            within 10 business days. If you successfully pass the challenge,
            your order will proceed as usual.
          </Paragraph>
        </Section>
        <Section>
          <Title>Copyright, Patents, Trademarks, and Licenses</Title>
          <Paragraph>
            All content found on {PRODUCT_DOMAIN} and associated websites within
            the
            {PRODUCT_DOMAIN} domain, including but not limited to graphics,
            text, buttons, logos, videos, audio, downloadable files, pricing
            information, and collectible databases, is the property of{' '}
            {COMPANY_NAME_OFFICIAL} and is protected by United States and
            international copyright laws.
            <br />
            <br />
            Furthermore, the graphics, logos, page headers, and button icons are
            trademarks or trade dress of {PRODUCT_NAME} in the United States.
            This material may not be used in connection with any service or
            product outside of {PRODUCT_NAME} without express written
            permission, particularly in any manner likely to cause confusion
            among customers.
            <br />
            <br />
            Other trademarks, graphics, and logos that may appear on{' '}
            {PRODUCT_NAME} are not owned by {PRODUCT_NAME}.These materials are
            the property of their respective owners, who may or may not be
            affiliated with or connected to {PRODUCT_NAME}.
            <br />
            <br />
            You agree not to engage in crawling, scraping, or spidering any of
            our websites without our express permission.
            <br />
            <br />
            We welcome ideas and feedback and encourage you to share your
            thoughts with us, as they can help enhance your experience and
            improve our services. However, any unsolicited ideas and feedback
            you provide will be considered non-confidential and non-proprietary.
            We may use, publish, or disseminate these ideas and feedback for any
            purpose without providing compensation or credit to you.
          </Paragraph>
          <Subtitle>User-Submitted Images and Content</Subtitle>
          <Paragraph>
            By uploading any images or content to {PRODUCT_NAME}, including but
            not limited to product listing images, profile pictures, and other
            user-submitted media, you hereby grant {PRODUCT_NAME} Marketplace,
            LLC a perpetual, non-exclusive, royalty-free, worldwide, irrevocable
            license to use, reproduce, modify, adapt, publish, distribute,
            publicly display, and create derivative works from such images or
            content in any form and for any purpose, provided that such data
            does not include personally-identifying information. These purposes
            may include, but are not limited to, advertising, promotional
            materials, and other marketing initiatives across all media and
            formats, whether now known or later developed, without further
            compensation or approval..
          </Paragraph>
        </Section>
        <Section>
          <Title>Risks and Liabilities</Title>
          <Paragraph>
            Items purchased from individual sellers on {PRODUCT_NAME} will be
            shipped by those sellers, who bear responsibility for the item until
            delivery is confirmed. In the event of a dispute regarding the
            delivery of your products, buyers should contact either the
            individual seller or {PRODUCT_NAME} Customer Service. For detailed
            information on resolving disputes, please refer to the Disputes
            section of these Terms of Service.
            <br />
            <br />
            If you are a buyer and signature is required for delivery, you
            acknowledge that you must sign to receive the item. By not doing so,
            you waive your right to dispute the item as not received. Likewise,
            if an item includes tracking and is marked as successfully
            delivered, you acknowledge that you waive your right to dispute the
            delivery status and must broach any issues with the delivery company
            directly.
            <br />
            <br />
            If you are a buyer, you acknowledge that {PRODUCT_NAME} acts as the
            sellers' limited payments agent for purchases made on the{' '}
            {PRODUCT_NAME_FULL}. Consequently, once {PRODUCT_NAME} receives your
            payment on behalf of the seller, your obligation to pay the seller
            is fulfilled in the amount received, subject to the terms outlined
            in this Agreement.
          </Paragraph>
        </Section>
        <Section>
          <Title>Limitation of Liability</Title>
          <Paragraph>
            {PRODUCT_NAME}, along with all information, content, materials,
            products, and services provided, is offered on an "as is" and "as
            available" basis, except where otherwise specified in writing.{' '}
            {PRODUCT_NAME} makes no representations or warranties, express or
            implied, regarding the performance or operation of its services, or
            the information, content, materials, or products accessible through
            the site. You expressly agree that your use of {PRODUCT_NAME} is at
            your own risk.
            <br />
            <br />
            To the fullest extent permitted by applicable law, {
              PRODUCT_NAME
            }{' '}
            disclaims all warranties. Specifically, {PRODUCT_NAME} does not
            guarantee that its services, applications, servers, or electronic
            communications are free from viruses or other harmful components.
            Furthermore, {PRODUCT_NAME} will not be liable for any damages or
            losses arising from the use of its services or from the information,
            content, materials, or products available through the site.
            <br />
            <br />
            Some state laws do not allow limitations on implied warranties or
            the exclusion of certain damages. If these laws apply to you, some
            or all of the above disclaimers, exclusions, and limitations may not
            apply, and you may have additional rights under the laws of your
            state.
          </Paragraph>
        </Section>
        <Section>
          <Title>Disputes</Title>
          <Subtitle>Disputes with {PRODUCT_NAME}</Subtitle>
          <Paragraph>
            Any claim against {PRODUCT_NAME} arising from or related to our
            Marketplace shall be resolved in a court located in San Francisco,
            California, and you agree to the exclusive jurisdiction of these
            courts. This agreement shall be governed by the laws of the State of
            California, without regard to its conflict of laws principles.
          </Paragraph>
          <Subtitle>Disputes Between Buyers and Sellers</Subtitle>
          <Paragraph>
            As {PRODUCT_NAME} is not directly involved in transactions between
            buyers and sellers, you agree to release {PRODUCT_NAME} from any
            claims, demands, and damages arising out of transaction disputes. In
            the event of a dispute, buyers and sellers are expected to engage
            directly with one another to seek a resolution. There may be
            instances where {PRODUCT_NAME}
            <br />
            <br />
            Customer Service intervenes to mediate disputes between buyers and
            sellers on our platform. During such mediation, {PRODUCT_NAME}{' '}
            Customer Service may issue full or partial refunds to resolve the
            disagreement. However, the issuance of any refunds is solely at the
            discretion of
            <br />
            <br />
            {PRODUCT_NAME}. If a buyer or seller is found to be in violation of
            these terms of service, including but not limited to engaging in
            fraudulent activity, misrepresentation, or other prohibited
            behaviors, {PRODUCT_NAME} reserves the right to forfeit any payments
            made or received for the relevant transaction. These funds may be
            returned to the other party at {PRODUCT_NAME}'s discretion. In cases
            of egregious or repeated violations of these terms, user accounts
            may be suspended or permanently terminated without prior notice.
            <br />
            <br />
            {PRODUCT_NAME} enforces a zero tolerance policy for fraudulent
            representation, false communication, deceptive advertising, or any
            other form of misconduct in transactions. Users engaging in such
            behaviors will face immediate action, which may include, but is not
            limited to, account suspension, transaction reversals, or permanent
            account termination. We take the security and integrity of our
            platform seriously to ensure a fair marketplace for all users.
          </Paragraph>
        </Section>
        <Section>
          <Title>Termination</Title>
          <Paragraph>
            If you choose to terminate your account or if your account is
            terminated by us, the Terms of Service will continue to apply even
            after your access to our platform is revoked or your use of our
            services ceases.
          </Paragraph>
          <Subtitle>Termination by You</Subtitle>
          <Paragraph>
            You have the right to terminate your account with {PRODUCT_NAME} and
            request the removal of your data. For further details, please refer
            to our{' '}
            <a href='/privacy' target='_blank' rel='noopener noreferrer'>
              Privacy Policy
            </a>{' '}
            .
          </Paragraph>
          <Subtitle>Termination by {PRODUCT_NAME}</Subtitle>
          <Paragraph>
            {PRODUCT_NAME} reserves the right to terminate a user's
            participation in the {PRODUCT_NAME_FULL} and other {PRODUCT_NAME}{' '}
            services at any time, for any reason, at its sole discretion, with
            or without notice. Violations of our Terms of Service may result in
            termination by {PRODUCT_NAME}. We also reserve the right to monitor
            user accounts to ensure compliance with our policies and applicable
            laws. {PRODUCT_NAME} retains the right to remove or modify any
            content provided by users. Any illegal or fraudulent activities may
            be reported to law enforcement or other relevant authorities.
            <br />
            <br />
            If your account is terminated, or if we discontinue a service or
            your access to it, {PRODUCT_NAME} shall not be liable for any impact
            these changes may have on you, including any loss of income or
            ability to generate revenue through those services.
          </Paragraph>
        </Section>
        <Section>
          <Title>Contact Us</Title>
          <Paragraph>
            Questions regarding these Terms of Service may be sent directly to
            our Support team.
          </Paragraph>
        </Section>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  padding: 20px;
  max-width: 800px;
  margin: auto;
  margin-bottom: 40px;
`;
