import { useMutation } from '@tanstack/react-query';

import useAPI from 'api';
import { Toast } from 'components/toast';
import { useUpdateAccountInfo } from 'hooks/user';
import { AccountInfoMinimal } from 'types';

import {
  CodeVerificationData,
  EmailVerificationSendData,
  NewAccountVerificationSendData,
  PhoneVerificationSendData,
} from './types';

export const useDoCodeVerifyAndUpdate = (
  setCodeErrorMsg: (msg: string) => void,
  setIsSubmitted: (val: boolean) => void
) => {
  const { mutate: updateAccountInfo } = useUpdateAccountInfo();
  const API = useAPI();
  return useMutation({
    mutationFn: async ({
      codeData,
      accountData,
    }: {
      codeData: CodeVerificationData;
      accountData: AccountInfoMinimal;
    }) => {
      await API('verifyCode', {
        method: 'POST',
        body: JSON.stringify(codeData),
      });
      return accountData;
    },
    onSuccess: (accountData) => {
      updateAccountInfo(accountData);
      setIsSubmitted(false);
      setCodeErrorMsg('');
    },
    onError: (error: any) => {
      let newValidationMsg = '';
      if (error.status === 403) {
        newValidationMsg = 'Security code did not match.';
      } else if (error.status === 500) {
        newValidationMsg = 'Server error. Please try again later.';
      } else {
        newValidationMsg = 'Security code did not match.';
      }
      setCodeErrorMsg(newValidationMsg);
    },
  });
};

export const useDoVerifyNewAccount = (setMessage: any, setIsSubmitted: any) => {
  const API = useAPI();
  return useMutation({
    mutationFn: async (data: NewAccountVerificationSendData) => {
      await API('verifyNewAccount', {
        method: 'POST',
        body: JSON.stringify(data),
      });
    },
    onSuccess: (data) => {
      setIsSubmitted(true);
      setMessage('');
    },
    onError: (err: Error) => {
      setMessage(err.message);
    },
  });
};

export const useDoSendEmailVerify = (
  setIsSubmitted: (arg0: boolean) => void
) => {
  const API = useAPI();
  return useMutation({
    mutationFn: async (data: EmailVerificationSendData) => {
      await API('sendVerifyEmail', {
        method: 'POST',
        body: JSON.stringify(data),
      });
    },
    onSuccess: (data) => {
      setIsSubmitted(true);
    },
    onError: (err: Error) => {
      Toast.error(err.message);
    },
  });
};

export const useDoSendPhoneVerify = (
  setIsSubmitted: (arg0: boolean) => void
) => {
  const API = useAPI();
  return useMutation({
    mutationFn: async (data: PhoneVerificationSendData) => {
      await API('sendVerifyPhone', {
        method: 'POST',
        body: JSON.stringify(data),
      });
    },
    onSuccess: (data) => {
      setIsSubmitted(true);
    },
    onError: (err: Error) => {
      Toast.error(err.message);
    },
  });
};
