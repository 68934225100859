import React from 'react';
import styled from '@emotion/styled';
import { UserInfoProps } from './types';

import { formatDate } from 'utils';

const UserInfo: React.FC<UserInfoProps> = (props) => {
  return (
    <>
      <UserInfoContainer>
        {props.userInfo.createdAt ? (
          <AboutUs>Member since {formatDate(props.userInfo.createdAt)}</AboutUs>
        ) : (
          <></>
        )}
        <AboutUs>Location: United States</AboutUs>
      </UserInfoContainer>
    </>
  );
};

const UserInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap; /* Allows tabs to wrap in smaller screens */
  margin-top: 20px;
`;

const AboutUs = styled.div`
  margin: 5px 0;
  font-size: 0.8rem;
`;

export default UserInfo;
