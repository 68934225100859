import { useNavigate } from 'react-router-dom';

import { Breadcrumbs } from 'components/breadcrumbs';
import {
  Container,
  Content,
  Divider,
  Header,
  Paragraph,
  Section,
  Title,
} from 'components/helpShared';
import { BlueLinkComponent } from 'components/links';
import { DAYS_MUST_SHIP_BY, PRODUCT_NAME } from 'config';

export const HowToSell = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <Breadcrumbs
        items={[
          { path: '/help', label: 'Help Home' },
          { path: '/help/sellerResources', label: 'Seller Resources' },
          {
            path: '/help/sellerResources/howToSell',
            label: 'How to Sell',
          },
        ]}
      />
      <Header>How to Sell</Header>
      <Divider />
      <Content>
        <Section>
          <Title>Requirements</Title>
          <Paragraph>
            To sell on {PRODUCT_NAME}, you must create an account and register a
            valid U.S. phone number and email address. Each of these will be
            verified via security code. You must also agree to the{' '}
            {PRODUCT_NAME}{' '}
            <BlueLinkComponent
              onClick={() => navigate('/sellerAgreement')}
              text='Seller Agreement'
              fontSize='16px'
            />
            .
          </Paragraph>
          <Title>Create a Listing</Title>
          <Paragraph>
            Create a new listing by clicking on your user avatar in the
            upper-right portion of the page and choosing "Create new listing".
            In the new listing page, you will specify a title, description,
            shipping options, and add images, among other required options. At
            least one image is required for every listing. Once you create the
            listing, it will immediately become live and searchable by anyone
            using {PRODUCT_NAME}.
          </Paragraph>
          <Title>Shipping an Item</Title>
          <Paragraph>
            After a buyer purchases your item, you must ship the item within{' '}
            {DAYS_MUST_SHIP_BY} business days. Please see the{' '}
            <BlueLinkComponent
              onClick={() => navigate('/help/sellerResources/shippingPolicy')}
              text='Shipping Policy'
              fontSize='16px'
            />{' '}
            for details and requirements regarding shipments.
          </Paragraph>
          <Title>Returns and Refunds</Title>
          <Paragraph>
            Returns must be accepted if you specify so when creating your
            listing. They must also be accepted if the item is damaged, faulty,
            or not as described. Please refer to the{' '}
            <BlueLinkComponent
              onClick={() => navigate('/help/returnsAndRefundsPolicy')}
              text='Returns and Refunds Policy'
              fontSize='16px'
            />{' '}
            for further details.
          </Paragraph>
          <Title>Feedback</Title>
          <Paragraph>
            After an order is settled, buyers and sellers may leave feedback for
            each other. We encourage you as a seller to leave feedback for all
            buyers as this serves as the primary mechanism for building and
            maintaining a reputation on {PRODUCT_NAME}.
          </Paragraph>
        </Section>
      </Content>
    </Container>
  );
};
