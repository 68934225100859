import { useEffect, useState } from 'react';
import { useLocation, useNavigate, Routes, Route } from 'react-router-dom';
import styled from '@emotion/styled';
import {
  AddressBookTabs,
  BellRinging,
  EnvelopeSimple,
  House,
  Mailbox,
  ShoppingCart,
  Star,
  Table,
  Tag,
  UserCheck,
  UserCircle,
} from '@phosphor-icons/react';

import { AccountHome, MyListings, MyOrders } from 'components/activity';
import { StyledIcon } from 'components/icons';
import { MessagingPortal } from 'components/messaging';
import { FeedbackTabs } from 'components/feedback';
import { OrderDetails } from 'components/orderDetails';
import { ManageListingOrder } from 'components/listingOrderManage';
import { NotificationSettings } from 'components/settings';
import { StripeAccountSetup } from 'components/stripeAccount';
import { W9TaxForm } from 'components/w9TaxForm';
import {
  EmailAndPassword,
  PersonalInfo,
  UsernameAndPicForm,
} from 'components/personal';
import { theme } from 'styles';

const AccountPage = () => {
  const [activeComponent, setActiveComponent] = useState('AccountHome');
  const [hoveredComponent, setHoveredComponent] = useState<string | null>(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const pathname = location.pathname;
    const suffix = pathname.slice('/myAccount/'.length);
    const component = suffix.split('/')[0];
    if (component) {
      setActiveComponent(
        component.charAt(0).toUpperCase() + component.slice(1)
      );
    }
  }, [location.pathname]);

  const getIconStatus = (component: string) => {
    if (component === activeComponent) return 'selected';
    if (component === hoveredComponent) return 'hover';
    return 'static';
  };

  const generateHandlers = (component: string) => ({
    onClick: () => {
      setActiveComponent(component);
      navigate(`/myAccount/${component}`);
    },
    onMouseEnter: () => setHoveredComponent(component),
    onMouseLeave: () => setHoveredComponent(null),
    isActive: activeComponent === component,
  });

  const iconTheme = theme.icon.leftNav;

  return (
    <PageContainer>
      <Sidebar>
        <NavItem {...generateHandlers('AccountHome')}>
          <IconWrapper>
            <StyledIcon
              icon={House}
              theme={iconTheme}
              status={getIconStatus('AccountHome')}
            ></StyledIcon>
            <span>Account Home</span>
          </IconWrapper>
        </NavItem>
        <NavItem {...generateHandlers('Purchases')}>
          <IconWrapper>
            <StyledIcon
              icon={ShoppingCart}
              theme={iconTheme}
              status={getIconStatus('Purchases')}
            ></StyledIcon>
            <span>Purchases</span>
          </IconWrapper>
        </NavItem>
        <NavItem {...generateHandlers('Listings')}>
          <IconWrapper>
            <StyledIcon
              icon={Tag}
              theme={iconTheme}
              status={getIconStatus('Listings')}
            ></StyledIcon>
            <span>Listings</span>
          </IconWrapper>
        </NavItem>
        <NavItem {...generateHandlers('Messages')}>
          <IconWrapper>
            <StyledIcon
              icon={EnvelopeSimple}
              theme={iconTheme}
              status={getIconStatus('Messages')}
            ></StyledIcon>
            <span>Messages</span>
          </IconWrapper>
        </NavItem>
        <NavItem {...generateHandlers('Feedback')}>
          <IconWrapper>
            <StyledIcon
              icon={Star}
              theme={iconTheme}
              status={getIconStatus('Feedback')}
            ></StyledIcon>
            <span>Feedback</span>
          </IconWrapper>
        </NavItem>
        <NavItem {...generateHandlers('UsernamePic')}>
          <IconWrapper>
            <StyledIcon
              icon={UserCircle}
              theme={iconTheme}
              status={getIconStatus('UsernamePic')}
            ></StyledIcon>
            <span>Username & Profile Pic</span>
          </IconWrapper>
        </NavItem>
        <NavItem {...generateHandlers('EmailPassword')}>
          <IconWrapper>
            <StyledIcon
              icon={Mailbox}
              theme={iconTheme}
              status={getIconStatus('EmailPassword')}
            ></StyledIcon>
            <span>Email & Password</span>
          </IconWrapper>
        </NavItem>
        <NavItem {...generateHandlers('PersonalInfo')}>
          <IconWrapper>
            <StyledIcon
              icon={AddressBookTabs}
              theme={iconTheme}
              status={getIconStatus('PersonalInfo')}
            ></StyledIcon>
            <span>Personal Info</span>
          </IconWrapper>
        </NavItem>
        <NavItem {...generateHandlers('SellerSetup')}>
          <IconWrapper>
            <StyledIcon
              icon={UserCheck}
              theme={iconTheme}
              status={getIconStatus('SellerSetup')}
            ></StyledIcon>
            <span>Seller Setup</span>
          </IconWrapper>
        </NavItem>
        <NavItem {...generateHandlers('SellerForms')}>
          <IconWrapper>
            <StyledIcon
              icon={Table}
              theme={iconTheme}
              status={getIconStatus('SellerForms')}
            ></StyledIcon>
            <span>Seller Forms</span>
          </IconWrapper>
        </NavItem>
        {/* <NavItem {...generateHandlers('NotificationSettings')}>
          <IconWrapper>
            <StyledIcon
              icon={BellRinging}
              theme={iconTheme}
              status={getIconStatus('NotificationSettings')}
            ></StyledIcon>
            <span>Notification Settings</span>
          </IconWrapper>
        </NavItem> */}
      </Sidebar>
      <Content>
        <Routes>
          <Route path='AccountHome' element={<AccountHome />} />
          <Route path='Messages' element={<MessagingPortal />} />
          <Route
            path='Messages/:conversationId'
            element={<MessagingPortal />}
          />
          <Route path='Purchases' element={<MyOrders />} />
          <Route path='Purchases/:listingId' element={<OrderDetails />} />
          <Route path='Listings' element={<MyListings />} />
          <Route path='Listings/:listingId' element={<ManageListingOrder />} />
          <Route path='Feedback' element={<FeedbackTabs />} />
          <Route path='EmailPassword' element={<EmailAndPassword />} />
          <Route path='PersonalInfo' element={<PersonalInfo />} />
          <Route path='UsernamePic' element={<UsernameAndPicForm />} />
          <Route path='SellerForms' element={<W9TaxForm />} />
          <Route path='SellerSetup' element={<StripeAccountSetup />} />
          <Route
            path='NotificationSettings'
            element={<NotificationSettings />}
          />
        </Routes>
      </Content>
    </PageContainer>
  );
};

const PageContainer = styled.div`
  display: flex;
  max-width: 1300px;
  min-height: 100vh;
  margin: 0px auto 0 auto;
`;

const Sidebar = styled.div`
  width: 280px;
  padding: 20px;
  border-right: 1px solid ${(props) => props.theme.colors.border.primary};
`;

const NavItem = styled.button<{ isActive: boolean }>`
  background: ${(props) =>
    props.isActive ? props.theme.colors.background.focus : 'none'};
  cursor: pointer;
  padding: 10px;
  width: 100%;
  font-size: ${(props) => props.theme.fonts.leftNav.size};
  font-weight: ${(props) => props.theme.fonts.leftNav.weight};
  color: ${(props) =>
    props.isActive
      ? props.theme.colors.primary
      : props.theme.fonts.leftNav.color};

  text-align: left;
  border: 3px solid transparent;
  border-left: ${(props) =>
    props.isActive
      ? `3px solid ${props.theme.colors.primary}`
      : '3px solid transparent'};

  &:hover {
    background-color: ${(props) =>
      !props.isActive && props.theme.colors.secondary_hover};
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  span {
    margin-left: 15px;
  }
`;

const Content = styled.div`
  flex: 1;
  padding: 28px;
  width: 1000px;
`;

export default AccountPage;
