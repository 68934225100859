export const formatNumber = (value: string | number): string => {
  let num: number;

  if (typeof value === 'string') {
    num = parseFloat(value);
    if (isNaN(num)) {
      throw new Error('Invalid number string');
    }
  } else if (typeof value === 'number') {
    num = value;
  } else {
    throw new Error('Invalid input type');
  }

  return num.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const formatShippingCost = (value: string | number): string => {
  const numberValue = formatNumber(value);
  if (numberValue === '0.00') {
    return 'Free';
  }
  return `$${numberValue}`;
};
