import React from 'react';
import styled from '@emotion/styled';

import {
  COMPANY_NAME_OFFICIAL,
  DAYS_MUST_SHIP_BY,
  PRODUCT_DOMAIN,
  PRODUCT_NAME,
  PRODUCT_NAME_FULL,
  SELLER_AGREEMENT_DATE,
} from 'config';

export const SellerAgreementPage: React.FC = () => {
  return (
    <Container>
      <Header>Seller Agreement</Header>
      <Updated>
        This document is effective as of {SELLER_AGREEMENT_DATE}.
      </Updated>
      <Section>
        <Paragraph>
          To sell items on the {PRODUCT_NAME_FULL} (the “Site”), you, as a
          “seller,” must agree to this Marketplace Seller Agreement
          ("Agreement"), which supplements our
          <a href='/terms' target='_blank' rel='noopener noreferrer'>
            Terms of Service
          </a>
          . By listing or purchasing items on the Site, you acknowledge and
          accept all terms and conditions outlined in this Agreement.
          <br />
          <br />
          The Site is managed by {COMPANY_NAME_OFFICIAL} (“
          {PRODUCT_NAME},” “we,” or “us”), which retains the right to modify
          this Agreement at any time. Modifications become effective once posted
          on the Site. Your continued selling activity on the Site signifies
          your acceptance of the updated Agreement. It is your responsibility to
          review any changes and determine whether you wish to continue using
          the Site. Amendments to the marketplace seller agreement may be made
          without prior notification to you.
        </Paragraph>
      </Section>
      <Section>
        <Title>The Marketplace</Title>
        <Paragraph>
          {PRODUCT_NAME} offers an online platform that enables third-party
          sellers to sell their products to buyers (the “Marketplace”). Except
          for the seller's designation of {PRODUCT_NAME} as a limited payments
          agent (as detailed in the “Payments” section below), {PRODUCT_NAME}
          does not serve as an agent for either buyers or sellers. Our service
          simply facilitates the platform where these transactions occur
        </Paragraph>
      </Section>
      <Section>
        <Title>Account Setup</Title>
        <Paragraph>
          To fully utilize the selling features on the Marketplace, you must log
          in with an existing account or complete the Site registration process
          to create an account with a username and password (the “Account”).
          Your password must not be shared with anyone except authorized
          employees (if you are a retail business or have a Pro Seller by
          {PRODUCT_NAME} account with user roles). You are required to provide
          accurate, up-to-date, and complete information, and you must update
          this information promptly to ensure its accuracy and completeness. Any
          activity on the Site conducted through your Account will be considered
          as performed by you. {PRODUCT_NAME} has the right to rely on the
          contact and other details provided through your Account. Your Account
          is non-transferable and cannot be assigned to another party.
        </Paragraph>
      </Section>
      <Section>
        <Title>Eligibility Requirements</Title>
        <Paragraph>
          Listing items for sale on the {PRODUCT_NAME_FULL} is restricted to
          individuals who can legally enter into a contract. Individuals under
          the age of eighteen are prohibited from buying or selling on the
          Marketplace. Additionally, anyone residing or working outside the
          United States is not permitted to buy or sell on the Marketplace. To
          list items for sale, you must provide your name, address, phone
          number, email address, payment details (including, but not limited to,
          Stripe account information), and any other information we may require.
          Sales proceeds will be paid out in United States dollars. If you
          receive a request to ship an item outside of the United States, you
          are required to report this to our Support Team, and we will take
          appropriate actions in accordance with our policies to ensure that
          you, as the seller, are made whole.
        </Paragraph>
      </Section>
      <Section>
        <Title>Marketplace Fees</Title>
        <Paragraph>
          For all items sold through the Marketplace, {PRODUCT_NAME} imposes a
          fee calculated as a percentage of the sale price. As the seller, you
          are responsible for this fee, which will be automatically deducted
          from the proceeds of your sale. Fees are subject to change. However,
          advance notice will be provided to all sellers prior to such a change.
          The fee applicable on the date of sale will govern the transaction.
          Please refer to the Fees page for the most current fee structure. By
          listing an item for sale on the Marketplace, you agree to pay the
          applicable fees and any associated collection costs in the event of
          non-payment. All fees are deducted from the sale proceeds before
          payouts are issued to sellers.
          <br />
          <br />
          You may not attempt to bypass the {PRODUCT_NAME} sales process or
          redirect
          {PRODUCT_NAME} customers to another website. This includes, but is not
          limited to, the use of marketing materials included in shipped orders,
          custom packing slips, links, and/or messages that encourage users to
          visit external websites or complete transactions elsewhere.
        </Paragraph>
      </Section>
      <Section>
        <Title>Required Communication</Title>
        <Paragraph>
          {PRODUCT_NAME} requires that you provide prompt support to buyers
          within the Marketplace; ensure that all communications are conducted
          through our online messaging center. When a buyer contacts you
          regarding an issue with their order, you must respond within 2
          business days. Failure to respond within this timeframe allows the
          buyer to escalate the issue to {PRODUCT_NAME} Customer Service for
          further assistance. Additionally, you are required to notify
          {PRODUCT_NAME} whenever you change the status of an order. When an
          order has been paid for for one of your listings, you agree to receive
          email reminders until you ship the item and mark it as shipped in the
          web application. Status changes may include, but are not limited to,
          canceling an order, issuing a refund, or informing a customer about a
          missing item from their order. When you mark an item as shipped,
          whether with or without tracking, an automatic email will be sent to
          the customer notifying them of the status update. Do not contact
          customers directly unless you are responding to a question, providing
          an order status update, or addressing a complaint. Marketing
          communications are strictly prohibited.
        </Paragraph>
      </Section>
      <Section>
        <Title>Shipping Expectations</Title>
        <Paragraph>
          All orders should be shipped to customers within 48 hours. Every order
          must arrive with the Buyer within the estimated delivery timeframe
          provided. You are obligated to adhere to our refund policy and address
          any issues a buyer may report regarding their order. If you fail to
          provide shipment notification within {DAYS_MUST_SHIP_BY} business
          days, we reserve the right to cancel the transaction and any related
          payment at our sole discretion. Untracked orders must be marked as
          “shipped,” while tracked orders must display movement through statuses
          such as “picked up,” “acceptance,” or “in transit.” Please review our
          Shipping Guidelines carefully. It is your responsibility to ensure the
          items in an order are delivered to the Buyer, and by not using
          Delivery Confirmation or Tracking, you forfeit the ability to win any
          disputes if the customer claims the package did not arrive. You also
          forfeit this ability if the customer claims non-delivery due to not
          following the Shipping Guidelines.
          <br />
          <br />
          All orders placed through {PRODUCT_DOMAIN} must be shipped via mail to
          buyers. Attempts to meet in person for transactions are prohibited.
        </Paragraph>
      </Section>
      <Section>
        <Title>Seller Restrictions</Title>
        <Paragraph>
          Sellers are prohibited from listing or selling items that infringe on
          intellectual property rights, or that are illegal, fake, counterfeit,
          or stolen. All items must be listed on the appropriate item page with
          an accurate and complete description of the product. Sellers are
          required to sell items at the price they have listed and must ensure
          that they have the right to sell the item, that it is in their
          possession, and that the description provided is accurate. Drop
          shipping or selling items that are not physically in the seller's
          possession is not allowed. Sellers must ship orders by the provided
          ship date and adhere to the {PRODUCT_NAME} Shipping Guidelines.
          Failure to meet these shipping requirements may result in the
          termination of your account.
          <br />
          <br />
          {PRODUCT_NAME} may impose limitations on seller accounts, restricting
          the number of transactions within a specified period, at its sole
          discretion. Marketplace transactions must occur between two different
          individuals, organizations, or entities. Sellers are strictly
          prohibited from purchasing items from their own accounts, whether
          through the same account or through any other accounts linked to them.
          Linked accounts include secondary buyer accounts associated with the
          same user or accounts owned by employees of the seller's business.
          Non-compliance with these guidelines can lead to the suspension or
          termination of your seller account(s).
          <br />
          <br />
          {PRODUCT_NAME} reserves the right to determine which products are
          offered for sale in the Marketplace, and products may be added or
          removed at any time without prior notice. Changes to item detail pages
          and the appearance of listings in the Marketplace can also occur
          without notice. Additionally, if you are listing presale items, you
          must comply with your publisher or distributor's restrictions and
          regulations when using our Marketplace.
          <br />
          <br />
          {PRODUCT_NAME} reserves the right to refuse service to anyone at any
          time for any reason.
        </Paragraph>
      </Section>
      <Section>
        <Title>Buyer Information</Title>
        <Paragraph>
          You are required to keep any buyer information you receive
          confidential and secure. Generally, your {PRODUCT_NAME} login
          information should only be shared with authorized employees, and those
          with access to your {PRODUCT_NAME} account must also ensure that both
          login credentials and buyer information remain secure. If you store
          buyer information outside of the {PRODUCT_NAME} Seller Portal, it must
          be securely stored to prevent unauthorized access.
          <br />
          <br />
          Additionally, you are prohibited from sharing, selling, renting, or
          distributing buyer information to any third party for purposes
          unrelated to the transaction conducted on the Site, including on
          social media platforms. Buyer information should only be used as
          necessary to fulfill the specific purposes for which it was provided
          (e.g., to complete the transaction). You may not use buyer information
          for marketing purposes.
        </Paragraph>
      </Section>
      <Section>
        <Title>Payments</Title>
        <Subtitle>
          Appointment of {PRODUCT_NAME} as Limited Payments Agent
        </Subtitle>
        <Paragraph>
          You appoint {PRODUCT_NAME} as your agent solely for the purpose of
          receiving, holding, and settling payments on your behalf for sales
          made in the Marketplace. As your agent, {PRODUCT_NAME} will collect
          payments from buyers on your behalf. You agree that any payment we
          receive from a buyer satisfies the buyer's obligation to you in the
          amount received, regardless of whether we actually disburse such
          payment to you. If we fail to make a payment to you as outlined in
          this Agreement, your recourse is solely against us and not the buyer,
          as the payment is considered made by the buyer to you when received by
          us. You acknowledge that, to the extent allowed by applicable
          third-party or governmental regulations, {PRODUCT_NAME} is not liable
          for your actions or omissions in accepting this appointment, and we
          disclaim any such liability.
        </Paragraph>
        <Subtitle>Receipt and Settlement of Funds</Subtitle>
        <Paragraph>
          Once a buyer completes a purchase, {PRODUCT_NAME} will typically
          initiate the settlement of your sales proceeds (minus any fees owed to
          us, taxes, shipping costs, and other applicable adjustments) to your
          designated banka account within 1 business day of being eligible for
          payout, subject to the “Risk of Loss” section below. See our
          <a
            href='/help/sellerResources/sellerTiers'
            target='_blank'
            rel='noopener noreferrer'
          >
            Seller Tiers
          </a>{' '}
          page for information on when payouts issues. In the event that a
          payment is made to you in error or if you receive funds to which you
          are not entitled at the time of disbursement, {PRODUCT_NAME} reserves
          the right to recover such amounts from you, including, but not limited
          to, initiating a charge to your bank account. Additionally,
          {PRODUCT_NAME}
          may offset any sums that are due, or are likely to become due, to
          {PRODUCT_NAME} against future funds pending settlement as per this
          Agreement.
        </Paragraph>
      </Section>
      <Section>
        <Title>Taxes</Title>
        <Paragraph>
          Sellers are responsible for collecting and remitting any taxes related
          to their transactions. While {PRODUCT_NAME} collects and remits sales
          tax for transactions in certain states based on our business
          requirements, there may still be instances where you, as a seller, are
          required to collect sales tax on sales into states where we do not
          automatically collect it. {PRODUCT_NAME} does not assume
          responsibility for determining when taxes should be applied and will
          not be held liable for the collection or non-collection of any taxes
          on such transactions.
        </Paragraph>
      </Section>
      <Section>
        <Title>Tax Documentation and Reporting Requirements</Title>
        <Paragraph>
          As a condition of receiving payouts for completed orders on the{' '}
          {PRODUCT_NAME_FULL}, all sellers are required to submit a completed
          W-9 tax form through the Site. Payouts will not be processed until the
          W-9 form has been submitted and verified. Sellers agree to provide
          accurate and complete information on the W-9 form and to update this
          information promptly if there are any changes.
        </Paragraph>
        <Paragraph>
          In accordance with U.S. tax regulations, {PRODUCT_NAME}
          will issue an IRS Form 1099-NEC or 1099-K, as applicable, to sellers
          whose gross sales exceed the relevant federal and state thresholds for
          the applicable tax year. Sellers are solely responsible for the
          accuracy of the information provided and for reporting any income
          received from sales on the Marketplace in their tax filings. The legal
          minimum for reporting may change, and sellers should consult the IRS
          guidelines or a tax professional to ensure compliance.
        </Paragraph>
        <Paragraph>
          By participating as a seller on the Marketplace, you acknowledge that{' '}
          {PRODUCT_NAME} may collect, store, and share your tax information with
          government authorities, as required by law. Failure to submit the
          required tax documentation or providing false or inaccurate
          information may result in the suspension of your account, withholding
          of payouts, or other actions as deemed necessary by
          {PRODUCT_NAME} to remain in compliance with applicable laws.
        </Paragraph>
      </Section>
      <Section>
        <Title>Return Policy</Title>
        <Paragraph>
          You agree to accept and process returns, refunds, and adjustments for
          your products in accordance with this Agreement and the {PRODUCT_NAME}
          Returns and Refund Policy as published on the Site at the time of the
          relevant order. We may notify customers that these policies apply to
          your products. Sellers are not allowed to charge restocking or refund
          processing fees when issuing a refund.
          <br />
          <br />
          You will calculate and determine the amounts for all refunds and
          adjustments, including any applicable taxes, shipping, handling, or
          other charges, using the functionality provided for your account. All
          such payments must be routed through {PRODUCT_NAME}, and we will
          disburse these payments to buyers, which may be done using the same
          payment method originally used for the purchase. You are responsible
          for reimbursing us for any amounts paid to buyers in this manner.
          <br />
          <br />
          We may offset these payments against any amounts {PRODUCT_NAME} owes
          to you under this Agreement, or we may seek reimbursement through
          other means authorized by this Agreement. You are required to promptly
          issue refunds and adjustments in accordance with the applicable
          {PRODUCT_NAME}
          Refund and Returns Policy and as necessary.
        </Paragraph>
      </Section>
      <Section>
        <Title>Termination</Title>
        <Paragraph>
          You may terminate your participation in the Marketplace at any time by
          providing written notice to {PRODUCT_NAME}. Similarly, {PRODUCT_NAME}
          reserves the right to terminate a user's participation in the
          Marketplace at its sole discretion, for any reason, with or without
          notice. {PRODUCT_NAME}
          also reserves the right to monitor user accounts to ensure compliance
          with policies and applicable laws, and may remove or edit any content
          provided by users. Any illegal or fraudulent activities may be
          reported to law enforcement or other relevant third parties. Upon
          termination, sellers are required to pay any outstanding fees incurred
          prior to the termination date, and any pending transactions will be
          canceled.
          <br />
          <br />
          Upon termination, {PRODUCT_NAME} reserves the right to withhold from
          any payments due to the seller an amount deemed sufficient to cover
          chargebacks, refunds, adjustments, or other payments made to buyers
          related to the seller's Marketplace transactions for a three-month
          period following termination. At the end of this three-month period,
          {PRODUCT_NAME} will disburse any remaining balance to the seller or,
          if necessary, seek reimbursement from the seller through any means
          authorized by this Agreement for any additional amounts required to
          cover chargebacks, refunds, adjustments, or other payments made to
          buyers.
        </Paragraph>
      </Section>
      <Section>
        <Title>Warranty</Title>
        <Paragraph>
          The {PRODUCT_NAME_FULL} is provided "as is" without any warranties.
          {PRODUCT_NAME} makes no expressed or implied warranties of any kind.
          While we strive to keep the Marketplace accessible 24 hours a day, 365
          days a year, we do not guarantee continuous availability.
          {PRODUCT_NAME} is not liable for any loss resulting from the
          Marketplace being unavailable. Additionally, {PRODUCT_NAME} is not
          liable for any loss resulting from errors in our software or in the
          software of third parties that we utilize.
        </Paragraph>
      </Section>
      <Section>
        <Title>Risk of Loss</Title>
        <Paragraph>
          We reserve the right to manage risks associated with providing our
          services to you. This may include actions such as imposing transaction
          limits, placing holds on your funds, instructing a payment service
          provider to hold your funds before disbursement, canceling or freezing
          settlement of proceeds, freezing your Account, or refusing service to
          anyone. Factors we consider when making these decisions include, but
          are not limited to, your selling history, seller performance, returns
          and cancellations, chargebacks, transaction value, your ability to
          process payments from your bank account, and buyer disputes. Any hold
          placed on your funds will be lifted once the issue that prompted the
          hold is resolved. Please note that our payment processors' holds and
          settlement procedures may cause delays in the disbursement of funds to
          your account, over which we have no control.
          <br />
          <br />
          Funds pending settlement to sellers will be held in a {PRODUCT_NAME}
          account until disbursement. Sellers acknowledge that they are not
          entitled to any interest or other compensation related to these
          pending funds, have no control over the account holding such funds,
          and cannot assign any interest in these accounts.
          <br />
          <br />
          If you fail to fulfill an order that abides by the provided order and
          shipping requirements, you assume the risk of fraud or loss for such
          orders. {PRODUCT_NAME} reserves the right to seek reimbursement from
          you if we, at our sole discretion, choose to reimburse the buyer,
          issue a refund because you could not deliver the goods promptly,
          discover erroneous or duplicate transactions, or receive a chargeback
          from a buyer's credit card issuer for a purchase from you.
          {PRODUCT_NAME}
          may recover any amounts owed by you by deducting them from future
          payments, charging your bank account, or pursuing other lawful methods
          of reimbursement. You authorize us to use any or all of these methods
          to obtain reimbursement.
          <br />
          <br />
          In the event that a buyer files a chargeback with their payment
          institution, {PRODUCT_NAME} will notify you. You must decide within
          five (5) business days whether to accept or challenge the chargeback.
          Should you accept the dispute, the disputed amount will be deducted
          from your account balance, and {PRODUCT_NAME} may initiate measures to
          recover the merchandise from the buyer. If you opt to challenge the
          dispute, you must submit substantial and persuasive evidence within
          the provided time frame to substantiate your case. The final
          resolution will be determined by the buyer's payment institution, and{' '}
          {PRODUCT_NAME} does not influence or control the outcome of the
          chargeback process. {PRODUCT_NAME} will make reasonable efforts to
          support you throughout the process, including guidance on compiling
          the necessary evidence and facilitating any communication required
          with the payment institution. Our goal is to assist you in reaching a
          fair and equitable resolution, but we cannot guarantee the outcome
          decided by the payment institution.
          <br />
          <br />
          If a buyer initiates a chargeback through their payment provider,{' '}
          {PRODUCT_NAME} will deduct a $15 dispute fee from your account balance
          in addition to the disputed amount, should you lose the dispute. This
          fee covers administrative costs associated with processing the
          chargeback. If you successfully challenge the dispute and it is
          resolved in your favor, the $15 dispute fee will be refunded.
        </Paragraph>
      </Section>
      <Section>
        <Title>Indemnity/Limitation Of Liability</Title>
        <Paragraph>
          You agree to defend, indemnify, and hold harmless {PRODUCT_NAME} and
          its affiliates, along with their respective employees, directors,
          agents, and representatives, from and against any and all claims,
          costs, losses, damages, judgments, penalties, interest, and expenses
          (including reasonable attorneys' fees) arising from any claim, action,
          audit, investigation, inquiry, or other proceeding initiated by any
          person or entity that arises from or relates to: (i) any actual or
          alleged breach of your representations, warranties, or obligations as
          outlined in this Agreement; or (ii) your own website or other sales
          channels, the products you sell, any content you provide, the
          advertisement, offer, sale, or return of any products you sell, any
          actual or alleged infringement of intellectual property or proprietary
          rights by any products you sell or content you provide, or the
          collection, payment, or failure to collect or pay any taxes.
          <br />
          <br />
          {PRODUCT_NAME.toUpperCase()} SHALL NOT BE LIABLE FOR ANY DAMAGES OF
          ANY KIND, INCLUDING BUT NOT LIMITED TO DIRECT, INDIRECT, INCIDENTAL,
          PUNITIVE, AND CONSEQUENTIAL DAMAGES, ARISING OUT OF OR IN CONNECTION
          WITH THIS MARKETPLACE SELLER AGREEMENT, THE SITE, THE INABILITY TO USE
          THE SITE OR THE MARKETPLACE, OR ANY GOODS OR SERVICES PURCHASED OR
          OBTAINED, MESSAGES RECEIVED, OR TRANSACTIONS ENTERED INTO THROUGH THE
          SITE.
        </Paragraph>
      </Section>
      <Section>
        <Title>Disputes</Title>
        <Paragraph>
          You agree to release {PRODUCT_NAME} from any claims, demands, and
          damages arising from transaction disputes with buyers. In the event of
          a dispute, buyers and sellers are expected to collaborate directly to
          reach a resolution. There may be occasions when {PRODUCT_NAME}
          Customer Service steps in to assist with disputes between buyers and
          sellers on our platform. During this process, {PRODUCT_NAME} Customer
          Service may issue full or partial refunds at their discretion to help
          resolve conflicts. All refund decisions are made solely by the
          Customer Service Team.
        </Paragraph>
      </Section>
      <Section>
        <Title>Jurisdiction</Title>
        <Paragraph>
          Any claim against {PRODUCT_NAME} related to the Marketplace must be
          resolved in a court located in San Francisco, California, and you
          agree to the exclusive jurisdiction of these courts. This Agreement
          shall be governed by the laws of San Francisco, without consideration
          of its conflict of laws principles.
        </Paragraph>
      </Section>
      <Section>
        <Title>Content Agreement</Title>
        <Paragraph>
          By accepting this Agreement, you grant us the right to use any content
          you submit to us on a royalty-free basis. You affirm that you either
          own the content you submit or have the necessary rights to distribute
          it. You will be held responsible for any violations of rights related
          to unauthorized content.
        </Paragraph>
      </Section>
      <Section>
        <Title>Severability</Title>
        <Paragraph>
          If any part of this Agreement is found to be unlawful, void, or
          unenforceable, that portion will be considered severable, and the rest
          of the Agreement will remain in effect. {PRODUCT_NAME}'s failure to
          enforce any section of this Agreement does not waive its right to
          enforce that section or any other section in the future.
        </Paragraph>
      </Section>
      <Section>
        <Title>Enforcement</Title>
        <Paragraph>
          Non-compliance with the guidelines set forth in this Agreement, as
          well as those in any other referenced agreements, may lead to the
          suspension or termination of your {PRODUCT_NAME} account, at
          {PRODUCT_NAME}'s sole discretion.
        </Paragraph>
      </Section>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  padding: 20px;
  max-width: 800px;
  margin: auto;
  margin-bottom: 40px;
  gap: 20px;
`;

const Header = styled.h1`
  margin-bottom: 0;
`;

const Section = styled.section``;

const Title = styled.h2`
  font-size: 26px;
  margin: 0;
`;

const Subtitle = styled.h3`
  font-size: 20px;
`;

const Paragraph = styled.p`
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 10px;
`;

const Updated = styled.p`
  font-size: 14px;
  margin: 0;
`;
