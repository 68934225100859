import styled from '@emotion/styled';

import { OrderStatuses } from 'types';

interface OrderStatusProps {
  status: string;
  size: 'small' | 'large';
  isActive: boolean;
}

const getStatusColor = (status: string, isActive?: boolean) => {
  switch (true) {
    case isActive === true:
      return 'lightgreen';
    case isActive === false && !status:
      return 'lightgrey';
    case status === OrderStatuses.OrderCaptured:
      return '#ffce7f';
    case status === OrderStatuses.Shipped:
      return '#92e5c2';
    case status === OrderStatuses.Received:
      return '#287a27';
    case status === OrderStatuses.Returning:
      return '#c68975';
    case status === OrderStatuses.Refunded:
      return '#b493f6';
    case status === OrderStatuses.Settled:
      return '#93c4f6';
    default:
      return 'white';
  }
};

const statusMap = (status: string, isActive?: boolean) => {
  switch (true) {
    case isActive === true:
      return 'Active';
    case isActive === false && !status:
      return 'Inactive';
    case status === OrderStatuses.OrderCaptured:
      return 'Awaiting Shipment';
    case status === OrderStatuses.Shipped:
      return 'Shipped';
    case status === OrderStatuses.Received:
      return 'Received';
    case status === OrderStatuses.Returning:
      return 'Returning';
    case status === OrderStatuses.Refunded:
      return 'Refunded';
    case status === OrderStatuses.Settled:
      return 'Settled';
    default:
      return 'n/a';
  }
};

export const OrderStatus = ({ status, size, isActive }: OrderStatusProps) => {
  return (
    <StatusDiv status={status} size={size} isActive={isActive}>
      {size === 'large'
        ? `Status: ${statusMap(status, isActive)}`
        : `${statusMap(status, isActive)}`}
    </StatusDiv>
  );
};

const StatusDiv = styled.div<{
  status: string;
  size: 'small' | 'large';
  isActive?: boolean;
}>`
  padding: ${(props) => (props.size === 'small' ? '2px' : '6px')};
  font-size: ${(props) => (props.size === 'small' ? '14px' : '18px')};
  width: ${(props) => (props.size === 'small' ? '160px' : '280px')};
  text-align: center;
  color: black;
  background-color: ${(props) => getStatusColor(props.status, props.isActive)};
`;
