import { useEffect } from 'react';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import styled from '@emotion/styled';

import { Input } from 'components/input';
import { Button } from 'components/buttons';
import { Container, Form, Row, Header } from 'components/myAccount';
import {
  firstNameValidation,
  middleNameValidation,
  lastNameValidation,
} from 'components/validation';
import { NameFormInputs, PersonalFormProps } from './types';
import { useUpdateAccountInfo } from './hooks';

export const NameForm: React.FC<PersonalFormProps> = ({ data }) => {
  const { mutate: updateAccountInfo } = useUpdateAccountInfo();
  const methods = useForm<NameFormInputs>({
    defaultValues: {
      first_name: '',
      middle_name: '',
      last_name: '',
    },
  });

  useEffect(() => {
    methods.reset({
      first_name: data.first_name || '',
      middle_name: data.middle_name || '',
      last_name: data.last_name || '',
    });
  }, [data, methods]);

  // TODO: Figure out empty data thing. What to do in this situation?
  if (!data) {
    return <></>;
  }

  const handleFormSubmit: SubmitHandler<{
    first_name: string;
    middle_name: string;
    last_name: string;
  }> = (formData) => {
    const accountInfo = {
      user_id: data.user_id,
      email: data.email,
      phone: data.phone,
      username: data.username,
      first_name: formData.first_name,
      middle_name: formData.middle_name,
      last_name: formData.last_name,
      address_1: data.address_1,
      address_2: data.address_2,
      city: data.city,
      state: data.state,
      zip: data.zip,
      createdAt: data.createdAt,
    };
    updateAccountInfo(accountInfo);
    methods.reset(formData);
  };

  const inputWidth = '360px';

  return (
    <StyledContainer>
      <Header>Name</Header>
      <Content>
        <FormProvider {...methods}>
          <Form
            onSubmit={methods.handleSubmit(handleFormSubmit)}
            autoComplete='off'
          >
            <Row>
              <InputWrapper>
                <Input {...firstNameValidation} width={inputWidth} />
              </InputWrapper>
              <InputWrapper>
                <Input {...middleNameValidation} width={inputWidth} />
              </InputWrapper>
            </Row>
            <Row>
              <InputWrapper>
                <Input {...lastNameValidation} width={inputWidth} />
              </InputWrapper>
            </Row>
            <ButtonContainer>
              <Button
                width={'240px'}
                type='submit'
                disabled={!methods.formState.isDirty}
              >
                Update Name
              </Button>
            </ButtonContainer>
          </Form>
        </FormProvider>
      </Content>
    </StyledContainer>
  );
};

const StyledContainer = styled(Container)`
  margin-bottom: 0;
`;

const Content = styled.div`
  padding: 20px;
  border: 1px solid ${(props) => props.theme.colors.border.primary};
`;

const InputWrapper = styled.div`
  flex: 1;
  &:not(:last-child) {
    margin-right: 20px;
  }
`;

const ButtonContainer = styled.div`
  margin-top: 10px;
`;
