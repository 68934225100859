import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';

import { ListingRecord } from 'types';

export const RowListingCard = ({ listing }: { listing: ListingRecord }) => {
  const navigate = useNavigate();

  const handleNavigateToListing = (id: string) => {
    navigate(`/listing/${id}`);
  };

  return (
    <ListingItem onClick={() => handleNavigateToListing(listing.id)}>
      <SquareContainer>
        <Image src={listing.signedS3Url} alt={listing.title} />
      </SquareContainer>
      <TextArea>
        <Title>{listing.title}</Title>
      </TextArea>
    </ListingItem>
  );
};

const ListingItem = styled.div`
  width: 100%;
  max-width: 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`;

const SquareContainer = styled.div`
  width: 100%;
  padding-top: 100%;
  background-color: #eee;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
`;

const Image = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: contain;
  transform: translate(-50%, -50%);
`;

const TextArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 10px;
`;

const Title = styled.div`
  font-size: 14px;
  margin-bottom: 5px;
  overflow-wrap: break-word;
  text-align: left;
`;
